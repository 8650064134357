import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import { generateLog } from '@/helpers/functions';
import globalConfig from '@/helpers/globalConfig';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/devices';

export class Devices extends Model<IDevice> implements IDevice {

  id!: number | null;
  type!: string;
  serialNumber!: string;
  ip!: string;
  macEth!: string;
  macWifi!: string;
  firmware!: string;
  apk!: string;
  manufacturer!: string;
  model!: string;
  switchMac!: string;
  switchIp!: string;
  timeZone!: string;
  name!: string;
  locationId!: number | null;
  deviceTypeId!: number | null;
  deviceActionStatus: DeviceActionStatus = '';
  deviceConnectionCode!: string;
  rs232!: string;
  cacheEnabled!: boolean
  cacheSize!: number;
  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          id: 0,
          type: '',
          serialNumber: '',
          ip: '',
          mac: '',
          firmware: '',
          apk: '',
          manufacturer: '',
          model: '',
          switchMac: '',
          switchIp: '',
          locationId: null,
          name: '',
          timeZone: '',
          deviceActionStatus: '',
          deviceConnectionCode: '',
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }
}

export default class DevicesCollection extends Collection<Devices> {

  routeName = 'device'; // same as routers base name

  model(): Constructor<Devices> {
    return Devices;
  }

  url(): string {
    return `${globalConfig.url}/${globalConfig.path}/admin`; // nared še eno metodo za type
  }
  tenantPath(tenantId: string): string {
    return `${globalConfig.url}/${tenantId}/${globalConfig.path}`; // nared še eno metodo za type
  }

  fetchData(index: number | null, search: string | null, perpage: number | 10, filterQuery: string | null) {
    let apiUrl = `${globalConfig.url}/${globalConfig.path}/admin/devices?page=${index}&perPage=${perpage}&`

    if (search) {
      apiUrl = apiUrl + 'search='+search+'&'
    }
    if (filterQuery) {
      apiUrl = apiUrl + filterQuery+'&'
    }

    return axiosInstance.get(apiUrl, {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    })
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateProject(deviceId: number | null, previousDeviceTenant: any, updateDeviceTenant: any)
  {
    return axiosInstance.put(`${globalConfig.url}/${globalConfig.path}/admin/deviceUpdate`, {deviceId, previousDeviceTenant, updateDeviceTenant}, {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    })
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createNewDevice(tenantId: string, data: any) {
    console.log('path', `${this.tenantPath(tenantId)}/devices`)
    return axiosInstance.post(`${this.tenantPath(tenantId)}/devices`, data)
        .then((response) => response.data)
        .catch((e) => {
          generateLog({
            route: this.routeName,
            message: e,
          });
          throw e;
        })
  }

  deviceTypes(tenantId: string) {
    return axiosInstance.get(`${this.url()}/${tenantId}/device-types`)
      .then((response) => response.data)
      .catch((e) => {
        generateLog({
          route: this.routeName,
          message: e,
        });
        return e.response.data;
      })

  }

  delete(data: any) {
    return axiosInstance.post(`${globalConfig.url}/${globalConfig.path}/admin/devices/delete`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  export(data: any) {
    return axiosInstance.post(`${globalConfig.url}/${globalConfig.path}/admin/devices/export`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  import(data: any) {
    return axiosInstance.post(`${globalConfig.url}/${globalConfig.path}/admin/devices/import`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e;
      });
  }
}
