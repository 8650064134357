
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import stores from '@/stores';
// @ts-ignore
import LoginView from '@/views/LoginView.vue';
import TwoFactorAuthenticationView from '@/views/TwoFactorAuthenticationView.vue';
import HomeView from '@/views/HomeView.vue';
import { AxiosError } from 'axios';
import ModulesSkeleton from '@/modules/ModulesSkeleton.vue';
// @ts-ignore
import i18n from './i18n.js';
// main.js
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import {ensureInitialAdminData} from "@/helpers/adminInitialization";
import AdminView from "@/views/AdminView.vue";
import { bus } from './helpers/functions';



Vue.component('VueSlider', VueSlider);
Vue.directive('click-outside', {
  bind: function (el : any, binding : any, vnode : any) {
    el.clickOutsideEvent = function (event : any) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el : any) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

@Component({
  components: {
    AdminView,
    HomeView,
    TwoFactorAuthenticationView,
    LoginView,
    ModulesSkeleton,
  },
})
export default class App extends Vue {
  admin = stores.admin;
  connection = '';

  adminDataReady = false;

  async created() {
    await this.setUp();

    bus().$on('login', () => {
      console.log("Login!")
      this.setUp();
    });

bus().$on('after-logout', () => {
      this.adminDataReady = false;
      stores.admin.isOTPVerified = false;
    });

bus().$on('token-changed', () => {
      console.log("token-changed");
      if (stores.admin.hasToken) {
        this.setUp();
      } else {
        this.adminDataReady = false;
        this.$router.push({ name: 'login' });
      }
    });
  }

  async setUp() {
    await stores.master.getAllProjectsInfo()
      .then((response) => {
        if (stores.session.project) {
          // @ts-ignore
          stores.session.project = response.find(
            (project: IManagerProject) => project.id === stores.session.project!.id,
          );
          Vue.prototype.WebsiteTitle.updateWebsiteTitle();
        }
        this.setFavicon();
      })
      .catch((error) => console.log(error));
    ensureInitialAdminData().then(() => {
      this.$nextTick(() => {
        this.adminDataReady = true;
      });
    });
  }

  setFavicon() {
    const browserFavicon = document.getElementById('favicon')! as HTMLAnchorElement;
    if(browserFavicon && browserFavicon.href)
    {
      const project = stores.session.project;
      browserFavicon.href = (project && project.mobileLogo) ? require(project.mobileLogo) : require('../assets/nevron_logo.png');
    }
  }

}
