
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {SweetModal} from 'sweet-modal-vue';
    import axiosInstance from '../../helpers/axios';
    import session from '@/stores/Session';
    import stores from '@/stores';
    import {PanelElement} from '@/stores/Dashboard';
    import NevronInput from '@/components/NevronInput.vue';

    @Component({
        components: {
            SweetModal,
            NevronInput,
        },
    })
    export default class AddMenu extends Vue {
        @Prop()
        formName!: string;

        services: [] = [];
        selectedModule: any = null;
        action: string = '';
        name: string = '';

        mounted() {
            stores.modules.modules()
                .then((response) => {
                    this.services = response;
                });
        }

        setDisplayName() {
          // @ts-ignore
          this.name = Vue.prototype.translate(this.services.find((mod: any) => mod.id === this.selectedModule).name, this.services.find((mod: any) => mod.id === this.selectedModule).name);
        }

        cancel() {
            // @ts-ignore
            this.$children[0].close();
            this.$emit('close');
        }

        next() {
            const ele = new PanelElement({
                name: this.name,
                linkAction: this.action,
                active: true,
                linkModuleId: this.selectedModule,
                linkLayout: 'element',
            });
            this.$emit('next', ele);
            // @ts-ignore
            this.$children[0].close();
        }
    }
