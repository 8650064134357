
import {Vue, Component, Prop} from 'vue-property-decorator';
import TabManager from '@/mixins/TabManager';

import ItemsIndex from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import CategoriesIndex from '@/components/ListNevronComponents/Categories/IndexBasic.vue';
import CategoriesIndexMulti from '@/components/ShowNevronComponents/Categories/ShowMulti.vue';
import ModuleSettings from '@/modules/Modules/Update.vue';
import EpgsIndex from '@/modules/Epgs/Index.vue';

@Component({
  components: {
    ItemsIndex,
    CategoriesIndex,
    CategoriesIndexMulti,
    ModuleSettings,
    EpgsIndex,
  },
})
export default class ModuleIndex extends TabManager {
  @Prop() module!: IModule;

  get TAB_KEYS(): string[] {
    const keys = ['list'];
    if (this.hasConfiguration) keys.push('configuration');
    keys.push('settings');
    return keys;
  }

  get hasConfiguration() {
    const moduleTypes = ['VOD', 'SERIES', 'MOD', 'TV_RADIO', 'CATALOGUE', 'VIDEO', 'TV_APPLICATION', 'WEBSITE_APPLICATION', 'APPLICATION', 'AOD', 'SERVICE'];
    return moduleTypes.includes(this.module.type);
  }

  get configurationComponent() {
    if (!this.hasConfiguration) {
      return null;
    }
    const modulesWithMultiLevelCategories = ['CATALOGUE'];
    if (modulesWithMultiLevelCategories.includes(this.module.type)) {
      return CategoriesIndexMulti;
    }
    return CategoriesIndex;
  }

  translateTabTitle(tab: string) {
    const translations = {
      list: {
        default: 'list',
        WEATHER: 'weather',
        TIME: 'time-h',
        SERVICE: 'room-care-services',
        TV_RADIO: 'channel-list',
        SHIP_POSITION_APPLICATION: 'ship-list',
      },
      configuration: {
        default: 'configuration',
        TV_RADIO: 'channel-type',
        SERVICE: 'room-care-categories',
      },
      settings: {
        default: 'settings',
        TIME: 'time-settings',
        WEATHER: 'weather-settings',
        SERVICE: 'room-care-configuration',
        SHIP_POSITION_APPLICATION: 'ship-position-settings',
      },
    } as Record<string, { default: string; [key: string]: string }>;

    const translation =
      translations[tab][this.module.type] || translations[tab]['default'];
    return Vue.prototype.translate(translation);
  }
}

