  // @ts-ignore
import i18n from './../i18n.js';
import {Vue} from 'vue-property-decorator';

export function mergeLocalization(translatables: any) {
  let global;
  for (const key in translatables) {
    if (key) {
      global = key;
      let currentKey;
      const translatable = translatables[key];
      for (const keys in translatable) {
        if (keys) {
          // @ts-ignore
          currentKey = keys.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase());
          translatables[key][currentKey] = translatables[key][keys];
          if (currentKey !== keys) {
            Vue.delete(translatable, keys);
          }
        }
      }
      i18n.mergeLocaleMessage(split_at_index(global, 2), translatables[global]);
    }
  }
}

function split_at_index(value: any, index: any) {
  return value.substring(0, index) + '_' + value.substring(index);
}


