
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import NevronInput from '@/components/NevronInput.vue';
import axiosInstance from '@/helpers/axios';
import session from '@/stores/Session';
import Skeleton from '@/modules/Skeleton.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import _ from 'lodash';
import {goToModuleIndex} from "@/helpers/routing";

@Component({
  methods: {goToModuleIndex},
  components: {
    virtualList,
    NevronImageField,
    NevronHeader,
    NevronInput,
    Skeleton,
    NevronFooter,
    TableModalGeneric,
    NevronAdvanceTextBox,
  },
})
export default class CategoryDetails extends Vue {
  @Prop()
  module!: IModule;

  response: any = null;
  category: ICategories | null = null;
  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  fullResponse: any = null;
  dragOver: boolean = false;
  preLeave: boolean = false;
  moduleItems: any[] = [];

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.refresh(this.$route.params.id);
  }

  imageSelected(image: IMediaItem) {
    if (this.category) {
      this.category.imageId = (image) ? image.id : null;
    }
  }

  onSubmitForm() {
    const form = document.getElementById('update-basic-form') as HTMLFormElement;
    if (form.checkValidity()) {
      this.submitForm();
    } else {
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
    }
    form.classList.add('was-validated');
  }

  submitForm() {
    if (this.category) {
      return this.module.updateCategory!(this.category.id!, this.category)
        .then(async (response) => {
          if (response.responseCode === 200) {
            await this.refresh(this.$route.params.id);
            showToaster('success', Vue.prototype.translate(this.category?.name), 'successfully updated');
          } else {
            showToaster('danger', Vue.prototype.translate(this.category?.name), 'Fail to update');
          }
        })
        .catch((e: any) => {
          console.log(e);
          // @ts-ignore
          showToaster('danger', Vue.prototype.translate(this.category.name), 'Fail to update');
        });
    }
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  /**
   *
   */
  getHeaders() {
    return stores.File.getHeader();
  }

  /**
   *
   */
  transmitFinish(files: any, res: any) {
    this.preLeave = false;
    this.dragOver = false;
    this.attachWithImage({result: res});
  }

  attachWithImage(imageObj: any) {
    if (this.category) {
      if (imageObj) {
        this.category.image = imageObj.result;
        this.category.imageId = imageObj.result.id;
      } else {
        this.category.image = null;
        this.category.imageId = null;
      }
    }
  }

  addItemsToCategory(newItemsList: any) {
    if (this.category && this.category.children) {
      const childrenIds = this.category.children.map((item: any) => item.id);
      const uniqueNewItemsList = newItemsList.filter((item: any) => !childrenIds.includes(item.id));
      this.category.children = this.sortArrays(this.category.children.concat(uniqueNewItemsList));
    }
  }
  sortArrays(arrays:  any) {
    return _.orderBy(arrays, 'sort', 'asc');
  }

  deleteCategory() {
    if (this.category && this.category.id) {
      return this.module.deleteCategory!(this.category.id)
        .then((response: any) => {
          if (!response.success) {
            throw new Error(response.error.message);
          }
          showToaster('success', Vue.prototype.translate(this.category?.name), Vue.prototype.translate('successfully-deleted'));
          goToModuleIndex(this.module, {tab: 'configuration'});
        })
        .catch((error: any) => {
          showToaster('danger','',error);
        });
    }
  }

  detachItem(item: IItem) {
    this.category!.children = this.category!.children!.filter((el: any) => el !== item);
  }

  refresh(id: string) {
    return this.module.getSpecificCategory!(Number(id))
      .then((response) => {
        this.response = response;
        this.category = response.data;
        const pageName = `${Vue.prototype.translate('category')} ${Vue.prototype.translate(this.category?.name)}`;
        Vue.prototype.WebsiteTitle.setTranslatedPageName(pageName);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  mounted() {
    this.refresh(this.$route.params.id);
  }

  checkAll() {
    if (!this.allChecked) {
      this.indexList = Array.from(new Set([...this.indexList, ...this.itemIds]));
      this.allChecked = true;
    } else {
      this.indexList = this.indexList.filter((id) => !this.itemIds.includes(id));
      this.allChecked = false;
    }
  }

  updateCheck() {
    if (this.itemIds.every((id: number) => this.indexList.includes(id))) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  get itemIds(): number[] {
    return (this.category as ICategories).children!.map((item: IItem) => item.id);
  }

  openAttachModal() {
    // @ts-ignore
    this.$refs.attach.$children[0].open();
  }

  get activeDeleteButton(){
    return this.indexList.length > 0;
  }

  deleteItems(){
    if (this.category && this.category.children) {
        // Filter out children whose IDs are not in this.indexList
        this.category.children = this.category.children.filter(child => !this.indexList.includes(child.id));
    }
    this.indexList = [];
    this.allChecked = false;
  }
}
