
import { Vue, Component, Prop, Inject, Emit } from 'vue-property-decorator';
import ChatRecent from '@/modules/Chat/ChatRecent.vue';
import stores from '@/stores';
import session from '@/stores/Session';
import moment from 'moment'
import { bus } from '@/helpers/functions';

@Component({
    components: {
        ChatRecent,
    },
})
export default class ChatRecentTab extends Vue {
    @Prop({ default: false }) tabTitle!: string;
    @Prop({ default: false }) activeTab!: string;
    @Prop({ default: false }) activeChat!: any;

    recentChats: any = []
    unreadMessages: number | null = 0;
    $bus = bus()

    mounted() {


        let recentChats = stores.ChatMessageCollection.fetchRecentChats();

        recentChats.then((response: any) => {
            this.recentChats = response.data.chats;
            if(this.recentChats.length > 0)
            {
              this.$emit('recentActiveChatUpdate',this.recentChats[0]);
            }
        })

        let messagesSocket = stores.ChatMessageCollection.connectSocket(session.project!.id);
        messagesSocket.private('Personal.' + session.project!.id + '.Chats.Admin.' + stores.ChatMessageCollection.getChatAdminId())
            .listen('.chat.new.message', (socketMessage: any) => {

                let index = this.recentChats.findIndex((chat: any) => chat.id === socketMessage.chat.id);
                if (index !== -1) {
                    let recentchat = this.recentChats[index];
                    recentchat = socketMessage.chat;
                    this.recentChats.splice(index, 1);
                    this.recentChats.unshift(recentchat);
                }
                else {
                    this.recentChats.unshift(socketMessage.chat)
                }
                this.$emit('recentActiveChatUpdate',this.recentChats[0]);
                this.$bus.$emit('unread-messages', {
                    'unreadMessages': socketMessage.totalUnreadMessages
                });
            });


    }

    isActiveChat(chat: any) {
        if (this.activeChat != null) {
            return this.activeChat.id == chat.id;
        }
        return false;
    }

    @Emit("user-selected")
    updateSelectedUser(user: any) {
        return user;
    }

    @Emit("recentActiveChatUpdate")
    updateActiveChat(chat: any) {
        return chat;
    }
}
