
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';
import {showToaster} from "@/components/DashboardComposer/helpers";

@Component({
  components: {
    SweetModal,
  },
})

export default class CreateDevice extends Vue {

  @Prop({default: false})
  emitCreated!: boolean;

  device: IDevice = {
    apk: '',
    firmware: '',
    ip: '',
    locationId: 0,
    macEth: '',
    macWifi: '',
    serialNumber: '',
    timeZone: '',
    type: '',
    manufacturer: 'Nevron',
    id: null,
    model: '',
    switchMac: '',
    switchIp: '',
    name: '',
    deviceTypeId: 0,
    deviceActionStatus: '',
    deviceConnectionCode: '',
    rs232:'',
    cacheEnabled: false,
    cacheSize: 8
  };
  deviceTypes: IDeviceType[] = [];

  projects: any = [];
  selectedProjectId: any = null;

  macRegex = '^([0-9A-Fa-f]{2}[:\\-]){5}([0-9A-Fa-f]{2})$';

  mounted() {
    stores.master.getAllProjects()
      .then((response) => {
        this.projects = response;
      })
  }

  handleFormSubmit(): void {
    const form = this.$refs.deviceForm as HTMLFormElement;
    form.classList.add('was-validated');

    if(form.checkValidity()) {
      this.save();
    }
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();

    const form = this.$refs["deviceForm"] as HTMLFormElement;
    if(form) {
      form.reset();
      form.classList.remove('was-validated');
    }

    this.device = {
      apk: '',
      firmware: '',
      ip: '',
      locationId: 0,
      macEth: '',
      macWifi: '',
      serialNumber: '',
      timeZone: '',
      type: '',
      manufacturer: 'Nevron',
      id: null,
      model: '',
      switchMac: '',
      switchIp: '',
      name: '',
      deviceTypeId: 0,
      deviceActionStatus: '',
      deviceConnectionCode: '',
      rs232:'',
      cacheEnabled: false,
      cacheSize: 8
    };
  }

  @Watch('selectedProjectId')
  getDeviceTypes() {
    return stores.SuperAdminDevices.deviceTypes(this.selectedProjectId)
      .then((response) => {
        this.deviceTypes = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  save() {
    return stores.SuperAdminDevices.createNewDevice(this.selectedProjectId, this.device)
      .then((response) => {
        if (!response.success) {
          showToaster('danger', 'Device', response.error.message, response.responseCode);
        } else {
          this.cancel();
          this.$emit('created', response.data);
          showToaster('success', 'Device created successfully','');
        }
      })
      .catch((e) => {
        showToaster('danger',
          'Device could not be created',
          e.response.data.error.message,
          e.response.data.responseCode,
          true);
      });
  }

  isEthMacFieldRequired(): boolean {
    return !(!!this.device.serialNumber.trim() || !!this.device.macWifi.trim());
  }
  isWifiMacFieldRequired(): boolean {
    return !(!!this.device.macEth.trim() || !!this.device.serialNumber.trim());
  }
  isSerialMacFieldRequired(): boolean {
    return !(!!this.device.macEth.trim() || !!this.device.macWifi.trim());
  }
}
