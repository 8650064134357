
import { Component, Vue, Prop } from 'vue-property-decorator';
import WeatherLocationModule from '../../stores/modules/WeatherLocationModule';
import Countries from '@/modules/Countries.vue';
import { SweetModal } from 'sweet-modal-vue';
import { showToaster } from "@/components/DashboardComposer/helpers";
import {translate, enableSubmitButton, disableSubmitButton, resetForm} from "@/helpers/functions";
import _ from 'lodash';

const DEFAULT_WEATHER_LOCATION = {
  id: null,
  moduleId: null,
  city: null,
  country: null,
  latitude: null,
  longitude: null,
  defaultCity: false
};

@Component({
  components: {
    Countries,
    SweetModal,
  },
})
export default class CreateWeatherLocation extends Vue {
  @Prop()
  module!: WeatherLocationModule;

  newWeatherLocation = _.cloneDeep(DEFAULT_WEATHER_LOCATION);

  get formId() {
    return 'create-weather';
  }
  countrySelected(country: string) {
    this.newWeatherLocation.country = country;
  }

  reset() {
    this.newWeatherLocation = _.cloneDeep(DEFAULT_WEATHER_LOCATION);
    (this.$refs.countrySelector as Countries).resetSelected();
    resetForm(this.formId);
  }

  cancel() {
    this.reset();
    (this.$refs.create as any).close();
  }

  open() {
    enableSubmitButton(this.formId);
  }

  roundLat(val: string) {
    this.newWeatherLocation.latitude = parseFloat(val);
  }

  roundLong(val: string) {
    this.newWeatherLocation.longitude = parseFloat(val);
  }

  createItem() {
    disableSubmitButton(this.formId);
    return this.module.createNewItem(this.newWeatherLocation)
      .then((response: any) => {
        if (!response.success) {
          throw new Error(response.error.message);
        }
        showToaster('success', translate(this.newWeatherLocation.city), translate('successfully-created'))
        this.cancel();
        this.$emit('created', response.data);
      })
      .catch((error: any) => {
        enableSubmitButton(this.formId);
        showToaster('danger', '', error);
      });
  }

  mounted() {
    const mainForm = document.getElementById(this.formId) as HTMLFormElement;
    const forms = [mainForm];
    Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.createItem();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }
}
