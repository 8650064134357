
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';
import NevronInput from '@/components/NevronInput.vue';
import { modulesMetaData } from '@/helpers/constants';
import { translate } from '@/helpers/functions';
import MultiSelect from 'vue-multiselect';
import moment from 'moment';

@Component({
  components: {
    SweetModal,
    NevronInput,
    MultiSelect
  },
})

export default class ShowMessageLogPopup extends Vue {
  @Prop({ required: true })
  instance!: IModule;

  @Prop()
  metaKey!: string | null;

  @Prop()
  referenceKey!: string | null;

  @Prop()
  item!: any;

  @Prop()
  onlyName!: boolean;

  icons: any = [
    {
      name: 'mobile',
      classes: '',
      styles: '',
      path: '../../../img/mobile-b.svg',
      text: 'In-app-notification'
    },
    {
      name: 'tv',
      classes: '',
      styles: '',
      path: '../../../img/tv-b.svg',
      text: 'Pop-up'
    }
  ];

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }

  get tableMeta(): any {
    if (this.metaKey && this.instanceMeta[this.metaKey]) {
      return this.instanceMeta[this.metaKey];
    }
    return this.instanceMeta;
  }

  get fields(): any {
    if (!this.tableMeta.fields) {
      return this.instanceMeta.fields;
    }
    return this.tableMeta.fields;
  }

  get readAt(): string{
    if(this.item?.readAt && this.item?.readAt !== ''){
      // Parse the date string into a Moment object
      const date = moment(this.item?.readAt);

      // Format the date and return
      return date.format('DD.MM.YYYY [at] HH:mm');
    }
    return ''
  }

  cancel() {
    // @ts-ignore
    this.$refs.editPopup.close();
  }

  update() {
    this.$emit('updateItem', this.item);
    this.cancel();
  }

  openModelTranslator(localText: any, localKey: any, referenceKey: any) {
    this.$emit('openTranslators', localText, localKey, referenceKey);
  }

  showIcon(displayedOn: any) {
    let path = null;
    this.icons.find((icon: any) => {
      if (icon.name == displayedOn) {
        path = icon.path;
      }
    });
    return path;
  }

  getIconText(displayedOn: any) {
    let text = '';
    this.icons.find((icon: any) => {
      if (icon.name == displayedOn) {
        text = icon.text;
      }
    });
    return text;
  }
}
