
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import axiosInstance from '../../helpers/axios';
import {Setting} from '@/stores/Setting';
import Skeleton from '@/modules/Skeleton.vue';

@Component({
  components: {
    NevronHeader,
    Skeleton,
  },
})
export default class MatomoSetting extends Vue {
  matomoId: ISetting = new Setting({'key': 'matomo_id'});
  matomoUrl: ISetting = new Setting({'key': 'matomo_url'});
  matomoAgentUrl: ISetting = new Setting({'key': 'matomo_agent_url'});
  reportMail: ISetting = new Setting({'key': 'report_mail'});
  // enable disable
  autoReport: ISetting = new Setting({'key': 'auto_report'});
  reportMonthDay: ISetting = new Setting({'key': 'report_month_day'});
  // general tv report
  dateFrom: any = null;
  dateTo: any = null;
  reportCategory: any = 'all';
  reportErrors: any = [];
  reportSuccess: any = {
    message: '',
    result: false,
  };
  reportWait: boolean = false;
  accounts: IAccount[] = [];
  // user tv report data
  accountDateFrom: any = null;
  accountDateTo: any = null;
  accountReportErrors: any = [];
  accountReportSuccess: any = {
    message: '',
    result: false,
  };
  accountReportWait: boolean = false;
  selectedAccount: any = 'all';
  accountReportCategory: any = 'all';
  defaultDate: any = '';

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('analytic-configuration');
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
      });

    stores.accounts.fetchData(1, '', 99999999999999)
      .then((response) => {
        // console.log("Account", response);
        this.accounts = response.data;
      });
    const now = new Date();
    this.dateTo = now.toLocaleDateString('en-CA');
    this.dateFrom = now.toLocaleDateString('en-CA');
    this.accountDateTo = now.toLocaleDateString('en-CA');
    this.accountDateFrom = now.toLocaleDateString('en-CA');
  }

  // load settings
  settings(data: ISetting[]) {
      // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'matomo_site_id') {
        this.matomoId = data[index];
      } else if (data[index].key === 'matomo_url') {
        this.matomoUrl = data[index];
      } else if (data[index].key === 'matomo_agent_url') {
        this.matomoAgentUrl = data[index];
      } else if (data[index].key === 'matomo_report_mail') {
        this.reportMail = data[index];
      } else if (data[index].key === 'auto_report') {
        this.autoReport = data[index];
        this.autoReport.value = this.autoReport.value !== '0';
      } else if (data[index].key === 'report_month_day') {
        this.reportMonthDay = data[index];
      }
    }
  }

  // update single setting
  updateSetting(id: any, value: any) {
    stores.Setting.update(id, {value})
      .then((response) => {
        console.log('setting updated successfully..');
      });
  }

  // send tv channels report
  sendReport() {
    this.clearNotifications();
    this.reportWait = true;
    axiosInstance.post(`/matomo/report/${this.dateFrom}/${this.dateTo}?type=${this.reportCategory}`)
      .then((res) => {
        return res.data;
      })
      .then((res: any) => {
        console.log(res);
        this.reportWait = false;
        if (res.result) {
          this.reportSuccess = {
            message: `Email has been sent at "${this.reportMail.value}" email address`,
            result: true,
          };
        } else {
          this.reportErrors.push({
            name: res.message,
          });
        }
      }).catch((error) => {
      this.reportWait = false;
      this.reportErrors.push({
        name: 'something went wrong please try again later.',
      });
    });
  }

  // send user tv channels report
  sendAccountReport() {
    this.clearNotifications();
    this.accountReportWait = true;
    const url = `/matomo/accounts/report/${this.accountDateFrom}/${this.accountDateTo}?type=${this.accountReportCategory}&account=${this.selectedAccount}`;

    axiosInstance.post(url)
      .then((res) => {
        return res.data;
      })
      .then((res: any) => {
        console.log(res);
        this.accountReportWait = false;
        if (res.result) {
          this.accountReportSuccess = {
            message: `Email has been sent at "${this.reportMail.value}" email address`,
            result: true,
          };
        } else {
          this.accountReportErrors.push({
            name: res.message,
          });
        }
      }).catch((error) => {
      this.accountReportWait = false;
      this.accountReportErrors.push({
        name: 'something went wrong please try again later.',
      });
    });
  }

  // remove error alert
  removeError(key: any) {
    this.reportErrors = [];
    this.accountReportErrors = [];
  }

  // clear all notifications and alert
  clearNotifications() {
    this.reportErrors = [];
    this.accountReportErrors = [];
    this.reportSuccess.result = false;
    this.accountReportSuccess.result = false;
  }
}
