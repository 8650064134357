import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog, translate} from '@/helpers/functions';
import Axios from "axios";
import {showToaster} from "@/components/DashboardComposer/helpers";

let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL = '/currency-exchange';

export class CurrencyExchange extends Model<ICurrencyExchangeRates> implements ICurrencyExchangeRates {

  id: any = null;
  baseCurrency: ICurrency | null = null;
  exchangeRates: ICurrencyExchangeRate[] = [];

  constructor(attributes: Partial<ICurrencyExchangeRates>, parentCollection?: Collection) {
    super(attributes, parentCollection);
    Object.assign(this, attributes);
  }

  urlRoot(): string {
    return URL;
  }

}

export default class CurrencyExchangeCollection extends Collection<CurrencyExchange> {
  routeName = 'currency-exchange';

  url(): string {
    return URL;
  }

  model(): new (attributes: ICurrencyExchangeRates, collection?: Collection) => CurrencyExchange {
    return CurrencyExchange;
  }

  build(attributes: ICurrencyExchangeRates): CurrencyExchange {
    return new CurrencyExchange(attributes, this);
  }

  fetchData() {
    return axiosInstance.get(this.url())
      .then((response) => response.data.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        showToaster('danger', translate("currency-exchange-rates"), translate('currency-exchange-error-fetching-data'));
        return Promise.reject(e);
      });
  }

  fetchAllCurrencies() {
    return axiosInstance.get(`${this.url()}/currencies`)
      .then((response) => response.data.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        showToaster('danger', translate("currency-exchange-rates"), translate('currency-exchange-error-fetching-data'));
        return Promise.reject(e);
      });
  }

  store(currencyExchange: ICurrencyExchangeRates) {
    return axiosInstance.post(`${this.url()}`, currencyExchange)
      .then((response) => response.data.data)
      .catch(e => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        showToaster('danger', translate("currency-exchange-rates"), translate('currency-exchange-error-saving'));
        return Promise.reject(e);
      });
  }
}
