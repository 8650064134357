
import { Component, Vue, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import PerPageDropdown from '@/components/PerPageDropdown.vue';
import vPagination from '@/components/VuePlainPagination.vue';
import CreateDevice from '@/modules/SuperAdminDevices/Create.vue';
import NevronSearch from "@/components/NevronSearch.vue";
import MultiSelect from 'vue-multiselect';
import {showToaster} from "@/components/DashboardComposer/helpers";
import Filters from '@/modules/SuperAdminDevices/Filters.vue'
import globalConfig from "@/stores/GlobalConfig";
import SuperAdminPage from "@/components/partials/super-admin-page.vue";
import _ from "lodash";
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';

@Component({
  components: { SuperAdminPage, MultiSelect, NevronSearch, PerPageDropdown, vPagination, CreateDevice, Filters,NevronClickConfirm},
})
export default class SuperAdminDevices extends Vue {
  stores = stores;
  allChecked: boolean = false;
  devices: any | null = null;
  indexList: { [key: string]: number[] } = {};
  tenants: any;
  search: any = {
    query: null,
    current: null,
  };

  perPage: number = 20;
  config = stores.globalConfig.config;
  projects: any | null = null;
  selectDevice: any | null = null;
  firstLoad: boolean = true
  loading: boolean = false
  response : any  = null
  placeholder: any | null = '';
  selectLabel: any | null = '';
  deselectLabel: any | null = '';
  enableDeleteButton: boolean = false;
  enableExportButton: boolean = false;
  enableImportButton: boolean = true;
  projectTypesList: IProjectType[] = [];

  appliedFilters: any = {};
  filtersQuery: string | null = null;
  confirmMessage = "You are about to change the project assigned to the device. The displayed content will be deactivated on this device in the current project. Would you still like to proceed?";
  uploadResponse: any = '';

  get filters() {
    let filters =  {
      'environments': {
        type: 'checkbox',
        values: Object.keys(this.groupedProjectTypes),
      },
      'accomodation type': {
        type: 'checkbox',
        values: this.projectTypesList.map((projectType) => projectType.name),
      },
      'devices': {
        type: 'checkbox',
        values: ["STB", "SMART_TV", "Mobile", "PC", "Nevron_Stb"],
      },
      'status': {
        type: 'checkbox',
        values: ["online", "offline"],
      },
      'operators': {
        type: 'select',
        values: stores.SuperAdminOperators
      }
    }

    // For now, environments filters are disabled
    if (!globalConfig.config.showBetaFeatures) {
      delete filters.environments;
    }

    return filters;

  }

  get groupedProjectTypes() {
    const groups: { [key: string]: IProjectType[] } = {};
    this.projectTypesList.forEach((projectType) => {
      projectType.name = projectType.name.toLowerCase();
      projectType.group = projectType.group.toLowerCase();
      if (!groups[projectType.group]) {
        groups[projectType.group] = [];
      }
      groups[projectType.group].push(projectType);
    });
    return groups;
  }

  async mounted() {
    this.fetchProjects();
    this.projectTypesList = await stores.master.getProjectTypes() || [];
  }

  deleteButtonStatus() {
    this.enableDeleteButton = false;
    for (const index in this.indexList) {
      if (this.indexList[index].length > 0 && this.indexList[index].some(value => value)) {
        this.enableDeleteButton = true;
        return;
      }
    }
    return false;
  }
  exportButtonStatus() {
    this.enableExportButton = false;
    for (const index in this.indexList) {
      if (this.indexList[index].length > 0 && this.indexList[index].some(value => value)) {
        this.enableExportButton = true;
        return;
      }
    }
    return false;
  }

  fetchData(index = 1) {
    stores.SuperAdminDevices.fetchData(index, this.search.query, this.perPage, this.filtersQuery)
      .then((response: any) => {
        this.response = response
        this.devices = response.data;
        let $counter = 0;
        this.devices.forEach((device: any) => {
          device.search = false;
          device.project = $counter;
          device.previousTenant = device.tenant;
          if(!device.deviceType) {
            device.deviceType = {
              default: 'N/A'
            }
          }
          $counter++;
        });
      })
      .catch((e: any) => {
        console.log(e);
      }).finally(() => {
        this.loading = false;
      });
  }
  toggleDevice(device: any) {
    const deviceIdentificator = device.id + device.tenant + device.macEth;
    this.devices = this.devices.map((d: any) => {
      const dIdentificator = d.id + d.tenant + d.macEth;
      d.search = (deviceIdentificator === dIdentificator) ? !d.search : false;
      return d;
    });
  }
  fetchProjects() {
    stores.master.getAllProjects()
      .then((response: any) => {
        this.projects = response.map((project: any) => ({ id: project.id, name: project.name }));

        for (const index in response) {
          this.indexList[response[index].id] = [];
        }

        // load devices after projects loading
        this.fetchData();
      })
      .catch((e: any) => {
        console.log(e);
      })
  }
  @Watch('response.currentPage')
  pageChange() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.loading = true;
    this.fetchData(this.response.currentPage);
  }
  checkAll() {
    if (this.allChecked && this.devices) { // Updated condition here
      this.allChecked = false; // Resetting allChecked when unchecking all
      for (const index in this.indexList) {
        this.indexList[index] = [];
      }
    } else {
      for (const iterator of this.devices) {
        if (iterator.id) {
          // @ts-ignore
          this.indexList[iterator.tenant][iterator.id] = true;
        }
      }
      this.allChecked = true;
    }
    this.deleteButtonStatus();
    this.exportButtonStatus();
  }
  updateCheck() {
    if (this.devices && this.indexList.length === this.devices.length) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
    this.deleteButtonStatus();
    this.exportButtonStatus();
  }

  openModal() {
    //@ts-ignore
    this.$refs.modal[0].openModal();
  }

  handleChange(deviceId: any, previousTenantDevice: any, updateTenantDevice: any) {

    const oldTenant = previousTenantDevice;

    this.devices = this.devices.map((device: any) => {
      if (device.id === deviceId) {
        device.tenant = updateTenantDevice.id;
        device.previousTenant = updateTenantDevice.id;
        device.search = !device.search;
      }
      return device;
    });

    stores.SuperAdminDevices.updateProject(deviceId, oldTenant, updateTenantDevice.id)
      .then((response: any) => {
        if (!response.success) {
            showToaster('danger', response.error.message, '');
          } else {
            showToaster('success', 'Project changed successfully', '');
          }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  // Filters
  @Watch('appliedFilters', { immediate: true, deep: true })
  generateFiltersQuery() {
    let filters = this.handleEnvironmentsFilter(this.appliedFilters);
    if (!this.isValidFilter(filters)) {
      this.devices = [];
      return;
    }
    this.filtersQuery = Filters.generateFilterQuery(filters);
    this.fetchData(1);
  }
  updateFilters(appliedFilters: any) {
    for (let filter in appliedFilters) {
      this.appliedFilters = {
        ...this.appliedFilters,
        [filter]: appliedFilters[filter]
      }
    }
  }
  clearFilters() {
    this.appliedFilters = {};
    this.filtersQuery = null;
  }
  isValidFilter(filters:any): boolean {
    if (Filters.hasFilter(filters, 'environments') && !Filters.hasFilter(filters, 'accomodation type')) {
      return false;
    }
    return true;
  }
  handleEnvironmentsFilter(filters: any): any {
    if (!Filters.hasFilter(filters, 'environments')) {
      return filters;
    }
    let allEnvironmentAccommodationTypes: string[] = [];
    filters.environments.forEach((environment: string) => {
      let groupProjectTypes = this.groupedProjectTypes[environment];
      const projectAccommodationTypes: string[] = groupProjectTypes.map((projectType) => projectType.name);
      allEnvironmentAccommodationTypes = allEnvironmentAccommodationTypes.concat(projectAccommodationTypes);
    });

    let newAccommodationTypes = allEnvironmentAccommodationTypes;
    if (Filters.hasFilter(filters, 'accomodation type')) {
      newAccommodationTypes = allEnvironmentAccommodationTypes.filter((accomodationType: string) => {
        return filters['accomodation type'].includes(accomodationType);
      });
    }
    return {
      ...filters,
      'accomodation type':  _.uniq(newAccommodationTypes),
    }
  }

  deleteDevices(list: any) {
    const confirmDelete = confirm('Are you sure you want to delete the selected devices?');

    if (confirmDelete) {
      for (let index in list) {
        list[index] = Object.keys(list[index]);
      }
      stores.SuperAdminDevices.delete(list)
        .then((response) => {
          if (!response.success) {
            showToaster('danger', response.error.message, '');
          } else {
            showToaster('success', 'Devices deleted successfully', '');
            this.fetchData();
            this.enableDeleteButton = false;
            this.enableExportButton = false;
          }
        });
    } else {
      return;
    }
  }
  exportDevices(list: any) {
    for (let index in list) {
      list[index] = Object.keys(list[index]);
    }
    stores.SuperAdminDevices.export(list)
      .then((response) => {
        if (response.result === true) {
          const csv = response.data;
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = 'Nevron-devices.csv';
          anchor.click();
        }else{
          showToaster('danger', response.error.message, '');
        }
      });
  }
  importFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadResponse = e.target ? e.target.result : '';
    };
    reader.readAsText(file);
  }

  @Watch('uploadResponse')
  importDevices() {

    if(this.uploadResponse.length === 0) return
    let devicesCsv = {
      data : null,
      module_id : null,
    };
    devicesCsv.data = this.uploadResponse;
    stores.SuperAdminDevices.import(devicesCsv).then((response) => {
      console.log(response)
        if (response.data && response.responseCode && response.responseCode === 200) {
          showToaster('success', '', response.data,response.responseCode);
          this.fetchData(1);
          return
        }
        let error = response;
        console.log(error)
        if (error.response.status >= 400 && error.response.status < 500) {
          showToaster('danger', '', error.response.data.error.message , error.response.status);
          console.log(error);
          this.fetchData(1);
        }
      }).catch((e) => {
        console.error(e)
      })
    this.uploadResponse  = ''
  }
}
