
import { Component, Vue, Watch } from 'vue-property-decorator';
import stores from '../../stores';
import virtualList from 'vue-virtual-scroll-list';
import vPagination from '@/components/VuePlainPagination.vue';
import NevronHeader from '@/components/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import hexColorCode from '@/helpers/hexColorCode';
import getInitials from '@/helpers/initials';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
import _ from 'lodash';
import { showToaster } from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    virtualList,
    vPagination,
    NevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
    NevronSearchNoResults,
    NevronClickConfirm
  },
})

export default class CustomRolesList extends Vue {
  watchSkip = true;
  response: any = null;
  items = stores.roles;
  perpage = 10;

  search: any = {
    query: '',
    current: '',
  };

  searchItems = _.debounce(() => {this.fetchData(1, this.search.query, this.perpage); }, 400);

  /*
   * Watch for pagination
   */
  @Watch('response.currentPage')
  ChangedPage() {
    if (this.watchSkip) {
      this.watchSkip = false;
    } else {
      this.search.query = this.search.current;
      this.fetchData(this.response.currentPage, this.search.current, this.perpage);
    }
  }
  perPageChange() {
    this.fetchData(1, this.search.query, this.perpage);
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    // set search query from URL
    if (this.$route.query.search) {
      this.search.query = this.$route.query.search;
      this.search.current = this.$route.query.search;
    }

    // set page from URL
    let index = 1;
    if (this.$route.query.page) {
      index = Number(this.$route.query.page);
    }

    // load data
    this.fetchData(index, this.search.current, this.perpage);
  }

  refresh() {
    this.fetchData(null, null, 10);
  }
  /**
   * Fetches data on the specified page and with the specified query
   */
  fetchData(index: number | null, search: string | null, perpage: number | 10) {
    return stores.roles.getAllCustom(index, search, perpage)
      .then((response) => {
        // wrong page
        if (response.currentPage < 0) {
          this.fetchData(1, search, perpage);
        } else if (response.currentPage > response.lastPage) {
          this.fetchData(response.lastPage, search, perpage);
        } else {
          this.response = response;
          this.items = this.response.data;

          // TODO to je treba rešiti lepše
          // če je page=1 potem tega ne dodamo :)
          if (this.search.query === '') {
            this.$router.push({query: {page: String(this.response.currentPage)}});
          } else {
            this.$router.push({query: {page: String(this.response.currentPage), search: this.search.query}});
          }

          this.search.current = this.search.query;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  editItem(item: any,force: boolean) {    
    this.$router.push({
        name: `customRoles.show`,
        params: {id: String(item.id)},
    });      
  }
  deleteItem(item: any) {            
    return stores.roles.delete(item.id)
        .then((response) => {        
        if(response.success)
        {
          showToaster('success', item.name, Vue.prototype.translate('successfully-deleted'));
        }else{
          showToaster('danger', Vue.prototype.translate(item.name), response.error.message);  
        }        
        //Refresh the list
        this.refresh();
      }).catch((e: any) => {
        showToaster('danger', Vue.prototype.translate(item.name), e);
        console.log(e);
      });
  }  

  /*
   * Get hex color code from string
   */
  hexColorCode(str: string) {
    return hexColorCode(str);
  }

  /*
   * Get initials from role's name
   */
  getInitials(str: string) {
    return getInitials(str);
  }
}
