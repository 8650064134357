
import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';


@Component
export default class ChatContact extends Vue {
  @Prop({ default: {} }) contact!: IChatContact;
  @Prop(Number) id!: number; // Assuming id is the user ID
  @Prop(Number) activeIndex!: number | null;

  get fullName() {
    return Vue.prototype.translate(this.contact.firstName) + " " + Vue.prototype.translate(this.contact.lastName);
  }

  get isActive() {
    return this.activeIndex === this.id;
  }

  selectUser() {
    const selectedUserData = {
      id: this.contact.id,
      name: Vue.prototype.translate(this.contact.firstName) + ' ' + Vue.prototype.translate(this.contact.lastName),
      time: moment.now(),
      message: 'test'
    };
    this.$emit('user-selected', selectedUserData);
  }

  getFullTimeTooltip(time: string) {
    return time;
  }

  getElapsedTime(time: string) {
    return time;
  }
  getDefaultAvatarImg()
  {
    return "/img/avatar.png"
  }
}
