
import { Component, Vue, Prop } from 'vue-property-decorator';

import stores from '@/stores';
import RecorderModule from '@/stores/modules/Recorders';

@Component({
  components: {
  },
})

export default class CreateTVRecordersStream extends Vue {
  @Prop()
  module!: RecorderModule;

  stream: IStream = {
    id: null,
    description: '',
    stream: {
      source: '',
      quality: null,
      timeShift: false,
    },
    recordingTime: 0,
  };

  save() {
    return this.module.createRecorderStream(Number(this.$route.params.id), this.stream)
      .then((response) => {
        console.log('You have successfully created a new recorder');
        this.cancel();
      })
      .catch((e) => {
        console.log(e);
      });
  }
  cancel(): void {
    this.stream = {
    id: 0,
    description: '',
    stream: {
      source: '',
      quality: 'SD',
      timeShift: false,
    },
    recordingTime: 0,
    };
    this.$router.back();
  }
}
