
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import {Setting} from '@/stores/Setting';
import Skeleton from '@/modules/Skeleton.vue';

@Component({
  components: {
    NevronHeader,
    Skeleton,
  },
})
export default class TimeServices extends Vue {
  epgTime: ISetting = new Setting({'key': 'epg_time'});
  epgDayLimit: ISetting = new Setting({'key': 'epg_day_limit'});
  stayTime: ISetting = new Setting({'key': 'stay_time'});
  timeout: ISetting = new Setting({'key': 'timeout'});
  isEnabled: ISetting = new Setting({'key': 'is_channel_timeout'});
  reconnectStream: ISetting = new Setting({'key': 'reconnect_stream'});

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('time-services');
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
      });
  }

  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'epg_time') {
        this.epgTime = data[index];
      } else if (data[index].key === 'epg_day_limit') {
        this.epgDayLimit = data[index];
      } else if (data[index].key === 'stay_time') {
        this.stayTime = data[index];
      } else if (data[index].key === 'channel_timeout') {
        this.timeout = data[index];
      } else if (data[index].key === 'is_channel_timeout') {
        this.isEnabled = data[index];
        this.isEnabled.value = this.isEnabled.value !== '0';
      } else if (data[index].key === 'reconnect_stream') {
        this.reconnectStream = data[index];
        this.reconnectStream.value = this.reconnectStream.value !== '0';
      }
    }
  }

  updateSetting(id: any, value: any) {
    stores.Setting.update(id, {value})
      .then((response) => {
        console.log('setting updated successfully..');
      });
  }
}
