
import { Component, Vue, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import vPagination from '@/components/VuePlainPagination.vue';
import Axios from 'axios';
import NevronHeader from '@/components/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import AddKey from '@/modules/Translation/AddKey.vue';
import { Item } from '../../stores/modules/ItemsCollection';
import ExportOption from './ExportOption.vue';
import PerPageDropdown from '@/components/PerPageDropdown.vue';
import _ from 'lodash';

@Component({
  components: {
    vPagination,
    NevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
    NevronSearchNoResults,
    AddKey,
    ExportOption,
    PerPageDropdown,
  },
  filters: {
    strLimmit(str: string) {
      return str.substring(0, 22) + ( str.length > 20 ? '...' : '');
    },
  },
})

export default class LanguageList extends Vue {
  watchSkip = true;
  response: any = null;
  btnDisable: boolean = false;
  items = stores.Language;

  search: any = {
    query: '',
    current: '',
  };

  perpage = 20;
  indexList: any[] = [];
  allChecked = false;
  apiChecked = true;
  firstLoad = true;
  availableLanguages: any = [];
  file: any = '';
  uploadResponse: any = '';
  exportData: any = '';
  message: any = {
    import: '',
    export: '',
    message: '',
  };
  messageTimer: any = null;
  timer: any = 0;

  searchItems = _.debounce(() => {this.fetchData(1); }, 400);
  /*
   * Watch for pagination
   */
  @Watch('response.currentPage')
  ChangedPage() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.fetchData(this.response.currentPage);
  }
  @Watch('uploadResponse')
  importCsv() {
    console.log('file reader', this.uploadResponse);
    stores.Language.importTranslations(this.uploadResponse)
    .then((response: any) => {
        this.message.import = response.result;
        this.message.message = response.message;
        this.setMessageTimer();
        if (response.result === true) {
          this.refresh();
        }
    });
  }
  importFile(event: any) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadResponse = e.target ? e.target.result : '';
      };
      reader.readAsText(file);
  }
  exportCSV(options: any) {
    stores.Language.exportTranslations(options.name)
    .then((response: any) => {
      this.message.message = response.message;
      this.message.export = response.result;
      this.setMessageTimer();
    });
  }
  setMessageTimer() {
    clearTimeout(this.messageTimer);
    this.messageTimer = setTimeout(() => {
      this.message = {
        import: '',
        export: '',
        message: '',
      };
    }, 10000);
  }
  perPageChange() {
    this.fetchData(1);
  }
  handleNewKey(key: ITranslation) {
    stores.Language.createTranslation(key)
    .then((response: any) => {
      if (response && response.key) {
        this.$router.push({name: 'translation.show', params: {key: response.key}});
      }
    });
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('translations');
    // set search query from URL
    if (this.$route.query.search) {
        this.search.query = this.$route.query.search;
        this.search.current = this.$route.query.search;
    }

    // set page from URL
    let index = 1;
    if (this.$route.query.page) {
      index = Number(this.$route.query.page);
    }
    if (this.$route.query.perpage) {
      this.perpage = Number(this.$route.query.perpage);
    }
    // fetch the languaes which containes the translations for table column
    stores.Language.translationLanguages()
    .then((response: any) => {
      this.availableLanguages = response;
        // load data
      this.fetchData(index);
    });
  }

  /* removes or appends all items ids on current page to indexList */
  checkAll() {
    if (!this.allChecked) {
      this.indexList = Array.from(new Set([...this.indexList, ...this.itemIds]));
      this.allChecked = true;
    } else {
      this.indexList = this.indexList.filter((id) => !this.itemIds.includes(id));
      this.allChecked = false;
    }
  }
  /* when item is checked */
  updateCheck() {
      if (this.itemIds.every((id: any) => this.indexList.includes(id))) {
         this.allChecked = true;
      } else {
         this.allChecked = false;
      }
  }
  get itemIds(): any[] {
    // @ts-ignore
    return this.items.map((item: any) => item.key);
  }
  // Refersh page method
  refresh() {
    stores.Language.translationLanguages()
    .then((response: any) => {
      this.availableLanguages = response;
        // load data
      this.fetchData(1);
    });
  }

  /**
   * Fetches data on the specified page and with the specified query
   */
  fetchData(index: number | 1) {
    this.btnDisable = true;
    if (this.apiChecked) {
      this.apiChecked = false;
      return stores.Language.translationList(index, this.perpage, this.search.query)
        .then((response: any) => {
          this.btnDisable = false;
          this.response = response;
          if (this.response.data !== undefined) {
            this.items = this.response.data;
          }
          this.search.current = this.search.query;
          this.apiChecked = true;
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  openItem(itemId: string | number) {
    this.$router.push({name: 'translation.show', params: {key: String(itemId)}, query: {lastpage: String(this.response.currentPage), perpage: String(this.perpage)}});
  }
}
