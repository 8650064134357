
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import Edit from '@/modules/Epgs/Edit.vue';
import CreateEpg from './Create.vue';
import ListNevronHeader from '@/components/ListNevronComponents/ListNevronHeader.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    Skeleton,
    ListNevronHeader,
    NevronSearch,
    Edit,
    CreateEpg,
  },
})

export default class EpgSources extends Vue {
  epg = stores.epg;
  epgSource: any[] | null = null;
  selectedSource: IEpgProvider | null = null;
  response: any = null;

  search: any = {
    query: '',
    current: '',
  };

  file: any = '';
  uploadResponse: any = '';
  exportData: any = '';

  message: any = {
    import: '',
    export: '',
    message: '',
  };

  indexList: number[] = [];
  allChecked: boolean = false;

  mounted() {
    this.fetchEpgSource();
  }

  refresh() {
    this.fetchEpgSource();
  }

  fetchEpgSource() {
    this.epg.getEpgSources()
      .then((response) => {
        this.epgSource = response;
        this.response = response;
      });
  }

  selectEdit(source: IEpgProvider, e: any) {
    e.preventDefault();
    this.selectedSource = source;
    // @ts-ignore
    this.$refs.edit.$children[0].open();
  }

  checkAll() {
    if (!this.allChecked) {
      this.indexList = [];
      // @ts-ignore
      for (const iterator of this.epgSource) {
        if (iterator.id) {
          this.indexList.push(iterator.id);
        }
      }
      this.allChecked = true;
    } else {
      this.indexList = [];
      this.allChecked = false;
    }
  }

  updateCheck() {
    if (this.indexList.length === this.epgSource?.length) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  saveEmited(epgSource: IEpgProvider) {
    this.epg.updateEpgSource(epgSource)
      .then((response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(epgSource.url), 'successfully updated');
        } else {
          showToaster('danger',  Vue.prototype.translate(epgSource.url), 'Fail to update');
        }
        // @ts-ignore
        this.$refs.edit.$children[0].close();
        this.fetchEpgSource();
      });
    return true;
  }

  deleteSource(epgSource: IEpgProvider) {
    this.epg.deleteEpgSource(epgSource)
      .then((response) => {
        // @ts-ignore
        this.$refs.edit.$children[0].close();
        this.fetchEpgSource();
      });
    return true;
  }

  @Watch('uploadResponse')
  importCsv() {
    stores.epg.importEpg(this.uploadResponse, 'epg_providers')
      .then((response) => {
        this.message.import = response.result;
        this.message.message = response.message;
        if (response.success === true) {
          this.refresh();
          showToaster('success', '', 'Data imported successfully');
        } else {
          showToaster('danger', '', 'Failed to imported data');
        }
      });
  }

  exportCSV() {
    stores.epg.exportEpg('epg_providers')
      .then((response) => {
        this.exportData = response.data;
        if (response.result === true) {
          const csv = this.exportData;
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = 'NevronEpgSources.csv';
          anchor.click();
        }
        this.message.message = response.message;
        this.message.export = response.result;
      });
  }

  importFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadResponse = e.target ? e.target.result : '';
    };
    reader.readAsText(file);
  }

}
