
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SweetModal, SweetButton } from 'sweet-modal-vue';
import stores from "@/stores";
import SurveyQuestion from "./Question.vue";
import {  translate } from '@/helpers/functions';

@Component({
  components: {
    SweetModal,
    SurveyQuestion,
  },
})

export default class SurveyQuestionModal extends Vue {

  @Prop()
  questions!: ISurvey;
  @Prop()
  stat!: any | null;
  showAnswerResult = false;

  icons: any =
    {
      TV: '../../../../../img/tv-b.svg',
      MOBILE: '../../../../../img/mobile-b.svg',
      EMOTICON: '../../../../../img/my_mood-b.svg',
      CLOSE_ENDED: '../../../../../img/survey-b.svg',
    }

  cancel() {
    // @ts-ignore
    this.$refs.question.close();
  }

  get completed() {
    return this.stat.completedSurvey ?? 0;
  }

  get total() {
    return this.stat.totalSurvey ?? 0;
  }

  get seen() {
    return this.stat.totalSeen ?? 0;
  }

  exportQuestionsAsPdf() {
    // @ts-ignore
    const url = this.$router.resolve({name :'surveys.questions.export',params :{id: this.questions.id}})
    window.open(url.href, '_blank');
  }

}
