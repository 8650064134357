
// @ts-ignore
import Switches from "vue-switches";
// @ts-ignore
import { Component, Vue } from "vue-property-decorator";
import stores from "@/stores";
import NevronHeader from "@/components/NevronHeader.vue";
import { Setting}  from "@/stores/Setting";
import routerBase from "@/helpers/routerBase";
import Skeleton from "@/modules/Skeleton.vue";
import { showToaster } from '@/components/DashboardComposer/helpers';
import AttachImage from "@/modules/Media/Attach.vue";
import axiosInstance from "@/helpers/axios";
import TableModalGeneric from "@/components/NevronTableModalGeneric.vue";

@Component({
  components: {
    TableModalGeneric,
    AttachImage,
    NevronHeader,
    Skeleton,
    showToaster,
    Switches,
  },
})
export default class AppSetting extends Vue {
  apiUrl = `${stores.master.url}/${routerBase}`;
  sourceTypes: ISourcesType[] = [];
  authorizationTypes: IAuthorizationType[] = [];
  transcoder: ISetting = new Setting({ key: "transcoder", value: "" });
  recoderLink1: ISetting = new Setting({ key: "recoder_link1", value: "" });
  recoderLink2: ISetting = new Setting({ key: "recoder_link2", value: "" });
  recorderLink1Type: ISetting = new Setting({ key: "recoder_url_1_type", value: "" });
  recorderLink2Type: ISetting = new Setting({ key: "recoder_url_2_type", value: "" });
  recorderLink1AuthType: ISetting = new Setting({ key: "recoder_url_1_auth_type", value: "" });
  recorderLink1Auth: ISetting = new Setting({ key: "recoder_url_1_auth", value: "" });
  recorderLink2AuthType: ISetting = new Setting({ key: "recoder_url_2_auth_type", value: "" });
  recorderLink2Auth: ISetting = new Setting({ key: "recoder_url_2_auth", value: "" });
  clientUrl: ISetting = new Setting({ key: "client_url", value: "" });
  matomoUrl: ISetting = new Setting({ key: "matomo_url", value: "" });
  pwaUrl: ISetting = new Setting({ key: "pwa_url", value: "" });
  reservationId: ISetting = new Setting({ key: "reservation_id", value: "" });
  tenantPrefix: ISetting = new Setting({ key: "tenant_prefix", value: "" });
  attachImageTo: ISetting | null = null;
  // logo: ISetting = new Setting({'key': 'logo'});
  mobileLogo: ISetting = new Setting({'key': 'mobile_logo'});
  webDashboardStb: ISetting = new Setting({ key: "web_dashboard_stb", value: "" });
  webDashboardStbObject: IDashboard | null = null;

  videoPlayerLogo: ISetting = new Setting({ key: "video_player_logo", value: "" });
  videoPlayerLogoSwitch: ISetting = new Setting({ key: "video_player_logo_switch", value: "" });
  appNotificationSwitch: ISetting = new Setting({ key: "app_notification_switch", value: "" });
  videoPlayerLogoImageUrl: string = '';
  attachableContent: any = null;
  selectedFolderId: any = 1;
  contentUrl: any = '';
  logo: ISetting = new Setting();


  mlAndriodAppIdentifier: ISetting = new Setting({
    key: "ml_android_app_identifier",
    value: "",
  });
  mlLinkToAppStore: ISetting = new Setting({ key: "ml_link_to_app_store", value: "" });
  mlAppleAppIdentifier: ISetting = new Setting({
    key: "ml_apple_app_identifier",
    value: "",
  });
  is_cart_email_enabled: any = '';
  is_cart_room_number_enabled: any = '';
  is_cart_email_required: any = '';
  is_cart_room_number_required: any = '';
  mlLinkToPlayStore: ISetting = new Setting({ key: "ml_link_to_play_store", value: "" });
  cart_room_number_enabled: ISetting = new Setting({ key: "cart_room_number_enabled", value: "" });
  cart_room_number_required: ISetting = new Setting({ key: "cart_room_number_required", value: "" });
  cart_email_enabled: ISetting = new Setting({ key: "cart_email_enabled", value: "" });
  cart_email_required: ISetting = new Setting({ key: "cart_email_required", value: "" });
  login_pwa: ISetting = new Setting({ key: "login_pwa", value: "" });
  login_app: ISetting = new Setting({ key: "login_app", value: "" });
  app_banners: ISetting = new Setting({ key: "app_banners", value: "" });

  copied: any = {
    apiUrl: false,
    clientUrl: false,
    matomoUrl: false,
    pwaFullURL: false,
    webDashboardStbUrl: false,
  };

  srCharCount = 0;
  isSeriesRatingRefreshInProgress = false;

  stores = stores;


  get webDashboardStbUrl() {
    try {
      let clientUrl = new URL(this.clientUrl.value);
      let baseUrl = `${clientUrl.protocol}//${clientUrl.host}`;
      return `${baseUrl}/${stores.session.projectId}?dashboardId=${this.webDashboardStb.value}`;
    } catch (e) {
      return '';
    }
  }

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName("project-configuration");
    Promise.all([
      stores.modules.getVideoSourceTypes(),
      stores.modules.getAuthorizationTypes()
    ]).then(([videoSourceTypes, authorizationTypes]) => {
      this.sourceTypes = videoSourceTypes;
      this.authorizationTypes = authorizationTypes;
    });

    stores.Setting.getSettings().then((response: any) => {
      this.settings(response);
    });
  }

  makeUpperCase() {
    this.reservationId.value = this.reservationId.value.toUpperCase();
    this.srCharCount = this.reservationId.value.length;
  }
  change_cart_room_number_enabled(cart_room_number_enabled_id:any,cart_room_number_enabled_value:any) {
    this.is_cart_room_number_enabled = cart_room_number_enabled_value ? true : false;
    this.cart_room_number_required.value = cart_room_number_enabled_value ? this.is_cart_room_number_required : false;
    this.updateSetting(cart_room_number_enabled_id, cart_room_number_enabled_value, 0);
    this.updateSetting(this.cart_room_number_required.id, this.cart_room_number_required.value, 0);
  }
  change_cart_email_enabled(cart_email_enabled_id:any,cart_email_enabled_value: any) {
    this.is_cart_email_enabled = cart_email_enabled_value ? true : false;
    this.cart_email_required.value = cart_email_enabled_value ? this.is_cart_email_required : false;
    this.updateSetting(cart_email_enabled_id, cart_email_enabled_value, 0);
    this.updateSetting(this.cart_email_required.id, this.cart_email_required.value,0);

  }
  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === "transcoder_url") {
        this.transcoder = data[index];
      } else if (data[index].key === "recoder_url_1") {
        this.recoderLink1 = data[index];
      } else if (data[index].key === 'recoder_url_1_type') {
        this.recorderLink1Type = data[index];
      } else if (data[index].key === 'recoder_url_1_auth') {
        this.recorderLink1Auth = data[index];
      } else if (data[index].key === 'recoder_url_1_auth_type') {
        this.recorderLink1AuthType = data[index];
      } else if (data[index].key === "recoder_url_2") {
        this.recoderLink2 = data[index];
      } else if (data[index].key === 'recoder_url_2_type') {
        this.recorderLink2Type = data[index];
      } else if (data[index].key === 'recoder_url_2_auth') {
        this.recorderLink2Auth = data[index];
      } else if (data[index].key === 'recoder_url_2_auth_type') {
        this.recorderLink2AuthType = data[index];
      } else if (data[index].key === "client_url") {
        data[index].value += `/${routerBase}`;
        this.clientUrl = data[index];
      } else if (data[index].key === "matomo_url") {
        this.matomoUrl = data[index];
      } else if (data[index].key === "pwa_url") {
        this.pwaUrl = data[index];
      } else if (data[index].key === "tenant_prefix") {
        this.tenantPrefix = data[index];
      } else if (data[index].key === "video_player_logo") {
        this.videoPlayerLogo = data[index];
      } else if (data[index].key === "mobile_logo") {
        this.mobileLogo = data[index];
      } else if (data[index].key === "reservation_id") {
        this.reservationId = data[index];
        this.srCharCount = this.reservationId.value.length;
      } else if (data[index].key === "ml_apple_app_identifier") {
        this.mlAppleAppIdentifier = data[index];
      } else if (data[index].key === "ml_andriod_app_identifier") {
        this.mlAndriodAppIdentifier = data[index];
      } else if (data[index].key === "ml_link_to_app_store") {
        this.mlLinkToAppStore = data[index];
      } else if (data[index].key === "ml_link_to_play_store") {
        this.mlLinkToPlayStore = data[index];
      } else if (data[index].key === "cart_room_number_enabled") {
        this.cart_room_number_enabled = data[index];
        this.cart_room_number_enabled.value = this.cart_room_number_enabled.value != 0;
        this.is_cart_room_number_enabled = this.cart_room_number_enabled.value != 0;
      } else if (data[index].key === "cart_room_number_required") {
        this.cart_room_number_required = data[index];
        this.cart_room_number_required.value = this.cart_room_number_required.value != 0;
        this.is_cart_room_number_required = this.cart_room_number_required.value != 0;
      } else if (data[index].key === "cart_email_enabled") {
        this.cart_email_enabled = data[index];
        this.cart_email_enabled.value = this.cart_email_enabled.value != 0;
        this.is_cart_email_enabled=this.cart_email_enabled.value != 0;
      } else if (data[index].key === "cart_email_required") {
        this.cart_email_required = data[index];
        this.is_cart_email_required = this.cart_email_required.value != 0;
        this.cart_email_required.value = this.cart_email_required.value != 0;
      }else if (data[index].key === "login_app") {
        this.login_app = data[index];
        this.login_app.value = this.login_app.value != 0;
      }else if (data[index].key === "login_pwa") {
        this.login_pwa = data[index];
        this.login_pwa.value = this.login_pwa.value != 0;
      }
      else if (data[index].key === "app_banners") {
        this.app_banners = data[index];
        this.app_banners.value = this.app_banners.value != 0;
      }
      else if (data[index].key === "video_player_logo_switch") {
        this.videoPlayerLogoSwitch = data[index];
        this.videoPlayerLogoSwitch.value = this.videoPlayerLogoSwitch.value != 0;
      }
      else if (data[index].key === "app_notification_switch") {
        this.appNotificationSwitch = data[index];
        this.appNotificationSwitch.value = this.appNotificationSwitch.value != 0;
      } else if (data[index].key === "web_dashboard_stb") {
        this.webDashboardStb = data[index];
        if (this.webDashboardStb.value) {
          stores.dashboards.getDashboard(this.webDashboardStb.value)
            .then((response: any) => {
              this.webDashboardStbObject = response;
            });
        }
      }
    }

    if (this.videoPlayerLogo) {
      this.videoPlayerLogoImageUrl = this.videoPlayerLogo.value;
    }
  }
  updateSetting(id: any, value: any, settingType = 0) {
    stores.Setting.update(id, { value, settingType: settingType }).then(
      (response: any) => {
        if (response.success) {
          showToaster('success', '', Vue.prototype.translate('successfully-updated'));
        } else {
          showToaster('danger', 'Error:', response.error.message);
        }
      }
    );
  }

  copyText(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    // remove from selected
    for (let key in this.copied) {
      this.copied[key] = false;
    }

    if (text === this.apiUrl) {
      this.copied.apiUrl = true;
    } else if (text === this.clientUrl?.value) {
      this.copied.clientUrl = true;
    } else if (text === this.matomoUrl?.value) {
      this.copied.matomoUrl = true;
    } else if (text === this.pwaFullURL) {
      this.copied.pwaFullURL = true;
    } else if (text === this.webDashboardStbUrl) {
      this.copied.webDashboardStbUrl = true;
    }
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
      showToaster('success', '', Vue.prototype.translate('copied'));
    });
  }

  get pwaFullURL() {
    if(this.pwaUrl.value) {
      return `${this.pwaUrl.value}/${routerBase}/gf-dashboard`;
    } else {
      return '';
    }
  }

  updatingStayReservationId(id: any, value: any) {
    if (this.srCharCount >= 3) {
      this.updateSetting(id, value, 0);
    }
  }

  attachVideoPlayerLogoWithImage(res: any) {
    console.info(res);
    this.videoPlayerLogoImageUrl = res.result.imageUrl;
    // @ts-ignore
    this.$refs.videoPlayerLogo.$children[0].close();
    this.getVideoPlayerLogoImage(res.result.id);
  }

  getVideoPlayerLogoImage(id: number) {
    return stores.File.getImage(id)
      .then((response: any) => {
        this.logo = response.imageUrl;
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  getVideoPlayerLogoImages() {
    return stores.Folder.getFolder(1)
      .then((response: any) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(
          this.attachableContent.files,
        );
        // @ts-ignore
        this.$refs.videoPlayerLogo.$children[0].open();
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  sendVideoPlayerLogoReload(files: any, result: any) {
    this.videoPlayerLogoImageUrl = result.imageUrl;
    this.getVideoPlayerLogoImage(result.id);
  }

  folderRequest(res: any) {
    this.selectedFolderId = res.folderId;
    // @ts-ignore
    this.$refs.image.$children[0].close();
    // @ts-ignore
    this.$refs.create.$children[0].open();
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }
  getHeaders() {
    return stores.File.getHeader();
  }

  clearVideoPlayerLogo() {
    this.updateSetting(this.videoPlayerLogo.id, null, 0);
    this.videoPlayerLogoImageUrl = '';
    this.videoPlayerLogoSwitch.value = false;
  }

  attachWithImage(res: any) {
      this.updateMobileLogo(this.attachImageTo!, res.result.id);
  }

  fetchSettings() {
      stores.Setting.getSettings()
        .then((response :any) => {
          this.settings(response);
        });
    }

  updateMobileLogo(setting: ISetting, newValue: string | null) {
      const data = { value: newValue, type: setting.type,key:setting.key };
      console.log('before update perform data is here..', data);
      stores.Setting.update(setting.id, data)
        .then((response:any) => {
          this.fetchSettings();
        });
    }

    getImages(setting: ISetting) {
      this.attachImageTo = setting;
      // @ts-ignore
      this.$refs.image.$children[0].open();
    }

  sendReload(files: any, result: any, setting: ISetting) {
      this.updateMobileLogo(this.attachImageTo!, result.id);
    }

  attachDashboard(dashboard: IDashboard) {
    this.webDashboardStb.value = dashboard.id;
    this.webDashboardStbObject = dashboard;
  }


  refreshSeriesRatings() {
    this.isSeriesRatingRefreshInProgress = true;
    axiosInstance.get(`series/refresh-ratings`)
      .then(() => showToaster('success', '', Vue.prototype.translate('series-refresh-setting-in-queue')))
      .catch((e) => showToaster('danger', '', e.message))
      .finally(() => this.isSeriesRatingRefreshInProgress = false)
  }

}
