
import stores from '@/stores';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ChatContact from '@/components/Chat/ChatContact.vue';

@Component({
  components: {
    ChatContact,
  }
})
export default class ChatContactsList extends Vue {
  @Prop({default: false}) isStaffList!: boolean;
  CustomerContacts: IChatContact[] = [];
  activeUserIndex: number | null = null;

  stores = stores;

  mounted(){
    stores.ChatContact.getAllChatContacts(this.isStaffList).then((response: any) => {
      this.CustomerContacts = response.data;
    }).catch((e :any) => {
      console.log(e);
    });
  }

  handleUserSelected(user: any)  {
    this.activeUserIndex = user.id;
    this.$emit('user-selected', user);
  }
}
