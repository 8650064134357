
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
// @ts-ignore
import i18n from '../i18n';
import stores from '@/stores';
// @ts-ignore
import CKEditor from 'ckeditor4-vue';
@Component({
  components: {
    DynamicContent,
    ckeditor: CKEditor.component,
  },
})
export default class NevronAdvanceTextBox extends Vue {
  @Prop()
  value: any | null;
  @Prop()
  referenceKey: any | '';

  @Prop()
  parentType: any | '';
  @Prop()
  checkTextEmpty: any | false;

  $languages: any = '';
  localKey: any = '';
  localText: any = '';

  mounted() {
    this.init();
  }

  handleTranslate() {
    if (this.parentType === 'model') {
      this.$emit('openTranslator', this.localText, this.localKey, this.referenceKey);
    } else {
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    }
  }

  @Watch('value')
  init() {
    this.localKey = this.value;
    stores.Language.fetchingTranslationsPromise
      .then((resolved: any) => {
        if (i18n.te(this.localKey)) {
          this.localText = Vue.prototype.translate(this.localKey);
        } else {
          // if field was edited and translation was not saved yet
          this.localText = this.localKey;
        }
      });
  }

  editorReady(editor: CKEditor.info) {
    // Workaround because of CKEditor limitations
    editor.on('change', () => {
      const updatedText = editor.getData();
      this.localText = updatedText;
      this.$emit('input', this.localText);
    });
  }

}
