
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import GlobalFunctions from '@/GlobalFunctions';

@Component({
  components: {
    SweetModal,
  },
})
export default class CreateCustomer extends Vue {

  @Prop({default: false})
  emitCreated!: boolean;

  @Prop()
  newCustomerIndex?: any;

  customer: any = {
    id: null,
    erpId: null,
    isCompany: false,
    company: '',
    gender: '',
    salutation: '',
    firstName: '',
    lastName: '',
    nationality: '',
    dateOfBirth: '',
    placeOfBirth: '',
    identityNumber: '',
    taxNumber: '',
    active: true,
    imageId: null,
    accounts: [],
    addresses: [{
      address: '',
      address2: '',
      city: '',
      country: '',
      id: null,
      customerId: null, // TODO: a to res rabimo?
      post: '',
      default: 1, // TODO: a to res rabimo, to naj določi backend
      firstName: '',
      lastName: '',
      company: '',
    }],
    contacts: [{
      id: 0,
      key: 'PHONE',
      value: '',
      customerId: -1,
    },
      {
        id: 1,
        key: 'EMAIL',
        value: '',
        customerId: -1,
      }],
  };
  firstNameRules = {};
  lastNameRules = {};
  companyRules = {};

  globalFunctions = new GlobalFunctions();

  mounted() {
    stores.Customer.getCustomerValidationRules()
      .then((response) => {
        const validationRules = response;
        if (validationRules[`first_name`]) {
          this.firstNameRules = validationRules[`first_name`];
        }
        if (validationRules[`last_name`]) {
          this.lastNameRules = validationRules[`last_name`];
        }
        if (validationRules[`company`]) {
          this.companyRules = validationRules[`company`];
        }
      });

    const form = document.getElementById('create-form-customer')! as HTMLFormElement;
    // Loop over them and prevent submission
    form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      if (form.checkValidity()) {
        event.stopPropagation();
        this.onSubmit();
      }
      if (!form.checkValidity()) {
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  }

  removeContact(id: number, e: any) {
    this.customer.contacts.splice(id, 1);
    e.preventDefault();
  }

  add() {
    // @ts-ignore
    this.customer.contacts.push({
      id: this.customer.contacts[this.customer.contacts.length - 1].id + 1,
      key: null,
      value: '',
      customerId: -1,
    });
  }

  countrySelected(country: string) {
    this.customer.addresses[0].country = country;
  }

  onSubmit(): Promise<void | {}> {
    // TODO: samo če je naslov vnešen, drugače to ne nastavimo
    this.customer.addresses[0].firstName = this.customer.firstName;
    this.customer.addresses[0].lastName = this.customer.lastName;
    this.customer.addresses[0].company = this.customer.company;
    return stores.Customer.createCustomer(this.customer)
      .then((response: any) => {
        this.cancel();
        if (this.emitCreated) {
          this.$emit('created', response.data);
          return;
        }
        this.$router.push({name: 'customers.show', params: {id: response.data.id}});
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
}
