
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import virtualList from 'vue-virtual-scroll-list';
  import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
  import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
  import NevronInput from '@/components/NevronInput.vue';
  import Skeleton from '@/modules/Skeleton.vue';
  import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
  import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
  import CreateCategory from '@/components/ShowNevronComponents/Categories/CreateMulti.vue';
  import NevronImageField from '@/components/NevronImageField.vue';
  import TableGeneric from '@/components/NevronTableGeneric.vue';
  import {showToaster} from '@/components/DashboardComposer/helpers';
  import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
  import router from '@/router';
  import MultiSelect, { Multiselect } from "vue-multiselect";
  import {translate, translatedDisplayName} from "@/helpers/functions";
  import {goToModuleIndex} from "@/helpers/routing";

  @Component({
    components: {
      virtualList,
      NevronHeader,
      NevronInput,
      Skeleton,
      NevronFooter,
      NevronSearchNoResults,
      CreateCategory,
      NevronImageField,
      TableModalGeneric,
      TableGeneric,
      NevronAdvanceTextBox,
      MultiSelect,
    },
  })
  export default class ShowMulti extends Vue {
    @Prop()
    module!: IModule;

    response: any = null;
    category: ICategoriesMulti | null = null;
    searchQuery: string = '';
    indexList: number[] = [];
    allChecked: boolean = false;

    source: any;
    fullResponse: any = null;
    subItemId:any=[];
    subCategoryId:any=[];
    subCategoryoptions:any=[];
    subItemoptions:any=[];
    get categoryName() {
      if (!this.category?.parentId) {
        return Vue.prototype.translate(this.module.name) + ' ' + Vue.prototype.translate('all');
      }
      return Vue.prototype.translate(this.category?.name);
    }

    mounted() {
      this.refresh(this.$route.params.id);
    }
    refresh(id: number | string) {
      return this.module.getSpecificCategory!(Number(id) || 0)
        .then((response) => {
          this.response = response;
          console.log('AOD', response);
          this.category = response.data.category;
          //sub category option
          this.subCategoryoptions = response.data.subcategories.map((subcategorie: { id: number, name: string }) => ({
            value: subcategorie.id,
            lable: Vue.prototype.translate(subcategorie.name),
            }));
            this.subCategoryId = response.data.aodCategory.map((subcategorie: { id: number, name: string }) => ({
            value: subcategorie.id,
            lable: Vue.prototype.translate(subcategorie.name),
            }));
            this.subItemoptions = response.data.moduleItem.map((item: { id: number, name: string }) => ({
            value: item.id,
            lable: Vue.prototype.translate(item.name),
            }));
            this.subItemId = response.data.children.map((item: { id: number, name: string }) => ({
            value: item.id,
            lable: Vue.prototype.translate(item.name),
            }));

          //end sub category option
          if (parseInt(this.$route.params.id, 10) === 0) {
            if (this.category === null) {
              router.go(0);
            } else {
              if (Vue.prototype.translate(this.category.name) === '') {
                this.category.name = 'all';
              }
            }
          }
          window.scrollTo(0, 0);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    @Watch('$route', {immediate: true, deep: true})
    changePage() {
      this.refresh(this.$route.params.id);
    }

    onSubmitForm() {
      const form = document.getElementById('update-aod-category-form') as HTMLFormElement;
      if (form.checkValidity()) {
        this.submitForm();
      } else {
        showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
      }
      form.classList.add('was-validated');
    }

    submitForm() {
      if (this.category) {
        this.category.subCategoryId=this.subCategoryId;
        this.category.subItemId=this.subItemId;
        return this.module.updateCategory!(this.category.id!, this.category)
          .then(async (response) => {
            if (response.responseCode === 200) {
              await this.refresh(Number(this.$route.params.id));
              // @ts-ignore
              showToaster('success', translatedDisplayName(this.category), translate('successfully-updated'));
            } else {
              // @ts-ignore
              showToaster('danger', translatedDisplayName(this.category), 'Fail to update');
            }
          })
          .catch((e: any) => {
            console.log(e);
          });
      }
    }

    imageSelected(image: IMediaItem) {
      if (this.category) {
        this.category.imageId = (image) ? image.id : null;
      }
    }

    addItemsToCategory(newItemsList: any) {
      if (this.category && this.category.subitems) {
        const subitemIds = this.category.subitems.map((item: any) => item.id);
        const uniqueNewItemsList = newItemsList.filter((item: any) => !subitemIds.includes(item.id));
        this.category.subitems = this.category.subitems.concat(uniqueNewItemsList);
      }
    }

    checkAll() {
      if (!this.allChecked) {
        this.indexList = Array.from(new Set([...this.indexList, ...this.itemIds]));
        this.allChecked = true;
      } else {
        this.indexList = this.indexList.filter((id) => !this.itemIds.includes(id));
        this.allChecked = false;
      }
    }

    updateCheck() {
      if (this.itemIds.every((id: number) => this.indexList.includes(id))) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    }

    get itemIds(): number[] {
      return (this.category as ICategories).children!.map((item: IItem) => item.id);
    }

    getItemsForModule() {
      // @ts-ignore
      this.$refs.attach.$children[0].open();
    }

    openItem(item: IItem) {
      this.$router.push({name: `${this.module.path}.items.show`, params: {id: String(item.id)}});
    }

    openCategory(category: ICategories) {
      this.$router.push({name: `${this.module.path}.categories`, params: {id: String(category.id)}});
    }

    deleteCategory(category: any) {
      this.module.deleteCategory!(Number(category.id))
        .then((response) => {
          if (!response.success) {
            throw new Error(response.error.message);
          }
          showToaster('success', translatedDisplayName(category), translate('successfully-deleted'));
          goToModuleIndex(this.module, {tab: 'configuration'})
        })
        .catch((error) => {
          showToaster('danger', '', error);
        })
    }

  }
  