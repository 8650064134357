
import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import {deletePath} from '../../helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import session from '../../stores/Session';
import axiosInstance from '../../helpers/axios';
import NevronInput from '@/components/NevronInput.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';

@Component({
  components: {
    EditContact,
    virtualList,
    EditCustomer,
    PreEdit,
    // AccountAttach,
    CustomerAddressEdit,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,
    NevronFooter,
    NevronImageField,
    TableGeneric,
    TableModalGeneric,
    NevronAdvanceTextBox,
  },
  mixins: [Clickaway],
})

export default class PromotionsDetails extends Vue {
  promotion: any = null;
  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;
  module = stores.Promotions;

  tempAcc!: IAccount;

  accountItems: IAccount[] = [];

  response: any = null;
  frontUrl: any = null;
  backUrl: any = null;
  search: any = {
    query: '',
  };

  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;

  attachableContent: any = null;
  stores = stores;
  emailCheck: boolean = true;

  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.$route.params.id));
  }

  sortArrays(arrays: any) {
    return _.orderBy(arrays, 'sort', 'asc');
  }

  /*
   *
   */
  onSubmitStatus() {
    const form = this.$refs.form as HTMLFormElement;
    form.classList.add('was-validated');
    const inputFields = form.querySelectorAll('input');

    if (!form.checkValidity()) {
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
      return ;
    }

    if (this.emailCheck) {
      // @ts-ignore
      return stores.Promotions.updateItem(this.promotion.id, this.promotion)
        .then(async (response) => {
          if (response.responseCode === 200) {
            await this.fetchData(Number(this.$route.params.id));
            showToaster('success', Vue.prototype.translate(this.promotion.name), 'successfully updated');
          } else {
            showToaster('danger', Vue.prototype.translate(this.promotion.name), 'Fail to update', response.code);
          }
        }).catch((e: any) => {
          console.log(e);
          showToaster('danger', Vue.prototype.translate(this.promotion.name), 'Fail to update');
        });
    }

  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(Number(this.$route.params.id));
  }

  loadPage() {
    this.fetchData(Number(this.$route.params.id));
  }

  /**
   *
   */
  popoverMethod() {
    if (this.promotion && this.promotion.id) {
      return deletePath(this.promotion.id);
    }
  }

  deletePromotion() {
    return stores.Promotions.deleteItem(this.promotion.id)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  fetchData(id: number) {
    return stores.Promotions.getDetail(id)
      .then((response: any) => {
        this.promotion = response.data;
        console.log('this.promotion', this.promotion);
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  imageSelected(image: IMediaItem) {
    if (this.promotion) {
      this.promotion.imageId = (image) ? image.id : null;
    }
  }

  // customers
  attachCustomer(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.customerIds.includes(el.id));
    this.promotion.customers = this.promotion.customers.concat(newItems);
  }

  // customers
  attachGuestFlow(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.guestFlowIds.includes(el.id));
    this.promotion.gfm = this.promotion.gfm.concat(newItems);
  }

  get customerIds() {
    return this.promotion.customers.map((el: IItem) => el.id);
  }

  get guestFlowIds() {
    return this.promotion.gfm.map((el: IItem) => el.id);
  }

  detachCustomer(item: IItem) {
    this.promotion.customers = this.promotion.customers.filter((el: any) => el.id !== item.id);
  }

  detachGuestflow(item: IItem) {
    this.promotion.gfm = this.promotion.gfm.filter((el: any) => el.id !== item.id);
  }

  validateEmail(val: string) {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    if (emailRegex.test(val)) {
      this.emailCheck = true;
      return true;
    } else {
      this.emailCheck = false;
      return false;
    }
  }

}
