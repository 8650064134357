
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {modulesMetaData} from '@/helpers/constants';
import PerPageDropdown from '@/components/PerPageDropdown.vue';
import vPagination from '@/components/VuePlainPagination.vue';
import ListNevronHeader from '@/components/ListNevronComponents/ListNevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/ListNevronComponents/EmptyNevronHeader.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import _ from 'lodash';
import {bus, exportData, importData, translatedDisplayName} from '@/helpers/functions';

import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
import NevronCopyMovePopupModal from '@/components/NevronCopyMovePopupModal.vue';
import axios from "axios";
// @ts-ignore
import config from '../../../config/config.js';

@Component({
  components: {
    ListNevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
    NevronSearchNoResults,
    PerPageDropdown,
    vPagination,
    TableGeneric,
    DynamicContent,
    NevronCopyMovePopupModal,
  },
})

export default class IndexGenericMultiTable extends Vue {
  @Prop()
  instance: any;
  response: any = null;
  items: IItem[] = [];
  selectedArray: number[] = [];
  allChecked = false;
  resetSelected = false;

  loading = true;
  file: any = '';
  uploadResponse: any = '';
  firstLoad = true;
  perpage = 20;

  search: any = {
    query: '',
    current: '',
  };
  ids: any = [];
  checkModule: any = '';
  searchQuery: any = [];


  createComponent: any = null;
  showComponent: any = null;
  selectedItem: IItem | null = null;
  storeRoutes: [] = [];
  storeInstances: [] = [];
  selectedInstance: any;

  localKey: any = '';
  localText: any = '';
  referenceKey: any = '';
  index: any = '';
  list: any = '';
  timer: any = 0;
  searchItems = _.debounce(() => {
    // this.fetchData(1);
  }, 400); // fetches data with debounce (ms)

  get pageTitle() {
    if (this.instance.name) {
      return this.instance.name;
    } else {
      return this.instanceMeta.displayName;
    }
  }

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }

  get fields() {
    return this.instanceMeta.fields;
  }
  addItemPopup(instance: any) {
    this.selectedInstance = instance;
    // @ts-ignore
    if (modulesMetaData[this.selectedInstance.routeName].createComponent) {
      // @ts-ignore
      this.createComponent = () => import(`@/${modulesMetaData[this.selectedInstance.routeName].createComponent}.vue`);
    } else {
      this.createComponent = null;
    }

    if (this.$refs.create) {
      this.$nextTick(() => {
        // Use setTimeout to wait for the component to be mounted in the DOM
        setTimeout(() => {
          const createComponentInstance = this.$refs.create;
          // @ts-ignore
          if (createComponentInstance && createComponentInstance.$children.length) {
            // @ts-ignore
            createComponentInstance.$children[0].open();
          }
        }, 10);
      });
    }
  }

  get instanceMultiMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.selectedInstance.routeName];
  }
  mounted() {

    // set search query from URL
    if (this.$route.query.search) {
      this.search.query = this.$route.query.search;
      this.search.current = this.$route.query.search;
    }
    // set perpage from URL
    if (this.$route.query.perpage) {
      this.perpage = Number(this.$route.query.perpage);
    }
    this.moduleChanged();

    bus().$on('openTranslator', (localText: any, localKey: any, referenceKey: any) => {
      this.localText = localText;
      this.localKey = localKey;
      this.referenceKey = referenceKey;
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    });
    // this.showDynamicModule = this.instanceMeta['dynamicModule'];

  }

  @Watch('instance')
  moduleChanged() {
    this.items = [];
    this.storeRoutes = [];
    this.storeInstances = [];
    this.searchQuery = [];

    this.index = (this.firstLoad && this.$route.query.page) ? Number(this.$route.query.page) : 1;
    this.perpage = 20;
    this.search.current = '';
    this.firstLoad = true;
    // this.fetchData(this.index);
    const instance = this.instance;
    for (const property in instance) {
      if (instance.hasOwnProperty(property) && typeof instance[property] === "object") {
        if (instance[property]) {
          this.selectedInstance = this.instance[property];
          // @ts-ignore
          this.storeRoutes.push(modulesMetaData[instance[property].routeName]);
          // @ts-ignore
          this.storeInstances.push(instance[property]);
          this.fetchData(this.index, instance[property]);
        } else {
          console.log(`No objects found in ${property}`);
        }
      }
    }

    if (this.instanceMultiMeta.createComponent) {
      this.createComponent = () => import(`@/${this.instanceMultiMeta.createComponent}.vue`);
    } else {
      this.createComponent = null;
    }

    if (this.instanceMultiMeta.showComponent) {
      this.showComponent = () => import(`@/${this.instanceMultiMeta.showComponent}.vue`);
    } else {
      this.showComponent = null;
    }
  }

  searchData(index: number | null, instance: any, arrayIndex: any) {

    if (this.firstLoad) {
      this.loading = true;
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    // @ts-ignore
    instance.fetchData(index, this.searchQuery[arrayIndex], this.perpage)
      .then((response: any) => {
        this.response = response;
        if (this.response.data) {
          // @ts-ignore
          this.items[arrayIndex] = [];
          // @ts-ignore
          this.items[arrayIndex] = this.response.data;
        }
        this.search.current = this.search.query;
        if (!response) {
          showToaster('danger', '', 'Failed to fetch Data', response.code);
        }
      })
      .catch((error: any) => {
        showToaster('danger', 'Error:', error);
      })
      .finally(() => {
        this.loading = false;
        this.firstLoad = false;
      });
  }
  fetchData(index: number | null, instance?: null) {

    if (this.firstLoad) {
      this.loading = true;
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    // @ts-ignore
    instance.fetchData(index, this.search.query, this.perpage)
      .then((response: any) => {
        this.response = response;
        // @ts-ignore
       if (instance.routeName && !instance.routeName.includes('Category') && !instance.routeName.includes('Type')) {

          // Use unshift method to add data at the start of the array
          this.items.unshift(this.response.data);
        } else {
          // @ts-ignore
          // Else push the data to the end of the array
          this.items.push(this.response.data);
        }
        this.search.current = this.search.query;
        if (!response) {
          showToaster('danger', '', 'Failed to fetch Data', response.code);
        }

        })
        .catch((error: any) => {
          showToaster('danger', '', 'Failed to fetch Data', error);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
            this.firstLoad = false;
          }, 500);
        });

  }

  deleteSelected(instance: any, index: any) {
    this.selectedInstance = instance;
    if (this.selectedArray.length === 0) {
      return;
    }
    const ids = this.selectedArray.map((el: any) => el.id);
    instance.deleteItems(ids)
      .then((response: any) => {
        this.refresh(this.selectedInstance, index);
        this.resetSelected = !this.resetSelected;
        if (response.responseCode && !response.success) {
          const error = response.error.message;
          showToaster('danger', 'Error in deleting Multiple Items', Vue.prototype.translate(error));
          return;
        }
        showToaster('success', '', Vue.prototype.translate('successfully-deleted'));
        if (this.instance.routeName === 'modules') {
          this.$router.push({name: 'home'});
        }
      });
  }

  checkOutSelected() {
    if (this.selectedArray.length === 0 ) {
      return;
    }
    const instance = axios.create({
      baseURL: config.apiUrl,
      timeout: config.apiTimeout,
    });
    const items = this.selectedArray.filter((el: any) => {
      if (el.stays.status === 'Active' || el.stays.status === 'active') {
        return this.selectedArray.map((item: any) => item.id);
      }
    });
    const guests = items.map(items => {
      // @ts-ignore
      return { "request": "out", "guest_id": items.id, 'checkin_type': 'mn-checkin' };
    });
    const array: any = {
      "isDatabaseResync": true,
      "guests":[]
    };
    guests.forEach(guest => {
      array.guests.push(guest);
    });

    const currentPaths = window.location.pathname.split('/').filter((el) => el.length > 0);
    instance.post(currentPaths[0], array)
      .then(response => {
        showToaster('success', ' ', 'check Out is completed..!!', 200);

      })
      .catch(error => {
        if (error.response.status !== 200) {
          showToaster('danger', ' ', error.response.data.message, error.response.status);
        }
      });4
  }

  openItem(item: IItem, instance: any) {
    this.selectedInstance = instance;
    if (this.showComponent) {
      this.selectedItem = item;
      // @ts-ignore
      this.$refs.show.$children[0].open();
      return;
    }
    const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
    this.$router.push({
      name: `${this.selectedInstance.routeName}.${showPath}`,
      params: {id: String(item.id)},
      query: {lastpage: String(this.response.currentPage), perpage: String(this.perpage)},
    });
  }

  deleteItem(item: IItem, instance: any) {
    this.selectedInstance = instance;
    const arrayIndex = this.storeInstances.findIndex((item: IModule) => item.routeName === instance.routeName);

    instance.deleteItem(item.id).then((response: any) => {
      this.refresh(this.selectedInstance, arrayIndex);
      if (response.responseCode && !response.success) {
        const error = response.error.message;
        showToaster('danger', translatedDisplayName(item), Vue.prototype.translate(error));
        return;
      }
      showToaster('success', translatedDisplayName(item), Vue.prototype.translate('successfully-deleted'));

      if (instance.routeName === 'modules') {
        this.$router.push({name: 'home'});
      }
    });
  }

  updateRadioValue(item: IItem, fieldName: string) {
    const data = {id: item.id};
    // @ts-ignore
    data[fieldName] = true;
    this.instance.updateItem(item.id, data).finally(() => {
      this.refresh(this.selectedInstance, this.index);
    })
  }

  refresh(instance: any, index: any) {
    this.searchData(this.index, instance, index);
  }

  exportSelected(instance: any) {
    // @ts-ignore
    const ids = this.selectedArray.map((item) => item.id);
    // @ts-ignore
    exportData(modulesMetaData[instance.routeName].tableName, ids).then((response) => {
      if (response.result === true) {
        const csv = response.data;
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'Nevron-' + this.instance.routeName + '.csv';
        anchor.click();
      }
    });
  }

  @Watch('uploadResponse')
  importCsv() {
    if(this.uploadResponse.length === 0) return
    let moduleId = {
      data : null,
      module_id : null,
    };
    moduleId.data = this.uploadResponse;
    moduleId.module_id = this.instance.id

    // Find the correct store instance to refresh.
    let instanceIndex = 0;
    for (let index = 0; index < this.storeInstances.length; index++) {
      if(this.selectedInstance == this.storeInstances[index])
      {
        instanceIndex = index;
        break;
      }

    }


    // @ts-ignore
    importData(moduleId, modulesMetaData[this.selectedInstance.routeName].tableName,)
    .then((response) => {
        if (response.data && response.responseCode && response.responseCode === 200) {
          showToaster('success', '', response.data,response.responseCode);
          this.moduleChanged();
          return
        }
        let error = response;
        if (error.response.status >= 400 && error.response.status < 500) {
          showToaster('danger', '', error.response.data.error.message , error.response.status);
          console.log(error);
          this.moduleChanged();
        }
      })
    this.uploadResponse  = ''
  }

  openImport(index: any) {
    this.selectedInstance = this.storeInstances[index];
    const files = `file${index}`;
    this.$nextTick(() => {
      const fileInput = this.$refs[files];
      // @ts-ignore
      if (fileInput[0].click) {
        // @ts-ignore
        fileInput[0].click();
      }
    });
  }

  importFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadResponse = e.target ? e.target.result : '';
    };
    reader.readAsText(file);
  }

  get shouldHideDelete() {
    return !!this.instanceMeta.options?.hideDelete;
  }

}
