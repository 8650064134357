
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';
import axiosInstance from '../../helpers/axios';
import CreateFolder from '@/modules/Media/CreateFolder.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import { translate } from '@/helpers/functions';

@Component({
  components: {
    SweetModal,
    CreateFolder,
    NevronSearch,
    NevronSearchNoResults,
    NevronFooter,
  },
  mixins: [Clickaway],
})

export default class AttachImageTo extends Vue {
  // for attaching multiple images
  @Prop({default: false})
  isGallery!: boolean;
  // which images are already attached
  @Prop({default: () => []})
  @Prop({default: false})
  allowMultiple: boolean = false;
  attached: IAttachFile[] = [];
  selected!: any[];
  attachImage: any = null;
  imageDescription: any = null;
  attachImageheight: any = null;
  attachImagewidth: any = null;
  multipleImages: any[] = [];
  uploadingFile: boolean = false;


  // What file types can be accepted?
  @Prop()
  acceptedFileTypes: any | [];
  @Prop()
  hideVideoElements: boolean | any;
  @Prop()
  hideImageElements: boolean | any;
  @Prop()
  module!: IModule;

  response: any = null;
  contentUrl: any = '';
  search: any = {
    query: '',
    current: '',
  };

  get backupMediaFolderName() {
    const routeName = this.module.routeName;
    if (routeName) {
      return `${routeName[0].toUpperCase()}${routeName.slice(1)}`;
    }
  }

  mounted() {
    this.getSubItems();
  }

  back() {
    return stores.Folder.getFolder(this.response.mediaFolderId)
      .then((response: any) => {
        this.response = response;
        this.response.children = this.response.folders.concat(this.response.files);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getSubItems(id: number = 0) {
    return stores.Folder.getFolder(id, this.search.query)
      .then((response: any) => {
        this.response = response;
        this.response.children = this.response.folders.concat(this.response.files);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  inAttached(file: any) {
    return this.attached.map((obj: any) => obj.id).includes(file.id);
  }

  getHeaders() {
    return stores.File.getHeader();
  }
  selectFile(file: any)
  {
    file.isImageFile = this.isImageFile(file.type);
    file.isVideoFile = this.isVideoFile(file.type);
    file.isNewFile = false;
    const fileExists = this.attached.some(existingFile => existingFile.id === file.id);
    if (!fileExists) {
        this.attached.push(file);
    }else{
      this.updateCheckboxState(file,false)
      // Remove file to implement toggle functionality
      this.attached = this.attached.filter(existingFile => existingFile.id !== file.id);
      return
    }
    // Disptach event if multiple items are not allowed to select
    if(!this.allowMultiple)
    {
      this.submit()
      this.attached = [];
      return;
    }
    this.updateCheckboxState(file,true)
  }
  updateCheckboxState(file : any,state: boolean = true)
  {
    // @ts-ignore
    Object.keys(this.$refs).forEach(ref => {
        if (ref.startsWith("fileCheckbox_" + file.id)) {
          // @ts-ignore
          this.$refs[ref][0].checked = state;
        }
    });
  }
  checkboxSelectFile(file : any,event: Event)
  {
    file.isImageFile = this.isImageFile(file.type);
    file.isVideoFile = this.isVideoFile(file.type);
    file.isNewFile = false;
    const fileExists = this.attached.some(existingFile => existingFile.id === file.id);
    if (!fileExists) {
        this.attached.push(file);
    }
    event.stopPropagation();
  }
  checkboxClick(event: Event)
  {
    event.stopPropagation();
  }
  submit()
  {
    this.attached.forEach((file) => {
      this.$emit('attach', {result: file, new: file.isNewFile});
    })
    if(this.attachImage)
    {
      this.$emit('attach', {result: this.attachImage, new: this.attachImage.isNewFile});
    }
    this.cancel();
  }
  attachModelOpen() {
    this.resetFileCheckBoxes()
  }
  resetFileCheckBoxes()
  {
     // @ts-ignore
    Object.keys(this.$refs).forEach(ref => {
        if (ref.startsWith('fileCheckbox_')) {
          // @ts-ignore
          this.$refs[ref][0].checked = false;
        }
    });
    this.attached.splice(0, this.attached.length);
  }
  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }
  transmitFinish(files: any, result: any) {
    this.uploadingFile = false;
    result.isImageFile = this.isImageFile(result.type);
    result.isVideoFile = this.isVideoFile(result.type);
    this.attachImage = result;
    const img = new Image();
    img.src = this.attachImage.imageUrl;
    this.attachImageheight = img.height;
    let width: number;
    let height: number;
    img.onload = () => {
      // here you got the width and height
      width = img.width;
      height = img.height;
    };
    setTimeout(() => {
      this.attachImageheight = height;
      this.attachImagewidth = width;
    }, 1000);
  }

  cancel() {
    this.attachImage = null;
    this.attached = [];
    // const createModal = document.querySelector('.create_modal') as HTMLElement;
    // createModal.style.display = 'block';
     // @ts-ignore
    this.$children[0].close();
    this.$emit('openCreatePage');
  };
  isImageFile(fileExtension:any) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'eps', 'bmp', 'tiff', 'tif', 'svg', 'raw'];
    return imageExtensions.includes(fileExtension);
  };
  isVideoFile(fileExtension:any) {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'webm', 'mkv', 'mpeg-4', 'ogg'];
    return videoExtensions.includes(fileExtension);
  };
  isNewFile(file:any) {
    return false;
  };
  videoType(type: any) {
    if(type === 'mov') return 'video/mp4';
    return 'video/'+type;
  }


}
