
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';

import GuestFlowModuleShow from '@/modules/GuestFlowModule/ShowBasic.vue';
import PromotionsList from '@/modules/Promotions/List.vue';
import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import TabManager from "@/mixins/TabManager";

@Component({
  components: {
    GuestFlowModuleShow,
    ItemsIndexGeneric,
    PromotionsList,
  },
})
export default class PromotionsIndex extends TabManager {

  guestFlowModule: IGuestFlowModule | null = null;

  async created() {
    const response = await stores.GuestFlow.fetchAll();
    const modules = response.data;
    if (modules) {
      this.guestFlowModule = modules.find((module: IGuestFlowModule) => module.type === 'PROMOTIONS');
    }
  }
}

