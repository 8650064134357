import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import { generateLog } from '@/helpers/functions';
import axiosInstance from '@/helpers/axios';
import Axios from 'axios';

let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL_TRANSLATION = '/translations';

export interface ITranslation {
  id?: number | null;
  languageId: any | null;
  key: string | null;
  value: string | null;
  static: boolean | true;
}

export class Translation extends Model<ITranslation> implements ITranslation {
  id: number | null = null;
  languageId: any = null;
  key: string = '';
  value: string = '';
  static: boolean = true;

  constructor(attributes: Partial<ITranslation>, parentCollection?: Collection) {
    super(attributes, parentCollection);
    Object.assign(this, attributes);
  }

  urlRoot(): string {
    return URL_TRANSLATION;
  }
}

export default class TranslationCollection extends Collection<Translation> {
  routeName = 'translation';

  url(): string {
    return URL_TRANSLATION;
  }

  model(): new (attributes: ITranslation, collection?: Collection) => Translation {
    return Translation;
  }

  build(attributes: ITranslation): Translation {
    return new Translation(attributes, this);
  }

  fetchData(index: number = 1, search: string | null = null, perpage: number = 10, optionalParams?: string) {
    if (typeof cancel === 'function') {
      cancel();
    }

    const cancelToken = new CancelToken(function executor(c: any) {
      cancel = c;
    });

    let baseURL = `${this.url()}?page=${index}&perpage=${perpage}`;
    if (search) baseURL += `&search=${search}`;
    if (optionalParams) baseURL += `&${optionalParams}`;

    return axiosInstance.get(baseURL, { cancelToken })
      .then(response => response.data)
      .catch(e => {
        generateLog({ route: this.routeName, message: e });
        return Promise.reject(e);
      });
  }

  createNewItem(item: ITranslation) {
    return axiosInstance.post(this.url(), item)
      .then(response => response.data)
      .catch(e => {
        generateLog({ route: this.routeName, message: e });
      });
  }

  getItem(id: number | string) {
    return axiosInstance.get(`${this.url()}/${id}`)
      .then(response => response.data)
      .catch(e => {
        generateLog({ route: this.routeName, message: e });
        return Promise.reject(e);
      });
  }

  deleteItem(_: string|number,  item: ITranslation) {
    const key = item.key;
    return axiosInstance.delete(`${this.url()}/key/${key}`)
      .then(response => response.data)
      .catch(e => {
        generateLog({ route: this.routeName, message: e });
      });
  }

  deleteItems(_: string|number, items: any[]) {
    const itemKeys = items.map((item) => String(item.key));
    return axiosInstance.post(`${this.url()}/key/delete`, { ...itemKeys })
      .then(response => response.data)
      .catch(e => {
        generateLog({ route: this.routeName, message: e });
      });
  }

  updateItem(id: number | string, item: ITranslation) {
    return axiosInstance.put(`${this.url()}/${id}`, item)
      .then(response => response.data)
      .catch(e => {
        generateLog({ route: this.routeName, message: e });
      });
  }

  // Implement other necessary methods as required
}

