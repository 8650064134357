
import { Component, Prop, Vue } from 'vue-property-decorator';
import stores from '../../stores';
import { Session } from '@/stores/Session';
import { showToaster } from '@/components/DashboardComposer/helpers';

@Component
export default class LostPassword extends Vue {
  session : Session = stores.session;
  email: string = '';
  error: boolean = false;
  errorMessage: string = '';
  admin = stores.admin;
  image = require('@/assets/images/profile-img.png');
  step: number = 1;

  mounted(){
  }
  onSubmit() {
    const response = stores.admin.forgotPassword(this.email)
    response.then( (response : any)  => {
      if(response && response.responseCode === 200)
      {
        showToaster("success","",response.data)
        return
      }
      showToaster("danger","","Something went wrong!")
    }).catch(e => {
      showToaster("danger","","Something went wrong!")
    })
  }

  defaultLogo(event?: any) {
    if (!event) {
      return require('../../../assets/nevron_logo.png');
    }
    event.target.src = require('../../../assets/nevron_logo.png');
  }

  onImageLoadFailure(event: any) {
    event.target.src = require('../../../assets/nevron_logo.png');
  }
  get loginLink() {
    return '/login'
  }
}
