
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';
import SurveyAnswers from './Answers.vue';
import stores from "@/stores";

@Component({
    components: {
        SweetModal,
        SurveyAnswers,
    },
})

export default class SurveyQuestionExport extends Vue {
    @Prop({default:false}) question!: any | null;
    @Prop({default: false}) index!: number;

    @Prop({default:false})
    showAnswerResult!: false;

    icons: any =
        {
            TV: '../../../../../img/tv-b.svg',
            MOBILE: '../../../../../img/mobile-b.svg',
            EMOTICON: '../../../../../img/my_mood-b.svg',
            CLOSE_ENDED: '../../../../../img/survey-b.svg',
        }

    cancel() {
        // @ts-ignore
        this.$refs.question.close();
    }

    mounted(){
    }

}
