
  import {Component, Vue} from 'vue-property-decorator';
  import stores from '@/stores';

  @Component({
    components: {},
  })

  export default class DeletePanel extends Vue {
    /**
     * Fired when module is created
     */
    created() {
      this.delete();
    }

    /**
     * delete dashboard event
     */
    delete() {
      return stores.dashboards.deletePanel(this.$route.params.panelId)
        .then((response) => {
          console.log(response);
          this.$router.go(-1);
        })
        .catch((reason) => {
          console.log('Could not delete', reason);
        });
    }
  }
