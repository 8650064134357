
import { Component, Vue, Watch } from 'vue-property-decorator';
import NevronSearch from "@/components/NevronSearch.vue";
import PerPageDropdown from "@/components/PerPageDropdown.vue";
import vPagination from "@/components/VuePlainPagination.vue";
import SuperAdminPage from "@/components/partials/super-admin-page.vue";
import Filters from "@/modules/SuperAdminDevices/Filters.vue";
import ProjectManagementModal from "@/modules/Projects/ProjectManagementModal.vue";
import CreateUser from "@/modules/SuperAdmin/Users/Create.vue";
import MultiSelect from 'vue-multiselect';
import stores from '../../../stores';
import _ from "lodash";
import moment from 'moment';
import {showToaster} from "@/components/DashboardComposer/helpers";
import SuperAdminDetails from "@/modules/SuperAdmin/Users/Show.vue";
import {RolesEnum} from "@/enums/RolesEnum";
import globalConfig from "@/helpers/globalConfig";
import { bus } from '@/helpers/functions';

@Component({
  components: {
    ProjectManagementModal,
    Filters,
    SuperAdminPage, vPagination, PerPageDropdown, NevronSearch, CreateUser, MultiSelect, SuperAdminDetails},
})

export default class SuperAdminUsers extends Vue {
  config = stores.globalConfig.config;
  master = stores.master;

  response: any = null
  projectTypesList: IProjectType[] = [];
  selectedUser: ISuperAdmin | null = null;

  currentAdmin: any = null;
  topSuperAdmin: any = null;

  allRoles: IRole[] = [];

  search = {
    query: '',
    current: '',
  };
  searchItems = _.debounce(() => {
    this.fetchData(1);
  }, 400);

  appliedFilters: any = {};
  filtersQuery: string | undefined = undefined;
  allOperators: IOperator[] = [];

  perpage = 20;
  firstLoad = true;

  selectedUsers: ISuperAdmin[] = [];
  selectedUsersIds: number[] = [];
  openDropdownUserIds: number[] = [];

  stores = stores;

  // Computed
  get allChecked() {
    // "me" needs to be added, and also topSuperAdmin, if different than "me"
    let selectedUsersCount = this.selectedUsers.length + 1;
    if (this.currentAdmin && this.topSuperAdmin && this.currentAdmin.id != this.topSuperAdmin.id) {
      selectedUsersCount = selectedUsersCount +1;
    }

    return selectedUsersCount === this.users.length && this.users.length > 0;
  }

  get pageReady() {
    return this.response && this.response.data;
  }

  get users(): ISuperAdmin[] {

    if(!this.response?.data) {
      return [];
    }

    return this.response.data.map((user: ISuperAdmin) => {
      //add the current timestamp to the image url, as the image can change behind the same url
      const timestamp = new Date().getTime();
      let modifiedImageUrl = user.profileImageUrl;
      if(modifiedImageUrl && modifiedImageUrl.length > 0 && modifiedImageUrl.indexOf('?') === -1)
      {
         modifiedImageUrl = `${user.profileImageUrl}?t=${timestamp}`;
      }
      return {
        ...user,
        profileImageUrl: modifiedImageUrl
      };
    });
  }

  get filters() {
    return {
      'user role': {
        type: 'checkbox',
        values: this.allRoles.map(role => role.name),
      },
      'status': {
        type: 'checkbox',
        values: ['active', 'inactive'],
      },
      'operators': {
        type: 'select',
        values: stores.SuperAdminOperators
      }
    }
  }

  get allRoleNames() {
    return this.allRoles.map(role => role.name);
  }

  // Watchers
  @Watch('response.currentPage')
  changedPage() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.search.query = this.search.current;
    this.fetchData(this.response.currentPage);
  }

  // Methods
  async mounted() {
    this.fetchData(1);
    this.fetchAllOperators();
    this.currentAdmin = await stores.admin.me();
    this.allRoles = await stores.SuperAdmins.getAllRoles();
    this.projectTypesList = await stores.master.getProjectTypes() || [];

    bus().$on('adminUpdated', async () => {
      this.fetchData(1, true);
    });
  }

  getOperatorById(id: number) {
    return this.allOperators.find(operator => operator.id === id) || null;
  }

  getOperatorLogo(id: number): string {
    return this.getOperatorById(id)?.logo || '';
  }

  showOperators(){
    return globalConfig.showOperators
  }

  fetchData(page: number = 1, setFirstLoad: boolean = false) {

    if(setFirstLoad) {
      this.firstLoad = true
      this.response = null;
    }

    stores.SuperAdmins.fetchData(page, this.search.query, this.perpage, this.filtersQuery)
      .then((response) => {
        this.search.current = this.search.query;
        this.response = response.data;

        // Get to super admin, so we dont delete him
        this.topSuperAdmin = response.data.data.reduce((lowest: any, current: any) => {
          if (!lowest || current.id < lowest.id) {
            return current;
          } else {
            return lowest;
          }
        }, null);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchAllOperators() {
    stores.SuperAdminOperators.fetchData(1, '', 1000)
      .then((response: any) => {
        this.allOperators = response.data.data;
      })
      .catch((e: any) => {
        console.error(e);
      });
  }


  openUser(user: ISuperAdmin) {
    this.selectedUser = user;
    const adminProfileRef = this.$refs.admin_profile as Vue;
    if (adminProfileRef.$children) {
      const modalComponent = adminProfileRef.$children[0];
      if (modalComponent) {
        (modalComponent as any).open();
      }
    }
  }

  editUser(user: ISuperAdmin) {
    stores.SuperAdmins.updateItem(user)
      .then((response: any) => {
        showToaster('success', '', 'User successfully updated.');
        this.fetchData();
      })
      .catch((error: any) => {
        console.error(error);
      });
  }


  checkAll() {
    if (this.allChecked) {
      this.selectedUsers = [];
    } else {
      this.selectedUsers = (this.users || []).filter(user => user.id !== this.currentAdmin.id && user.id !== this.topSuperAdmin.id);
    }
  }

  userInitials(user: ISuperAdmin): string {
    const initials = user.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  // Filters
  @Watch('appliedFilters', { immediate: true, deep: true })
  generateFiltersQuery() {
    let filters = this.appliedFilters;
    if (!this.isValidFilter(filters)) {
      this.response['data'] = [];
      this.response['total'] = 0;
      return;
    }
    this.filtersQuery = Filters.generateFilterQuery(filters);
    this.fetchData(1);
  }
  updateFilters(appliedFilters: any) {
    for (let filter in appliedFilters) {
      this.appliedFilters = {
        ...this.appliedFilters,
        [filter]: appliedFilters[filter]
      }
    }
  }
  clearFilters() {
    this.appliedFilters = {};
    this.filtersQuery = undefined;
  }
  isValidFilter(filters:any): boolean {
    return true;
  }

  updateUserRole(user: ISuperAdmin, value: string) {
    if (!value) {
      return;
    }
    user.role = value;
    this.editUser(user);
  }

  closeDropdown(user: ISuperAdmin) {
    this.openDropdownUserIds = this.openDropdownUserIds.filter(id => id !== user.id);
  }
  openDropdown(user: ISuperAdmin) {
    if(this.currentAdmin.operatorId) {
      return;
    }

    this.openDropdownUserIds.push(user.id!);
  }
  isDropdownOpen(user: ISuperAdmin) {
    return this.openDropdownUserIds.includes(user.id!);
  }

  formatDate(dateString: string): string {
    return moment(dateString).format('DD MMM, YYYY');
  }

  isNevronSuperAdmin(superAdmin: any) {
    return superAdmin.role && superAdmin.role === RolesEnum.NEVRON_SUPERADMIN;
  }
  isOperator(superAdmin: any) {
    return superAdmin.role && superAdmin.role === RolesEnum.OPERATOR_SUPERADMIN;
  }

  deleteUsers() {
    const confirmDelete = confirm('Are you sure you want to delete the selected users?');
    if (!confirmDelete) {
      return;
    }
    this.selectedUsersIds = this.selectedUsers.map(user => user.id).filter(id => id !== undefined) as number[];
    this.executeDelete(this.selectedUsersIds);
  }

  private executeDelete(superAdminsToDelete: number[]): void {
    console.log(superAdminsToDelete)
    stores.SuperAdmins.delete(superAdminsToDelete)
      .then((response) => {
        if (!response.success) {
          showToaster('danger', response.error.message, '');
        } else {
          showToaster('success', 'Admins deleted successfully', '');
          this.fetchData();
        }
      });
  }
}
