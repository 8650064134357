
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import StaffAccess from '@/modules/Admins/Index.vue';
import CustomRolesList from '@/modules/CustomRoles/Index.vue';

@Component({
  components: {
    StaffAccess,
    CustomRolesList
  },
})

export default class AccessRoles extends Vue {
  // Component logic goes here

};
