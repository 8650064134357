
import {Component, Vue} from 'vue-property-decorator';
import stores from '../../stores';
import axiosInstance from '../../helpers/axios';
import editMedia from '@/modules/Media/Edit.vue';
import session from '@/stores/Session';
import NevronHeader from '@/components/NevronHeader.vue';
import UpdateFile from '@/modules/File/Update.vue';

@Component({
  components: {
    editMedia,
    NevronHeader,
    UpdateFile,
  },
})

export default class FileDetails extends Vue {
  image: any = null;
  imageDetails: IMediaItem = {
    id: null,
    updatedAt: '',
    type: 'file',
    mediaFolderId: 0,
    name: '',
    path: '',
    children: [],
    folderName: null,
    fileName: null,
    active: true,
    imageUrl: '',
  };
  contentUrl: any = '';
  newName: string = '';

  getHeaders() {
    return stores.File.getHeader();
  }

  remove() {
    if (confirm('Are you certain you want to delete this image?') && this.imageDetails.id) {
      return stores.Setting.deleteIcon(this.imageDetails.id)
        .then((response) => {
          console.log(response);
          this.$router.push({name: 'file.library', params: {folderId: String(this.imageDetails.mediaFolderId)}});
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getImage(id: number) {
    console.log(id);
    return stores.Setting.getIcon(id)
      .then((response) => {
        console.log(response);
        this.imageDetails = response.data;
        this.image = response.data.imageUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFilePath(id: number | null): string {
    return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl() + '/' + id;
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.getImage(Number(this.$route.params.fileId));
    // this.contentUrl = session.project.contentUrl;
  }

  /**
   * Called when changes invalid image source url
   */
  onImageLoadFailure(event: any) {
    event.target.src = require('../../../assets/no-image.png');
  }
}
