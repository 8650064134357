
import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import {deletePath} from '@/helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import NevronInput from '@/components/NevronInput.vue';

import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import MultiSelect from 'vue-multiselect';
import Axios from 'axios';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    EditContact,
    virtualList,
    EditCustomer,
    PreEdit,
    CustomerAddressEdit,
    AttachImage,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,

    NevronFooter,
    MultiSelect,
    TableModalGeneric,
    TableGeneric,
  },
  mixins: [Clickaway],
})

export default class LocationDetails extends Vue {
  location: any = null;
  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;
  stays: any = '';

  tempAcc!: IAccount;
  accountItems: IAccount[] = [];

  response: any = null;
  frontUrl: any = null;
  backUrl: any = null;
  search: any = {
    query: '',
  };
  searchQuery: string = '';
  CancelToken: any = Axios.CancelToken;
  source: any;
  categories: any[] = [];
  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;
  ids: number[] = [];

  indexAccountList: number[] = [];
  allAccountsChecked: boolean = false;
  searchInProgress: boolean = false;
  dragOver: boolean = false;
  preLeave: boolean = false;
  attachableContent: any = null;
  stores = stores;

  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.$route.params.id));
  }

  getAccountsOnPage(index: number) {
    return stores.accounts.fetchData(index, null, 10)
      .then((response) => {
        this.fullResponse = response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  deleteZone() {
    stores.Rooms.deleteItem(this.location.id)
      .then((response) => {
        showToaster('success', '', Vue.prototype.translate('successfully-deleted'));
        this.$router.push({name: 'rooms'});
      });
  }

  sortArrays(arrays: any) {
    return _.orderBy(arrays, 'sort', 'asc');
  }

  onSubmitStatus() {
    const form = this.$refs.form as HTMLFormElement;
    form.classList.add('was-validated');

    if (!form.checkValidity()) {
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
      return ;
    }
    return stores.Rooms.updateItem(this.location.id, this.location)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.fetchData(Number(this.$route.params.id));
          showToaster('success', this.location.name, 'successfully updated');
          return;
        }
        if(response && response.response && response.response.status === 422)
        {
          const error = response?.response?.data?.errors?.name || 'Something went wrong.';
          showToaster('warning',  this.location.name, error);
          return;
        }
        showToaster('danger',  this.location.name, 'Fail to update', response.code);
      }).catch((e: any) => {
        console.error(e);
        showToaster('danger',  this.location.name, 'Fail to update');
      });
  }

  fetchAccountData(search: string | null) {
    return stores.accounts.fetchData(1, this.search.query, 10)
      .then((response: any) => {
        // wrong page
        this.response = response;
        this.accountItems = this.response.data;
        this.accountItems = this.accountItems.filter((el) => {
          return el.customerId == null;
        });
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  mounted() {
    this.fetchData(Number(this.$route.params.id));
    this.getAutocompleteCategories();
  }

  loadPage() {
    this.fetchData(Number(this.$route.params.id));
  }

  popoverMethod() {
    if (this.location && this.location.id) {
      return deletePath(this.location.id);
    }
  }

  fetchData(id: number) {
    return stores.Rooms.getDetail(id)
      .then((response: any) => {
        this.location = response;
        this.location.categories.forEach((category: any) => {
          category.name = Vue.prototype.translate(category.name);
        });
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  attachWithImage(imgObj: any) {
    const data = {
      imageId: null,
    };
    if (imgObj) {
      this.location.imageId = imgObj.result.id;
      this.location.image = this.location.image || {};
      this.location.image.imageUrl = imgObj.result.imageUrl;
      // @ts-ignore
      this.$refs.image.$children[0].close();
      data.imageId = imgObj.result.id;
    } else {
      this.location.imageId = null;
      this.location.image = null;
    }
    // @ts-ignore
    return stores.Location.update(this.location.id, data)
      .then((response: any) => {
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  async attachCategory(selectedCategory: []) {
    try {
      await stores.Rooms.attachCatgories(selectedCategory, Number(this.$route.params.id));
    } catch (e) {
      console.error(e);
    }
  }

  async attachCategoryWithDetach(selectedCategory: []) {
    try {
      await stores.Rooms.attachCatgoriesWithDetach(selectedCategory, Number(this.$route.params.id));
    } catch (e) {
      console.error(e);
    }
  }

  async detachCategory(selectedInterest: []) {
    try {
      await stores.Rooms.detachCatgories(selectedInterest, Number(this.$route.params.id));
    } catch (e) {
      console.error(e);
    }
  }
  getAutocompleteCategories() {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();

    setTimeout(() => {
      this.searchQuery = 'all=true';
      return stores.Rooms.getAutocompleteCategories(this.searchQuery, this.source.token)
        .then((response) => {
          this.categories = response.data;
          this.categories.forEach((category: any) => {
            category.name = Vue.prototype.translate(category.name);
          });

          this.searchInProgress = false;
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500);
  }

  // stays
  attachStays(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.stayIds.includes(el.id));
    this.location.stays = this.location.stays.concat(newItems);
  }
  get stayIds() {
    return this.location.stays.map((el: IItem) => el.id);
  }
  detachStay(item: IItem) {
    this.location.stays = this.location.stays.filter((el: any) => el.id !== item.id);
  }

  // devices
  attachDevices(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.deviceIds.includes(el.id));
    this.location.devices = this.location.devices.concat(newItems);
  }
  get deviceIds() {
    return this.location.devices.map((el: IItem) => el.id);
  }
  detachDevice(item: IItem) {
    this.location.devices = this.location.devices.filter((el: any) => el.id !== item.id);
  }

}
