
import { Component, Vue, Watch } from 'vue-property-decorator';
import stores from "@/stores";
import ItemsIndex from "@/components/ListNevronComponents/Items/IndexGeneric.vue";
import { showToaster } from '@/components/DashboardComposer/helpers';

@Component({
  computed: {
    stores() {
      return stores
    }
  },
  components: {
    ItemsIndex,
  },
})
export default class DeviceGroupsList extends Vue {
  selectedItems: IDeviceGroup[] = [];

  async onActionClick(action: string) {
    if (this.selectedItems.length <= 0) {
      return;
    }
    const deviceGroupsIds = this.selectedItems.map(item => item.id).filter(id => id !== null) as number[];
    stores.Devices.deviceMacAddressesFromGroupId(deviceGroupsIds)
      .then((macAddresses) => {
        let response = null;
        let popupType = '';
        switch (action) {
          case 'ping':
            response = stores.Devices.ping(macAddresses.data);
            popupType = Vue.prototype.translate("ping");
            break;
          case 'reboot':
            response = stores.Devices.reboot(macAddresses.data);
            popupType = Vue.prototype.translate("reboot");
            break;
          case 'factoryReset':
            response = stores.Devices.factoryReset(macAddresses.data);
            popupType = Vue.prototype.translate("factory-reset");
            break;
          default:
            break;
        }
        if (response !== null) {
          response.then((data:any) => {
            if(data.responseCode == 200 && !data.error.message) {
              showToaster('success', popupType, data.data);
            } else {
              showToaster('warning', popupType, data.error.message, 404);
            }
          }).catch((error:any) => {
            console.error('An error occurred:', error);
            showToaster('danger', popupType, error.message);
          });
        }
      })
      .catch((error:any) => {
        showToaster('danger', '', error.message);
      });
  }
}
