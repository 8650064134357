
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import axiosInstance from '../../helpers/axios';
import stores from '../../stores';
import session from '@/stores/Session';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditCustomer extends Vue {
  @Prop()
    customer!: ICustomer;

  user: ICustomer = JSON.parse(JSON.stringify(this.customer));

  @Watch('customer', { immediate: true })
    setModuleFromProps() {
      this.user = JSON.parse(JSON.stringify(this.customer));
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  openFolderSys() {
    this.$emit('openFileSys');
    // @ts-ignore
    this.$children[0].close();
  }

  sendReload(files: any, result: any) {
    this.user.imageId = result.id;
    this.$emit('sendReload', this.user);
  }

  getFilePath(): string {
      return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }

  removeCustomer() {
    if (confirm('Do you really want to remove this customer?')) {
      this.$emit('delete');
    }
  }

  save(): void {
     this.$emit('save', this.user);
  }
  cancel(): void {
    // @ts-ignore
    this.$children[0].close();
  }

}
