
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {SweetModal} from 'sweet-modal-vue';
import axiosInstance from '../../helpers/axios';
import session from '@/stores/Session';

@Component({
  components: {
    SweetModal,
  },
})
export default class CollectionItem extends Vue {
  option: any = 'collection';

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.$emit('close', this.option);
  }

  update(option: string) {
    this.option = option;
  }

  next() {
    this.$emit('next', this.option);
    // @ts-ignore
    this.$children[0].close();
  }
}
