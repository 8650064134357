
import {Component, Vue} from 'vue-property-decorator';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
// @ts-ignore
import Switches from 'vue-switches';

@Component({
  components: {
    NevronHeader,
    Skeleton,
    Switches,
  },
})

export default class PaymentsDetails extends Vue {
  mail: any = '';
  response: any = '';
  paymentOptions: ISetting = {
    id: 0,
    key: 'payment_options',
    value: '0',
    type: 'payment',
  };
  countryCode: ISetting = {
    id: 0,
    key: 'country_code',
    value: ' ',
    type: 'country_code',
  };
  paymentCurrency: ISetting = {
    id: 0,
    key: 'payment_currency',
    value: ' ',
    type: 'currency',
  };
  secretKey: ISetting = {
    id: 0,
    key: 'secret_key',
    value: 'secret',
    type: 'payment',
  };
  publicKey: ISetting = {
    id: 0,
    key: 'public_key',
    value: 'secret',
    type: 'payment',
  };
  language: any = [];

  /**
   * Called when changes need to re-render
   */
  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('payment-configuration');
    this.fetch();
  }

  fetch() {
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
        console.log(response);
      });
    stores.Language.activeLanguages()
      .then((res) => {
        this.language = res;

      });
  }

  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'payment_options') {
        this.paymentOptions = data[index];
        this.paymentOptions.value = data[index].value;
      } else if (data[index].key === 'secret_key') {
        this.secretKey = data[index];
        this.secretKey.value = data[index].value;
      } else if (data[index].key === 'payment_currency') {
        this.paymentCurrency = data[index];
        this.paymentCurrency.value = data[index].value;
      }  else if (data[index].key === 'country_code') {
        this.countryCode = data[index];
        this.countryCode.value = data[index].value;
      } else if (data[index].key === 'public_key') {
        this.publicKey = data[index];
        this.publicKey.value = data[index].value;
      }
    }
  }

  /*
   *
   */
  onSubmit(id: any, value: any) {
    stores.Setting.update(id, {value})
      .then((response) => {
        console.log(response);
        this.fetch();
        console.log('setting updated successfully..');
      });
  }
}
