
import {Component, Vue} from 'vue-property-decorator';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
// @ts-ignore
import Switches from 'vue-switches';
import {Setting} from "@/stores/Setting";
import {hasPermission} from "@/helpers/permissions";

@Component({
  methods: {hasPermission},
  components: {
    NevronHeader,
    Skeleton,
    Switches,
  },
})

export default class UserSettings extends Vue {
  mail: any = '';
  response: any = '';

  request_checkout: ISetting = {
    key: 'request_checkout',
    value: "",
  };
  request_checkout_email: ISetting = {
    key: 'request_checkout_email',
    value: "",
  };
  survey: ISetting = {
    key: 'survey',
    value: "",
  };
  view_bill: ISetting = {
    key: 'view_bill',
    value: "",
  };
  casting: ISetting = {
    key: 'casting',
    value: "",
  };

  /**
   * Called when changes need to re-render
   */
  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('payment-configuration');
    this.fetch();
  }

  fetch() {
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
      });
  }

  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'request_checkout') {
        this.request_checkout = data[index];
        this.request_checkout.value = this.request_checkout.value !== '0';
      } else if (data[index].key === 'request_checkout_email') {
        this.request_checkout_email = data[index];
        this.request_checkout_email.value = data[index].value;
      } else if (data[index].key === 'survey') {
        this.survey = data[index];
        this.survey.value = this.survey.value !== '0';
      }  else if (data[index].key === 'view_bill') {
        this.view_bill = data[index];
        this.view_bill.value = this.view_bill.value !== '0';
      } else if (data[index].key === 'casting') {
        this.casting = data[index];
        this.casting.value = this.casting.value !== '0';
      }
    }
  }

  isValidEmail(email: string): boolean {
    if(email == "") {
      return true;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  onSubmit( id: number, value: ISetting) {
    if (this.isValidEmail(this.request_checkout_email.value)) {
      stores.Setting.update(id, { value }).then((response) => {
        this.fetch();
        console.log('setting updated successfully..');
      });
    } else {
      console.log(this.request_checkout_email.value + " is not valid");
    }
  }
  updateMultiple() {
    const array = [this.request_checkout, this.request_checkout_email];
    for (const item of array) {
      const idAsNumber = Number(item.id);
      if (!isNaN(idAsNumber)) {
        this.onSubmit(idAsNumber, item);
      }
    }
  }
}
