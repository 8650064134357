
import { Component, Vue, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';
import { showToaster } from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    SweetModal,
  },
})

export default class CreateDevice extends Vue {

  @Prop({default: false})
  emitCreated!: boolean;

  device: IDevice = {
    apk: '',
    firmware: '',
    ip: '',
    locationId: 0,
    macEth: '',
    macWifi: '',
    serialNumber: '',
    timeZone: '',
    type: '',
    manufacturer: 'Nevron',
    id: null,
    model: '',
    switchMac: '',
    switchIp: '',
    name: '',
    deviceTypeId: 0,
    deviceActionStatus: '',
    deviceConnectionCode:'',
    rs232: '',
    cacheEnabled: false,
    cacheSize: 8
  };
  deviceTypes: IDeviceType[] = [];

  macRegex = '^([0-9A-Fa-f]{2}[:\\-]){5}([0-9A-Fa-f]{2})$';
  wasFormSubmited: boolean = false;

  mounted() {
    this.getDeviceTypes();
  }

  handleFormSubmit(): void {
    const form = this.$refs.deviceForm as HTMLFormElement;
    this.wasFormSubmited = true;

    if(form.checkValidity()) {
      this.save();
    }
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  getDeviceTypes() {
    return stores.Devices.getAllDeviceTypesFull()
      .then((response) => {
        this.deviceTypes = response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  save() {
    return stores.Devices.createNewDevice(this.device)
      .then((response) => {
        console.log(response);
        if(response.success) {
          this.cancel();
          if (this.emitCreated) {
            this.$emit('created', response.data);
            return;
          }
          this.$parent?.$emit('submit', response); // what is this for?
          this.$router.push({name: 'device.show', params: {id: response.data.id}});
        } else {
          showToaster('danger', 'Device', response.error.message, response.responseCode);
        }

      });
  }

  isEthMacFieldRequired(): boolean {
    return !(!!this.device.serialNumber.trim() || !!this.device.macWifi.trim());
  }

  isWifiMacFieldRequired(): boolean {
    return !(!!this.device.macEth.trim() || !!this.device.serialNumber.trim());
  }

  isSerialMacFieldRequired(): boolean {
    return !(!!this.device.macEth.trim() || !!this.device.macWifi.trim());
  }
}
