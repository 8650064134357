import globalConfig from '@/helpers/globalConfig';
import axios from '@/helpers/axios';
import axiosInstance from '@/helpers/axios';

import {generateLog} from '@/helpers/functions';
import stores from '.';
import Echo from 'laravel-echo';

export default class Master {

  url: string;
  path: string;
  fullUrl: string;
  projects?: IManagerProject[] | null = null;
  projectTypes: IProjectType[] | null = null;
  features?: IFeature[] | null = null;
  routeName: string = 'Master';

  constructor() {
    this.url = globalConfig.url;
    this.path = globalConfig.path;
    this.fullUrl = `${globalConfig.url}/${globalConfig.path}`;
  }

  fetchData(index: number | null = null, search: string | null = null, perpage: number | null = null, filterQuery: string | null,  operator: string | null = null): Promise<any> {

    let url = `${this.fullUrl}/tenants`;
    if (index) {
      url += `?page=${index}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (perpage) {
      url += `&perpage=${perpage}`;
    }
    if (filterQuery) {
      url += `&${filterQuery}`;
    }
    if (operator) {
      url += `&filters[operators][]=${operator}`;
    }


    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

    async getAllProjects() {
      return axios.get(`${this.fullUrl}/tenants`)
        .then((response) => {
          this.projects = response.data.data;
          return this.projects;
        })
        .catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }

  async getAllProjectsInfo() {
    return axios.get(`${this.fullUrl}/alltenants`)
      .then((response) => {
        this.projects = response.data.data;
        return this.projects;
      })
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getSpecificProject(projectId: number | string) {
    return axios.get(`${this.fullUrl}/tenants/${projectId}`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getSpecificProjectInfo(projectId: number | string) {
    return axios.get(`${this.fullUrl}/tenants/${projectId}/info`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  checkTenantExists(projectId: number | string) {
    return axios.get(`${this.fullUrl}/tenants/${projectId}/exists`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createProject(project: any) {
    return axios.post(`${this.fullUrl}/tenants`, {project})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);

        return e;
      });
  }

  updateProject(id: string, project: any) {
    return axios.put(`${this.fullUrl}/tenants/${id}`, {project})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  copyProject(id: string, project: any) {
    return axios.post(`${this.fullUrl}/tenants/${id}/copy`, {project})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

    deleteProject(projectId: number | string) {
        return axios.delete(`${this.fullUrl}/tenants/${projectId}`)
            .then((response) => response.data).catch((e) => {
            const log: any = {
              route: this.routeName,
              message: e,
            };
            generateLog(log);
          });
    }

  getFeatures() {
    return axios.get(`${this.fullUrl}/features`)
      .then((response) => {this.features = response.data; return this.features; })
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getProjectTypesConfig(): Promise<any> {
    return axios.get(`${this.fullUrl}/project-type`)
      .then((response) => {
        this.projectTypes = response.data;
        return response.data;
      })
      .catch((e) => {
        const log: any = {
          route: this.routeName, message: e,
        };
        generateLog(log);
        return null;
      });
  }

  getProjectTypes(): Promise<IProjectType[] | null> {
      return this.getProjectTypesConfig()
        .then((data) => {
          if (!data) {
            return null;
          }
          return data.types as IProjectType[];
        });
  }

  getProjectTypesTranslations(): Promise<any> {
    return this.getProjectTypesConfig()
      .then((data) => {
        if (!data) {
          return null;
        }
        return data.translations;
      });
  }

  socket() {
    let globalConfig = stores.globalConfig.config;
    let echo = new Echo({
      broadcaster: 'pusher',
      key: globalConfig.wsAppKey,
      wsHost: globalConfig.pusherHost,
      wsPort: globalConfig.pusherPort,
      // wssPort: 443,
      forceTLS: false,
      authEndpoint: globalConfig.url + '/middleware/broadcasting/auth',
      auth: {
        headers: {
          'Authorization': 'Bearer ' +  sessionStorage.getItem('superadmin-token'),
          'Accept': 'application/json',
        }
      },
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws'],
    });

    return echo;
  }
}
