
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import NevronInput from '@/components/NevronInput.vue';
import {modulesMetaData} from '@/helpers/constants';
import Skeleton from '@/modules/Skeleton.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    SweetModal,
    NevronInput,
    Skeleton
  },
})

export default class NevronCopyMovePopupModal extends Vue {

  @Prop()
  ids!: string | null;

  @Prop({required: true})
  instance!: IModule;

  @Prop()
  path!: string | null;

  @Prop()
  checkMoveCopy!: string | null;

  modules: any = [];
  selectedModule: any = '';
  loadSkeleton = false;

  cancel() {
    // @ts-ignore
    this.$refs.editPopup.close();
  }

  @Watch('ids', { immediate: true })
  fetchModuleList() {
    stores.modules
      .getSpecificModule(this.instance.type)
      .then((response: any) => {
        this.modules = response;
        this.modules = this.modules.filter((module: IModule) => module.id !== this.instance.id);

      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  next() {
    this.loadSkeleton = true;
    if (this.checkMoveCopy === 'copy') {
      stores.modules
        .copyExistingItems(this.ids, this.selectedModule)
        .then((response: any) => {
          console.log(response);
          if (response.responseCode === 200) {
            this.loadSkeleton = false;
            this.$router.push({
              name: `${this.instance.routeName}.index`,
              params: {moduleId: this.selectedModule},
            });
          } else {
            showToaster('danger', ' ', response.message, 500);
            this.cancel();
          }

        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      stores.modules
        .moveExistingItems(this.instance.id!, this.ids, this.selectedModule)
        .then((response: any) => {
          console.log(response);
          if (response.responseCode === 200) {
            this.loadSkeleton = false;
            this.$router.push({
              name: `${this.instance.routeName}.index`,
              params: {moduleId: this.selectedModule},
            });
          } else {
            showToaster('danger', ' ', response.message, 500);
            this.cancel();
          }

        })
        .catch((e: any) => {
          console.log(e);
        });

    }


  }

}
