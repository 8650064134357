
import { Vue, Component, Prop } from 'vue-property-decorator';
import ChatContactsList from '@/components/Chat/ChatContactList.vue';

@Component({
    components: {
        ChatContactsList
  },
})
export default class ChatContacts extends Vue {
    @Prop({ default: false }) tabTitle!: string;
    @Prop({ default: false }) activeTab!: string;

    get isStaffContactList() {
        return this.activeTab === 'staff';
    }
  handleUserSelected(user: any) {
    this.$emit('user-selected', user);
  }

  public getRandomTimeWithinDay() {
    const today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const endOfDay = new Date();
    if (endOfDay < startOfDay) {
      endOfDay.setTime(startOfDay.getTime());
    }
    const randomTime = startOfDay.getTime() + Math.random() * (endOfDay.getTime() - startOfDay.getTime());
    return new Date(randomTime).toISOString();
  }
}


