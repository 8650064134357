interface IExcludedFeatures {
  [projectTypeKey: string]: string[];
}
export const excludedFeaturesForProjectType: IExcludedFeatures = {
  'project-type.HOTEL': [
    'module-type.AOD',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',
    'module-type.SHIP_POSITION_APPLICATION',
  ],
  'project-type.APARTMENT': [
    'module-type.TV_RADIO',
    'module-type.EPG',
    'module-type.VOD',
    'module-type.MOD',
    'module-type.SERIES',
    'module-type.AOD',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',
    'module-type.SHIP_POSITION_APPLICATION',
    'module-type.WIDGETS',
    'module-type.TIME',
    'module-type.WEATHER',
  ],
  'project-type.CAMPSITE': [
    'module-type.TV_RADIO',
    'module-type.EPG',
    'module-type.VOD',
    'module-type.MOD',
    'module-type.SERIES',
    'module-type.AOD',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',
    'module-type.SHIP_POSITION_APPLICATION',
    'module-type.WIDGETS',
    'module-type.TIME',
    'module-type.WEATHER',
  ],
  'project-type.CRUISE_SHIP': [
    'module-type.AOD',
    'guest-flow-type.FAST_CHECK_IN',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',

  ],
  'project-type.FERRY': [
    'module-type.VOD',
    'module-type.MOD',
    'module-type.SERIES',
    'module-type.AOD',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',
    'guest-flow-type.FAST_CHECK_IN',
    'guest-flow-type.SAVE_PLANET',

  ],
  'project-type.MEGAYACHT': [
    'module-type.AOD',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',
    'guest-flow-type.FAST_CHECK_IN',
  ],
  'project-type.BOAT': [
    'module-type.VOD',
    'module-type.MOD',
    'module-type.SERIES',
    'module-type.AOD',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',
    'module-type.SERVICE',
    'guest-flow-type.FAST_CHECK_IN',
    'guest-flow-type.SAVE_PLANET',
  ],
  'project-type.RESIDENTIAL': [
    'module-type.AOD',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',
    'module-type.SHIP_POSITION_APPLICATION',
    'guest-flow-type.FAST_CHECK_IN',
    'guest-flow-type.SAVE_PLANET',
  ],

  'project-type.HOSPITAL': [
    'module-type.AOD',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',
    'module-type.SHIP_POSITION_APPLICATION',
    'guest-flow-type.FAST_CHECK_IN',
    'guest-flow-type.SAVE_PLANET',
  ],
  'project-type.WORKSITE': [
    'module-type.AOD',
    'module-type.APPLICATION',
    'module-type.TV_APPLICATION',
    'module-type.WEBSITE_APPLICATION',
    'module-type.SHIP_POSITION_APPLICATION',
    'guest-flow-type.FAST_CHECK_IN',
    'guest-flow-type.SAVE_PLANET',
  ],
};
