import stores from '@/stores';

import { Route } from 'vue-router';
import router from '@/router';

/**
 * Checks if the currently logged in admin has permission for the passed in permission
 *
 * @param permission
 */
export function hasPermission(permission: string) {
  return stores.admin.permissions.includes(permission);
}

export function hasPermissionByScopeAndName(scope: string, permission: string) {
  return hasPermission(scope + '.' + permission);
}

/**
 * Checks if the currently logged in admin has permission for the passed in module type
 *
 * @param moduleType
 */
export function hasModulePermissionForType(moduleType: string) {
  return hasPermissionByScopeAndName('module-type', moduleType);
}

export function hasFeaturePermissionForType(featureType: string) {
  return hasPermissionByScopeAndName('feature', featureType);
}

export function hasPermissionForGuestFlowType(guestFlowType: string) {
  return hasPermissionByScopeAndName('guest-flow-type', guestFlowType);
}

export function anyPermissionForGuestFlowType() {
  return hasPermissionByScopeAndName('guest-flow-type','BASIC_PROFILING') ||
   hasPermissionByScopeAndName('guest-flow-type','ACCOMODATION_LIBRARY') ||
   hasPermissionByScopeAndName('guest-flow-type','RATINGS') ||
   hasPermissionByScopeAndName('guest-flow-type','SOCIAL_MEDIA') ||
   hasPermissionByScopeAndName('guest-flow-type','SAVE_PLANET') ||
   hasPermissionByScopeAndName('guest-flow-type','PROMOTIONS')
}

export function anyPermissionForAnyModule() {
  return hasModulePermissionForType('CATALOGUE') ||
  hasModulePermissionForType('TV_RADIO') ||
  hasModulePermissionForType('LIVE_CAMERAS') ||
  hasModulePermissionForType('VOD') ||
  hasModulePermissionForType('VIDEO') ||
  hasModulePermissionForType('AOD') ||
  hasModulePermissionForType('CONTACTS') ||
  hasModulePermissionForType('SERVICE') ||
  hasModulePermissionForType('WEBSITE_APPLICATION') ||
  hasModulePermissionForType('TV_APPLICATION') ||
  hasModulePermissionForType('TIME') ||
  hasModulePermissionForType('WEATHER') ||
  hasModulePermissionForType('SHIP_POSITION_APPLICATION') ||
  hasPermissionForGuestFlowType('BASIC_PROFILING') ||
  hasPermissionForGuestFlowType('ACCOMODATION_LIBRARY') ||
  anyPermissionForGuestFlowType() ||
  hasFeaturePermissionForType('MESSAGING') ||
  hasFeaturePermissionForType('DIGITAL_SIGNAGE') ||
  hasPermissionForGuestFlowType('FAST_CHECK_IN') ||
  hasPermissionForGuestFlowType('EMOTION_RECOGNITION') ||
  (  hasFeaturePermissionForType('CHAT') && stores.globalConfig.config.showBetaFeatures ) ||
  (  hasFeaturePermissionForType('WELCOME_SCREEN') && stores.globalConfig.config.showBetaFeatures )

}

export function hasPermissionForRoleByType(role: string, type: string) {
  if (role !== 'Admin' && role !== 'User') {
    return hasPermissionByScopeAndName('admin.role', 'Custom' + '.' + type);
  }
  return hasPermissionByScopeAndName('admin.role', role + '.' + type);
}

export function canNavigateTo(routeName: string) {
  const route = router.resolve({ name: routeName }).route;
  const hasPermissionForThisRoute = hasPermissionForRoute(route);
  return hasPermissionForThisRoute;
}

function hasPermissionForRoute(route: Route): boolean {
  const matchedRoutes = route.matched;
  for (let i = matchedRoutes.length - 1; i >= 0; i--) {
    const matchedRoute = matchedRoutes[i];
    if (matchedRoute.meta?.permission) {
      // If the route or any parent route has a permission in meta, check if user has it
      return hasPermission(matchedRoute.meta.permission);
    } else if (matchedRoute.meta?.permissions) {
      // multiple permissions for one route
      // return true if any permission exist for selected route
      let permissionsToCheck: boolean = false;
      matchedRoute.meta.permissions.forEach((item: string) => {
         if (hasPermission(item)) {
           permissionsToCheck = true;
         }
      });
      return permissionsToCheck;
    }
  }
  return true;
}
