window.GlobalConfig = window.GlobalConfig ||  {
  appName: 'Nevron IPTV/OTT Solutions',
  url: 'http://api.dev.nevron.eu',
  path: '/api/middleware',
  showBetaFeatures: true,
  showOperators: true,
  wsAppKey: 'app-key',
  pusherHost: 'localhost',
  pusherPort: 6001
};

function globalConfig() {
  let config: any = {};

  if (typeof window.GlobalConfig !== 'undefined') {
    config = window.GlobalConfig;
  }
  config.path = (config.path[0] === '/' ? config.path.slice(1) : config.path);

  return config;
}
export default globalConfig();
