
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import Series from '../../../../stores/modules/Series';
import VueTagsInput from '@johmun/vue-tags-input';
import Axios from 'axios';
import axiosInstance from '../../../../helpers/axios';
import session from '@/stores/Session';
import { SweetModal } from 'sweet-modal-vue';
import SeriesModules from '@/stores/modules/Series';

@Component({
  components: {
    VueTagsInput,
    SweetModal,
  },
})
export default class CreateSeasons extends Vue {
  @Prop()
  module!: SeriesModules;

  langTag: string = '';
  langTags: object[] = [];
  autocompleteLangItems: any[] = [];
  CancelToken: any = Axios.CancelToken;
  source: any;
  searchInProgress: boolean = false;
  imageUrl: string = '';
  attachableContent: any = null;
  contentUrl: any = '';
  selectedFolderId: any = 1;
  categriesName: any = [];

  renderKey: number = 0;

  get filteredlangItems(): any {
    return this.autocompleteLangItems.filter((i) => new RegExp(this.langTag, 'i').test(i.name));
  }

  catTag: string = '';
  catTags: object[] = [];
  autocompleteCatItems: any[] = [];

  get filteredCatItems(): any {
    return this.autocompleteCatItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.catTag.toLowerCase()) !== -1;
    });
  }

  serTag: string = '';
  serTags: object[] = [];
  autocompleteSerItems: any[] = [];

  get filteredSerItems() {
    return this.autocompleteSerItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.serTag.toLowerCase()) !== -1;
    });
  }
  seasons: Iseasons = this.initializeSeason();

  initializeSeason(): Iseasons {
    return {
      id: null,
      active: true,
      sort: null,
      name: '',
      shortDescription: '',
      fullDescription: '',
      image: null,
      imageId: null,
      year: null,
      price: '0',
      rating: '',
      numberOfEpisodes: 1,
      moduleId: null,
      seriesId: null,
    };
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.seasons = this.initializeSeason();
    this.renderKey++;
  }

  onSubmit() {
    if (!this.validateForm('create-form')) {
      return;
    }
    this.createSeasonItem(this.seasons)
      .then((response) => {
        this.cancel();
        this.$emit('created', response);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  createSeasonItem(item: any) {
    return axiosInstance.post(`series/${this.module.id}/series/${this.$route.params.id}/seasons`, item)
      .then((response) => {
        return response.data;
      });
  }
  validateForm(formRef: string): boolean {
    const form = this.$refs[formRef] as HTMLFormElement;
    form.classList.add('was-validated');
    return form.checkValidity();
  }
}
