
import {Component, Vue} from 'vue-property-decorator';
import TabManager from '@/mixins/TabManager';

import ItemsIndex from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import stores from "@/stores";
import NevronHeader from "@/components/NevronHeader.vue";
import {Setting} from "@/stores/Setting";
import {showToaster} from "@/components/DashboardComposer/helpers";

@Component({
  computed: {
    stores() {
      return stores
    }
  },
  components: {
    NevronHeader,
    ItemsIndex,
  },
})
export default class DigitalSignageIndex extends TabManager {

  settings: { [key: string]: ISetting } = {
    durationImages: new Setting({'key': 'module-type.DIGITAL_SIGNAGE.durationImages'}),
    durationTv: new Setting({'key': 'module-type.DIGITAL_SIGNAGE.durationTv'}),
    durationUrls: new Setting({'key': 'module-type.DIGITAL_SIGNAGE.durationUrls'}),
    hasImages: new Setting({'key': 'module-type.DIGITAL_SIGNAGE.hasImages'}),
    hasTv: new Setting({'key': 'module-type.DIGITAL_SIGNAGE.hasTv'}),
    hasUrls: new Setting({'key': 'module-type.DIGITAL_SIGNAGE.hasUrls'}),
    defaultTransition: new Setting({'key': 'module-type.DIGITAL_SIGNAGE.defaultTransition'}),
  };

  get TAB_KEYS() {
    return ['playlists', 'settings'];
  }

  mounted() {
    this.fetchSettings();
  }

  fetchSettings() {
    stores.Setting.getSettings()
      .then((response) => {
        Object.keys(this.settings).forEach((key) => {
          const setting = this.settings[key];
          const responseSetting = response.find((s: ISetting) => s.key === setting.key);
          if (responseSetting) {
            Object.assign(setting, {
              value: responseSetting.value,
              type: responseSetting.type,
              id: responseSetting.id,
            });
          }
        });
      });
  }

  async updateSetting(setting: ISetting): Promise<void> {
    await this.updateSettingApi(setting);
    showToaster('success', '', Vue.prototype.translate('successfully-updated'));
    await this.fetchSettings();
  }

  async updateSettings(settings: ISetting[]) {
    const updatePromises = settings.map(setting => this.updateSettingApi(setting));
    await Promise.all(updatePromises);
    showToaster('success', '', Vue.prototype.translate('successfully-updated'));
    await this.fetchSettings();
  }

  async updateSettingApi(setting: ISetting): Promise<void> {
    try {
      await stores.Setting.updateByKey(setting.key, setting);
    } catch (error) {
      showToaster('error', '', error);
    }
  }

  get elementTransitionFromOptions() {
    return [
      { value: 'NONE', icon: 'bx bx-x' },
      { value: 'LEFT', icon: 'bx bxs-right-arrow-square' },
      { value: 'RIGHT', icon: 'bx bxs-left-arrow-square' },
      { value: 'TOP', icon: 'bx bxs-up-arrow-square' },
      { value: 'BOTTOM', icon: 'bx bxs-down-arrow-square' },
    ]
  }
}
