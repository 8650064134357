
  import {Component, Vue} from 'vue-property-decorator';
  import stores from '@/stores';
  import NevronHeader from '@/components/NevronHeader.vue';
  import {generateLog} from '@/helpers/functions';
  import {showToaster} from '@/components/DashboardComposer/helpers';

  @Component({
    components: {
      NevronHeader,
    },
  })

  export default class CreateDashboardPanel extends Vue {
    panel: IPanel = {
      id: null,
      dashboardId: null,
      name: '',
      showName: true,
      showMore: false,
      linkModuleId: '',
      linkLayout: '',
      linkAction: '',
      linkElementId: '',
      sort: 0,
      panelType: '',
      position: '',
      elementType: '',
      dashboardBackground: null,
      active: true,
      elements: [],
    };
    nameRules = {};
    activeRules = {};
    elementTypeRules = {};
    showNameRules = {};
    showMoreRules = {};
    panelTypeRules = {};
    positionRules = {};
    sortRules = {};
    dashboardId: any | null = null;
    panelId: any | null = null;

    mounted() {
      this.dashboardId = this.$route.params.id;
      stores.dashboards.getDashboardPanelValidationRules()
        .then((response) => {
          const validationRules = response;
          if (validationRules[`name`]) {
            this.nameRules = validationRules[`name`];
          }
          if (validationRules[`active`]) {
            this.activeRules = validationRules[`active`];
          }
          if (validationRules[`element_type`]) {
            this.elementTypeRules = validationRules[`element_type`];
          }
          if (validationRules[`show_more`]) {
            this.showMoreRules = validationRules[`show_more`];
          }
          if (validationRules[`show_name`]) {
            this.showNameRules = validationRules[`show_name`];
          }
          if (validationRules[`panel_type`]) {
            this.panelTypeRules = validationRules[`panel_type`];
          }
          if (validationRules[`position`]) {
            this.positionRules = validationRules[`position`];
          }
          if (validationRules[`sort`]) {
            this.sortRules = validationRules[`sort`];
          }

        });

      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      const forms = document.getElementsByClassName('needs-validation');

      // Loop over them and prevent submission
      const validation = Array.prototype.filter.call(forms, (form) => {
        form.addEventListener('submit', (event: any) => {
          event.preventDefault();
          if (form.checkValidity() === true) {
            event.stopPropagation();
            this.save();
          }
          if (form.checkValidity() === false) {
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        }, false);
      });
    }

    // send data to backend
    save() {
      return stores.dashboards.createPanel(this.panel, this.dashboardId)
        .then((response) => {
          if (!response.success) {
            throw new Error(response.error.message);
          }
          console.log('You have successfully created a new dashboard panel!');
          this.panelId = response.id;
          this.$router.push({ name: 'dashboard-panel.show', params: {id: this.panelId} });
        })
        .catch((e: any) => {
          showToaster('danger', '', e.message);
        });
    }

    // reset all form data to there default condition
    cancel(): void {
      this.panel = {
        id: null,
        dashboardId: null,
        name: '',
        showName: true,
        showMore: true,
        linkModuleId: '',
        linkLayout: '',
        linkAction: '',
        linkElementId: '',
        sort: null,
        panelType: '',
        position: '',
        elementType: '',
        dashboardBackground: null,
        active: true,
        elements: [],
      };
    }
  }
