
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';

import GuestFlowModuleShow from '@/modules/GuestFlowModule/ShowBasic.vue';
import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import TabManager from "@/mixins/TabManager";

@Component({
  components: {
    GuestFlowModuleShow,
    ItemsIndexGeneric,
  },
})
export default class SocialMediaIndex extends TabManager {

  guestFlowModule: IGuestFlowModule | null = null;
  ratingPlatformModule = stores.SocialMedia;
  commentProposalModule = stores.CommentProposal;

  get TAB_KEYS() {
    return ['gfm', 'platforms', 'templates'];
  }

  async created() {
    const response = await stores.GuestFlow.fetchAll();
    const modules = response.data;
    if (modules) {
      this.guestFlowModule = modules.find((module: IGuestFlowModule) => module.type === 'SOCIAL_MEDIA');
    }
  }

}

