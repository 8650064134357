
import {Component, Prop, Vue} from 'vue-property-decorator';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronFileField from "@/components/NevronFileField.vue";
import {imageExtensions,videoExtensions} from "@/helpers/files";

@Component({
  components: {
    AttachImage,
    NevronFileField,
  },
})
export default class NevronImageField extends Vue {

  @Prop({default: null})
  media!: IMediaItem | null;
  fileExtensions : string[] = [];
  mounted()
  {
    this.fileExtensions = [...imageExtensions,...videoExtensions]
  }
  mediaChanged(file: IMediaItem)
  {
    this.$emit('selected', file);
  }

}
