
import { Vue, Component, Prop } from 'vue-property-decorator';
import AttachFile from '@/modules/Media/Attach.vue';
import stores from '@/stores';
import { showToaster } from '@/components/DashboardComposer/helpers';
import { translate } from '@/helpers/functions';

@Component({
  components: {
    AttachFile,
  },
})
export default class ChatSendMessageForm extends Vue {
  @Prop({ default: false }) selectedContactId !: number | null;
  @Prop({ default: false }) activeChat!: any;
  imageFiles: any[] = [];
  fileInput: HTMLInputElement | null = null;
  errors: any[] = [];
  files: any[] = [];
  messageText: string = '';
  sendingMessage: boolean = false;

  get isMessageSendable(){
    const isTypeTextMessage = this.messageText.length > 0;
    const isTypeFiles = this.files.length > 0;
    const isTypeImageFiles = this.imageFiles.length > 0;

    return isTypeTextMessage || isTypeFiles || isTypeImageFiles;
  }

  sendMessage(event: any) {
    event.preventDefault();
    if(this.sendingMessage) return
    if (this.selectedContactId != null) {
      this.sendingMessage = true;
      const formData = new FormData();

      formData.append('toUser', String(this.selectedContactId))
      formData.append('admin', String(true));
      formData.append('content', this.messageText);

      const files = this.files;
      const imageFiles = this.imageFiles;
      // Process each file
      if (imageFiles && imageFiles.length) {
        for (let i = 0; i < imageFiles.length; i++) {
          formData.append('images[]', imageFiles[i].id)
        }
      }

      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i].file)
        }
      }

      if(!this.isMessageSendable){
        this.sendingMessage = false;
        return;
      }

      if (files && files.length) {
        this.fileInput = null;
        this.files = [];
      }
      if (imageFiles && imageFiles.length) {
        this.imageFiles = [];
      }

      stores.ChatMessageCollection.sendMessage(formData)
        .then((response: any) => response.data).catch((e: any) => {
          const validationErrors = e.response.data.errors;

          Object.keys(validationErrors).forEach(key => {
            // Check if the key contains 'images'
            if (key.startsWith('images.')) {
              // Extract the index from the key
              const index = parseInt(key.split('.')[1]);
              // Assuming you have an array to store image errors
              if (!this.errors[index]) {
                this.$set(this.errors, index, []);
              }
              // Push each error message into the corresponding index in the array
              validationErrors[key].forEach((errorMessage: any) => {
                this.errors[index].push(errorMessage);
              });
            } else if (key.startsWith('files.')) {
              // Extract the index from the key
              const index = parseInt(key.split('.')[1]);
              // Assuming you have an array to store image errors
              if (!this.errors[index]) {
                this.$set(this.errors, index, []);
              }
              // Push each error message into the corresponding index in the array
              validationErrors[key].forEach((errorMessage: any) => {
                this.errors[index].push(errorMessage);
              });
            }
          });
        }).finally(()=>{
          // @ts-ignore
          this.$emit('messageSent',this.messageText)
          this.sendingMessage = false;
        });
      this.messageText = '';
    }
  }

  openImageFileInput(event: any) {
    this.$nextTick(() => {
      // @ts-ignore
      this.$refs.attach.$children[0].open();
    });
    // event.preventDefault();
    this.errors = [];
  }

  handleImageFileChange(event: any) {
    // to handle the design changes
    // const files = this.imageInput?.files;

    // if (files && files.length) {
    //   for (let i = 0; i < files.length; i++) {
    //     this.imageFiles.push({
    //       id: i,
    //       imageurl: URL.createObjectURL(files[i]),
    //       file: files[i],
    //       name: files[i].name
    //     });
    //   }
    // }
  }

  deleteImagefromUploadFilesList(event: any, fileId: any) {
    event.preventDefault();
    const objWithIdIndex = this.imageFiles.findIndex((obj) => obj.id === fileId);
    this.imageFiles.splice(objWithIdIndex, 1);
  }

  openFileInput(event: any) {
    // event.preventDefault();
    this.errors = [];
    this.fileInput = this.$refs.fileInput as HTMLInputElement;
    this.fileInput.click();
  }

  handleFileChange(event: any) {
    // to handle the design changes
    const files = this.fileInput?.files;

    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.files.push({
          id: i,
          imageurl: URL.createObjectURL(files[i]),
          file: files[i],
          name: files[i].name
        });
      }
    }
  }

  deleteFilefromUploadFilesList(event: any, fileId: any) {
    event.preventDefault();
    const objWithIdIndex = this.files.findIndex((obj) => obj.id === fileId);
    this.files.splice(objWithIdIndex, 1);
  }
  attachFileFromMediaLibrary(attach : any)
  {
    if(!attach.result.isImageFile)
    {
      showToaster('warning', ' ', translate('invalid-image'), 422);
      return;
    }
    const fileExists = this.imageFiles.some(existingFile => existingFile.id === attach.result.id);
    if (fileExists) {
      return
    }
    this.imageFiles.push({
      id: attach.result.id,
      name: attach.result.fileName,
      url: attach.result.imageUrl,
      path: attach.result.path,
      type: attach.result.svg
    })
  }
}
