
  import {Component, Vue, Watch} from 'vue-property-decorator';
  import stores from '@/stores';
  import NevronHeader from '@/components/NevronHeader.vue';
  import AttachImage from '@/modules/Media/Attach.vue';
  import CreateFolder from '@/modules/Media/CreateFolder.vue';
  import {Language} from '@/stores/Language';
  import LanguageSelector from '@/components/LanguageDropDown.vue';
  import DynamicContent from '@/modules/Translation/DynamicContent.vue';

  @Component({
    components: {
      NevronHeader,
      AttachImage,
      CreateFolder,
      LanguageSelector,
      DynamicContent,
    },
  })
  export default class CreateLanguage extends Vue {
    reference: ITranslation = {
      key: '',
      languageId: 2,
      value: '',
      id: null,
      static: true,
    };
    defLang: any = {
      name: 'English',
      localCode: 'en_US',
    };
    selectedLanguage: any = {
      name: '',
      flag: '',
      shortCode: '',
      id: null,
      territoryCode: '',
      localCode: '',
      fidelioCode: '',
      encoding: '',
      font: '',
      direction: 'rtol',
      active: true,
      translations: [],
    };
    target: ITranslation = {
      id: null,
      languageId: this.selectedLanguage.id,
      value: '',
      key: '',
      static: true,
    };
    referenceAvailable: boolean = false;
    testText: string = 'A woman raises her young grandson after her daughter goes missing. ';
    testKey: string = '1.1.description.text';

    mounted() {
      stores.Language.fetchData(1, '', 20).then((response: any) => {
        this.selectedLanguage = response.data[0];
      });
      this.onReferenceChange();
      // this.$refs.dynamic.$children[0].open();
    }

    onReferenceChange() {      
      // @ts-ignore
      this.reference.key = this.reference.value.toLowerCase().replace(/\s+/g, '-');
      stores.Language.checkKey(this.reference.key, this.selectedLanguage.id).then((response: any) => {
        if (!response) {
          this.referenceAvailable = false;
        } else {
          this.referenceAvailable = true;
        }
      });
    }

    changesLanguageIndex(language: Language) {
      this.selectedLanguage = language;
      this.updateTarget();
    }

    updateTarget() {
      // @ts-ignore
      this.target.key = this.reference.value.toLowerCase().replace(/\s+/g, '-');
      console.log('target key>>>', this.target.key);
    }

    saveReference() {
      stores.Language.createTranslation(this.reference).then((response: any) => {
        console.log('translation created', response);
      });
    }

    saveTarget() {
      this.target.languageId = this.selectedLanguage.id;
      stores.Language.createTranslation(this.target).then((response: any) => {
        console.log(response);
      });
    }
  }
