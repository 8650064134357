
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';
import SeriesModules from "@/stores/modules/Series";
import AttachImage from "@/modules/Media/Attach.vue";
import { bus } from '@/helpers/functions';

export interface INewSource {
  source: IContentSource,
  devices: number[]
}

@Component({
  components: {AttachImage, SweetModal},
  mixins: [Clickaway],
})
export default class NevronNewSourcePopup extends Vue {

  newSource: IContentSource = {
    id: null,
    source: '',
    quality: 'SD',
    deviceTypeId: null,
    sort: null,
    sourceType: '',
    sourceableType: null,
    mediaType: 'stream-url',
    authorizationType: 'auth_none',
    authorizationKey: '',
    mainSource: false,
  };

  selectedDevices: number[] = [];
  selectedMediaType: string | null | undefined = '';
  selectedAuth: string | null | undefined = '';
  allSourceTypes: any = null;
  videoSourceTypes: ISourcesType | null = null;
  authorizationTypes: any = null;

  mounted() {

    this.getSourceData();

    // @ts-ignore
    bus().$on('loadSourcePopup', (response) => {
      this.allSourceTypes = JSON.parse(JSON.stringify(response.deviceTypes));
      this.videoSourceTypes = JSON.parse(JSON.stringify(response.videoSourceTypes));
      this.authorizationTypes = JSON.parse(JSON.stringify(response.authorizationTypes));

      this.setModuleFromProps();
    });
  }

  @Watch('source', {immediate: true})
  setModuleFromProps() {
    this.selectedMediaType = this.newSource?.sourceType ?? null;
    this.selectedAuth = this.newSource?.authorizationType;
  }

  save(): void {
    const form = this.$refs.form as HTMLFormElement;

    if (!form.checkValidity()) {
      form.classList.add('was-validated');
      return;
    }

    let sourcesArray: IContentSource[] = this.selectedDevices.map(id => {
      return {
        ...this.newSource,
        deviceTypeId: id
      };
    });

    if(sourcesArray.length === 0) {
      sourcesArray = [this.newSource];
    }

    this.$emit('saveNew', sourcesArray);

    this.newSource = {
      id: null,
      source: '',
      quality: 'SD',
      deviceTypeId: null,
      sort: null,
      sourceType: '',
      sourceableType: null,
      mediaType: 'stream-url',
      authorizationType: 'auth_none',
      authorizationKey: '',
      mainSource: false,
    };

    this.selectedDevices = [];

    // @ts-ignore
    this.$children[0].close();
  }

  cancel(): void {
    this.$emit('cancel');
    // @ts-ignore
    this.$children[0].close();
  }

  openMediaLibrary(sourceType: string) {
    if (sourceType !== 'media-library') {
      return;
    }
    // @ts-ignore
    this.$refs.attachMediaUrl.$children[0].open()
  }

  mediaSource(source: any) {
    if(source.result.imageUrl)
    {
      this.newSource.source = source.result.imageUrl;
    }
    if(source.result.videoUrl)
    {
      this.newSource.source = source.result.videoUrl;
    }

  }

  getSourceData(e: any = null) {
    if (e) {
      e.preventDefault();
    }

    Promise.all([
      stores.modules.getSourceTypes(),
      stores.modules.getVideoSourceTypes(),
      stores.modules.getAuthorizationTypes()
    ]).then(([sourceTypes, videoSourceTypes, authorizationTypes]) => {

      bus().$emit('loadSourcePopup', {
        deviceTypes: sourceTypes,
        videoSourceTypes: videoSourceTypes,
        authorizationTypes: authorizationTypes
      });
    });
  }

}
