
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import { translate } from '@/helpers/functions';

@Component({
  components: {
    SweetModal,
   },
  mixins: [ Clickaway ],
})
export default class EditEpgSource extends Vue {
  @Prop()
  loc!: IEpgProvider;

  newEpgSource: IEpgProvider = JSON.parse(JSON.stringify(this.loc));
  types : IEpgProviderType[] = []

  mounted() {
    this.types = stores.epg.providerTypes
    console.log(this.newEpgSource)
  }
  @Watch('loc', { immediate: true })
    setModuleFromProps() {
      this.newEpgSource = JSON.parse(JSON.stringify(this.loc));
      console.log('post', this.loc);
  }

  deleteSource() {
    if (confirm('Do you really want to delete this source?')) {
      this.$emit('delete', this.loc);
    }
  }
  save() {
    this.$emit('save', this.newEpgSource);
  }
  cancel(): void {
    // @ts-ignore
    this.$children[0].close();
  }

}
