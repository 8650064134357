
import { Vue, Component, Prop, Inject, Watch } from 'vue-property-decorator';
import session from '@/stores/Session';
import stores from '@/stores';
import ChatMessage from '@/modules/Chat/ChatMessage.vue';
import { bus } from '@/helpers/functions';

@Component({
    components: {
        ChatMessage,
    },
})
export default class ChatMessages extends Vue {
    @Prop({ default: false }) activeChat !: any;
    @Prop({ default: false }) selectedContactId !: number | null;
    messages: any[] = [];
    $bus = bus()
    mounted() {
        let messagesSocket = stores.ChatMessageCollection.connectSocket(session.project!.id);

        let messages = stores.ChatMessageCollection.getChatMessages(this.activeChat?.id)

        if (messages != null) {
            messages.then((response: any) => {
                this.messages = response.data.messages;

                let user: any = {};
                let chat = response.data.chat;
                if (stores.ChatMessageCollection.getAdminType() !== chat.receiverUserType) {
                    // create user selected object
                    user = {
                        id: chat.receiverUser.id,
                        name: Vue.prototype.translate(chat.receiverUser.firstName) + ' ' + Vue.prototype.translate(chat.receiverUser.lastName),
                    }
                }
                else if (stores.ChatMessageCollection.getAdminType() !== chat.senderUserType) {
                    // create user selected object
                    user = {
                        id: chat.senderUser.id,
                        name: Vue.prototype.translate(chat.senderUser.firstName) + ' ' + Vue.prototype.translate(chat.senderUser.lastName),
                    }
                }
                this.$emit('update-user', user);
                this.$bus.$emit('unread-messages', {
                    'unreadMessages': response.data.totalUnreadMessages
                });
                this.$nextTick(() => {
                  this.$emit('messagesLoaded',response.data)
                })
            })
        }

        messagesSocket.private('Personal.' + session.project!.id + '.Chats.Admin.' +  stores.ChatMessageCollection.getChatAdminId())
            .listen('.chat.new.message', (socketMessage: any) => {
                this.messages = socketMessage.chat.chatMessages;
                this.$bus.$emit('unread-messages', {
                    'unreadMessages': socketMessage.totalUnreadMessages
                });
            });
    }

    @Watch('selectedContactId')
    getMessagesHistoryWithContact(){
        let messages = stores.ChatMessageCollection.getChatMessages(null,this.selectedContactId)
        if (messages != null) {
            messages.then((response: any) => {
                this.messages = response.data.messages;
                this.$nextTick(() => {
                  this.$emit('messagesLoaded',response.data)
                })
            })
        }
    }

    @Watch('activeChat')
    updateMessages() {
        let messages = stores.ChatMessageCollection.getChatMessages(this.activeChat.id)
        if (messages != null) {
            messages.then((response: any) => {
                this.messages = response.data.messages;
                this.$nextTick(() => {
                  this.$emit('messagesLoaded',response.data)
                })
            })
        }
    }
}
