
import { Component } from 'vue-property-decorator';
import TabManager from '@/mixins/TabManager';
import ItemsIndex from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import SurveySettings from "@/modules/Surveys/SurveySettings.vue";
import stores from "@/stores";
import SurveyQuestionModal from '@/modules/Surveys/Questions.vue'


@Component({
  computed: {
    stores() {
      return stores
    }
  },
  components: {
    ItemsIndex,
    SurveySettings,
    SurveyQuestionModal

  },
})
export default class SurveysIndex extends TabManager {
 
  selectedItem: IItem | null = null;
  stat: SurveyAnswerStats[] = [];
  get TAB_KEYS() {
    return ['surveys', 'survey-settings'];
  }

  async openResult(item: IItem)
  {
    this.selectedItem = item;
    await this.showStat(item)
    this.$nextTick(() => {
    // @ts-ignore
        this.$refs.question.$children[0].open();
    });
  }

  async showStat(item: IItem) {
      const response = await stores.Surveys.getStat(item.id);
      this.stat = response.data;
  }

}
