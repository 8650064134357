
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import FilterCheckbox from '@/modules/SuperAdminDevices/FilterCheckbox.vue';
import FilterSelect from '@/modules/SuperAdminDevices/FilterSelect.vue';

@Component({
    components: { FilterCheckbox, FilterSelect }
})
export default class AdminFilter extends Vue {
    @Prop({ default: false }) filter!: any;
    @Prop({ default: false }) filterTitle!: string;
    @Prop({default: false}) isClearFilters : boolean|any;

    appliedFilterOptions : any = [];

    mounted() {
    }

    @Watch('isClearFilters')
    clearFilters() {
       if (this.isClearFilters == false) {
         this.appliedFilterOptions = [];
       }
    }

    @Emit('updateFilters')
    updateFilters(checkedFilters: any) {
        if (checkedFilters.type === 'checkbox') {
          if (checkedFilters.isActive) {
            this.appliedFilterOptions.push(checkedFilters.filterOption)
          } else {
            let index = this.appliedFilterOptions.indexOf(checkedFilters.filterOption);
            if (index !== -1) {
              this.appliedFilterOptions.splice(index, 1);
            }
          }
        }

        if (checkedFilters.type === 'select') {
          this.appliedFilterOptions = [];

          checkedFilters.filterOption.forEach((element: any) => {
            console.log('iterating');
            console.log(element);
            this.appliedFilterOptions.push(element);
          });
        }

        return { [this.filterTitle]: this.appliedFilterOptions}
    }
}
