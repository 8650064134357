
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import stores from "@/stores";
import axiosInstance from "@/helpers/axios";
import {showToaster} from '@/components/DashboardComposer/helpers';
import {VueTransmit} from "vue-transmit/dist/src";
import VTransmitFile from "vue-transmit/dist/src/classes/VTransmitFile";
import NevronIcons from "@/components/NevronIcons.vue";

@Component({
  components: {
    NevronIcons
  },
})
export default class NevronFileDrop extends Vue {
  @Prop({ default: 0 }) folderId!: number;

  @Prop({ default: true }) fullpageDropzone!: boolean;

  uploadingFiles: VTransmitFile[] = [];

  get getHeaders() {
    return stores.File.getHeader();
  }
  get getFilePath() {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }
  get isLoading() {
    return this.uploadingFiles.length > 0;
  }

  // Hooks
  mounted() {
    if (this.fullpageDropzone) {
      window.addEventListener('dragover', function(e) {
        e.preventDefault();
      });
      window.addEventListener('drop', this.onFullPageDrop);
    }
  }

  beforeDestroy() {
    if (this.fullpageDropzone) {
      window.removeEventListener('dragover', function(e) {
        e.preventDefault();
      });
      window.removeEventListener('drop', this.onFullPageDrop);
    }
  }

  // Methods
  onFileAdded(file: VTransmitFile, a: any) {
    this.uploadingFiles.push(file);
    this.$emit('added', file);
  }
  onUploadSuccess(file: VTransmitFile, response: any) {
    this.uploadingFiles = this.uploadingFiles.filter(f => f !== file);
    showToaster('success', file.name, Vue.prototype.translate('file-uploaded'));
    const hasMoreFiles = this.uploadingFiles.length > 0;
    if (!hasMoreFiles) {
      this.clearAllFiles();
    }
    this.$emit('uploaded', file, hasMoreFiles);
  }

  onUploadError(file: VTransmitFile, error: any, request: XMLHttpRequest) {
    this.uploadingFiles = this.uploadingFiles.filter(f => f !== file);
    showToaster('danger', Vue.prototype.translate('file-upload-failed'), file.name);
    this.$emit('error', file, error);
  }

  clearAllFiles() {
    const uploader = this.$refs.uploader as VueTransmit;
    if (uploader) {
      uploader.removeAllFiles();
      this.uploadingFiles = [];
    }
  }

  cancelFile(file: VTransmitFile) {
    const uploader = this.$refs.uploader as VueTransmit;
    if (uploader) {
      uploader.removeFile(file);
      this.uploadingFiles = this.uploadingFiles.filter(f => f !== file);
      this.$emit('canceled', file);
    }
  }

  onFullPageDrop(e: any) {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const uploader = this.$refs.uploader as VueTransmit;
      Array.from(e.dataTransfer.files).forEach(file => {
        uploader.addFile(file as File);
      });
    }
  }

  openFileBrowser() {
    (this.$refs.uploader as Vue).$emit('triggerBrowseFiles');
  }

  formatFileSize(size: number): string {
    if (size < 1024) return size + ' bytes';
    let i = Math.floor(Math.log(size) / Math.log(1024));
    let num = (size / Math.pow(1024, i));
    let round = Math.round(num);
    let unit = ['KB', 'MB', 'GB', 'TB'][i - 1];
    return `${round} ${unit}`;
  }

}
