
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';
import stores from "@/stores";
import SurveyQuestion from "./Question.vue";


@Component({
  components: {
    SweetModal,
    SurveyQuestion,
  },
})

export default class SurveyQuestionModal extends Vue {

  @Prop()
  questions!: any | null;
  showAnswerResult = false;

  icons: any =
    {
      TV: '../../../../../img/tv-b.svg',
      MOBILE: '../../../../../img/mobile-b.svg',
      EMOTICON: '../../../../../img/my_mood-b.svg',
      CLOSE_ENDED: '../../../../../img/survey-b.svg',
    }

  cancel() {
    // @ts-ignore
    this.$refs.question.close();
  }

}
