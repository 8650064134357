
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import stores from '@/stores';
@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditUser extends Vue {
  // @ts-ignore
  @Prop()
  original!: ICombinedUser;

  user: any = {
    firstName: '',
    lastName: '',
    active: true,
    username: '',
    email: '',
    gender: '',
    password: '',
  };
  passwordConfirmError = false;


  @Watch('original', { immediate: true })
  setModuleFromProps() {
    this.user = JSON.parse(JSON.stringify(this.original));
    this.user.password = '';
  }

  submit() {
    const form = this.$refs.form as HTMLFormElement;
    const passwordsMatch = this.confirmPassword();
    if (form!.checkValidity() && passwordsMatch) {
      event!.stopPropagation();
      this.save();
    }
    if (form!.checkValidity() === false) {
      event!.stopPropagation();
    }
    form!.classList.add('was-validated');
  }

  confirmPassword(): boolean {
    this.passwordConfirmError = (!!this.user.passwordConfirmation && this.user.passwordConfirmation !== this.user.password );
    return ! (this.passwordConfirmError);
  }

  save(): void {
    this.cancel();
    this.$emit('save', this.user);
  }
  cancel(): void {
    (this.$refs.form as HTMLFormElement).classList.remove('was-validated');
    this.passwordConfirmError = false;
    // @ts-ignore
    this.$children[0].close();
  }

}
