import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/digitalsignage';

export class DigitalSignage extends Model<IPlaylist> implements IPlaylist {

  id!: number | null;
  title!: string | null;
  orientation!: DisplayOrientation;
  status!: PlaylistStatus;
  onDisplayStart!: string | null;
  onDisplayEnd!: string | null;
  displayInfinitely!: boolean;
  active!: boolean;
  elements!: null;
  deviceGroups!: [];
  constructor(attributes: any = {}, parentCollection?: any) {
    super({
        ...{
          id: null,
          title: '',
          orientation: '',
          status: '',
          onDisplayStart: '',
          onDisplayEnd: '',
          displayInfinitely: '',
          active: '',
          elements: null,
        }, ...attributes,
      }, // Default values
      parentCollection);
  }

  urlRoot(): string {
    return URL;
  }
}

export default class DigitalSignageCollection extends Collection<DigitalSignage> {

  routeName = 'digitalSignage';

  model(): Constructor<DigitalSignage> {
    return DigitalSignage;
  }

  url(): string {
    return URL;
  }

  fetchData(index: number | null, search: string | null, perPage: number | null) {
    if (cancel !== undefined) {
      cancel();
    }

    let url = `${this.url()}/playlist`;
    if (search) {
      url += `?search=${search}`;
    } else {
      url += `?page=${index}&perpage=${perPage}`;
    }

    return axiosInstance.get(url, {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      })
    })
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName, message: e,
        };
        generateLog(log);
      });
  }


  createNewItem(playlist: IPlaylist) {
    return axiosInstance.post(`${this.url()}/playlist`, playlist)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: 'DigitalSignagePlaylists', message: e,
        };
        generateLog(log);
      });
  }

  getItem(id: number | string): Promise<IPlaylist> {
    return axiosInstance.get(`${this.url()}/playlist/${id}`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: 'DigitalSignagePlaylists', message: e,
        };
        generateLog(log);
      });
  }

  updateItem(id: number | string, playlist: IPlaylist) {
    return axiosInstance.patch(`${this.url()}/playlist/${id}`, playlist)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: 'DigitalSignagePlaylists', message: e,
        };
        generateLog(log);
      });
  }

  deleteItem(id: number | string) {
    return axiosInstance.delete(`${this.url()}/playlist/${id}`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: 'DigitalSignagePlaylists', message: e,
        };
        generateLog(log);
      });
  }

  deleteItems(ids: number[] | string[]) {
    return axiosInstance.post(`${this.url()}/playlist/delete`, {ids})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  duplicateItem(id: number | string) {
    return axiosInstance.post(`${this.url()}/playlist/${id}/duplicate`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: 'DigitalSignagePlaylists', message: e,
        };
        generateLog(log);
      });
  }


}
