import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import stores from '@/stores/index';
import { generateLog } from '@/helpers/functions';
import Echo from 'laravel-echo';
import Pusher from "pusher-js";
import { Admin } from '../Admins';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/chats';
const AdminType = 'App\\Admin';
const CustomerType = 'App\\Customer';

export class ChatMessage extends Model<IChatMessage> implements IChatMessage {

  id!: number | null;
  message!: string;
  to!: number;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          id: -1,
          message: '',
          to: 0,
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }
}

export default class ChatMessageCollection extends Collection<ChatMessage> {

  routeName = 'chat';
  activeChat: number | null = null;

  model(): Constructor<ChatMessage> {
    return ChatMessage;
  }

  url(): string {
    return URL;
  }

  connectSocket(projectId: any) {
    let globalConfig = stores.globalConfig.config;

    const pusher = new Pusher(globalConfig.wsAppKey, {
      wsHost: globalConfig.pusherHost,
      wsPort: globalConfig.pusherPort,
      forceTLS: false,
      // encrypted: true,
      disableStats: true,
      enabledTransports: ['ws'],
    });

    let laravelEcho = new Echo({
      broadcaster: 'pusher',
      key: globalConfig.wsAppKey,
      wsHost: globalConfig.pusherHost,
      wsPort: globalConfig.pusherPort,
      // wssPort: 443,
      forceTLS: false,
      authEndpoint: globalConfig.url + '/' + projectId + '/middleware/broadcasting/auth',
      auth: {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem(projectId + '-token'),
          'Accept': 'application/json',
        }
      },
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws'],
    });

    return laravelEcho;
  }
  getChatAdminId()
  {

    if(!stores.admin.isNevronSuperAdmin) return stores.admin.id;
    let adminChatId = 0;
    stores.Setting.models.forEach(setting => {
      if(setting.key === 'chat_admin_user')
      {
        adminChatId = setting.value;
      }
    })

    return adminChatId;
  }
  async getChatAdmin()
  {
    if(!stores.admin.isNevronSuperAdmin) return stores.admin;
    return await Admin.getById(this.getChatAdminId().toString())
  }
  isSuperAdminUserSetup()
  {
    // By Default other admin user should not see
    if(!stores.admin.isNevronSuperAdmin) return true;
    let adminChatId = null;
    stores.Setting.models.forEach(setting => {
      if(setting.key === 'chat_admin_user')
      {
        adminChatId = setting.value;
      }
    })
    if(adminChatId) return true;
    return false;
  }

  fetchRecentChats() {
    return axiosInstance.get(this.url() + "?admin=true", {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    })
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  sendMessage(message: any) {
    return axiosInstance.post(this.url() + "/send", message).catch((e:any) => {
        throw e;
    });
  }

  getChatMessages(chatId: number | null, contactId: number | null = null) {
    // for fetching messages with chat id
    if (chatId != null) {
      return axiosInstance.get(this.url() + "/" + chatId + "?admin=true")
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }

    // for fetching messages with contact id
    if (contactId != null) {
      return axiosInstance.get(this.url() + "/history/" + contactId + "?admin=true")
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }

  sendMedia(filesData: any) {
    return axiosInstance.post(this.url() + "/send", filesData)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getUnreadMessageCount() {
    return axiosInstance.get(this.url() + "/unread?admin=true")
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getAdminType() {
    return AdminType;
  }

  getCustomerType() {
    return AdminType;
  }
}
