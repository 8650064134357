<script>
/**
 * Stat component -- specify the widget icon, title and value.
 */
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: '',
    },
    percentage: {
      type: Number,
      default: 0,
    },

  },
  methods: {
    dynamicClass(percentage) {
      if (percentage === 0) {
        return 'badge badge-pill badge-soft-warning font-size-16'
      }
      else if (percentage > 0) {
        return 'badge badge-pill badge-soft-success font-size-16'
      }
      else if (percentage < 0) {
        return 'badge badge-pill badge-soft-danger font-size-16'
      }
    }
  }


};
</script>

<template>
  <div class="stat-wrapper">
    <div class="media">
      <div class="media-body">
        <p class="heading">{{ translate(title) }}</p>
        <h4 class="mb-0">{{ count }} <span class="ml-1"> <i class="fas fa-caret-up icon-color"></i></span></h4>
        <div class="mt-2"> <span class="px-2 py-1" :class="dynamicClass(percentage)">
            <small><b> {{ percentage >= 0 ? '+' + percentage : + percentage }} %</b> </small>
          </span>
          <span class="ml-2">{{ translate('from_previous_period')}}</span>
        </div>
      </div>

      <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
        <span class="avatar-title rounded-circle">
          <img :src="icon" width="25" />
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>

.icon-color {
  color: #45c798;
}

.image-circle {
  border-radius: 50%;
}

.heading {
  font-weight: 600;
}
</style>
