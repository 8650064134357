
  import {Component, Vue, Watch} from 'vue-property-decorator';
  import stores from '@/stores';
  import NevronHeader from '@/components/NevronHeader.vue';
  import {Dashboards} from '@/stores/Dashboard';
  import vPagination from '@/components/VuePlainPagination.vue';
  import Skeleton from '@/modules/Skeleton.vue';
  import NevronEmpty from '@/components/NevronEmpty.vue';
  import NevronSearch from '@/components/NevronSearch.vue';
  import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
  import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
  import NevronInput from '@/components/NevronInput.vue';

  @Component({
    components: {
      vPagination,
      NevronHeader,
      Skeleton,
      NevronEmpty,
      NevronSearch,
      NevronSearchNoResults,
      NevronClickConfirm,
      NevronInput,
    },
  })

  export default class ShowDashboard extends Vue {
    selectedDashboard: Dashboards = new Dashboards();
    defaultDashboardId: any = null;
    modules = stores.modules.models;
    itemsForModule = [];

    mounted() {
      this.fetchData(this.$route.params.id);
    }

    onSubmit() {
      if (this.selectedDashboard) {
        // preparing data to send
        const data = {
          name: this.selectedDashboard.name,
          active: this.selectedDashboard.active,
          activePanel: this.selectedDashboard.activePanel,
          deviceTypeId: this.selectedDashboard.deviceTypeId,
          background: this.selectedDashboard.background,
          redirectModuleId: this.selectedDashboard.redirectModuleId,
          redirectElementId: this.selectedDashboard.redirectElementId,
          redirectAction: this.selectedDashboard.redirectAction,
        };
        // request to backend
        return stores.dashboards.update(this.selectedDashboard.id, data)
          .then((response) => {
            console.log('Success');
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    fetchData(id: string) {
      stores.dashboards.getDashboard(id)
        .then((dashboard) => {
          this.selectedDashboard = dashboard;
          this.activeHandler(dashboard.type);
        })
        .catch((reason) => {
          console.log('Dashboard Could not found', reason);
          if (!this.selectedDashboard.id) {
            this.$router.push({name: 'dashboards'});
          }
        });
    }

    activeHandler(type: any) {
      if (type === 'STB' ) {
        stores.Setting.getDashboard('default_dashboard_stb')
        .then((response) => {
        this.defaultDashboardId = response.value;
      });
      } else if (type === 'Mobile' ) {
        stores.Setting.getDashboard('default_dashboard_mobile')
        .then((response) => {
        this.defaultDashboardId = response.value;
      });
      } else {
        this.defaultDashboardId = 0;
      }
    }

  }
