
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronInput from '@/components/NevronInput.vue';
import {dynamicModules} from '@/helpers/constants';
import Skeleton from '@/modules/Skeleton.vue';
// @ts-ignore
import Switches from 'vue-switches';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
// @ts-ignore
import NevronModuleQr from "@/components/NevronModuleQr.vue";
import { hasPermission } from '@/helpers/permissions';
import {isSettingDisabled, isSettingEnabled} from "@/helpers/modules-settings";
import {bus, translate, validateForm} from '@/helpers/functions';

@Component({
  methods: {isSettingEnabled, isSettingDisabled, hasPermission},
  components: {
    NevronHeader,
    NevronFooter,
    NevronInput,
    Skeleton,
    Switches,
    NevronImageField,
    NevronAdvanceTextBox,
    NevronModuleQr,
  },
})
export default class ModuleUpdate extends Vue {
  // optional prop module id
  @Prop() moduleId?: number;
  module: any = {
    description: '',
    id: null,
    name: null,
    note: '',
    status: 'Active',
    type: 'TV_RADIO',
    imageId: null,
    active: null,
    qr: {
      id: null,
      moduleType: 'DYNAMIC',
      moduleId: null,
      url: null,
      counter: 0,
    },
    qrAttributes: {} = {}
  };
  timeShift = {
      enabled: {
        key: 'time_shift',
        value: false,
        type: 0,
      },
      daysInPast: {
        key: 'time_shift_days',
        value: 1,
        type: 0,
      }
  };
  itemName: IModuleSetting = {
    key: 'item_name',
    value: 'Items',
    type: 0,
  };
  categoryName: IModuleSetting = {
    key: 'category_name',
    value: 'Categories',
    type: 0,
  };
  itemActionLabel: IModuleSetting = {
    key: 'action_label',
    value: 'ORDER',
    type: 0,
  };
  itemAppearance: IModuleSetting = {
    key: 'item_appearance',
    value: 'List',
    type: 0,
  };
  elementType: IModuleSetting = {
    key: 'element_type',
    value: 'card-cover',
    type: 0,
  };
  orderable: IModuleSetting = {
    key: 'orderable',
    value: false,
    type: 0,
  };
  bookable: IModuleSetting = {
    key: 'bookable',
    value: false,
    type: 0,
  };
  countable: IModuleSetting = {
    key: 'countable',
    value: false,
    type: 0,
  };
  sharable: IModuleSetting = {
    key: 'sharable',
    value: false,
    type: 0,
  };
  sizes: IModuleSetting = {
    key: 'sizes',
    value: false,
    type: 0,
  };
  variants: IModuleSetting = {
    key: 'variants',
    value: false,
    type: 0,
  };
  accessories: IModuleSetting = {
    key: 'accessories',
    value: false,
    type: 0,
  };
  linkModule: IModuleSetting = {
    key: 'link_module',
    value: false,
    type: 0,
  };
  staffEmail: IModuleSetting = {
    key: 'staff_email',
    value: '',
    type: '',
  };

  options: any = '';
  itemAppearanceList: any = [
    {name: 'simple-list', type: 'Simple_List'},
    {name: 'list', type: 'List'},
    {name: 'tiles', type: 'Tiles'},
    {name: 'block', type: 'Block'},
    {name: 'details', type: 'Details'},
    {name: 'content', type: 'Content'}
  ];
  listTranslated: any = '';

  isBookable: boolean = false;
  selectedAppearanceItem: any = '';

  get currentModuleId(): number {
    return this.moduleId || Number(this.$route.params.id);
  }

  get isLoading() {
    if (!this.module) {
      return true;
    }
    if (!this.module.id) {
      return true;
    }
    return false;
  }

  get hasElementType() {
    return this.module.id && dynamicModules.includes(this.module.type);
  }

  hasPermission(permission: string): boolean {
    return hasPermission(permission);
  }

  mounted() {
    if (this.moduleId) {
      this.$route.params.id = String(this.moduleId);
    }
    this.fetchData();
  }

  submitForm() {
    if (validateForm('update-module-form')) {
      this.update();
    }
  }

  imageSelected(image: IMediaItem) {
    if (this.module) {
      this.module.image_id = (image) ? image.id : null;
    }
  }

  hideFieldOnType(moduleType: string){
    if (moduleType == this.module.type){
      return false;
    }

    return true;
  }

  async update() {
    if (this.module.active === 1) {
      this.module.active = true;
    } else if (this.module.active === 0) {
      this.module.active = false;
    }
    await stores.modules.updateItem(this.currentModuleId, this.module)
      .then((response) => {
        this.updateSettings();
        this.updateOptions();
        this.fetchData();
      });

  }

  updateSettings() {
    const settings = [];
    settings.push(this.orderable);
    settings.push(this.staffEmail);
    settings.push(this.bookable);
    settings.push(this.countable);
    settings.push(this.sharable);
    settings.push(this.sizes);
    settings.push(this.variants);
    settings.push(this.accessories);
    settings.push(this.linkModule);
    settings.push(this.timeShift.enabled);
    settings.push(this.timeShift.daysInPast);

    if (this.hasElementType) {
      settings.push(this.elementType);
    }
    stores.modules.updateModuleSetting(this.currentModuleId, settings)
      .then((response: any) => {
        // console.log(response);
      })
      .catch((e: any) => {
        console.log(e);
        return e;
      });
  }

  updateOptions() {
    const options = [];
    if (hasPermission('global-settings.PRIVILEGED_EDITOR')) {
      options.push(this.itemName);
      options.push(this.categoryName);
    }
    options.push(this.itemActionLabel);
    this.itemAppearance.value = this.selectedAppearanceItem;
    options.push(this.itemAppearance);

    stores.modules.updateModuleOption(this.currentModuleId, options)
      .then(async (response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(this.module.name), 'successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.module.name), 'Fail to update');
        }
      })
      .catch((e: any) => {
        console.log(e);
        showToaster('danger', Vue.prototype.translate(this.module.name), 'Fail to update');
        return e;
      });
  }

  fetchData() {
    stores.modules.getModule(this.currentModuleId)
      .then((response) => {
        this.module = response;
        this.fetchSetting();
        this.fetchOptions();
      });
  }

  fetchSetting() {
    stores.modules.fetchModuleSetting(this.currentModuleId)
      .then((response) => {
        if (!response) {
          return;
        }

        this.elementType.value = (response.elementType) ? response.elementType : this.elementType.value;
        if (response.bookable === '1') {
          this.bookable.value = true;
        }
        if (response.countable === '1') {
          this.countable.value = true;
        }
        if (response.orderable === '1') {
          this.orderable.value = true;
        }
        if (response.sharable === '1') {
          this.sharable.value = true;
        }
        if (response.sizes === '1') {
          this.sizes.value = true;
        }
        if (response.variants === '1') {
          this.variants.value = true;
        }
        if (response.accessories === '1') {
          this.accessories.value = true;
        }
        if (response.linkModule === '1') {
          this.linkModule.value = true;
        }
        if (response.staffEmail) {
          this.staffEmail.value = response.staffEmail;
        }
        if (response?.timeShift) {
          this.timeShift.enabled.value = response.timeShift == 1;
        }
        if (response?.timeShiftDays) {
          this.timeShift.daysInPast.value = response.timeShiftDays == 0 ? 1 : response.timeShiftDays;
        }
      });
  }

  fetchOptions() {
    stores.modules.fetchModuleOptions(this.currentModuleId)
      .then((response) => {
        this.options = response;
        this.itemName.value = (response.itemName) ? response.itemName : this.itemName.value;
        this.categoryName.value = (response.categoryName) ? response.categoryName : this.categoryName.value;
        this.itemActionLabel.value = (response.actionLabel) ? response.actionLabel : this.itemActionLabel.value;
        this.itemAppearance.value = (response.itemAppearance) ? response.itemAppearance : this.itemAppearance.value;
        this.selectedAppearanceItem = Vue.prototype.translate(this.itemAppearance.value);
      });

  }

  updateRatio(ratio: string) {
    this.elementType.value = ratio;
  }

  deleteModule() {
    stores.modules.deleteItem(this.module.id)
      .then((response: any) => {
        showToaster('success', 'Module' , ' Deleted Successfully');

    bus().$emit('refreshSidebar', true);
        this.$router.push({name: 'home'});
      }).catch(e => showToaster('danger', 'Module', e));
  }

  cancel(): void {
    this.$router.push({name: 'modules.index'});
  }
}
