
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';

@Component({
  components: {},
})

export default class DeleteCustomer extends Vue {
  /**
   * Fired when this module is created
   */
  created() {
    this.delete();
  }

  delete() {
    return stores.Customer.deleteItem(Number(this.$route.params.id))
      .then(() => {
        this.$router.push({name: 'customers'});
      })
      .catch((reason) => {
        console.error('Could not delete', reason);
      });
  }
}
