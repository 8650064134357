import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import { generateLog } from '@/helpers/functions';
import Axios from 'axios';

let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL = '/surveys';

export class Survey extends Model<ISurvey> implements ISurvey {
  id: number | null = null;
  name: string = '';
  status: SurveyStatus = 'DRAFT';
  active: boolean = true;
  deliveryChannels: string[] = [];
  questions: ISurveyQuestion[] = [];
  scheduleType: string = '';
  displayFromType: string = '';
  fromDisplayDateTime: string = '';
  toDisplayDateTime: string = '';
  displayInfinitely: boolean = false;
  numberOfDays?: number = 0;

  customers = [];
  deviceGroups = [];

  constructor(attributes: Partial<ISurvey>, parentCollection?: Collection) {
    super(attributes, parentCollection);
    Object.assign(this, attributes);
  }

  urlRoot(): string {
    return URL;
  }
}

export default class SurveyCollection extends Collection<Survey> {
  routeName = 'surveys';

  url(): string {
    return URL;
  }

  model(): new (attributes: ISurvey, collection?: Collection) => Survey {
    return Survey;
  }

  build(attributes: ISurvey): Survey {
    return new Survey(attributes, this);
  }

  deleteItem(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data);
  }

  deleteItems(ids: number[]) {
    return axiosInstance.post(`${this.url()}/delete`, { ids })
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  fetchData(index: number = 1, search: string | null = null, perpage: number = 10) {
    if (typeof cancel === 'function') {
      cancel();
    }

    const cancelToken = new CancelToken(function executor(c: any) {
      cancel = c;
    });

    const baseURL = `${this.url()}?page=${index}&perpage=${perpage}`;
    const url = search ? `${baseURL}&search=${search}` : baseURL;

    return axiosInstance.get(url, { cancelToken })
      .then((response) => response.data.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  createNewItem(survey: ISurvey) {

    return axiosInstance.post(`${this.url()}`, survey)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(id: number, survey: ISurvey) {
    return axiosInstance.put(`${this.url()}/${id}`, survey)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        throw e;
      });
  }

  duplicateItem(id: number | string) {
    return axiosInstance.post(`${this.url()}/${id}/duplicate`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: 'Surveys', message: e,
        };
        generateLog(log);
      });
  }
  showIcons(item: any, icon: any): boolean {
    if (!icon || !icon.name) return false;
    if (icon.name === 'tv') {
      let showTvIcon = false
      item.deliveryChannels.forEach((element: any) => {
        if (element.toLowerCase() === 'tv')
          showTvIcon = true;
      })
      return showTvIcon;
    }
    if (icon.name === 'mobile') {
      let showMobileIcon = false
      item.deliveryChannels.forEach((element: any) => {
        if (element.toLowerCase() === 'mobile')
          showMobileIcon = true;
      })
      return showMobileIcon;
    }

    if (icon.name === 'scheduled') {
      let showScheduledIcon = false
      if (item.status.toLowerCase() === 'scheduled') {
        showScheduledIcon = true;
      }
      return showScheduledIcon;
    }

    if (icon.name === 'emoticons') {
      let emoticonsIcon = false
      item.questions.forEach((element: any) => {
        if (element.answerType.toLowerCase() === 'emoticon')
          emoticonsIcon = true;
      })
      return emoticonsIcon;
    }

    if (icon.name === 'open_ended') {
      let openEndedIcon = false
      item.questions.forEach((element: any) => {
        // Edge case: Handle for both open ended and close ended and show the same icon.
        if (element.answerType.toLowerCase() === 'open_ended' || element.answerType.toLowerCase() === 'close_ended')
          openEndedIcon = true;
      })
      return openEndedIcon;
    }
    return false;
  }

  showButtons(item: object, key: object) {
    // Show all button by default. Overriden in child classes
    return true;
  }
  handleButtonClick(item: object, key: object) {
    // Handle Results Modal (Followup Ticket)
  }

  getAnswers(questionId: number | null) {
    return axiosInstance.get(`${this.url()}/guest/answers?survey_question_id=${questionId}`)
      .then((response: any) => {return response.data.data}).catch((e: any) => {
        const log: any = {
          route: 'Surveys', message: e,
        };
        generateLog(log);
      });
  }

  getRecipient(surveyId: number){
    return axiosInstance.get(`${this.url()}/${surveyId}/recipients`)
    .then((response:any) => {
      return response.data;
    }).catch((e:any) => {
      const log: any = {
        route: 'Surveys', message: e,
      };
      generateLog(log);
    });
  }

  getStat(survey_id: number | null) {
    return axiosInstance.get(`${this.url()}/guest/stats?survey_id=${survey_id}`)
      .then((response: any) => {return response.data}).catch((e: any) => {
        const log: any = {
          route: 'Surveys', message: e,
        };
        generateLog(log);
      });
  }
}
