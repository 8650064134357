
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {SweetModal} from "sweet-modal-vue";
import {hasModulePermissionForType} from "@/helpers/permissions";
import {enableSubmitButton, disableSubmitButton, resetForm, translate} from '@/helpers/functions';
import ModulesSkeleton from '@/modules/ModulesSkeleton.vue';
import stores from "@/stores";
import {goToModuleIndex} from "@/helpers/routing";

@Component({
  methods: {
    translate,
    hasModulePermissionForType,
  },
  components: {
    SweetModal,
    ModulesSkeleton
  },
})
export default class DuplicateModule extends Vue {
  localModule = this.getInitialModuleState();
  isLoading: boolean = false;

  moduleName: boolean = false;

  @Prop() module?: any;

  nameRules = {
    pattern: '^(?!\s*$).+',
  };

  isNameValid = false;

  errors: string[] = [];

  getInitialModuleState()  {
    return {
      description: "",
      id: null,
      name: "",
      note: "",
      status: "ENABLED",
      type: "TV_RADIO",
      qr: {
        id: null,
        moduleType: 'DYNAMIC',
        moduleId: null,
        url: null,
        counter: 0,
      }
    };
  }

  onModalOpen() {
    // this.onModuleChanged();
  }

  mounted() {
    const form = document.getElementById('duplicate-form-module') as HTMLFormElement;
    form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      this.save();
    }, false);
  }

  @Watch("module")
  onModuleChanged() {
    this.isLoading = false;
    this.localModule = this.module;
    this.localModule.name = translate(this.module.name) + ' (copy)';
    this.validateName();
  }

  cancel() {
    this.errors = [];
    this.localModule = this.getInitialModuleState();
    resetForm('duplicate-form-module')
    // @ts-ignore
    this.$children[0].close();
  }

  validateName() {
    this.isNameValid = new RegExp(this.nameRules.pattern).test(this.localModule.name);
  }

  save() {
    this.errors = [];
    const form = document.getElementById('duplicate-form-module') as HTMLFormElement;
    if (form && this.isNameValid && form.checkValidity() === true) {
      this.isLoading = true;
      disableSubmitButton('duplicate-form-module');
      stores.modules.copyModule({id: this.module.id, name: this.module.name})
        .then((response: any) => {
          enableSubmitButton('duplicate-form-module');
          if (!response.success) {
            throw new Error(response.error.message);
          }
          this.cancel();
          stores.modules.getData().then((r) => {
            goToModuleIndex(response.data, {tab: 'settings'});
          });
        })
        .catch((e: any) => {
          enableSubmitButton('duplicate-form-module');
          this.errors.push(e.message);
        });
      this.isLoading = false;
    }
    form.classList.add('was-validated');
  }
}
