
import stores from '@/stores';
import { Component, Vue, Prop, Emit} from 'vue-property-decorator';
import { showToaster } from './DashboardComposer/helpers';

@Component({
})
export default class NevronSlideInPopup extends Vue {
    @Prop({ default: false }) isModalOpen: boolean = false;
    categories: IInstructions[] = [];
    categoryToggleStates: boolean[] = [];

    mounted(){
        if(stores.admin.isSuperAdmin)
        {
            stores.InstructionCategories.superAdmin = true;
        }
        stores.InstructionCategories.fetchData().then(response => {
            this.categories = response.data
            this.categoryToggleStates = new Array(this.categories.length).fill(false);
        }).catch(e => {
            showToaster('danger','','Unable to load instructions',400);
        });
    }

    toggleCategories(index: number) {
        // Toggle the state of the clicked category
        this.$set(this.categoryToggleStates, index, !this.categoryToggleStates[index]);
    }

    isCategoryExpanded(index:number){
        return this.categoryToggleStates[index];
    }

    @Emit('closePopup')
    closeModal() {
        return false;
    }
}
