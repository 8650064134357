
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';
import routerBase from '@/helpers/routerBase';
import globalConfig from '@/helpers/globalConfig';

import {AxiosError} from 'axios';
import { bus } from '@/helpers/functions';

@Component
export default class AdminLoginItem extends Vue {
  username: string = '';
  password: string = '';
  rememberMe: boolean = false;
  session = stores.session;

  inDevelopment = globalConfig.env === 'development';

  redirectPath: string | null = null;

  errorMessage: string | null = null;

  admin = stores.admin;
  image = require('@/assets/images/profile-img.png');

  @Watch('admin.isLoggedIn', {immediate: true})
  adminWatch() {
    this.redirect();
  }

  redirect() {
bus().$emit('logged', 'User logged');
    if (this.admin.isLoggedIn) {
      this.$router.replace(this.redirectPath || '/admin/projects');
    }
  }

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('login');
    this.redirectPath = '/admin/projects'
  }

  async onSubmit() {
    try {
      const loginResponse = await stores.admin.login(this.username, this.password, this.rememberMe, true);
      this.errorMessage = null;
      bus().$emit('login');
      this.redirect();
    } catch (error) {
      console.error(error);
      this.errorMessage = (error && error.message) || (error && error.data && error.data.message) || 'An unexpected error occurred.';
      throw error;
    }
  }
}
