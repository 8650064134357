
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditDeviceTypes extends Vue {
  @Prop()
    dev!: IDeviceType;

    newDeviceType: IDeviceType = JSON.parse(JSON.stringify(this.dev));

  @Watch('dev', { immediate: true })
    setModuleFromProps() {
      this.newDeviceType = JSON.parse(JSON.stringify(this.dev));
  }

  removeDevice() {
    this.$emit('delete');
  }

  save() {
    this.$emit('save', this.newDeviceType);
  }
  cancel(): void {
    // @ts-ignore
    this.$children[0].close();
  }
}
