
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class NevronError extends Vue {
  @Prop({default: 404}) code!: number;
  @Prop({default: 'error'}) message!: string;
  @Prop({default: true}) isTranslatable!: boolean;

  get errorMessageToShow() {
    return this.isTranslatable ? Vue.prototype.translate(this.message) : this.message;
  }

  mounted() {
    window.addEventListener('popstate', () => {
      this.$router.go(-2);
    });
  }
}
