
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditTranscoderHardware extends Vue {
  @Prop()
  str!: ITranscoderStream;

  stream: ITranscoderStream | null = JSON.parse(JSON.stringify(this.str));

  @Watch('str', { immediate: true })
    setModuleFromProps() {
      this.stream = JSON.parse(JSON.stringify(this.str));
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  save() {
    this.$emit('save', this.stream);
    // @ts-ignore
    this.$children[0].close();
  }

}
