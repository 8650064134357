
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {SweetModal} from 'sweet-modal-vue';
import axiosInstance from '../../helpers/axios';
import session from '@/stores/Session';
import stores from '@/stores';
import NevronImageField from '@/components/NevronImageField.vue';
import {Dashboards} from '@/stores/Dashboard';

@Component({
  components: {
    SweetModal,
    NevronImageField,
  },
})
export default class CollectionItem extends Vue {

  services: any = [];
  component: any = {
    elementType: 'BANNER1',
    linkAction: 'DIGITAL_SERVICE',
    linkModuleId: null,
    url: '',
    dashboardBackground: null,
    background: null
  };
  module!: IDashboards;

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.$emit('close');
  }

  imageSelected(image: IMediaItem) {
    if (this.component) {
      this.component.dashboardBackground = (image) ? image.id : null;
      this.component.background = image;
    }
  }

  mounted() {
    this.module = new Dashboards({name: 'dashboard', routeName: 'dashboard'});
    stores.modules.modules()
      .then((response) => {
        this.services = response;
      });
  }

  next() {
    this.$emit('next', this.component);
    // @ts-ignore
    this.$children[0].close();
  }
}
