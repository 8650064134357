
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import GlobalFunctions from '@/GlobalFunctions';
import {showToaster} from '@/components/DashboardComposer/helpers';
import {SuperAdmin} from "@/stores/SuperAdmins";
import MultiSelect from 'vue-multiselect';
import globalConfig from "@/helpers/globalConfig";

@Component({
  components: {
    SweetModal,
    MultiSelect
  },
})
export default class SuperAdminUserCreate extends Vue {

  @Prop({default: false})
  emitCreated!: boolean;

  @Prop()
  newuserIndex?: any;

  user = new SuperAdmin({});
  allRoles: IRole[] = [];
  allOperators: IOperator[] = [];

  rerenderKey = 0;
  globalFunctions = new GlobalFunctions();
  stores = stores;

  get isToShort() {
    if (0 < this.user.password.length && this.user.password.length < 8)
      return true;
  }

  get isInvalidConfirmation() {
    if (!this.user.password)
      return false;

    if (this.user.passwordConfirmation.length == 0)
      return false;

    return this.user.password !== this.user.passwordConfirmation;
  }

  async mounted() {
    this.allRoles = await stores.SuperAdmins.getAllRoles();

    if(globalConfig.showOperators) {
      stores.SuperAdminOperators.fetchData(1, '', 100, null).then((response: any) => {
        this.allOperators = response.data.data;
      })
        .catch((e: any) => {
          console.error(e);
        }).finally(() => {
      });
    } else {
      this.allOperators = [];
    }
  }

  onSubmit(): Promise<void | {}> {
    if (!this.validateForm()) {
      return Promise.resolve();
    }
    return stores.SuperAdmins.createNewItem(this.user)
      .then((response: any) => {
          showToaster('success', '', 'User successfully created.');
          this.cancel();
          if (this.emitCreated) {
            this.$emit('created', response.data);
            return;
          }
          this.$router.push({
            name: 'combinedUser.show',
            params: {id: response.data.id},
          });
      })
      .catch((e: any) => {
        console.log({e});
      });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  clean() {
    this.user = new SuperAdmin({});
    this.rerenderKey++;
  }

  validateForm(): boolean {
    const form = this.$refs['createForm'] as HTMLFormElement;
    form.classList.add('was-validated');
    return form.checkValidity() && !this.isInvalidConfirmation;
  }
}
