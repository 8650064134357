
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {SweetModal} from 'sweet-modal-vue';
import axiosInstance from '../../helpers/axios';
import session from '@/stores/Session';

@Component({
  components: {
    SweetModal,
  },
})
export default class CollectionItem extends Vue {
  components: any = [
    {name: 'weather', type: 'weather'},
    {name: 'my-mood', type: 'mood'},
    {name: 'never-opened', type: 'never-opened'},
    {name: 'banner', type: 'banner'},
    {name: 'activities-timeline', type: 'activities-timeline'},
  ];

  option: string = '';

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.$emit('close');
  }

  update(option: string) {
    this.option = option;
  }

  next() {
    this.$emit('next', this.option);
    // @ts-ignore
    this.$children[0].close();
  }
}
