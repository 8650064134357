
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';

@Component({
  components: {},
  mixins: [ Clickaway ],
})
export default class EditModule extends Vue {
 @Prop()
  old!: IModule;

  newModule: IModule | null = this.old;

  save(): void {
    this.$emit('created', this.newModule);
 }
  cancel(): void {
    this.newModule = null;
    this.$emit('cancel');
  }
}
