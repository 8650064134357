import axiosInstance from '@/helpers/axios';
import stores from '@/stores';
import { Vue } from 'vue-property-decorator';

import {showToaster} from '@/components/DashboardComposer/helpers';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function exportData(url: string, ids: number[], columns?: string[]) {
  return axiosInstance.post(url, {ids})
    .then((response) => response.data).catch((e) => {
      const log: any = {
        route: url,
        message: e,
      };
      generateLog(log);
      return e;
    });
}


export function importData(url: string, data: any) {
  return axiosInstance.post(url, data)
    .then((response) => response.data).catch((e) => {
      const log: any = {
        message: e
      };
      generateLog(log);
      return e;
    });
}

export function translatedDisplayName(item: IItem) {
  if (item.username) {
    return item.username;

  } else if (item.name) {
    return Vue.prototype.translate(item.name);

  } else if (item.firstName && item.lastName) {
    const string = `${Vue.prototype.translate(item.firstName)} ${Vue.prototype.translate(item.lastName)}`;
    if (item.salutation) {
      return `${Vue.prototype.translate(item.salutation)} ${string}`;
    }
    return string;

  } else if (item.macEth) {
    return item.macEth;

  } else if (item.macWifi) {
    return item.macWifi;

  } else if (item.city) {
    return Vue.prototype.translate(item.city);

  } else if (item.mainCustomer) {
    return `${Vue.prototype.translate(item.mainCustomer.firstName)} ${Vue.prototype.translate(item.mainCustomer.lastName)}  ${Vue.prototype.translate('stay')}`;

  } else {
    return '';
  }
}

export function itemDisplayName(item: IItem) {
  if (item.username) {
    return item.username;

  } else if (item.name) {
    return item.name;

  } else if (item.firstName && item.lastName) {
    const string = `${item.firstName} ${item.lastName}`;
    if (item.salutation) {
      return `${item.salutation} ${string}`;
    }
    return string;

  } else if (item.macEth) {
    return item.macEth;

  } else if (item.macWifi) {
    return item.macWifi;

  } else if (item.city) {
    return item.city;

  } else if (item.mainCustomer) {
    return `${item.mainCustomer.firstName} ${item.mainCustomer.lastName}  ${'stay'}`;

  } else {
    return '';
  }
}
export function generateLog(object: any) {
  console.log('Log:', object);
}

export function translate(key: any) {
  return Vue.prototype.translate(key);
}

export function manageTranslate(key: any) {
  if (Vue.prototype.i18nte(key) == '') {
    return key;
  }
  return Vue.prototype.translate(key);
}

export function checkTranslationKey(key: any): boolean {
  return Vue.prototype.i18nte(key);
}

export function titleCase(str: string): string {
  return str.toLowerCase().split(' ').map((word) => {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

export function capitalizeFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}


export function disableSubmitButton(formSelectorId: string) {
  const formSubmitBtn = document.querySelector('[form="' + formSelectorId + '"]') as HTMLFormElement;
  formSubmitBtn.setAttribute('disabled', 'disabled')
}

export function enableSubmitButton(formSelectorId: string) {
  const formSubmitBtn = document.querySelector('[form="' + formSelectorId + '"]') as HTMLFormElement;
  formSubmitBtn.removeAttribute('disabled')
}

export function resetForm(formSelectorId: string) {
  const form = document.getElementById(formSelectorId) as HTMLFormElement;
  form.classList.remove('was-validated');
  form.reset();
}

export function concatenateErrors(response: any) {

  const characterLimit = 50;

  if (response.data && typeof response.data.errors === 'object') {
    const errors = response.data.errors;

    let concatenatedErrors = '';

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {

        // Check if adding the next error message will exceed the limit
        if ((concatenatedErrors + errors[key]).length <= characterLimit) {
          concatenatedErrors += errors[key] + ', ';

        } else {
          // Add dots if there are more errors
          concatenatedErrors += '...' ;
          break;
        }
      }
    }

    return concatenatedErrors;
  }
}


/**
 * @param formId string \
 * Returns true if valid, else false. \
 * Appends class was-validated to form element. \
 * If the form is false, we show a toastr message.
 */
export function  validateForm(formId: string): boolean {
  const form = document.getElementById(formId) as HTMLFormElement;

  form.classList.add('was-validated');

  if (!form.checkValidity()) {
    showToaster('danger', 'Validation', translate('some-fields-are-required'), 'Validation');
    return false
  }

  return true
}

const eventBus = new Vue;

export function bus()
{
  return eventBus;
}


export function emit(emit: string,value : any)
{
  return bus().$emit(emit,value)
}
interface PdfOptions {
  width?: number;       // Width of the PDF in mm
  height?: number;      // Height of the PDF in mm
  paperSize?: string;   // Paper size, e.g., 'a4'
  orientation?: 'p' | 'l'; // 'p' for portrait, 'l' for landscape
  unit?: "em" | "mm" | "pt" | "px" | "in" | "cm" | "ex" | "pc" | undefined;        // Unit of measurement, e.g., 'mm'
  format?: 'png' | 'jpeg'; // Image format, either 'png' or 'jpeg'
  quality?: number;     // Image quality scale (default 1)
}

export async function exportAsPdf(element: HTMLElement, options: PdfOptions = {}): Promise<ArrayBuffer> {
  const {
      width = 210,       // Default width for A4 paper in mm
      height = 297,      // Default height for A4 paper in mm
      paperSize = 'a4',  // Default paper size
      orientation = 'p', // Default orientation 'p' for portrait, 'l' for landscape
      unit = 'mm',       // Default unit
      format = 'png',    // Default image format
      quality = 1        // Default image quality (1 is highest)
  } = options;

  const canvas = await html2canvas(element, {
      scale: quality,
  });

  const imgData = canvas.toDataURL(`image/${format}`);
  const pdf = new jsPDF({
      orientation: orientation,
      unit: unit,
      format: paperSize,
  });
  const imgWidth = width;
  const imgHeight = canvas.height * imgWidth / canvas.width;
  let heightLeft = imgHeight;
  let position = 0;

  pdf.addImage(imgData, format.toUpperCase(), 0, position, imgWidth, imgHeight);

  return pdf.output('arraybuffer'); // Return the raw PDF as an array buffer
}
