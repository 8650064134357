
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VueTagsInput from '@johmun/vue-tags-input';
import Axios from 'axios';
import {SweetModal} from 'sweet-modal-vue';
import SeriesModules from '@/stores/modules/Series';
import MODModule from '@/stores/modules/MOD';

@Component({
  components: {
    VueTagsInput,
    SweetModal,
  },
})
export default class CreateEpisodes extends Vue {
  @Prop()
  module!: MODModule;
  @Prop()
  checkParentType!: any;
  @Prop()
  parentId!: any;
  @Prop()
  checkEpisodeType!: any;
  langTag: string = '';
  langTags: object[] = [];
  autocompleteLangItems: any[] = [];
  CancelToken: any = Axios.CancelToken;
  source: any;
  searchInProgress: boolean = false;
  imageUrl: string = '';
  attachableContent: any = null;
  contentUrl: any = '';
  selectedFolderId: any = 1;
  categriesName: any = [];
  localEpisodeType: string = '';
  // @ts-ignore
  episodes: IEpisodes = {
    id: null,
    active: true,
    isTrailer: false,
    sort: null,
    name: '',
    shortDescription: '',
    fullDescription: '',
    image: null,
    releaseDate: '',
    price: '0',
    rating: '',
    duration: '',
    moduleId: null,
    parentId: null,
    releaseYear: '',
  };

  @Watch('module', {immediate: true})
  updateLocal() {
    this.localEpisodeType = this.checkEpisodeType;
  }

  mounted() {
    const forms = document.getElementsByClassName('needs-validation');
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          // this.onSubmit();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  cancel() {
    const form = document.getElementById("create-form") as HTMLFormElement;

    if (form) {
      form.reset();
      form.classList.remove("was-validated"); // Remove validation state
      this.episodes.name = ""; // Reset model
    }

    this.$emit('success');
    // @ts-ignore
    this.$children[0].close();
  }

  onSubmit() {
    const form = document.getElementById('create-form') as HTMLFormElement;
    if (form.checkValidity()) {
      if (localStorage.getItem('episodeTypes') === 'trailer') {
        // @ts-ignore
        this.episodes.isTrailer = 1;
      } else {
        // @ts-ignore
        this.episodes.isTrailer = 0;
      }
      this.module.createEpisodesItem(this.episodes, this.checkParentType, this.parentId)
        .then((response) => {
          this.cancel();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      form.classList.add('was-validated');
    }

  }
}
