
import { Component } from 'vue-property-decorator';
import TabManager from '@/mixins/TabManager';
import ItemsIndex from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import SurveySettings from "@/modules/Surveys/SurveySettings.vue";
import stores from "@/stores";
import SurveyQuestionModal from '@/modules/Surveys/Questions.vue'


@Component({
  computed: {
    stores() {
      return stores
    }
  },
  components: {
    ItemsIndex,
    SurveySettings,
    SurveyQuestionModal

  },
})
export default class SurveysIndex extends TabManager {
 
  selectedItem: IItem | null = null;
  get TAB_KEYS() {
    return ['surveys', 'survey-settings'];
  }

  openResult(item: IItem)
  {
    this.selectedItem = item;
    this.$nextTick(() => {
    // @ts-ignore
        this.$refs.question.$children[0].open();
    });
  }

}
