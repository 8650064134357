
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import NevronInput from '@/components/NevronInput.vue';
import {modulesMetaData} from '@/helpers/constants';
import { translate } from '@/helpers/functions';
import MultiSelect from 'vue-multiselect';
import moment from 'moment';


@Component({
  components: {
    SweetModal,
    NevronInput,
    MultiSelect
  },
})

export default class NevronTablePopupModal extends Vue {
  @Prop({required: true})
  instance!: IModule;

  @Prop()
  metaKey!: string | null;

  @Prop()
  referenceKey!: string | null;

  @Prop()
  item!: any;

  @Prop()
  onlyName!: boolean;

  selectedDate = '';
  selectedTime = '';

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }

  get tableMeta(): any {
    if (this.metaKey && this.instanceMeta[this.metaKey]) {
      return this.instanceMeta[this.metaKey];
    }
    return this.instanceMeta;
  }

  get fields(): any {
    if (!this.tableMeta.fields) {
      return this.instanceMeta.fields;
    }
    return this.tableMeta.fields;
  }

  cancel() {
    // @ts-ignore
    this.$refs.editPopup.close();
  }

  update() {
    this.$emit('updateItem', this.item);
    this.cancel();
  }

  openModelTranslator(localText: any, localKey: any, referenceKey: any) {
    this.$emit('openTranslators', localText, localKey, referenceKey);
  }

  selectDate(date: any) {
    this.selectedDate = date;
  }

  removeDate(date: any)
  {
    Vue.delete(this.item.datesAndTimes, date);
  }
  toggleTimeSlotStatus(date: string, time: string) {
    if (!this.item.datesAndTimes[date]) {
      return; 
    }

    const index = this.item.datesAndTimes[date].findIndex((slot: any) => slot.time === time);
    if (index !== -1) {
      const updatedSlot = Object.assign({}, this.item.datesAndTimes[date][index], {
        active: !this.item.datesAndTimes[date][index].active
      });
      Vue.set(this.item.datesAndTimes[date], index, updatedSlot);
    }
  }
  removeTime(date: any, time: any)
  {
    const indexToRemove = this.item.datesAndTimes[date].findIndex((item: any) => {
      return item.time == time.time
    } );
    if (indexToRemove !== -1) {
        this.item.datesAndTimes[date].splice(indexToRemove, 1);
    }
  }
  addTime(date: string) {
    this.selectedDate = date
    // @ts-ignore
    this.$refs.timeModal.open();
  }

  addDate() {
    // @ts-ignore
    this.$refs.dateModal.open();
  }
  closeTimeModal() {
    // @ts-ignore
    this.$refs.timeModal.close();
  }
  submitTimeModal() {
    if(this.selectedTime=="")
    {
      // @ts-ignore
      this.$refs.timeModal.close();
      return;
    }
    if (!(this.selectedDate in this.item.datesAndTimes)) {
      // @ts-ignore
      Vue.set(this.datesAndTimes, this.selectedDate, [])
    }

    const timeExists = this.item.datesAndTimes[this.selectedDate]?.find(
      (timeField : any) => timeField.time === this.selectedTime
    );

    if (!timeExists) {
      this.item.datesAndTimes[this.selectedDate].push({
        time: this.selectedTime,
        active: true, 
        display: true
      });
    }


    this.selectedDate = '';
    this.selectedTime = '';
    // @ts-ignore
    this.$refs.timeModal.close();
  }

  submitDateModal() {
    if(this.selectedDate=="")
    {
      // @ts-ignore
      this.$refs.dateModal.close();
      return;
    }

    if (!(this.selectedDate in this.item.datesAndTimes)) {
      Vue.set(this.item.datesAndTimes, this.selectedDate, [])
    }
    this.selectedDate = '';
    this.selectedTime = '';

    // @ts-ignore
    this.$refs.dateModal.close();
  }

  closeDateModal() {
    this.selectedDate = '';
    this.selectedTime = '';
    // @ts-ignore
    this.$refs.dateModal.close();
  }

  clearTime() {
    this.selectedTime = ''
  }

  formatTime(time: any) {
    return time ? moment(time, 'HH:mm:ss').format('HH:mm') : '';
  }

  dateFormat(date: any)
  {
    if (date.includes('-')) {
      return date; 
    } else {
      return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    }
  }
  @Watch('item')
  onItemChanged() {
    if (this.item?.datesAndTimes && Object.keys(this.item.datesAndTimes).length == 0) {
      this.item.datesAndTimes = {}
    }
  }
  
}
