
import { Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import Countries from '@/modules/Countries.vue';

@Component({
  components: {Countries},
})
export default class CreateCustomerAddress extends Vue {

  newAddress: ICustomerAddress | null = null;

  save() {
    if (this.newAddress) {
      return stores.Location.createNewAddress(Number(this.$route.params.id), this.newAddress)
      .then((response) => {
        this.cancel();
      })
      .catch((e) => {
        console.log(e);
      });
    }
  }

  cancel() {
    this.$router.push({name: 'customers.show', params: {id: this.$route.params.id}});
  }

  countrySelected(countrty: string) {
    if (this.newAddress) {
      this.newAddress.country = countrty;
    }
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.newAddress = {
      address: '',
      address2: '',
      city: '',
      country: '',
      id: null,
      customerId: null,
      post: '',
      default: 0,
      firstName: '',
      lastName: '',
      company: '',
    };
  }
}
