
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import NevronHeader from "@/components/NevronHeader.vue";
import {Setting} from "@/stores/Setting";
import stores from "@/stores";
import {showToaster} from "@/components/DashboardComposer/helpers";

@Component({
  components: {
    NevronHeader
  },
})
export default class SurveySettings extends Vue{

  settings: { [key: string]: ISetting } = {
    displayTv: new Setting({'key': 'survey_display_tv'}),
    displayMobile: new Setting({'key': 'survey_display_mobile'}),
  };

  mounted() {
    this.fetchSettings();
  }

  async updateSettings() {
    try {
      const updatePromises = Object.keys(this.settings).map(key => this.updateSettingApi(this.settings[key]));
      await Promise.all(updatePromises);
      showToaster('success', '', Vue.prototype.translate('successfully-updated'));
    } catch (error) {
      showToaster('error', '', error);
    }
  }

  async updateSettingApi(setting: ISetting): Promise<void> {
    try {
      await stores.Setting.updateByKey(setting.key, setting);
    } catch (error) {
      showToaster('error', '', error);
    }
  }

  fetchSettings() {
    stores.Setting.getSettings()
      .then((response) => {
        Object.keys(this.settings).forEach((key) => {
          const setting = this.settings[key];
          const responseSetting = response.find((s: ISetting) => s.key === setting.key);
          if (responseSetting) {
            Object.assign(setting, {
              value: responseSetting.value,
              type: responseSetting.type,
              id: responseSetting.id,
            });
          }
        });
      });
  }
};
