
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
// @ts-ignore
import i18n from '../i18n';
import stores from '@/stores';

@Component({
  components: {
    DynamicContent,
  },
})
export default class NevronInput extends Vue {
  @Prop() value: any | null;
  @Prop() referenceKey: any | '';
  @Prop() placeholder: any;
  @Prop() classes: any;
  @Prop() parentType: any | '';
  @Prop() checkTextEmpty: any | false;
  @Prop() setCheckPattern: any | '.*';
  @Prop({default: true}) translatable? : boolean;

  $languages: any = '';
  localKey: any = '';
  localText: any = '';

  mounted() {
    this.init();
  }

  handleTranslate() {
    if (this.parentType === 'model') {
      this.$emit('openTranslator', this.localText, this.localKey, this.referenceKey);
    } else {
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    }
  }

  @Watch('value')
  onValueChanged(newVal: any, oldVal: any) {
    this.init();
  }

  @Watch('localText')
  onLocalTextChanged(newVal: any, oldVal: any) {
    this.$emit('input', newVal);
  }

  init() {
    this.localKey = this.value;
    if(!this.translatable)
    {
      this.localText = this.value;
      return
    }
    stores.Language.fetchingTranslationsPromise
      .then((resolved: any) => {
        if (i18n.te(this.localKey)) {
          this.localText = Vue.prototype.translate(this.localKey);
        } else {
          // if field was edited and translation was not saved yet
          this.localText = this.localKey;
        }
      });
  }
}
