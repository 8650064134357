
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {SweetModal} from 'sweet-modal-vue';
import axiosInstance from '../../helpers/axios';
import session from '@/stores/Session';
import WeatherLocationModule, { WeatherLocationModuleItem, WeatherlocationItemsCollection } from '@/stores/modules/WeatherLocationModule';
import stores from '@/stores';
import { showToaster } from './helpers';

@Component({
  components: {
    SweetModal,
  },
})
export default class CollectionItem extends Vue {

  selectedWeather: WeatherLocationModuleItem | null = null;
  weathers: WeatherLocationModuleItem[] | [] = [];
  selectedModule: any = null;
  modules: IModule[] | [] = [];
  initialSelection: boolean = true;


  async mounted() {
    this.modules = await stores.modules.getSpecificModule('WEATHER');
  }

  async loadWeatherLocations()
  {
    let weatherLocationModule = new WeatherLocationModule(this.selectedModule);
    let weatherLocations = await weatherLocationModule.getAllWeatherLocations();
    this.weathers = weatherLocations.data
    this.selectedWeather = null;
  }

  @Watch('selectedModule')
  onSelectedModuleChange(newModule: any, oldModule: any) {
    if (!this.initialSelection && newModule && !this.selectedWeather) {
      showToaster('danger', 'Weather', 'You must select a weather', 400);
    }
    this.selectedWeather = null;
    this.initialSelection = false;
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.$emit('close');
    this.selectedWeather = null;
    this.selectedModule = null;
  }

  next() {
    console.log(this.selectedWeather);
    if(this.selectedWeather === null)
    {
      showToaster('danger', 'Weather', 'You must select a weather',400);
      return
    }
    this.$emit('next', this.selectedWeather );

    this.selectedWeather = null;
    this.selectedModule = null;

    // @ts-ignore
    this.$children[0].close();
  }

  onOpen() {
    this.$emit('onOpen', this.selectedWeather );
  }
}
