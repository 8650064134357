
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import stores from '../../../stores';
import TvModule , { TvModuleItem } from '@/stores/modules/TvModule';

@Component({
  components: {},
})

export default class DeleteRecorder extends Vue {
  @Prop()
    module!: TvModule;
  /**
   * Fired when this module is created
   */
  created() {
    this.delete();
  }

  delete() {
    return this.module.deleteItem(Number(this.$route.params.id))
      .then(() => {
        this.$router.push({name: 'tv.items', params: {moduleId: String(this.module.id)}});
      })
      .catch((error: any) => {
        console.error('Could not delete', error);
      });
  }
}
