
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import ChatRecentTab from '@/modules/Chat/ChatRecentTab.vue';
import ChatContacts from '@/modules/Chat/ChatContacts.vue';


@Component
export default class ChatTabDetails extends Vue {
    @Prop({ default: false }) tabTitle!: string;
    @Prop({ default: false }) activeTab!: string;
    @Prop({ default: false }) activeChat: any = null;

    get currentActiveTab() {
        if (this.activeTab === this.tabTitle.toLowerCase() && this.activeTab === 'recent') {
            return ChatRecentTab;
        }
        else if (this.activeTab === this.tabTitle.toLowerCase() && this.activeTab === 'guest') {
            return ChatContacts;
        }
        else if (this.activeTab === this.tabTitle.toLowerCase() && this.activeTab === 'staff') {
            return ChatContacts;
        }
    }

    @Emit("user-selected")
    handleUserSelected(user: any) {
        return user;
    }

    @Emit("recentActiveChatUpdate")
    recentActiveChatUpdate(chat: any) {
        return chat;
    }

}
