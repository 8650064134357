
import {Component, Vue, Watch} from 'vue-property-decorator';
import AdminChangePassword from '@/modules/Admins/ChangePassword.vue';
import {Admin} from '../../stores/Admins';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
import NevronInput from '@/components/NevronInput.vue';
import {showToaster} from "@/components/DashboardComposer/helpers";
import Multiselect from "vue-multiselect";
import {hasPermissionForRoleByType} from "@/helpers/permissions";
import {translate} from '@/helpers/functions';

interface Role {
  createdAt: string;
  guardName: string;
  id: number;
  name: string;
  updatedAt: string;
}

@Component({
  components: {
    Multiselect,
    AdminChangePassword,
    NevronHeader,
    Skeleton,
    NevronInput,
  },
})

export default class AdminDetails extends Vue {
  currentAdmin = stores.admin;
  admin: Admin = new Admin();
  roles: [] = [];

  /*
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(this.$route.params.id);
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(this.$route.params.id);

    stores.roles.getAllRoles().then((response) => {
      this.roles = response;
      this.sortScopedRoles();
    });
  }

  sortScopedRoles() {
    this.roles.sort((a: Role, b: Role) => {
      if (a.name === 'User' || a.name === 'Admin') {
        return 1; // Move 'User' and 'Admin' roles to the end
      } else if (b.name === 'User' || b.name === 'Admin') {
        return -1; // Keep 'User' and 'Admin' roles in their current order
      }
      return 0; // Maintain the order of other roles
    });
  }
  getScopedRoles()
  {
    return this.roles
  }

  /*
   *
   */
  fetchData(id: string) {
    Admin.getById(id)
      .then((admin) => {
        this.admin = admin;
      })
      .catch((reason) => {
        console.log('Could not fetch admin', reason);
        if (!this.admin.id) {
          this.$router.push({name: 'access.roles'});
        }
      });
  }

  /*
   *
   */
  onSubmit() {
    const data = {
      id: this.admin.id,
      name: this.admin.name,
      chat_alias: this.admin.chatAlias,
      username: this.admin.username,
      status: this.admin.status,
      email: this.admin.email,
      roles: this.admin.roles
    };

    return stores.admins.update(data)
      .then((response) => {
        if (response.data.errors) {
          for (const key in response.data.errors) {
            if (response.data.errors.hasOwnProperty(key)) {
              const messages = response.data.errors[key];
              for (const message of messages) {
                showToaster('danger', '', message, response.status);
              }
            }
          }
        } else {
          showToaster('success', translate('successfully-updated'), this.admin.name);
        }
      }).catch((e: any) => {
        showToaster('danger', this.admin.name, e);
        console.log(e);
      });
  }

  toggleActive(admin: any){
    admin.status = admin.status === 1 ? 0 : 1;
  }
  /*
   *
   */
  onSubmitStatus() {
    console.log(this.admin.status);
    return stores.admins.update({id: this.admin.id, status: this.admin.status})
      .then(() => {
        console.log('success');
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /*
   *
   */
  editPass(user: IAdmin) {
    if (user.id) {
      return stores.admins.changePassword(user.id, user)
        .then((response: any) => {
          if (!response.success) {
            showToaster('danger', '', translate(response.error.message), response.responseCode);
            return false;
          }
          user.password = '';
          user.passwordConfirmation = '';
          // @ts-ignore
          this.$refs.pass.$children[0].close();
          showToaster('success', user.name, translate('successfully-updated'));
        })
        .catch((e: any) => {
          showToaster('danger', '', e);
        });
    }
  }

  /*
   *
   */
  removeAdmin() {
    if (stores.admin.id !== Number(this.$route.params.id)) {
      return stores.admins.delete(Number(this.$route.params.id))
        .then((response) => {
          if(!response.success) {
            throw new Error(response.error.message);
          } else {
            showToaster('success', 'Admin', 'successfully removed.')
            this.$router.push({name: 'access.roles'});
          }
        })
        .catch((e) => {
          showToaster('danger',  'Error: ',e)
          console.log(e);
        });
    } else {
      showToaster('danger', 'Error: ', 'You can not delete yourself');
    }
  }
}
