
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {SweetModal} from 'sweet-modal-vue';
    import axiosInstance from '../../helpers/axios';
    import session from '@/stores/Session';
    import stores from '@/stores';
    import {DashboardPanel, PanelElement} from '@/stores/Dashboard';
    import {createItemPanelElement, createModulePanelElement} from './helpers';
    import NevronImageField from "@/components/NevronImageField.vue";
    @Component({
        components: {
          NevronImageField,
            SweetModal,
        },
    })
    export default class AppendItems extends Vue {
        @Prop()
        panelIndex: any;
        @Prop()
        checkFloatingSection: any;

        option: any = 'collection';
        modules: any = [];
        saveservices: [] = [];
        categories: ICategories[] = [];
        items: IItem[] = [];
        playlists: any[] = [];
        selectedModule: IModule | null = null;
        selectedCategory: ICategories | null = null;
        selectedItem: any = '';
        responseChannels: any = '';
        test: boolean = false;
        image: string | undefined = undefined;
        imageId: number | null = null;
        renderKey = 0;

        loadModuleCategories() {
            this.items = [];
            this.categories = [];
            if (this.selectedModule && typeof this.selectedModule.getAllCategories !== 'undefined') {
              this.selectedModule.getAllCategories()
                .then((response: any) => {
                  this.categories = response.data;
                });
            }

            if (this.selectedModule?.type === 'DIGITAL_SIGNAGE') {
              stores.DigitalSignage.fetchData(1, '', 90000)
                .then((response) => {
                  this.playlists = response.data;
                })
            } else {
              this.playlists = [];
            }
            if (this.selectedModule?.type === 'RSS') {
              stores.RSS.fetchData(1, '', 90000)
                .then((response) => {
                  this.items = response.data;
                })
            }

          if (this.selectedModule?.type === 'RSS') {
            stores.RSS.fetchData(1, '', 90000)
              .then((response) => {
                this.items = response.data;
              })
          }
        }
        @Watch('checkFloatingSection')
         loadModules() {
          this.modules = [];
          this.modules = stores.modules.models;
          stores.GuestFlow.listofServices()
            .then((response) => {
              this.modules = [].concat(this.modules, response.data);
            });
          const contactModule = {name: 'contact-module'};
          for (const index in contactModule) {
            if (index) {
              // @ts-ignore
              this.modules = [].concat(this.modules, contactModule);
            }
          }

        }

      imageSelected(image: IMediaItem | null) {
        this.image = image?.imageUrl ?? undefined;
        this.imageId = image?.id ?? null;
      }

        loadCategoryItems() {
            this.items = [];
            if (this.selectedModule && this.selectedCategory && typeof this.selectedModule!.getSpecificCategory !== 'undefined') {
              this.selectedModule.getSpecificCategory(this.selectedCategory.id!)
                .then((response: any) => {
                  this.items = response.data.subitems ? response.data.subitems : response.data.children;
                  this.items = this.items.filter((element: any) => {
                    if (element.active === 1) {
                      return element;
                    }
                  });
                });
            }
        }

        cancel() {
          this.selectedModule = null;
          this.selectedCategory = null;
          this.selectedItem = null;
          this.playlists = [];
          this.imageId = null;
          this.image = undefined;
          this.renderKey += 1;

          // @ts-ignore
          this.$children[0].close();
        }

        async next() {
          if (!this.selectedModule) {
            return;
          }
          const isFormValid = await this.validateForm('appendItemsForm');
          if (!isFormValid) {
            return;
          }
          const elements: IPanelElement[] = [];
          if (this.selectedItem) {
            if (this.selectedItem === 'none') {
              const panelElement = JSON.parse(JSON.stringify(createItemPanelElement(this.selectedModule, this.selectedCategory, 'category')));
              elements.push(panelElement);
            } else {
              const panelElement = JSON.parse(JSON.stringify(createItemPanelElement(this.selectedModule, this.selectedItem)));
              elements.push(panelElement);
            }
          } else if (this.selectedCategory) {
          // ADD CATEGORY
            for (const item of this.items) {
              const panelElement =  JSON.parse(JSON.stringify(createItemPanelElement(this.selectedModule, item)))
              elements.push(panelElement);
            }
          } else {
            // ADD ESERVICE
            const panelElement = JSON.parse(JSON.stringify(createModulePanelElement(this.selectedModule)));
            elements.push(panelElement);
          }

          // if image is selected then assign
          if (this.imageId && this.image) {
            elements[0].image = this.image;
            elements[0].imageId = this.imageId;
          }
          this.$emit('next', elements, this.selectedModule);
          this.cancel();
        }

      validateForm(formRef: string): Promise<boolean> {
        return new Promise((resolve) => {
          this.$forceUpdate();
          this.$nextTick(() => {
            const form = this.$refs[formRef] as HTMLFormElement;
            form.classList.add('was-validated');
            resolve(form.checkValidity());
          });
        });
      }

    }
