
import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import NevronInput from '@/components/NevronInput.vue';

import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    EditContact,
    virtualList,
    EditCustomer,
    PreEdit,
    // AccountAttach,
    CustomerAddressEdit,
    AttachImage,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,

    NevronFooter,
    NevronImageField,
    TableModalGeneric,
    TableGeneric,

  },
  mixins: [Clickaway],
})

export default class AssetsDetails extends Vue {
  asset: any = null;
  dragOver: boolean = false;
  preLeave: boolean = false;
  module = stores.Assets;

  stores = stores;

  imageSelected(image: IMediaItem) {
    if (this.asset) {
      this.asset.imageId = (image) ? image.id : null;
    }
    console.log(this.asset);
  }
  loadLibrary(data: any) {
    console.log('data', data);
    stores.Assets.attachAccomodation([data], this.$route.params.id)
      .then((resp: any) => {
        this.loadPage();
        setTimeout(() => {
          this.$router.push({name: 'library.show', params: {id: data}});
        }, 2000);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  galleryAttach(imgObj: any) {
    if (this.asset) {
      // this.asset.imageId = imgObj.result.id;
      if (this.asset && this.asset.id) {
        stores.Assets.attachGallery(this.asset.id, imgObj.result.id)
          .then((response) => {
            // @ts-ignore
            this.asset.mediaFiles.push(imgObj.result);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  galleryDetach(element: any, e: any = null) {
    if (e) {
      e.preventDefault();
    }
    if (this.asset && this.asset.id) {
      console.log('asset', this.asset.id);
      console.log('element', element.id);
      stores.Assets.detachGallery(this.asset.id, element.id)
        .then((response) => {
          console.log(response);
          // @ts-ignore
          this.asset.mediaFiles.splice(element.i, 1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.$route.params.id));
  }

  /*
   *
   */
  onSubmitStatus() {
    const form = this.$refs.form as HTMLFormElement;
    form.classList.add('was-validated');

    if (!form.checkValidity()) {
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
      return ;
    }

    // @ts-ignore
    return stores.Assets.updateItem(this.asset.id, this.asset)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.fetchData(Number(this.$route.params.id));
          showToaster('success', Vue.prototype.translate(this.asset.name), 'successfully updated');
        } else {
          showToaster('danger',  Vue.prototype.translate(this.asset.name), 'Fail to update', response.code);
        }
      }).catch((e: any) => {
        console.log(e);
        showToaster('danger',  Vue.prototype.translate(this.asset.name), 'Fail to update');
      });
  }

  mounted() {
    this.fetchData(Number(this.$route.params.id));
  }

  loadPage() {
    this.fetchData(Number(this.$route.params.id));
  }

  fetchData(id: number) {
    return stores.Assets.getDetail(id)
      .then((response: any) => {
        this.asset = response;
        console.log('this.asset', this.asset);
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  deleteAsset() {
    return stores.Assets.deleteItem(this.asset.id)
      .then((response: any) => {
        if (response.responseCode && !response.success) {
          const error = response.error.message;
          showToaster('danger', 'Error in deleting Item', error);
          return;
        }
        showToaster('success', '', Vue.prototype.translate('successfully-deleted'));
        this.$router.push({name: 'property'});
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  // locations
  attachLocations(locations: IRooms[]) {
    const newItems = locations.filter((el: IRooms) => !this.locationIds.includes(el.id));
    this.asset.locations = this.asset.locations.concat(newItems);
  }
  get locationIds() {
    return this.asset.locations.map((el: IRooms) => el.id);
  }
  detachLocation(location: IRooms) {
    this.asset.locations = this.asset.locations.filter((el: IRooms) => el.id !== location.id);
  }

  // libraries
  attachLibraries(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.libraryIds.includes(el.id));
    this.asset.accommodationLibrary = this.asset.accommodationLibrary.concat(newItems);
  }
  get libraryIds() {
    return this.asset.accommodationLibrary.map((el: IItem) => el.id);
  }
  detachLibrary(item: IItem) {
    this.asset.accommodationLibrary = this.asset.accommodationLibrary.filter((el: any) => el.id !== item.id);
  }

  detachaccommodations(id: any) {
    if (this.asset && confirm('Do you really want to detach this Library?')) {
      stores.Assets.detachAccomodation(id, this.$route.params.id)
        .then((resp: any) => {
          this.loadPage();
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }
}
