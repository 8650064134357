
import {Component, Vue} from 'vue-property-decorator';
import NevronHeader from '@/components/NevronHeader.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import AddSection from '@/components/DashboardComposer/AddSection.vue';
import EServices from '@/components/DashboardComposer/EServices.vue';
import CollectionItem from '@/components/DashboardComposer/CollectionItemGuestFlow.vue';
import DashboardComponents from '@/components/DashboardComposer/DashboardComponents.vue';
import BannerComponent from '@/components/DashboardComposer/BannerComponent.vue';
import MyMoodAttribute from '@/components/DashboardComposer/MyMoodAttibute.vue';
import WeatherComponent from '@/components/DashboardComposer/WeatherComponent.vue';
import Categories from '@/components/DashboardComposer/Categories.vue';
import Items from '@/components/DashboardComposer/ItemsGuestFlow.vue';
import SingleItem from '@/components/DashboardComposer/AppendItems.vue';
import EditPanel from '@/components/DashboardComposer/EditPanel.vue';
import axiosInstance from '../../../../helpers/axios';
import stores from '@/stores';
import {DashboardPanel, Dashboards, PanelElement} from '@/stores/Dashboard';
import EditElement from '@/components/DashboardComposer/EditElement.vue';
// @ts-ignore
import Sortable from 'vue-drag-sortable';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
import NevronInput from '@/components/NevronInput.vue';
import GuestFlow from '@/components/DashboardComposer/guestFlow.vue';
import {createItemPanelElement, showToaster} from '@/components/DashboardComposer/helpers';
import Skeleton from '@/modules/Skeleton.vue';
import DashboardSettings from '@/components/DashboardComposer/DashboardSettings.vue';
import {RawLocation} from "vue-router/types/router";
import {hasPermission} from "@/helpers/permissions";
import NevronModuleQr from '@/components/NevronModuleQr.vue';
import { bus } from '@/helpers/functions';
import { translate } from '@/helpers/functions';

enum  PanelType {
  SLIDER = 'slider',
  BANNER = 'banner',
  MOOD = 'mood',
  WEATHER = 'weather',
  ACTIVITIES = 'activities-timeline',
  NEVER_OPENED = 'never-opened',
  ROW = 'row',
  BOTTOM_FLOATING= 'bottom-floating'
}

@Component({
  components: {
    EditPanel,
    SingleItem,
    NevronHeader,
    AttachImage,
    AddSection,
    EServices,
    CollectionItem,
    Categories,
    Items,
    Sortable,
    EditElement,
    DynamicContent,
    NevronInput,
    GuestFlow,
    Skeleton,
    DashboardSettings,
    NevronModuleQr,
    DashboardComponents,
    MyMoodAttribute,
    BannerComponent,
    WeatherComponent,
  },
})
export default class DashboardComposer extends Vue {
  PanelType = PanelType;
  dragData: any = {};
  // header background attributes
  selectedPanel: any = null;
  // container for composer dashboard component
  panels: DashboardPanel[] = [];

  dashboardSetting: any = {
    radius: '10',
    bgColor: '',
    neutralColor: '',
    primaryColor: '',
    logo: null,
    imageId: null,
    bgImageId: null,
    backGroundImage: null,
    gradiantStartColor: null,
    gradiantEndColor: null,
    imageUrl: '',
    isGradiant: '',
    buttonTextColor: null,
    selectionColor: null,
    alertColor: null,
    reminderColor: null,
    topGradiant: null,
    bottomGradiant: null,
    floatMenuColor: null,
    isImageOnDashboard: false,
  };
  dashboard: IDashboards = new Dashboards({name: 'Composer Dashboard', type: 'Mobile'});
  // one slider only for one dashboard therefor separate instant
  slider: any = null;
  sliderInterval: any = null;
  floatingSection: any = null;
  checkFloatingSection: any = null;
  checkGFModule: any = {
    elements: [],
  };
  // helper veriables
  selectedPanelIndex: any = 1;
  selectedElementIndex: any = 1;
  selectedElement: any = null;
  panel: any = {
    id: null,
    dashboardId: null,
    name: '',
    sort: null,
    elements: [],
  };
  localText: any = '';
  localKey: any = '';
  notification: any = {
    result: false,
    message: '',
  };
  notificationTimer: any;
  attachableContent: any = null;
  contentUrl: any = '';
  buttonWidth: any = 511;
  isGradiant: boolean = false;
  sectionCounter: number = 0;
  sectionLimit: number = 0;



  get isWithinSelectionsLimit(): boolean {
    return !this.sectionLimit || this.sectionCounter < this.sectionLimit || hasPermission('global-settings.PRIVILEGED_EDITOR');
  }

  get isSliderVisible(): boolean
  {
    return (this.slider !== null);
  }

  get createBackgroundHeaderColor() {
    return `linear-gradient(90deg, ${this.dashboardSetting.gradiantStartColor + 'cc'} 40%, ${this.dashboardSetting.gradiantEndColor + 'cc'}  60%)`;
  }

  get backRoute(): RawLocation {
    return {
      name: 'dashboards',
      query: {tab: 'mobile', page: this.$route.query.lastpage, perpage: this.$route.query.perpage}
    };
  }

  mounted() {
    clearInterval(this.sliderInterval);

    this.selectedElementIndex = 0;
    this.loadComposerData(this.$route.params.id);
    bus().$on('openTranslator', (localText: any, localKey: any, referenceKey: any) => {
      this.localText = localText;
      this.localKey = localKey;
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    });
  }

  isImageFile(fileExtension: any) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'eps', 'bmp', 'tiff', 'tif', 'svg', 'raw'];
    return imageExtensions.includes(fileExtension);
  };

  isVideoFile(fileExtension: any) {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'webm', 'mkv', 'mpeg-4', 'ogg'];
    return videoExtensions.includes(fileExtension);
  };

  videoType(type: any) {
    if(type === 'mov') return 'video/mp4';
    return 'video/' + type;
  };

  getImage(id: number) {
    return stores.File.getImage(id)
      .then((response) => {
        this.dashboardSetting.imageUrl = response.imageUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  attachWithImage(res: any) {
    this.dashboardSetting.imageUrl = res.result.imageUrl;
    this.dashboardSetting.bgImageId = res.result.id;
    // @ts-ignore
    this.$refs.image.$children[0].close();
    if (this.dashboardSetting.imageUrl === '' || this.dashboardSetting.imageUrl === null) {
      this.buttonWidth = `margin-top: ${511}px;`;
    } else {
      this.buttonWidth = `margin-top: ${860}px;`;
    }
    this.getImage(res.result.id);
  }

  handleTranslation(data: any) {
    this.localText = data.value;
    this.localKey = data.key;
    // @ts-ignore
    this.$refs.translator.$children[0].open();
  }

  // load dashboard data
  loadComposerData(id: any) {
    stores.mobileComposer.getDashboard(id)
      .then((response: any) => {
        // assigning response objects to local variables
        this.dashboard = response.dashboard;
        this.dashboardSetting = response.setting;
        this.floatingSection = response.floatingSection;
        !this.floatingSection && this.initFloatingSection();
        this.slider = response.slider;
        this.sectionLimit = response.limitOfInstance;
        const tempArray = response.panels;
        this.dashboardSetting.imageUrl = response.setting.imageUrl;
        this.dashboard.url = this.dashboard.qr ? this.dashboard.qr.url : '';
        if (this.dashboardSetting.imageUrl === '' || this.dashboardSetting.imageUrl === null) {
          this.buttonWidth = `margin-top: ${511}px;`;
        } else {
          this.buttonWidth = `margin-top: ${860}px;`;
        }

        this.panels = [];
        for (const index in tempArray) {
          if (index && tempArray[index]) {
            this.panels.push(tempArray[index]);
          }
        }

        if (this.slider) {
          this.slider.linkElementId = 0;
          this.slider.elements.map((element : any,index : number) => {
            element.active = index === 0 ? true : false
          })
        }
        this.dashboardSetting.isGradiant = !(response.setting.isGradiant === '0' || response.setting.isGradiant === 0);
      })
      .then(() => {
        this.countSections();
      });
    this.selectedElementIndex = 0;
  }

  initFloatingSection() {
    const elements = Array.from({length: 3}, () => new PanelElement({active: false}));
    this.floatingSection = new DashboardPanel({
      elementType: 'floating',
      name: 'Floating Section',
      active: false,
      panelType: PanelType.BOTTOM_FLOATING,
      position: 'top',
      elements,
    });
  }

  countSections() {
    this.sectionCounter = this.panels.length + (this.slider ? 1 : 0) + (this.floatingSection ? 1 : 0);
  }

  save() {
    const form = this.$refs.form as HTMLFormElement;
    form.classList.add('was-validated');

    if (!form.checkValidity()) {
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
      return ;
    }

    const data = JSON.parse(JSON.stringify(this.dashboard));
    data.setting = this.dashboardSetting;
    data.panels = this.panels;
    data.slider = this.slider;
    data.floating = this.floatingSection;
    data.url = this.dashboard.url;
    delete data.public;
    stores.mobileComposer.updateItem(this.dashboard.id, data)
      .then((response) => {
        if (!response.success) {
          throw new Error(response.error.message);
        }
        this.loadComposerData(this.$route.params.id);
        showToaster('success', Vue.prototype.translate(this.dashboard.name), 'successfully updated');
      }).catch((error) => {
      showToaster('danger', '', error.message);
    });
  }

  updateSliderElement(result: any) {
    if (!this.slider) {
      return;
    }

    this.slider.elements[result.elementIndex] = result.element;
    this.slider.showName = result.sliderShowName;
  }

  editSliderElement(index: any, element: any) {
    this.selectedElementIndex = index;
    this.selectedElement = element;
    // @ts-ignore
    this.$refs.editElement.$children[0].open();
  }

  handleDashboardComponent(option: any) {
    if (option === 'weather') {
      // @ts-ignore
      this.$refs.weatherComponent.$children[0].open();
    } else if (option === 'never-opened') {
      const tempPanel = new DashboardPanel({
        name: 'Never opened widget',
        panelType: PanelType.NEVER_OPENED,
        active: true,
      });
      this.panels.push(tempPanel);
    } else if (option === 'mood') {
      // @ts-ignore
      this.$refs.myMoodAttribute.$children[0].open();
    } else if (option === 'banner') {
      // @ts-ignore
      this.$refs.bannerComponent.$children[0].open();
    } else if (option === 'activities-timeline') {
      // @ts-ignore
      const tempPanel = new DashboardPanel({
        name: 'Activites Timeline',
        panelType: PanelType.ACTIVITIES,
        active: true,
      });
      this.panels.push(tempPanel);
    }
  }

  addMyMoodComponent(hours: number) {
    const tempPanel = new DashboardPanel({
      name: 'My mood widget',
      panelType: PanelType.MOOD,
      linkAction: hours,
      active: true,
    });
    this.panels.push(tempPanel);
  }

  addBannerWidget(data: any) {
    const tempPanel = new DashboardPanel({
      name: 'Banner widget',
      panelType: PanelType.BANNER,
      linkModuleId: data.linkModuleId,
      linkElementId: data.url,
      linkAction: data.linkAction,
      elementType: data.elementType,
      dashboardBackground: data.dashboardBackground,
      background: data.background,
      active: true,
    });
    this.panels.unshift(tempPanel);
  }

  addWeatherWidget(data: any) {
    const tempPanel = new DashboardPanel({
      name: 'Weather widget',
      panelType: PanelType.WEATHER,
      linkModuleId: data.moduleId,
      linkElementId: data.id,
      linkAction: data.linkAction,
      elementType: data.elementType,
      dashboardBackground: data.dashboardBackground,
      background: data.background,
      active: true,
    });
    this.panels.push(tempPanel);
  }

  // sorting functionality
  sort(e: any) {
    const {oldIndex, newIndex} = e;
  }

  sortend(e: any, list: any) {
    const {oldIndex, newIndex} = e;
    this.rearrange(list, oldIndex, newIndex);
  }

  rearrange(panelList: [], oldIndex: any, newIndex: any) {
    if (oldIndex > newIndex) {
      this.panels.splice(newIndex, 0, this.panels[oldIndex]);
      this.panels.splice(oldIndex + 1, 1);
    } else {
      this.panels.splice(newIndex + 1, 0, this.panels[oldIndex]);
      this.panels.splice(oldIndex, 1);
    }
  }

  showSectionOption() {
    // @ts-ignore
    this.$refs.section.$children[0].open();
  }

  showSelectServiceAndImageDialog(elementId: any | number, $refs: any) {
    this.selectedElementIndex = elementId;
    this.checkFloatingSection = false;
    $refs.singleitem.$children[0].open()
  }

  // perform action on add saction model
  sectionStepOne(option: string) {
    // @ts-ignore
    this.$refs.section.$children[0].close();
    if (option === 'banner') {

        this.slider = this.panel;
        this.slider.name = 'Slider';
        this.slider.panelType = PanelType.SLIDER;
        this.slider.linkElementId = 0;
        this.slider.elements = [];
    } else if (option === 'collection') {
      // @ts-ignore
      this.$refs.collections.$children[0].open();
    } else if (option === 'dashboardComponents') {
      // @ts-ignore
      this.$refs.dashboardComponents.$children[0].open();
    }
    this.countSections();
  }

  // handle type of collection
  collectionResponse(option: string) {
    // @ts-ignore
    this.$refs.collections.$children[0].close();

    if (option === 'services') {
      // @ts-ignore
      this.$refs.services.$children[0].open();
    } else if (option === 'categories') {
      // @ts-ignore
      this.$refs.categories.$children[0].open();
    } else if (option === 'guestflow') {
      // @ts-ignore
      this.$refs.guestflow.$children[0].open();
    } else if (option === 'items') {
      // @ts-ignore
      this.$refs.items.$children[0].open();
    }
  }

  // add services to dashboard
  servicesResponse(services: []) {
    if (services.length > 0) {
      const temp = new DashboardPanel({active: 1});
      temp.name = 'Digital service';
      // @ts-ignore
      temp.elementType = this.$refs.services.data.ratio;
      for (const index in services) {
        if (index && services[index]) {
          temp.elements.push(new PanelElement({
            // @ts-ignore
            name: services[index].name,
            // @ts-ignore
            linkModuleId: services[index].id,
            linkLayout: 'element',
            // @ts-ignore
            image: services[index].image ? services[index].image.imageUrl : null,
            // @ts-ignore
            imageId: services[index].image ? services[index].image.id : null,
            active: true,
          }));
        }
      }
      this.selectedPanel = temp;
      this.selectedPanelIndex = this.panels.length;

      this.panels.push(temp);
      this.countSections();
      // @ts-ignore
      this.$refs.editPanel.$children[0].open();
    }
  }

  categoriesResponse(categories: []) {
    if (categories.length > 0) {
      const temp = new DashboardPanel();
      temp.name = 'Categories';
      temp.active = true;
      for (const index in categories) {
        if (index && categories[index]) {
          const tempService = new PanelElement();
          // @ts-ignore
          tempService.linkModuleId = services[index].id;

          temp.elements.push(tempService);
        }
      }
      this.panels.push(temp);
      this.countSections();
    }

    this.save();
  }

  // add collection of items
  itemsResponse(result: PanelElement) {
    this.panels.push(new DashboardPanel(result));
    this.countSections();
  }

  guestFlowResponse(guestFlow: []) {
    if (guestFlow.length > 0) {
      const temp = new DashboardPanel({active: 1});
      temp.name = 'Essentials';
      // @ts-ignore
      temp.elementType = this.$refs.services.data.ratio;
      for (const index in guestFlow) {
        if (index && guestFlow[index]) {
          temp.elements.push(new PanelElement({
            // @ts-ignore
            name: guestFlow[index].name,
            linkAction: 'GuestFlow',
            // @ts-ignore
            linkModuleId: guestFlow[index].id,
            linkLayout: 'element',
            // @ts-ignore
            image: guestFlow[index].image ? guestFlow[index].image.imageUrl : null,
            // @ts-ignore
            imageId: guestFlow[index].image ? guestFlow[index].image.id : null,
            active: true,
          }));
        }
      }
      this.selectedPanel = temp;
      this.selectedPanelIndex = this.panels.length;

      this.panels.push(temp);
      this.countSections()
      // @ts-ignore
      this.$refs.editPanel.$children[0].open();
    }
  }

  // add single item with panel
  singleItemResponse(result: any, module: IModule) {
    if (this.checkFloatingSection === false && this.floatingSection) {
      let element = new PanelElement();
      element = result[0];
      element.active = true;
      Vue.set(this.floatingSection.elements, this.selectedElementIndex, element);
    } else {
      if(result[0] !== undefined){
        this.panels[this.selectedPanelIndex].elements.push(result[0]);
      }
      else{
        var message = translate('no-item-found');
        if(message != ''){
          showToaster('danger','Error',message,'404')
        }else{
          showToaster('danger','Error','Item not found','404')
        }

      }
    }
  }

  // show pop to add image with element of panel
  showImagesForElement(panelId: any, elementId: any) {
    this.selectedPanelIndex = panelId;
    this.selectedElementIndex = elementId;
    // @ts-ignore
    this.$refs.elementImage.$children[0].open();
  }

  // attach image with element
  attachImageToElement(img: any) {
    const element = this.panels[this.selectedPanelIndex].elements[this.selectedElementIndex];
    element.image = img.result.imageUrl;
    element.imageId = img.result.id;
    // @ts-ignore
    this.$refs.elementImage.$children[0].close();
  }

  showImagesForFloating(elementId: any) {
    this.selectedElementIndex = elementId;
    // @ts-ignore
    this.$refs.floatingImage.$children[0].open();
  }

  attachImageToFloating(img: any) {
    const element = this.floatingSection.elements[this.selectedElementIndex];
    element.image = img.result.imageUrl;
    element.imageId = img.result.id;
    // @ts-ignore
    this.$refs.floatingImage.$children[0].close();
  }

  // banner slider handlers start
  removeSlider() {
    this.slider = null;
    clearInterval(this.sliderInterval);
    this.countSections();
  }

  removeSlide(slide: any) {
    this.nextSlide();
    this.slider.elements = this.slider.elements.filter((item: any) => {
      return item !== slide;
    });
    const temp: PanelElement[] = [];
    for (const index in this.slider.elements) {
      if (index) {
        temp.push(this.slider.elements[index]);
      }
    }
    this.slider.elements = temp;
  }

  showAddSlide(index: any) {
    // @ts-ignore
    this.$refs.slide.$children[0].open();
  }

  attachSlide(imgObj: any) {
    if(!(imgObj.result.isVideoFile || imgObj.result.isImageFile))
    {
      showToaster('warning', '', "Please select valid media files!");
      return
    }
    const ele = new PanelElement();
    ele.image = imgObj.result.imageUrl;
    ele.imageId = imgObj.result.id;
    ele.active = true;
    ele.elementImage.type = imgObj.result.type;
    this.slider.elements.push(ele);
    // @ts-ignore
    this.slider.elements.map((element : any,index : number) => {
      element.active = index === 0 ? true : false
    })
    this.$delete(ele, 'foo');
    // @ts-ignore
    this.$refs.slide.$children[0].close();
    clearInterval(this.sliderInterval);
    if (this.slider && this.slider.elements.length > 0) {
      this.sliderInterval = setInterval(() => {
        // this.nextSlide();
      }, 5000);
    }
  }

  nextSlide() {
    let countElements = this.slider.elements.length;
    if(countElements === 1) return;
    let activeElement = this.slider.linkElementId;
    if(activeElement + 1 >= countElements) return;
    this.slider.elements[activeElement].active = false;
    this.slider.elements[activeElement + 1 ].active = true;
    this.slider.linkElementId = activeElement + 1;
  }

  preSlide() {
    let countElements = this.slider.elements.length;
    if(countElements === 1) return;
    let activeElement = this.slider.linkElementId;
    if(activeElement - 1 < 0) return;
    this.slider.elements[activeElement].active = false;
    this.slider.elements[activeElement - 1 ].active = true;
    this.slider.linkElementId = activeElement - 1;
  }

  // banner slider handlers end here
  removePanel(panel: any) {
    this.panels = this.panels.filter((item: any) => {
      return panel !== item;
    });
    this.countSections();
  }

  updatePanel(result: any) {
    this.$set(this.panels, result.panelIndex, result.panel);
  }
  translatePanelName(panel: IPanel,defaultKey : string)
  {
    const translatedValue = Vue.prototype.translate(panel.name);
    if(panel.name.startsWith('dashboard_panels')) return translatedValue
    if(panel.name === undefined) return defaultKey
    if(panel.name === null) return defaultKey
    return panel.name
  }

  removeElement(element: any, index: any) {
    this.panels[index].elements = this.panels[index].elements.filter((item: any) => {
      return item !== element;
    });
  }

  /**
   * Left Side module Setting Area start
   */
  radiusControll() {
    const list = document.getElementsByClassName('b-radius');
    for (const i in list) {
      // @ts-ignore
      if (i && list[i] && list[i].style) {
        // @ts-ignore
        list[i].style.borderRadius = this.dashboardSetting.radius + '%';
      }
    }
  }

  /**
   * Lift Side module Setting Area End
   */

  getHeaders() {
    return stores.File.getHeader();
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }

  /**
   * Galley Pop up configuration end here
   */

  /**
   * Logo setting start here
   */
  // header handlers
  cancelLogo() {
    this.dashboardSetting.logo = null;
    this.dashboardSetting.imageId = null;
    this.$forceUpdate();
  }

  attachLogo(imgObj: any) {
    this.dashboardSetting.logo = imgObj.result.imageUrl;
    this.dashboardSetting.imageId = imgObj.result.id;
    this.$forceUpdate();
  }

  selectLogo() {
    // @ts-ignore
    this.$refs.logo.$children[0].open();
  }

  removeFloating(elementId: any) {
    const resetElement = new PanelElement();
    resetElement.active = false;
    Vue.set(this.floatingSection.elements, elementId, resetElement);
  }

  /**
   * Logo setting end here
   */

  dashboardHandler(dashboard: any) {
    this.dashboardSetting = dashboard;
  }

}
