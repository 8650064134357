
import {Component, Vue, Watch} from 'vue-property-decorator';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
import EditCredentials from '@/modules/MailConfiguration/EditCredentials.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    NevronHeader,
    Skeleton,
    EditCredentials,
  },
})

export default class AdminDetails extends Vue {
  currentAdmin = stores.admin;
  mail: any = '';
  response: any = '';
  mailType: IModuleSetting = {
    key: 'mail_type',
    value: '',
    type: 'mail',
  };
  mailHost: IModuleSetting = {
    key: 'mail_host',
    value: '',
    type: 'mail',
  };
  mailPort: IModuleSetting = {
    key: 'mail_port',
    value: '',
    type: 'mail',
  };
  mailUsername: IModuleSetting = {
    key: 'mail_username',
    value: '',
    type: 'mail',
  };
  mailPassword: IModuleSetting = {
    key: 'mail_password',
    value: '',
    type: 'mail',
  };
  mailEncryption: IModuleSetting = {
    key: 'mail_encryption',
    value: '',
    type: 'mail',
  };
  mailFromName: IModuleSetting = {
    key: 'mail_from_name',
    value: '',
    type: 'mail',
  };
  mailFromAddress: IModuleSetting = {
    key: 'mail_from_address',
    value: '',
    type: 'mail',
  };
  mailToAddress: IModuleSetting = {
    key: 'mail_to_address',
    value: '',
    type: 'mail',
  };

  setCredentials(userName: any, password: any) {
    this.mailUsername.value = userName.value;
    this.mailPassword.value = password.value;
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('mail-configuration');
    this.fetchData();
  }

  fetchData() {
    return stores.MailConfiguration.fetchMailData()
      .then((response) => {
        this.response = response.data;
        for (const index in this.response) {
          if (this.response[index].key === 'mail_type') {
            this.mailType = this.response[index];
          } else if (this.response[index].key === 'mail_host') {
            this.mailHost = this.response[index];
          } else if (this.response[index].key === 'mail_port') {
            this.mailPort = this.response[index];
          } else if (this.response[index].key === 'mail_username') {
            this.mailUsername = this.response[index];
          } else if (this.response[index].key === 'mail_password') {
            this.mailPassword = this.response[index];
          } else if (this.response[index].key === 'mail_encryption') {
            this.mailEncryption = this.response[index];
          } else if (this.response[index].key === 'mail_from_name') {
            this.mailFromName = this.response[index];
          } else if (this.response[index].key === 'mail_from_address') {
            this.mailFromAddress = this.response[index];
          } else if (this.response[index].key === 'mail_to_address') {
            this.mailToAddress = this.response[index];
          }
        }
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /*
   *
   */
  onSubmit() {
    const settings = [];
    settings.push(this.mailType);
    settings.push(this.mailHost);
    settings.push(this.mailPort);
    settings.push(this.mailUsername);
    settings.push(this.mailPassword);
    settings.push(this.mailEncryption);
    settings.push(this.mailFromName);
    settings.push(this.mailFromAddress);
    settings.push(this.mailToAddress);
    return stores.MailConfiguration.update(settings)
      .then((response) => {
        if (response.responseCode === 200) {
          // @ts-ignore
          showToaster('success', 'Mail Configuration', 'successfully updated');
        } else {
          // @ts-ignore
          showToaster('danger', 'Mail Configuration', 'Fail to update');
        }
      }).catch((e: any) => {
        console.log(e);
      });
  }
}
