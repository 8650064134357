
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import Edit from '@/modules/Modules/Edit.vue';

@Component({
  components: {
    Edit,
  },
})
export default class ModuleView extends Vue {
  @Prop()
  moduleId?: number;  // Make moduleId optional

  @Prop({ required: true })
  moduleType!: ModuleType;

  module: any = null;
  rerenderKey = 0;

  // Watch for changes in moduleId prop
  @Watch('moduleId', { immediate: true })
  onModuleIdChange(newId: number | undefined) {
    if (newId !== undefined) {
      // Store moduleId in local storage
      this.fetchModule(newId);
    } else {
      // Attempt to retrieve moduleId from local storage
      const storedId = localStorage.getItem('moduleId');
      if (storedId) {
        const id = parseInt(storedId, 10);
        this.fetchModule(id);
      }
    }
  }

  fetchModule(id: number) {
    console.log('Fetching module with id:', id);
    this.module = stores.modules.get(id);

    if (this.module) {
      Vue.prototype.WebsiteTitle.setModuleName(this.module.name);
      this.triggerRerender();
    } else {
      console.warn('No module found for id:', id);
    }
  }

  triggerRerender() {
    this.rerenderKey++;
  }

  mounted() {
    if (this.moduleId !== undefined && !isNaN(this.moduleId)) {
      localStorage.setItem('storedModuleId', this.moduleId.toString());
      this.fetchModule(this.moduleId);
    } else {
      const storedId = localStorage.getItem('storedModuleId');
      console.log(storedId)
      if (storedId) {
        const id = parseInt(storedId, 10);
        if (!isNaN(id)) {
          this.fetchModule(id);
        } else {
        }
      } else {
        console.warn('No module ID found in local storage during mounted.');
      }
    }
  }
}
