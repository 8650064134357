
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';

import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import TabManager from "@/mixins/TabManager";
import { hasFeaturePermissionForType } from '@/helpers/permissions';

@Component({
  components: {
    ItemsIndexGeneric,
  },
  methods: {
    hasFeaturePermissionForType
  }
})

export default class CustomersIndex extends TabManager {
  // Component logic goes here
  stores = stores;

  get TAB_KEYS(): string[] {
    const keys = ['guests'];
    keys.push('stays');
    keys.push('combined-users');
    return keys;
  }
};
