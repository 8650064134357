import { render, staticRenderFns } from "./CollectionItem.vue?vue&type=template&id=00f40672&scoped=true&v-on-clickaway=cancel&"
import script from "./CollectionItem.vue?vue&type=script&lang=ts&"
export * from "./CollectionItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f40672",
  null
  
)

export default component.exports