
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';
import ProjectManagement from "@/modules/Projects/ProjectManagement.vue";

@Component({
  components: {
    ProjectManagement,
    SweetModal,
  },
})

export default class ProjectManagementModal extends Vue {

  @Prop({ default: null }) allowedFeatures!: string[] | null;
  @Prop({ default: null }) operator!: IOperator | null;
  @Prop({ default: undefined }) projectId!: string | null | undefined;

  refreshKey = 0;

  cancel() {
    this.$emit('cancel');
    // @ts-ignore
    this.$children[0].close();
  }

}
