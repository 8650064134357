
import { Component, Vue, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class CreateFile extends Vue {
  @Prop()
  image: any = '';

  index: any = {
    key: '',
    file: '',
    type: 'icon',
  };

  uploadFile(e: any) {
    console.log('file', e);
    let blnValid = false;
    if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/svg+xml') {
      this.image.file = e.target.files[0];
      blnValid = true;
    }
    if (!blnValid) {
      alert('Sorry, ' + e.target.files[0].type + ' is invalid, allowed extensions are: (PNG/SVG) ');
      // @ts-ignore
      this.$refs.pickBackFile.value = null;
    }
  }
  onSubmit() {
    const form = this.$refs.form as HTMLFormElement;
    if (!form.checkValidity()) {
      form!.classList.add('was-validated');
    } else {
      form!.classList.add('was-validated');
      const formdata = new FormData();
      formdata.append('key', this.image.key);
      formdata.append('file', this.image.file);
      formdata.append('type', this.image.type);
      return stores.Setting.createIcon(formdata)
        .then((response: any) => {
          this.cancel();
          this.$emit('refresh');
        })
        .catch((error: any) => {
          console.log(error);
        });
    }

  }

  cancel(): void {
    this.image.key = '';
    this.image.file = '';
    // @ts-ignore
    this.$children[0].close();
    (this.$refs.form as HTMLFormElement).classList.remove('was-validated');
  }
}
