
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';
import stores from "@/stores";
import { showToaster } from "@/components/DashboardComposer/helpers";
import TimeZones from "@/modules/TimeZones.vue";
import { bus } from '@/helpers/functions';

@Component({
  components: { TimeZones, SweetModal }
})
export default class SuperAdminDetails extends Vue {
  @Prop({ required: true }) userData!: ISuperAdmin;

  currentPassword: string = '';
  adminProfile: any = { profileImageUrl: null, name: '' };
  showAllSessions: boolean = false;

  @Watch('userData', { immediate: true })
  onProfileToEditChange(newValue: ISuperAdmin) {
    this.adminProfile = newValue;
  }

  get adminProfileInitials() {
    if (!this.adminProfile?.name) return '';
    const initials = this.adminProfile.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  get currentTime() {
    return new Date();
  }

  openFileExplorer() {
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    fileInput?.click();
  }

  uploadImage(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files?.[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        if (this.adminProfile) {
          this.adminProfile.profileImageUrl = reader.result as string;
        }
        fileInput.value = '';
      };
      reader.readAsDataURL(fileInput.files[0]);
    }
  }

  get profileImageStyle() {
    return this.adminProfile?.profileImageUrl ? {
      backgroundImage: `url(${this.adminProfile.profileImageUrl})`,
      cursor: 'pointer',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '68px',
      height: '68px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    } : {
      cursor: 'pointer',
      width: '68px',
      height: '68px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#007bff',
      color: '#fff',
      fontSize: '20px'
    };
  }

  async onSubmit() {
    if (!this.validateForm() || !await this.validatePassword()) {
      return;
    }

    return stores.SuperAdmins.updateItem(this.adminProfile)
      .then(() => {
        showToaster('success', '', 'User successfully updated.');
        this.closeModal();
      })
      .catch((e) => console.error(e));
  }

  formatTime(time: Date, format: string): string {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const formattedHours = format === '12' ? (hours % 12 || 12) : hours;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const ampm = format === '12' ? (hours >= 12 ? 'PM' : 'AM') : '';
    return `${formattedHours}:${formattedMinutes} ${ampm}`.trim();
  }

  formatDate(date: Date, format: string): string {
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return format === 'dmy' ? `${day} ${monthName}, ${year}` : format === 'mdy' ? `${monthName} ${day}, ${year}` : '';
  }

  closeModal() {
    (this.$refs.adminProfile as any)?.close();
    this.clearValidation();
    bus().$emit('adminUpdated');
  }

  validateForm(): boolean {
    const form = this.$refs.updateForm as HTMLFormElement;
    form.classList.add('was-validated');
    return form.checkValidity() && !this.isInvalidConfirmation;
  }

  timeZoneSelected(zone: string) {
    if (this.adminProfile) {
      this.adminProfile.timeZone = zone;
    }
  }

  get isInvalidConfirmation() {
    return this.adminProfile.password && this.adminProfile.password !== this.adminProfile.passwordConfirmation;
  }

  async validatePassword(): Promise<boolean> {
    if (this.adminProfile) {
      try {
        const isValid = await stores.SuperAdmins.checkCurrentPassword(this.adminProfile.id, {
          password: this.currentPassword,
          passwordConfirmation: !!this.adminProfile.password
        });
        (this.$refs.currentPassword as HTMLInputElement).classList.toggle('is-invalid', !isValid);
        this.currentPassword = isValid ? '' : this.currentPassword;
        return isValid;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
    return false;
  }

  clearValidation() {
    if (!this.adminProfile) return;
    const form = this.$refs.updateForm as HTMLFormElement;
    form.classList.remove('was-validated');
    (this.$refs.currentPassword as HTMLInputElement).classList.remove('is-invalid');
    this.adminProfile.password = this.adminProfile.passwordConfirmation = '';
  }

  toggleSessionView(event: Event) {
    event.preventDefault();
    this.showAllSessions = !this.showAllSessions;
  }

  get displayedSessions() {
    return this.showAllSessions ? this.adminProfile?.sessions : this.adminProfile?.sessions?.slice(0, 7);
  }

  formatLoginTime(loginTime: Date | null): string {
    if (!loginTime) return 'Never logged in';
    return `${this.formatDate(loginTime, 'dmy')} ${this.formatTime(loginTime, '24')}`;
  }

  getLoginTime(session: ISession): Date | null {
    return session.loginTime ? new Date(session.loginTime) : null;
  }

  getRecentSessionLoginTime(): Date | null {
    return this.displayedSessions.length > 0 ? this.getLoginTime(this.displayedSessions[0]) : null;
  }
}
