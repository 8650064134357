export default function hexColorCode(str: string) {
  return intToRGB(hashCode(str));
}

/* tslint:disable */
function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

/* tslint:disable */
function intToRGB(i: number = 0) {
  const c = (i & 0x00FFFFF)
    .toString(16)
    .toUpperCase();

  return '0000'.substring(0, 6 - c.length) + c;
}

