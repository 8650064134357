import { render, staticRenderFns } from "./QuestionsExport.vue?vue&type=template&id=06977e28&scoped=true&"
import script from "./QuestionsExport.vue?vue&type=script&lang=ts&"
export * from "./QuestionsExport.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionsExport.vue?vue&type=style&index=0&id=06977e28&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06977e28",
  null
  
)

export default component.exports