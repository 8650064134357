
import {Component,Vue} from 'vue-property-decorator';
import stores from '@/stores';
import ItemsMultiIndexGeneric from '@/components/ListNevronComponents/Items/IndexGenericMultiTable.vue';
import IndexGeneric from "@/components/ListNevronComponents/Items/IndexGeneric.vue";
import ItemsIndexGeneric from "@/components/ListNevronComponents/Items/IndexGeneric.vue";

type Tab = 'all' | 'categories';

const ALLOWED_TABS: Tab[] = ['all', 'categories'];

@Component({
  components: {
    ItemsIndexGeneric,
    IndexGeneric,
    ItemsMultiIndexGeneric,

  },
})

export default class PropertyAssetsIndex extends Vue {

  stores = stores;
  openedTab: Tab = 'all';

  handleTabChange(tab: Tab) {

    if(this.openedTab !== tab) {
      this.openedTab = tab;
    }

    if(this.$route.query.tab !== tab) {
      this.$router.push({query: { tab }});
    }

  }

  beforeMount() {

    let { tab } = this.$route.query;

    if(typeof tab === 'string' && ALLOWED_TABS.includes(tab.toLowerCase() as Tab)) {
        this.handleTabChange(tab.toLowerCase() as Tab);
    }
    
  }

};
