
import { Component, Vue } from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import { Setting } from '@/stores/Setting';
import Skeleton from '@/modules/Skeleton.vue';
import { showToaster } from '@/components/DashboardComposer/helpers';
import { translate } from '@/helpers/functions';

@Component({
  components: {
    NevronHeader,
    Skeleton,
  },
})
export default class MoodSettings extends Vue {
  timeout: ISetting = new Setting({'key': 'timeout'});

  automaticSlideUp: ISetting = {
    id: 0,
    key: 'mood_automatic_slide_up',
    value: '',
  };
  showAfterStart: ISetting = {
    id: 0,
    key: 'mood_show_after_start',
    value: '',
  };

  afterStartMinDuration: ISetting = {
    id: 0,
    key: 'mood_after_start_duration_minutes',
    value: '',
    type: 'minute',
  };

  afterStartSecDuration: ISetting = {
    id: 0,
    key: 'mood_after_start_duration_seconds',
    value: '',
    type: 'second'
  };
  ShowDisplayInterval: ISetting = {
    id: 0,
    key: 'mood_show_display_interval',
    value: '',
  };

  displayInterval: ISetting = {
    id: 0,
    key: 'mood_display_interval',
    value: '',
  }

  showStopDisplay: ISetting = {
    id: 0,
    value: '',
    key: 'mood_show_stop_display',
  }

  stopDisplayInterval: ISetting = {
    id: 0,
    value: '',
    key: 'mood_stop_display_interval',
  }

  allSettings: ISetting[] = []

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('Settings');
    this.fetch();
  }

  fetch() {
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
      });
  }

  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      switch (data[index].key) {
        case 'channel_timeout':
          this.timeout = data[index];
          break;
        case 'mood_automatic_slide_up':
          this.automaticSlideUp = data[index];
          break;
        case 'mood_show_after_start':
          this.showAfterStart = data[index];
          break;
        case 'mood_after_start_duration_minutes':
          this.afterStartMinDuration = data[index];
          break;
        case 'mood_after_start_duration_seconds':
          this.afterStartSecDuration = data[index];
          break;
        case 'mood_show_display_interval':
          this.ShowDisplayInterval = data[index];
          break;
        case 'mood_display_interval':
          this.displayInterval = data[index];
          break;
        case 'mood_show_stop_display':
          this.showStopDisplay = data[index];
          break;
        case 'mood_stop_display_interval':
          this.stopDisplayInterval = data[index];
          break;
        default:
          break;
      }
    }
  }

  updateSetting() {
    if(this.allSettings.length > 0){
      for(const setting of this.allSettings){
        console.log("here inside update func call",setting);
        stores.Setting.update(setting.id, setting)
        .then((response) => {
          this.fetch();
          showToaster("success"," ",translate("successfully-updated"));
        });
      }
    }
  }
  
  settingEdited(setting:ISetting){
    let index = this.allSettings.findIndex((settingObj) => settingObj.key === setting.key)
    if(index !== -1){
      this.allSettings[index] = setting
    }
    else{
      this.allSettings.push(setting);
    }
    console.log("updated settings: ",this.allSettings)
  }
}
