
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';
import SeriesModules from '@/stores/modules/Series';

@Component({
  components: {
    SweetModal,
  },
})
export default class CreateEpisodes extends Vue {
  @Prop() module!: SeriesModules;
  @Prop()
  checkParentType!: any;
  @Prop()
  parentId!: any;
  @Prop()
  checkEpisodeType!: any;
  // @ts-ignore
  episodes: IEpisodes = {
    id: null,
    active: true,
    isTrailer: false,
    sort: null,
    name: '',
    shortDescription: '',
    fullDescription: '',
    image: null,
    releaseDate: '',
    price: '0',
    rating: '',
    duration: '',
    moduleId: null,
    parentId: null,
    releaseYear: '',
  };

  mounted() {
    const form = document.getElementById('create-form') as HTMLFormElement;
    form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      form.classList.add('was-validated');
    }, false);
  }

  cancel() {
    const form = document.getElementById('create-form') as HTMLFormElement;
    if (form) {
      form.classList.remove('was-validated');
    }
    this.episodes.name = ''; // Reset episode name
    // Close the modal
    this.$emit('success');
    (this.$refs.create as any).close();
  }

  onSubmit() {
    const form = document.getElementById('create-form') as HTMLFormElement;
    if (form.checkValidity()) {
      if (localStorage.getItem('episodeTypes') === 'trailer') {
        // @ts-ignore
        this.episodes.isTrailer = 1;
      } else {
        // @ts-ignore
        this.episodes.isTrailer = 0;
      }
      this.module.createEpisodesItem(this.episodes, this.checkParentType, this.parentId)
        .then((response) => {
          this.cancel();
          this.episodes.name = '';
          const form = document.getElementById('create-form');
          if (form) {
            form.classList.remove('was-validated');
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      form.classList.add('was-validated');
    }
  }
}
