import globalConfig from '@/helpers/globalConfig';
import {generateLog} from '@/helpers/functions';
import axiosInstance from '@/helpers/axios';

export default class CombinedUser {

  url: string;
  path: string;
  fullUrl: string;
  users?: ICombinedUser[] | null = null;
  routeName: string = 'combinedUser';

  constructor() {
    this.url = globalConfig.url;
    this.path = globalConfig.path;
    this.fullUrl = `${globalConfig.url}/${globalConfig.path}`;
  }

  fetchData(index: any = 1, search: any = '', perpage: any = 10) {
    return axiosInstance.get(`${this.fullUrl}/combined/users?search=${search}&page=${index}&perpage=${perpage}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createItem(data: any) {
    return axiosInstance.post(`${this.fullUrl}/combined/users`, data)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e.response.data;
      });
  }

  getUser(userId: number) {
    return axiosInstance.get(`${this.fullUrl}/combined/users/${userId}`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(userId: any, data: any) {
    return axiosInstance.put(`${this.fullUrl}/combined/users/${userId}`, data)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e.response.data;
      });
  }
  deleteItem(userId: number) {
    return axiosInstance.delete(`${this.fullUrl}/combined/users/${userId}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  // stay management
  addStay(userId: any, reservationId: string) {
    return axiosInstance.post(`${this.fullUrl}/combined/users/${userId}/stays/attach`, {reservationId})
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e.response.data;
      });
  }

  removeStays(userId: any, ids: any) {
    return axiosInstance.post(`${this.fullUrl}/combined/users/${userId}/stays/detach`, {ids})
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e.response.data;
      });
  }

  updateProfile(userId: any, data: any) {
    return axiosInstance.post(`${this.fullUrl}/combined/users/${userId}/profile`, data)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e.response.data;
      });
  }
}
