<template>
  <div>
    <!-- click blocking layer -->
    <span :id="randomId" ref="trigger" tabindex="-1" @click.capture="interceptEvent">
      <slot></slot>
    </span>

    <!-- modal -->
    <sweet-modal ref="modal" hide-close-button blocking>
      <template v-slot:title>
        <h3>{{ translate("are-you-sure") }} ?</h3>
        <button class="close-modal" @click="cancel()"></button>
      </template>
      <template>
        <div>
          <p style="font-size: 20px">
            <slot name="confirm-message"></slot>
          </p>
        </div>
      </template>

      <template v-slot:button>
          <button class="btn btn-outline-secondary mr-3 w-md" @click="cancel()">
            {{ translatedCancelButton }}
          </button>
          <button class="btn btn-primary w-lg" @click.prevent="onOk">
            {{ translatedProceedButton }}
          </button>
      </template>
    </sweet-modal>

  </div>
</template>

<script>
import { translate } from '@/helpers/functions';
import { SweetModal } from 'sweet-modal-vue';
export default {
  components: {
    SweetModal,
  },
  data() {
    return {
      isOpen: false,
      randomId: 'clickConfirm' + this._uid,
      target: null,
      allow: false,
      item: null,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Are you sure'
    },
    showQuestionMark:{
      type: Boolean,
      default: true,
    },
    translated: {
      type:Boolean,
      default: true,
    },
    cancelButtonText:{
      type: String,
      default: 'no',
    },
    proceedButtonText:{
      type: String,
      default: 'yes',
    },
    onSubmit: {
      type: Function | null,
      default: null,
    }
  },
  watch: {
    disabled(newValue) {
      if (newValue && this.isOpen) {
        this.cancel();
      }
    },
  },
  computed: {
    questionMark: function(){
      return (this.showQuestionMark) ? '?' : '';
    },
    translatedTitle: function (){
      return (this.translated) ? translate(this.title) : this.title;
    },
    translatedCancelButton:function (){
      return (this.translated) ? translate(this.cancelButtonText) : this.cancelButtonText;
    },
    translatedProceedButton:function (){
      return (this.translated) ? translate(this.proceedButtonText) : this.proceedButtonText;
    }
  },
  methods: {
    onHidden() {
      this.target = null;
    },
    openModal(item = null) {
      if(item != null){
        this.item = item;
      }
      this.$refs.modal.open();
      this.isOpen = true;
    },
    onOk() {
      if (this.onSubmit) {
        if(this.item != null){
          this.onSubmit(this.item);
          this.cancel();
          return;
        }
        this.onSubmit();
        this.cancel();
        return;
      }

      this.$emit('removeItem');
      if (this.target !== null) {
        this.allow = true;
        const mouseClick = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          composed: true,
        });
        if (!this.target.dispatchEvent(mouseClick)) {
          console.error('Confirmed event failed to dispatch');
        }
        this.allow = false;
      }
      this.cancel();
    },
    cancel() {
      this.$refs.modal.close();
      this.isOpen = false;
    },
    interceptEvent(e) {
      if (this.disabled) {
        return;
      }
      this.target = e.target;
      if (this.allow) {
          return;
      }
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      this.openModal();
    },
  },
  mounted() {
    document.addEventListener('keyup', (event) => {
        if (!this.isOpen) {
          return;
        }
        if (event.keyCode === 13) {
          this.onOk();
        }
    });
  },
  beforeDestroy() {
    if (this.isOpen) {
      this.cancel();
    }
  },
};
</script>
