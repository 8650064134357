import {BToast} from 'bootstrap-vue';
// @ts-ignore
import i18n from './i18n.js';
import stores from "@/stores";

export function createItemPanelElement(module: IModule, item: any, type: string = '') {
  const panelElement = stores.panelElement;
  panelElement.linkModuleId = module.id!;
  panelElement.linkElementId = type === 'category' ? item.id.toString() : item.id;
  panelElement.name = item.name!;
  panelElement.linkLayout = 'element';
  panelElement.linkAction = type === 'category' ? type : 'play';
  panelElement.image = item.image ? item.image.imageUrl : null;
  panelElement.imageId = item.image ? item.image.id : null;
  panelElement.active = true;
  if (module.name === 'guestFlow') {
    panelElement.linkAction = module.name;
    // @ts-ignore
    panelElement.linkModuleId = module.moduleId;
  }

  if (module.name === 'contact-module') {
    panelElement.linkAction = 'contact-module';
    panelElement.linkModuleId = item.id;
    panelElement.linkElementId = '';
  }

  if (module.type === 'DIGITAL_SIGNAGE') {
    panelElement.linkAction = 'DIGITAL_SIGNAGE';
    // @ts-ignore
    panelElement.linkModuleId = module.id;
    panelElement.linkElementId = item.id;

    panelElement.name = item.title;
    // @ts-ignore
    panelElement.imageId = module.imageId;
    // @ts-ignore
    panelElement.image = module.image ? module.image.imageUrl : '';
  }

  if (module.type === 'RSS') {
    panelElement.linkAction = 'RSS';
  }

  if (module.type === 'APPLICATION') {
    panelElement.linkLayout = 'application';
    panelElement.linkAction = item.packageName;
    panelElement.linkType = item.type;
    // panelElement.linkElementId = item.packageName;
  } else if (module.type === 'SERIES') {
    panelElement.linkLayout = 'series';
  }
  return panelElement;
}

export function createModulePanelElement(module: IModule) {
  const panelElement = stores.panelElement;
  panelElement.name = module.name;
  // @ts-ignore
  panelElement.linkModuleId = module.id;
  // @ts-ignore
  if (module.name === 'contact-module') {
    panelElement.linkAction = 'ContactModule';
  } else {
    // @ts-ignore
    panelElement.linkAction = typeof module.licenseKey !== 'undefined' ? 'guestFlow' : null;
  }
  panelElement.linkLayout = 'element';
  // @ts-ignore
  panelElement.imageId = module.imageId;
  // @ts-ignore
  panelElement.image = module.image ? module.image.imageUrl : '';
  panelElement.active = true;
  return panelElement;
}

export function createModulesPanel(modules: IModule[], elementType = 'image') {
  const panel = stores.dashboardPanel;
  panel.name = 'Digital service';
  panel.elementType = elementType;
  for (const module of modules) {
    let clonePanelElement : IPanelElement = JSON.parse(JSON.stringify(stores.panelElement));
    const panelElement : IPanelElement= {
      // @ts-ignore
      ...clonePanelElement,
      name: module.name,
      linkModuleId: module.id as number,
      linkLayout: 'element',
      active: true,
    };
    panel.elements.push(panelElement);
  }
  return panel;

}

export function showToaster(variant: any, name: any, message: any, statusCode: any = 500, customTitle?: boolean) {
  const title = customTitle ? name : (variant === 'success' ? 'Success' : 'Error ' + '(' + statusCode + ')');
  const body = customTitle ? message : (variant === 'success' ? message + ' ' + name : message);


  const bootStrapToaster = new BToast();
  bootStrapToaster.$bvToast.toast(body, {
    title,
    variant,
    solid: true,
  });
}
export function showFirstValidationErrorToaster(pageName: 'default',error: any) {
  let errorMessage = "Validation Error";

  if (error.response && error.response.data && error.response.data.errors) {
    // Extract the validation errors from the response
    const errors = error.response.data.errors;

    // Loop through the errors object to find the first validation error
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        errorMessage = errors[field][0]; // Get the first validation error message
        break; // Stop looping after finding the first error
      }
    }
  }

  // Display the error message using a toaster (Replace this with your actual toaster implementation)
  return showToaster('warning',pageName,errorMessage,422)
}
