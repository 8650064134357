
import { Component, Vue, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import Edit from '@/modules/Recorders/Edit.vue';
import RecorderModule from '@/stores/modules/Recorders';

@Component({
  components: {
    Edit,
  },
})

export default class TVRecorderDetails extends Vue {
  @Prop()
  module!: RecorderModule;

  recorder: IRecorder | null = null;

    deleteStream(id: number) {
      if (confirm('Do you want to delete this stream?') && this.recorder) {
        this.module.deleteRecordStream(Number(this.recorder.id), id)
          .then((response) => {
            console.log(response);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

  deleteRecorder() {
    if (confirm('Do you want to delete this recorder?') && this.recorder) {
      this.module.deleteRecord(Number(this.recorder.id))
        .then((response) => {
          console.log(response);
          this.$router.push({name: 'recorders'});
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  toPassword(text: string) {
    let output: string = '';

    for (const iterator of text) {
      output += '*';
    }

    return output;
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    return this.module.getSelectedRecorder(Number(this.$route.params.id))
      .then((response) => {
        this.recorder = response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

}
