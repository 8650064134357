
import { Component, Vue, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';
import { showToaster } from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    SweetModal
  },
})
export default class AssignProjectModal extends Vue {
  @Prop({ default: [] })
  devices: any = [];
  devicesCount: number = 0;
  projects: any = [];
  selectedProjectId: null | number = null;
  selectedProjectName: null | string = null;
  mounted() {
    stores.master.getAllProjects()
      .then((response) => {
        this.projects = response;
      }).finally(() => {
        if (this.projects.length > 0) {
          this.selectedProjectId = this.projects[0].id;
          this.selectedProjectName = this.projects[0].name;
        }
      })

  }
  open() {
    var devices = Object.keys(this.devices).map((key) => [key, this.devices[key]]);
    devices.forEach((tenantDevice: any, index: number) => {
      tenantDevice[1].forEach((element: any, subIndex: number) => {
        if (element === true) {
          this.devicesCount++;
        }
      });
    });
    if (this.projects.length > 0) {
      this.selectedProjectId = this.projects[0].id;
      this.selectedProjectName = this.projects[0].name;
    }

  }
  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.devicesCount = 0;
    this.selectedProjectId = null;
  }
  assignDevicesToProject() {
    const devices = this.formatDevices(this.devices);
    stores.SuperAdminDevices.updateDevicesProject(devices)
      .then((response: any) => {
        showToaster('success', 'Devices assigned to project successfully', '');
        // @ts-ignore
        this.$emit('assigned', this.devices);
      })
      .catch((e: any) => {
        console.log(e);
      }).finally(() => {
        this.cancel()
      });
  }

  formatDevices(devices: any) {
    let formattedDevices: any = [];
    const selectedDevices = Object.keys(this.devices).map((key) => [key, this.devices[key]]);
    selectedDevices.forEach((tenantDevice: any, index: number) => {
      tenantDevice[1].forEach((element: any, subIndex: number) => {
        if (element === true) {
          formattedDevices.push({
            id: subIndex,
            previousTenantId: tenantDevice[0],
            newTenantId: this.selectedProjectId,
          })
        }
      });
    });

    return formattedDevices;
  }
}

