
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import Axios, { AxiosPromise, AxiosResponse } from 'axios';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import vPagination from '@/components/VuePlainPagination.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';

@Component({
  components: {
    SweetModal,
    vPagination,
    NevronSearch,
    NevronSearchNoResults,
  },
  mixins: [ Clickaway ],
})

export default class AttachMobileDashboard extends Vue {

  @Prop()
  type!: any;

  @Prop({required: false})
  selectedMobileDashboardId!: number | string;

  newDashboardId = this.selectedMobileDashboardId || null;

  searchQuery: string = '';
  response: any = null;
  items: any = null;

  firstLoad = true;

  searchItems = _.debounce((res: any) => {
    this.fetchData();
  }, 400);

  mounted() {
    this.fetchData(1);
  }

  fetchData(index = 1): any {
    stores.dashboards.fetchData(index, this.searchQuery, 10, 'Mobile')
      .then((response) => {
        this.response = response;
        this.items = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

  }

  @Watch('response.currentPage')
  changedPage(index: number) {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.fetchData(index);
  }

  createMobileDashboard() {
    if (this.type === 'STB') {
      this.$router.push({name : 'stb-dashboards'});
    } else {
      this.$router.push({name: 'mobile-dashboards'});
    }
  }

  changeDashboard(id: any) {
    this.newDashboardId = id;
  }

  save(id: any) {
    this.$emit('save', id ? id : this.newDashboardId);
    this.cancel();
  }
  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
}
