
import {Component} from 'vue-property-decorator';
import TabManager from '@/mixins/TabManager';
import stores from '@/stores';
import {hasPermission} from "@/helpers/permissions";
import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';

@Component({
  methods: {hasPermission},
  components: {
    ItemsIndexGeneric
  },
})
export default class OrdersIndex extends TabManager {

  stores = stores;

  get TAB_KEYS(): string[] {
    return ['orders', 'reservations','roomcare'];
  }
}
