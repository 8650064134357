import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import stores from '@/stores/index';
import { generateLog } from '@/helpers/functions';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/skipcleaning';

interface ISkipStatusRequest {
  status: string,
  skipCleaningId: Number | null,
}

// @ts-ignore
export class SkipCleaningRequest extends Model<ISkipCleaningRequest> implements ISkipCleaningRequest {

  id!: number | null;
  customerId!: number | null;
  skipOn!: string;
  location!: ILocation;
  status!: string;
  createdAt!: string;
  updatedAt!: string;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          id: null,
          customerId: null,
          skipOn:'',
          location:{
            id: null,
            propertyId:  null,
            name: '',
            description: '',
            pmsRoomId: '',
            staff: 0,
            active: 1,
            sort:  null,
            crmRoomId: '',
            createdAt: '',
            updatedAt: '',
            translatables: [],
            categories: [],
          },
          status:'',
          createdAt:'',
          updatedAt:''
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }

}

export default class SkipCleaningRequestCollection extends Collection<SkipCleaningRequest> {

  routeName = 'skipcleaning';

  model(): Constructor<SkipCleaningRequest> {
    return SkipCleaningRequest;
  }

  url(): string {
    return URL;
  }

  fetchData(index: number | null, search: string | null, perPage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }

    if (index === null) {
      index = 1;
    }

    if (search) {
      return axiosInstance.get(`${this.url()}?search=${search}&page=${index}&perpage=${perPage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.url()}?page=${index}&perpage=${perPage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }

  updateStatus(statusRequest: ISkipStatusRequest) {
    return axiosInstance.post(`${this.url()}/${statusRequest.skipCleaningId}`, { status: statusRequest.status })
      .then((response: any) => response.data)
      .catch(e => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        throw e;
      });
  }
}
