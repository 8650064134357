
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import Axios from 'axios';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {
    SweetModal,
  },
  mixins: [ Clickaway ],
})

export default class AttachUserTo extends Vue {
  name: string = 'nuclues-localization';
  save() {
      this.$emit('save', {name: this.name});
      this.cancel();
  }
  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
}
