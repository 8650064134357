
import { Component, Vue,Prop,Emit,Watch } from 'vue-property-decorator';
import AdminFilter from '@/modules/SuperAdminDevices/AdminFilter.vue';
import globalConfig from "@/helpers/globalConfig";
import {RolesEnum} from "@/enums/RolesEnum";

@Component({
    components:{AdminFilter}
})
export default class Filters extends Vue {
    @Prop({default:false}) filters : any;
    @Prop({default:false}) appliedFilters : any;
    enableClearFilters : boolean| null = null;
  mounted() {
    this.enableClearFilters = false;
  }

    @Emit('clearFilters')
    clearFilters(){
        this.enableClearFilters = false;
    }

    @Watch('appliedFilters')
    handleClearFilter(){
        let count = 0;
        for (const key in this.appliedFilters) {
            if (Array.isArray(this.appliedFilters[key])) {
                count = count+ this.appliedFilters[key].length;

            }
        }

        if(count > 0){
            this.enableClearFilters = true;
        }
        else{
            this.enableClearFilters = false;
        }
    }

    @Emit('updateFilters')
    updateFilters(appliedFilters: any){
        return appliedFilters;
    }

  public static generateFilterQuery(filters: any) {
    let query = '';
    for (let filterName in filters) {
      if (filters[filterName].length === 0) {
        continue;
      }
      for (let filterOption of filters[filterName]) {
        let transformFilterName = filterName.replace(' ', '').toLowerCase();
        query += `filters[${transformFilterName}][]=${filterOption}&`;
      }
    }
    return query;
  }

    public static hasFilter(filters: any, filterName: string) {
        return filters[filterName] && filters[filterName].length > 0;
      return
    }
  get filteredFilters() {
    // @ts-ignore
    const filtersCopy = { ...this.filters };
    if (!globalConfig.showOperators && filtersCopy.hasOwnProperty('operators')) {
      delete filtersCopy['operators'];
    }
    return filtersCopy;
  }

}
