
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';
import { translate } from '@/helpers/functions';
import moment from 'moment';


@Component({
  components: {
    SweetModal,
  },
})

export default class SingleMoodModal extends Vue {

  @Prop()
  mood!: any | null;

  icons: any =
    {
      1: require(`@/assets/emoticons/emoji_sad.svg`),
      2: require(`@/assets/emoticons/emoji_unhappy.svg`),
      3: require(`@/assets/emoticons/emoji_content.svg`),
      4: require(`@/assets/emoticons/emoji_happy.svg`),
    }

  cancel() {
    // @ts-ignore
    this.$refs.mood.close();
  }

  formatDate(date: any) {
    return moment(date).format("MMM Do YYYY HH:mm");
  }

  getCustomerContact() {
    const emailValues = this.mood?.customer?.contacts
      ?.filter((item: any) => (item.key === "EMAIL" || item.key === "Email") && item.value !== null)
      .map((item: any) => item.value) ?? [];

    return emailValues.join(", ");
  }

  getLocation() {
      if (this.mood.location) {
        return this.mood.location.name;
      } else {
        return '';
      }
  }

  getFirstName(customer: any)
  {
      return customer.first_name ?? customer.firstName;
  }

  getLastName(customer: any)
  {
      return customer.last_name ?? customer.lastName;
  }

}
