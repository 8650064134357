import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/orders';

export class Reservations extends Model<IOrder> implements IOrder {

  id!: number;
  orderableType!: string;
  orderableId!: number;
  guestName!: string;
  discount!: number;
  totalPrice!: number;
  customerId!: number;
  customer!: ICustomer;
  orderType!: string;
  currency!: string;
  roomId!: number;
  email!: string;
  phone!: string;
  orderAt!: string;
  status!: any;
  specialRequest!: string;
  paymentOption!: any;
  scheduleDate!: any;
  time!: any;
  urgency!: any;
  accessories!: IAccessories[];
  orderItems!: IOrderItem[];
  orderSlots!: IOrderSlot[];
  modules!: IModule[];

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          id: 0,
          orderableType: '',
          orderableId: null,
          guestName: '',
          discount: null,
          totalPrice: null,
          customerId: null,
          orderType: '',
          currency: '',
          roomId: null,
          email: '',
          phone: '',
          orderAt: '',
          status: null,
          specialRequest: '',
          paymentOption: null,
          scheduleDate: null,
          time: null,
          urgency: null,
          accessories: null,
          orderItems: null,
          orderSlot: null,
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }
}

export default class OrderCollection extends Collection<Reservations> {

  routeName = 'reservations'; // same as routers base name

  model(): Constructor<Reservations> {
    return Reservations;
  }

  url(): string {
    return URL; // nared še eno metodo za type
  }

  fetchData(index: number | null, search: string | null, perpage: number | 10) {
    if (index === null) {
      index = 1;
    }
    let listURL = `${this.url()}?page=${index}&perpage=${perpage}&type=reservations`;
    if (search) {
      listURL = listURL + `&search=${search}`;
    }
    return axiosInstance.get(listURL, {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    })
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e.response.data;
      });
  }

  deleteItem(id: any) {
    return this.deleteItems([id]);
  }

  deleteItems(ids: any[]) {
    return axiosInstance.post(`${this.url()}/delete`, {ids})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e.response.data;
      });
  }
}
