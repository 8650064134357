
  import {Component} from 'vue-property-decorator';
  import TabManager from '@/mixins/TabManager';
  import ItemsIndex from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
  import GuestFlowModuleShow from '@/modules/GuestFlowModule/ShowBasic.vue';
  import MoodSettings from './Settings.vue';
  import SingleMoodModal from '@/modules/Moods/SingleMoodModal.vue';
  import stores from "@/stores";
  
  @Component({
    computed: {
      stores() {
        return stores
      }
    },
    components: {
      ItemsIndex,
      GuestFlowModuleShow,
      MoodSettings,
      SingleMoodModal
    },
  })
  export default class MoodsIndex extends TabManager {
  
  moduleId = 5;  
  selectedItem: IItem | null = null;

    get TAB_KEYS() {
      return ['my-mood-overview', 'configurations','settings'];
    }

    openResult(item: IItem)
    {
      this.selectedItem = item;
      this.$nextTick(() => {
      // @ts-ignore
        this.$refs.mood.$children[0].open();
      });

    }
  
  }
  