import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import stores from '@/stores/index';
import {generateLog} from '@/helpers/functions';

const URL: string = '/roles';
const customURL: string = '/customroles';
const CancelToken: any = Axios.CancelToken;
let cancel: any;

export class Role extends Model<IRole> implements IRole {

  static getById(id: string): Promise<Role> {
    return axiosInstance.get(`${URL}/${id}`)
      .then((response) => {
        return new Role(response.data);
      });
  }

  id!: number | null;
  name!: string;
  guardName!: string;
  createdAt!: string;
  updatedAt!: string;
  permissions!: string[];

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          id: null,
          name: '',
          guardName: '',
          createdAt: '',
          updatedAt: '',
          permissions: [],
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }
}

export default class RoleCollection extends Collection<Role> {

  routeName = 'roles';

  model(): Constructor<Role> {
    return Role;
  }

  url(): string {
    return URL;
  }

  customUrl(): string {
    return customURL;
  }

  update(role: any) {
    delete role._original;
    return axiosInstance.patch(`${this.url()}/${role.id}`, role)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e;
      });
  }

  delete(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getAllRoles() {
    return axiosInstance.get(`${this.url()}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  create(role: []) {
    return axiosInstance.post(this.url(), role)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e;
      });
  }

  getAllCustom(index: number | null, search: string | null, perpage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }

    if (index === null) {
      index = 1;
    }

    if (search) {
      return axiosInstance.get(`${this.customUrl()}?search=${search}&page=${index}&perpage=${perpage}`, { cancelToken: new CancelToken(function executor(c: any) { cancel = c; })})
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.customUrl()}?page=${index}&perpage=${perpage}`, { cancelToken: new CancelToken(function executor(c: any) { cancel = c; })})
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }

  getRoleValidationRules() {
    return axiosInstance.get(`roles/validation`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
