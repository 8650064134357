import { render, staticRenderFns } from "./EditElement.vue?vue&type=template&id=54fb9cb0&scoped=true&v-on-clickaway=cancel&"
import script from "./EditElement.vue?vue&type=script&lang=ts&"
export * from "./EditElement.vue?vue&type=script&lang=ts&"
import style0 from "./EditElement.vue?vue&type=style&index=0&id=54fb9cb0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54fb9cb0",
  null
  
)

export default component.exports