
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
// @ts-ignore
import draggable from 'vuedraggable';
import stores from '@/stores';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';
import TvModule from '@/stores/modules/TvModule';
import vPagination from "@/components/VuePlainPagination.vue";

@Component({
  components: {vPagination, SweetModal},
  mixins: [Clickaway],
})

export default class AttachEPGToTvChannel extends Vue {
  @Prop()
  module!: TvModule;

  response: any = null;
  epgs: any[] = [];

  searchQuery: string = '';
  page: number = 1;

  @Watch('response.currentPage')
  onPageChange() {
    this.fetchData(this.response.currentPage, this.searchQuery);
  }

  mounted() {
    this.fetchData();
  }

  search() {
    this.fetchData(1, this.searchQuery);
  }

  fetchData(page: number = 1, searchQuery: string = '') {
    stores.epg.fetchAvailableEpgSources(page, searchQuery)
      .then((response: any) => {
        this.response = response.data;
        this.epgs = this.response.data;
      });
  }

  attach(channel: string) {
    // @ts-ignore
    this.$emit('attach', channel);
    this.cancel();
  }

  cancel(e: any = null) {
    if (e) {
      e.preventDefault();
    }
    // @ts-ignore
    this.$children[0].close();
  }

}
