
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import AttachImage from '@/modules/Media/Attach.vue';
import AttachDashboard from '@/modules/Dashboards/Attach.vue';
import ResetPin from '@/modules/Accounts/Profiles/ResetPin.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/NevronHeader.vue';
import axiosInstance from '../../../helpers/axios';
import session from '../../../stores/Session';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
import NevronInput from '@/components/NevronInput.vue';

@Component({
  components: {
    AttachImage,
    AttachDashboard,
    ResetPin,
    Skeleton,
    NevronHeader,
    NevronClickConfirm,
    NevronInput,
  },
})

export default class ProfileDetails extends Vue {
  item: IProfile | null = null;
  dragOver: boolean = false;
  preLeave: boolean = false;
  dashboardResponse: any = null;
  selectedDashboard: number | string | null = null;
  oldName: string = '';

  /**
   * Watches for the change in route for use with next/prev neighbor
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.$route.params.id));
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(Number(this.$route.params.id));

  }
  /**
   * Handles the image drag enter
   */
  dragEnter(num: number) {
    this.dragOver = true;
    this.preLeave = true;
    console.log('ENTER', num);
  }

  attachSelectedDashboard(dashboardId: number | string) {
    stores.profile.attachDashboard(this.$route.params.id, dashboardId)
      .then((response) => {
        this.fetchData(Number(this.$route.params.id));
      });
  }

  /**
   * Handles the image drag leave
   */
  dragLeave(num: number) {
    if (this.preLeave) {
      this.preLeave = false;
    } else {
      this.preLeave = false;
      this.dragOver = false;
    }
    console.log('LEAVE', num);
  }

  /**
   * Gets the path for image upload
   */
  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  /**
   * Gets the current projects name for use with image upload
   */
  getHeaders() {
    return stores.File.getHeader();
  }

  /**
   * Closes the drag-over box and forwards the data of the uploaded image object
   */
  transmitFinish(files: any, res: any) {

    this.preLeave = false;
    this.dragOver = false;
    // @ts-ignore
    // this.item.image.imageUrl = res.imageUrl;
    this.attachWithImage({result: res});
  }

  /**
   * Saves the data of this item
   */
  onSubmit() {
    if (this.item) {
      if (this.oldName !== Vue.prototype.translate(this.item.name)) {
        const data = {
          name: this.item.name,
          pinRequired: this.item.pinRequired,
          isAdult: this.item.isAdult,
          imageId: this.item.imageId,
          languageId: null,
        };
        return stores.accounts.updateProfile(this.item.accountId, this.item.id, data)
          .then((response) => {
            console.log(response, 'Success');
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        const data = {
          name: this.item.name,
          pinRequired: this.item.pinRequired,
          isAdult: this.item.isAdult,
          imageId: this.item.imageId,
        };

        return stores.accounts.updateProfile(this.item.accountId, this.item.id, data)
          .then((response) => {
            console.log(response, 'Success');
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }

  /**
   * Saves the new active status for this item
   */
  onSubmitStatus() {
    if (this.item) {
      return stores.accounts.updateProfile(this.item.accountId, this.item.id, {active: this.item.active})
        .then(() => {
          console.log('success');
        }).catch((e: any) => {
          console.log(e);
        });
    }
  }

  /**
   * Saves the new pin for this item
   */
  savePin(newPin: string) {
    if (this.item) {
      return stores.accounts.updateProfile(this.item.accountId, this.item.id, {pin: newPin})
        .then((response) => {
          // this.item = response;
          // @ts-ignore
          this.$refs.reset.$children[0].close();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  /**
   * Attaches an image with this item
   */
  attachWithImage(imgObj: any) {
    if (!imgObj.new) {
      // @ts-ignore
      this.item.imageId = imgObj.result.id;
      // @ts-ignore
      this.item.image = this.item.image || {};
      // @ts-ignore
      this.item.image.imageUrl = imgObj.result.imageUrl;

      // @ts-ignore
      this.$refs.image.$children[0].close();
      this.onSubmit();
    }
  }

  /**
   * Fetches data for the item with the specified ID
   */
  fetchData(id: number) {
    return stores.accounts.getAccountProfile(Number(this.$route.params.acc), id)
      .then((response) => {
        this.item = response;
        this.oldName = Vue.prototype.translate(response.name);
        if (this.item) {
          this.selectedDashboard = this.item.dashboardId;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
