
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SuperAdminHeader from "@/components/partials/super-admin-header.vue";
import stores from "@/stores";
import TimeZones from "@/modules/TimeZones.vue";
import {showToaster} from "@/components/DashboardComposer/helpers";
import SuperAdminPage from "@/components/partials/super-admin-page.vue";

@Component({
  components: {SuperAdminPage, TimeZones, SuperAdminHeader}
})
export default class AdminProfilePage extends Vue {
  adminProfile: any = null;
  currentPassword: string = '';
  isPhoneValid: boolean = true;

  async created() {
    try {
      this.adminProfile = await stores.admin.me();
    } catch (error) {
      console.error(error);
    }
  }

  timeZoneSelected(zone: string) {
    if (this.adminProfile) {
      this.adminProfile.timeZone = zone;
    }
  }

  async onSubmit(): Promise<void | {}> {
    const isFormValid = this.validateForm('updateProfileForm');
    const isConfirmationValid = !this.isInvalidConfirmation;
    const isPasswordValid = await this.validatePassword();
    const isPhoneValid = this.validatePhoneNumber(this.adminProfile.phone);

    if (!isFormValid || !isConfirmationValid || !isPasswordValid || !isPhoneValid) {
      return;
    }

    return stores.SuperAdmins.updateItem(this.adminProfile)
      .then((response: any) => {
        showToaster('success', '', 'Profile successfully updated.');

      })
      .catch((e: any) => {
        console.log({e});
      });
  }

  get isInvalidConfirmation() {
    if (this.adminProfile) {
      if (!this.adminProfile.password)
        return false;
      return this.adminProfile.password !== this.adminProfile.passwordConfirmation;
    }
  }

  async validatePassword(): Promise<boolean> {
    if (this.adminProfile) {
      try {
        const passwordCheck = {
          'password': this.currentPassword ?? false,
          'passwordConfirmation': !!this.adminProfile.password
        };
        const isValid = await stores.SuperAdmins.checkCurrentPassword(this.adminProfile.id, passwordCheck);
        if (isValid) {
          this.adminProfile.password = this.currentPassword;
        } else {
          console.log("error")
          const inputField = this.$refs['currentPassword'] as HTMLInputElement;
          inputField.classList.add('is-invalid');
          inputField.dispatchEvent(new Event('input'));
        }
        return isValid;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
    return true;
  }

  validatePhoneNumber(phone: string): boolean {
    const phonePattern = /^\+?[0-9]\d{1,20}$/;
    const isValid = phonePattern.test(phone);
    this.isPhoneValid = isValid;
    return isValid;
  }

  validateForm(formRef: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.$forceUpdate();
      this.$nextTick(() => {
        const form = this.$refs[formRef] as HTMLFormElement;
        form.classList.add('was-validated');
        resolve(form.checkValidity());
      });
    });
  }
}
