
import { Vue, Component, Prop,Emit } from 'vue-property-decorator';
import ChatMessages from '@/modules/Chat/ChatMessages.vue';
import ChatSendMessageForm from '@/modules/Chat/ChatSendMessageForm.vue';
import SimpleBar from 'simplebar';


@Component({
  components: {
      ChatMessages,
      ChatSendMessageForm,
  }
})
export default class ChatBody extends Vue {
  @Prop({default: false}) activeChat!: any;
  @Prop({default: false}) selectedContactId!: number | null;

  simplebar?: any;
  @Emit('update-user')
  updateSelectedUser(selectedUser : any){
    return selectedUser;
  }
  mounted() {
    let element = document.getElementById('simplescrollbar-chat-body');
    if(!element) return
    this.simplebar = new SimpleBar(element,{
      autoHide: false
    });
    this.scrollToBottom()

  }
  messageSent(message: any)
  {
    this.scrollToBottom()
  }
  messagesLoaded()
  {
    this.scrollToBottom()
  }
  scrollToBottom()
  {
    let contentElement = this.simplebar.getScrollElement();
    contentElement.scrollTop = contentElement.scrollHeight - 200;
  }
}
