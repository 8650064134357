
import { Component, Prop, Vue } from 'vue-property-decorator';
import stores from '@/stores';

import ItemsIndexGeneric from "@/components/ListNevronComponents/Items/IndexGeneric.vue";
import GuestFlowModuleShow from '@/modules/GuestFlowModule/ShowBasic.vue'
import { showToaster } from '@/components/DashboardComposer/helpers';
import { translate } from '@/helpers/functions';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';

@Component({
    components: {
        ItemsIndexGeneric,
        GuestFlowModuleShow,
        NevronClickConfirm,
    },
})
export default class SkipCleaningIndex extends Vue {
    activeTab = 0;
    skipCleaning = stores.SkipCleaning;
    showConfirmModal(item: any) {
        //@ts-ignore
        this.$refs.modal.openModal(item);
    }
    onSubmit(item: any) {
        stores.SkipCleaning.updateStatus({
            status: item.status,
            skipCleaningId: item.id,
        }).then((response: any) => {
            if (response.responseCode == 200) {
                showToaster('success', translate('success'), translate('successfully-updated'), 200)
            }
            else {
                showToaster('danger', '', translate('unable-to-update'), 400)
            }
        }).catch(e => {
            let errorMessage = '';
            for (let err in e.response.data.errors) {
                errorMessage = e.response.data.errors[err][0];
            }

            if (!errorMessage) {
                showToaster('danger', '', translate(errorMessage), 400)
            } else {
                showToaster('danger', '', translate('unable-to-update'), 400)
            }
        });
    }
}

