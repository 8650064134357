
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class AddTranscoderDestination extends Vue {
  @Prop()
  str!: ITranscoderStream;

  i: number = -1;
  stream: ITranscoderStream = JSON.parse(JSON.stringify(this.str));
  fin: boolean = false;
  start: boolean = false;

  @Watch('str', { immediate: true })
    setModuleFromProps() {
      this.start = true;
      this.stream = JSON.parse(JSON.stringify(this.str));

      this.stream.out.push(
        {
          video: {
            vcodec: '',
            vf: '',
            bitrate: '',
            minrate: '',
            maxrate: '',
            bufsize: '',
            keyint: '',
            keyintMin: '',
            cbr: false,
            bV: '',
          },
          audio: {
            ac: '',
            acodec: '',
            bA: '',
          },
          destination: {
            codec: '',
            destination: '',
          },
       });
      this.i = this.stream.out.length - 1;
      this.fin = true;
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  save() {
    this.$emit('save', this.stream);
    // @ts-ignore
    this.$children[0].close();
  }
}
