import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';
import globalConfig from '@/helpers/globalConfig';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/operators';

export class Operator extends Model<IOperator> implements IOperator {


  id = null;
  name= '';
  environments = {};
  country = null;
  superAdmins: ISuperAdmin[] = [];
  tenants = [];
  tenantsCount: number = this.tenants.length;
  features: ISetting[] = [];
  logo: any = '';
  logoDisplay: string = '';
  settings: string = '';

  constructor(attributes: Partial<IOperator>, parentCollection?: Collection) {
    super(attributes, parentCollection);
    Object.assign(this, attributes);
  }

  urlRoot(): string {
    return URL;
  }
}

export default class SuperAdminOperatorsCollection extends Collection<Operator> {

  routeName = 'operator';

  model(): Constructor<Operator> {
    return Operator;
  }

  url(): string {
    return `${globalConfig.url}/${globalConfig.path}/admin`;
  }

  getOperator(id: number) {
    return axiosInstance.get(`${this.url()}/operators/show/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        const log = { route: this.routeName, message: error };
        generateLog(log);
      });
  }

  fetchData(index: number | null, search: string | null, perpage: number | 10, filterQuery: string | null = null) {
    let apiUrl = `${this.url()}/operators?page=${index}&perPage=${perpage}`

    if (search) {
      apiUrl = apiUrl + '&search=' + search
    }
    if (filterQuery) {
      apiUrl = apiUrl + '&' + filterQuery
    }

    return axiosInstance.get(apiUrl, {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    })
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  fetchOperators(searchQuery: string, filterQuery: string | null) {
    let apiUrl = `${this.url()}/operators_search`;

    if (searchQuery) {
      apiUrl += `?searchOperatorName=${searchQuery}`;
      if (filterQuery) {
        apiUrl += `&${filterQuery}`;
      }
    } else if (filterQuery) {
      apiUrl += `?${filterQuery}`;
    }

    return axiosInstance.get(apiUrl, {
      cancelToken: new CancelToken((c: any) => { cancel = c; }),
    })
      .then((response) => response.data)
      .catch((error) => {
        const log = { route: this.routeName, message: error };
        generateLog(log);
      });
  }

  editOperator(operator: IOperator) {
    let formData = new FormData();

    const operatorAny: { [key: string]: any } = operator;

    // Loop through the properties of the operator object
    for (const key in operator) {
      if(key === 'logo') continue;

      if (Object.prototype.hasOwnProperty.call(operator, key)) {
        const value = operatorAny[key];

        // Check if the value is null or undefined, skip if so
        if (value === null || value === undefined) {
          continue;
        }

        // Handle special cases for objects and arrays
        if (typeof value === 'object') {
          // If it's an object, stringify it
          formData.append(key, JSON.stringify(value));
        } else {
          // Otherwise, directly append
          formData.append(key, value);
        }
      }
    }

    formData.append('logo', operator.logo);

    return axiosInstance.post(`${this.url()}/operators/edit/${operator.id}`, formData)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        throw new Error(log.message.response.data.error.message.errorId);
      });
  }

  createNewOperator(operator: IOperator) {
    let formData = new FormData();

    const operatorAny: { [key: string]: any } = operator;

    // Loop through the properties of the operator object
    for (const key in operator) {
      if(key === 'logo') continue;

      if (Object.prototype.hasOwnProperty.call(operator, key)) {
        const value = operatorAny[key];

        // Check if the value is null or undefined, skip if so
        if (value === null || value === undefined) {
          continue;
        }

        // Handle special cases for objects and arrays
        if (typeof value === 'object') {
          // If it's an object, stringify it
          formData.append(key, JSON.stringify(value));
        } else {
          // Otherwise, directly append
          formData.append(key, value);
        }
      }
    }

    formData.append('logo', operator.logo);

    return axiosInstance.post(`${this.url()}/operators/create`, formData)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        throw new Error(log.message.response.data.error.message.errorId);
      });
  }


  delete(data: number[]) {
    return axiosInstance.post(`${this.url()}/operators/delete`, {'ids': data})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
