
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';
import {showToaster} from "@/components/DashboardComposer/helpers";
import {translate} from "@/helpers/functions";

@Component({
  components: {
    SweetModal,
  },
})

export default class CreateAccount extends Vue {

  @Prop({default: false})
  emitCreated!: boolean;
  // @ts-ignore
  account: IAccount = {
    id: null,
    activationCode: '',
    active: true,
    customerId: null,
    erpId: '',
    name: '',
    welcomeScreen: true,
    customer: null,
    profiles: [],
    users: [],
    devices: [],
    deviceTypeId: 1,
    deviceType: null,
    deviceLimitStb: 1,
    deviceLimitMobile: 1,
    languages: [],
    selectProfile: false,
    switchProfile: true,
    logout: false,
    locked: false,
  };
  accId: number | null = null;
  nameRules = {};
  activationCodeRules = {};
  deviceTypes = [];
  codeTaken = false;

  mounted() {

    stores.accounts.getDeviceTypes()
      .then((response) => {
        this.deviceTypes = response;
      });

    stores.accounts.getAccountValidationRules()
      .then((response) => {
        const validationRules = response;
        if (validationRules[`name`]) {
          this.nameRules = validationRules[`name`];
        }
        if (validationRules[`activation_code`]) {
          this.activationCodeRules = validationRules[`activation_code`];
        }
      });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const form = document.getElementById('create-form-account')! as HTMLFormElement;
    form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      if (form.checkValidity() === true) {
        event.stopPropagation();
        this.save();
      }
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  save() {
    if (this.$route.params.customerId) {
      this.account.customerId = Number(this.$route.params.customerId);
    }
    return stores.accounts.createNewItem(this.account)
      .then((response) => {
        if (!response.success) {
          throw new Error(response.error.message);
        }
        showToaster('success', this.account.name, translate('successfully-created'));
        // @ts-ignore
        this.$children[0].close();
        if (this.emitCreated) {
          this.$emit('created', response.data);
          return;
        }
        this.$router.push({name: 'accounts.show', params: {id: response.data.id}});

      })
      .catch((e) => {
        showToaster('danger', '', e);
        const status = e.response.status;
        // activation code taken
        if (Number(status) === 409) {
          this.codeTaken = true;
        }
      });
  }

  /**
   * Routes the user back tho the corect details window
   */
  routeBack() {
    if (this.$route.params.devId) {
      this.$router.push({name: 'devices.show', params: {id: this.$route.params.devId}});
    } else if (this.$route.params.customerId) {
      this.$router.push({name: 'customers.show', params: {id: this.$route.params.customerId}});
    } else if (this.$route.params.userId) {
      const temp: number[] = [];
      if (this.accId) {
        temp.push(this.accId);
      }
      this.$router.push({name: 'users.show', params: {id: this.$route.params.userId}});
    } else if (this.accId) {
      this.$router.push({name: 'accounts.show', params: {id: String(this.accId)}});
    } else {
      this.$router.push({name: 'accounts'});
    }
  }
}
