
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import NevronInput from "@/components/NevronInput.vue";
import TableModalGeneric from "@/components/NevronTableModalGeneric.vue";
import TableGeneric from "@/components/NevronTableGeneric.vue";
import {SweetModal} from "sweet-modal-vue";
import {FormWizard, TabContent} from "vue-form-wizard";
import {Operator} from "@/stores/SuperAdminOperators";
import stores from "@/stores";
import Countries from "@/modules/Countries.vue";
import {showToaster} from "@/components/DashboardComposer/helpers";

@Component({
  components: {
    Countries,
    SweetModal,
    FormWizard,
    TabContent,
    NevronInput,
    TableGeneric,
    TableModalGeneric,
  }})
export default class CreateOperator extends Vue {

  wizardKey = 0;
  projectTypesList: IProjectType[] = [];
  checkedEnvironments: {} = {};
  allSettings: ISetting[] = [];
  featuresList: IFeature[] = [];
  catalogueLimit: any = null;
  isDragging: boolean = false;
  operator: IOperator = new Operator({});
  @Prop()	selectedOperator!: IOperator | null;
  @Prop({default: null}) onFormSubmit!: ((isNew: boolean, operator: IOperator) => void) | null;

  async mounted() {
    this.projectTypesList = await stores.master.getProjectTypes() || [];
    await stores.master.getFeatures();
    // this.projectTypesTranslations = await stores.master.getProjectTypesTranslations() || [];
    this.featuresList = (stores.master.features || [])
      .filter(item => item.permission !== "module-type.CATALOGUE.item");

    // Filter out beta features
    if (!stores.globalConfig.config.showBetaFeatures) {
      this.featuresList = (stores.master.features || [])
        .filter(item => item.permission !== "feature.CHAT" && item.permission !== "feature.WELCOME_SCREEN");
    }

    this.prepareSettings();
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    // @ts-ignore
    this.$refs.operatorFormWizard.reset();
    this.checkedEnvironments = {};
    this.resetForm('formStep1');
    this.resetAllFeatures();
  }

  get environments(): string[] {
    return Object.keys(this.groupedProjectTypes);
  }

  get groupedFeatures() {
    const groups: { [key: string]: IFeature[] } = {};
    this.featuresList.forEach((feature) => {
      if (!groups[feature.group]) {
        groups[feature.group] = [];
      }
      groups[feature.group].push(feature);
    });
    return groups;
  }

  get groupedProjectTypes() {
    const groups: { [key: string]: IProjectType[] } = {};
    this.projectTypesList.forEach((projectType) => {
      projectType.name = projectType.name.toLowerCase();
      projectType.group = projectType.group.toLowerCase();
      if (!groups[projectType.group]) {
        groups[projectType.group] = [];
      }
      groups[projectType.group].push(projectType);
    });
    return groups;
  }

  get showPayment() {
    return !!(this.getSettingValue('module-type.CATALOGUE.reservations') || this.getSettingValue('module-type.CATALOGUE.orders'));
  }

  resetForm(formRef: string) {
    const form = this.$refs[formRef] as HTMLFormElement;
    if(form) {
      form.reset();
      form.classList.remove('was-validated');
    }
  }

  resetAllFeatures() {
    this.allSettings = [];
    this.catalogueLimit = null;
    Object.entries(this.groupedFeatures).forEach(([groupName, group]) => {
      this.setValueToAllFeaturesInGroup(group, false);
    });
    this.operator = new Operator({});
  }

  countrySelected(country: string) {
      this.operator.country = country;
  }

  allChecked() {
    return Object.values(this.groupedFeatures).every(group => this.allCheckedInGroup(group));
  }

  @Watch('selectedOperator', {immediate: true})
  async onSelectedOperatorChange() {
    if (this.selectedOperator) {
      this.operator = new Operator(this.selectedOperator);
      this.operator.logoDisplay = this.selectedOperator.logo;
      this.checkedEnvironments = {};

      // @ts-ignore
      const environments = JSON.parse(this.selectedOperator.environments);
      // @ts-ignore
      const settings = JSON.parse(this.selectedOperator.settings);

      // @ts-ignore
      environments.forEach((env) => {
        // @ts-ignore
        this.checkedEnvironments[env] = true;
      });

      this.allSettings = [];
      // @ts-ignore
      settings.forEach((feature: { key: string, value: string | boolean }) => this.updateSettingValue(feature.key, feature.value ?? true));
    } else {
      this.operator = new Operator({});
    }
  }

  allCheckedInGroup(group: any) {
    for (const feature of group) {
      if (this.getSettingValue(feature.permission) === false || this.getSettingValue(feature.permission) === undefined) {
        return false;
      }
    }

    return true;
  }

  setValueToAllFeatures(value: boolean) {
    Object.values(this.groupedFeatures).forEach((group) => this.setValueToAllFeaturesInGroup(group, value));
  }

  setValueToAllFeaturesInGroup(group: any, value: boolean) {
    for (const feature of group) {
      this.updateSettingValue(feature.permission, value);
    }
  }

  changeFile() {
    //@ts-ignore
    this.operator.logo = this.$refs.logofile.files[0];
    this.updateImage();
  }

  getSettingValue(settingKey: string): any {
    const setting = this.allSettings.find((setting: ISetting) => setting.key == settingKey);
    return setting?.value;
  }

  updateSettingValue(settingKey: string, value: boolean | string) {
    const index = this.allSettings.findIndex((setting: ISetting) => setting.key == settingKey);
    // If setting doesn't exist, create it
    if (index === -1) {
      this.allSettings.push({key: settingKey, value: value});
      return;
    }
    // Update value of the setting
    this.allSettings[index].value = value;
  }

  prepareSettings() {
    this.updateSettingValue('module-type.CATALOGUE.orders', false);
    this.updateSettingValue('module-type.CATALOGUE.reservations', false);
    this.updateSettingValue('feature.SETTINGS.online-payments', false);

    for (const feature of this.featuresList) {
      this.allSettings.push({
        key: feature.permission,
        value: false,
      });
      if (feature.settingKey) {
        this.allSettings.push({
          key: feature.settingKey,
          value: false,
        });
      }
    }
  }

  dropzoneDragleave(event: any) {
    // Prevent default behavior (open as link for some elements)
    event.preventDefault();
    this.isDragging = false;
  }

  dropzoneDragover(event: any) {
    // Prevent default behavior (open as link for some elements)
    event.preventDefault();
    this.isDragging = true;
  }

  dropzoneDrop(event: any) {
    // Prevent default behavior (open as link for some elements)
    event.preventDefault();
    //@ts-ignore
    this.$refs.logofile.files = event.dataTransfer.files;
    //@ts-ignore
    this.operator.logo = this.$refs.logofile.files[0];
    this.updateImage();
  }

  updateImage() {
    //@ts-ignore
    this.operator.logoDisplay = URL.createObjectURL(this.$refs.logofile.files[0]);
  }

  async saveAndPublish() {
    if (!await this.validateForm('formStep1')){
      return;
    }

    //@ts-ignore
    this.operator.environments = Object.keys(this.checkedEnvironments).filter(env => this.checkedEnvironments[env]);

    this.operator.features = this.allSettings.filter(setting => setting.value);

    await this.onSubmit();
  }

  validateForm(formRef: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.$forceUpdate();
      this.$nextTick(() => {
        const form = this.$refs[formRef] as HTMLFormElement;
        form.classList.add('was-validated');
        resolve(form.checkValidity());
      });
    });
  }

  handleUploadClick(e: any) {
    e.preventDefault();
    //@ts-ignore
    this.$refs.logofile.click();
  }

  handleUploadRemoveClick(e: any) {
    e.preventDefault();
    this.$nextTick(() => {
      this.operator.logo = null;
      this.operator.logoDisplay = '';
      //@ts-ignore
      (this.$refs.logofile as HTMLInputElement).value = null;
    });
  }

  private async onSubmit(): Promise<void> {

    let promise = null;
    let successMessage = '';
    let errorMessage = '';

    if (this.selectedOperator?.id) {
      promise = stores.SuperAdminOperators.editOperator(this.operator);
      successMessage = 'Operator sucessfully updated';
      errorMessage = 'There was an error updating the operator';
    } else {
      promise = stores.SuperAdminOperators.createNewOperator(this.operator);
      successMessage = 'Operator sucessfully created';
      errorMessage = 'There was an error creating the operator';
    }

    // @ts-ignore
    return promise.then((response) => {

      if (this.onFormSubmit) {
        this.onFormSubmit(!!this.selectedOperator, response.data);
      }

      showToaster('success', 'Sucess', successMessage);
      this.$emit('created', response.data);
      this.cancel();
    }).catch((err: unknown) => {
      if (err instanceof Error) {
        if (err.message == 'operator_already_exists') {
          errorMessage = 'Operator already exists. Change operators name.';
        } else if (err.message == 'logo_required') {
          errorMessage = 'Logo is required or invalid file type.';
        }
      }
      showToaster('danger', 'Error', errorMessage);
    });
  }
}
