
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import stores from '@/stores';
import {AxiosResponse, AxiosError} from 'axios';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {
    SweetModal,
  },
})
export default class CreatePackage extends Vue {
  @Prop({default: false})
  emitCreated!: boolean;

  newPackage: IPackage = {
    id: null,
    name: '',
    comment: '',
    default: false,
    type: '',
    active: true,
    items: [],
    imageId: null,
  };
  imageUrl: string = '';
  attachableContent: any = null;

  nameRules = {
    required: true
  };

  mounted() {
    stores.Package.getPackageValidationRules()
    .then((response) => {
      const validationRules = response;
      if (validationRules[`name`]) {
        this.nameRules = validationRules[`name`];
      }
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.inserNewPackage();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
  inserNewPackage() {
    return stores.Package.createPackage(this.newPackage)
      .then((response) => {
        this.cancel();
        if (this.emitCreated) {
          this.$emit('created', response.data);
          return;
        }
        this.$router.push({name: 'packages.show', params: {id: response.data.id}});
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
