
import { Component } from 'vue-property-decorator';
import stores from '@/stores';

import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import TabManager from '@/mixins/TabManager';
import LocalizationSettings from '../Settings/LocalizationSettings.vue';

@Component({
  components: {
    ItemsIndexGeneric,
    LocalizationSettings
  },
})
export default class Localization extends TabManager {
  stores = stores;

  get TAB_KEYS() {
    return ['languages', 'translations','settings'];
  }
};
