
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import axiosInstance from '../../helpers/axios';
import session from '@/stores/Session';
import NevronHeader from '@/components/NevronHeader.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import Skeleton from '@/modules/Skeleton.vue';
import {showToaster} from "@/components/DashboardComposer/helpers";
import {translate} from "@/helpers/functions";

@Component({
  components: {
    NevronHeader,
    AttachImage,
    Skeleton,
  },
})
export default class CreateLanguage extends Vue {
  language: ILanguage = {
    name: '',
    imageId: null,
    image: null,
    shortCode: '',
    id: null,
    territoryCode: '',
    localCode: '',
    fidelioCode: '',
    encoding: '',
    font: '',
    direction: 'ltor',
    active: true,
    default: true,
    sort: 1,
    translations: [],
    neighbours: {prev: null, next: null},
    pivot: {accountId: null, isDefault: false},
  };
  nameRules = {
    required: true,
  };
  shortCodeRules = {};
  selectedFolderId = '';
  attachableContent = '';
  contentUrl = '';
  isVisible: any = false;
  isLoaded: boolean = false;
  errors: any = [];
  font = 'Ariel';
  encoding = 'UTF-8';

  getImage(id: number) {
    return stores.File.getImage(id)
      .then((response: any) => {
        this.language.image = response;
        this.language.imageId = response.id;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  activePopup() {
    // @ts-ignore
    if (this.language.active === 1) {
      this.language.active = true;
    } else {
      // @ts-ignore
      this.language.active = 1;
    }
    this.$swal('Alert!', 'This is default Language!', 'error');
  }
  clickActive() {
    this.language.active = true;
    // @ts-ignore
    this.language.sort = 1;
  }
  sortChange() {
    this.language.default = Number(this.language.sort) === 1;
  }
  updateLocalCode() {
    if (this.language.territoryCode) {
      this.language.territoryCode = this.language.territoryCode.toUpperCase();
    }
    this.language.localCode = this.language.shortCode + '_' + this.language.territoryCode;
  }
  folderRequest(res: any) {
    this.selectedFolderId = res.folderId;
    // @ts-ignore
    this.$refs.image.$children[0].close();
    // @ts-ignore
    this.$refs.create.$children[0].open();
  }

  attachWithImage(res: any) {
    this.language.imageId = res.result.id;
    this.language.image = res.result;
    // @ts-ignore
    this.$refs.image.$children[0].close();
    this.getImage(res.result.id);
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  getImages() {
    return stores.Folder.getFolder(1)
      .then((response: any) => {
        this.attachableContent = response;
        // @ts-ignore
        this.attachableContent.children = this.attachableContent.folders.concat(
          // @ts-ignore
          this.attachableContent.files,
        );
        // @ts-ignore
        this.$refs.image.$children[0].open();
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  sendReload(files: any, result: any) {
    this.getImage(result.id);
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.loadLanguage();
  }

  mounted() {
    this.loadLanguage();

    const form = document.getElementById('update-lang-form')! as HTMLFormElement;
    form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      if (form.checkValidity()) {
        event.stopPropagation();
        this.save();
      }
      if (!form.checkValidity()) {
        event.stopPropagation();
      }
      form.classList.add('was-validated');

    }, false);
  }
  keymonitor(event: { target: { id: string; }; shiftKey: any; key: any; keyCode: number; }) {
    let keyMessage = '';
    if (event.shiftKey) {
      keyMessage += 'Shift+';
    }
    keyMessage += event.key || String.fromCharCode(event.keyCode);
    if (keyMessage === '0' || keyMessage < '0') {
      this.language.sort = 1;
    }

  }

  loadLanguage() {
    if (this.$route.params.id) {
      this.isLoaded = false;
      stores.Language.show(this.$route.params.id).then((response: any) => {
        this.language = response;
        console.log(this.language);
        this.language.localCode = this.language.shortCode + '_' + this.language.territoryCode;
        this.isLoaded = true;
      });
    }
  }

  submitForm() {
    const form = document.getElementById('update-lang-form') as HTMLFormElement;
    if (form.checkValidity()) {
      this.save();
    } else {
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
    }
    form.classList.add('was-validated');
  }

  save() {

    if (!this.$route.params.id) {
      return stores.Language.createNewItem(this.language)
        .then((response: any) => {
          if (response.type && response.type === 'error') {
            this.validation(response);
          }
          console.log('You have successfully create a new Language', response);
          this.$router.push({name: 'languages.show', params: {id: response.id}});
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      return stores.Language.updateItem(this.language!.id!, this.language)
        .then((response: any) => {
          if (response.type && response.type === 'error') {
            showToaster('danger', 'Error', response.messages[0],response.code);
            return;
          }
          showToaster('success', response.data.name, translate('successfully-updated'));
          this.loadLanguage();
          console.log('You have successfully update language');
        }).catch((e: any) => {
          console.log(e);
        });
    }
    // }
  }


  validation(response: any) {
    for (const index in response.messages) {
      if (index) {
        this.errors.push(response.messages[index][0]);
      }
    }
    setTimeout(() => {
      this.errors = [];
    }, 5000);
  }
}
