
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';
import SurveyAnswers from './Answers.vue';
import stores from "@/stores";


interface Question {
  id: number;
  surveyId: number;
  text: string;
  sort: number;
  createdAt: string;
  updatedAt: string;
  answerType: string;
  entityName: string;
  predefinedAnswers: ISurveyQuestionAnswer[];
}

interface BasicAnswer {
  answer: string;
  answerCount: number;
}

interface ExtendedAnswer {
  id: number;
  surveyQuestionId: number;
  userId: number | null;
  text: string;
  createdAt: string | null;
  updatedAt: string | null;
  sort: number;
  answerCount: number;
}

type Answer = BasicAnswer | ExtendedAnswer;

@Component({
    components: {
        SweetModal,
        SurveyAnswers,
    },
})

export default class SurveyQuestion extends Vue {
    @Prop({ default: false }) question!: Question;
    @Prop({ default: false }) index!: number;
    @Prop() percentage!: Record<string, number> | null;
    @Prop({default:false})
    showAnswerResult: boolean = false;
    answers:Answer[] = [];


    icons: any =
        {
            TV: '../../../../../img/tv-b.svg',
            MOBILE: '../../../../../img/mobile-b.svg',
            EMOTICON: '../../../../../img/my_mood-b.svg',
            CLOSE_ENDED: '../../../../../img/survey-b.svg',
        }

    cancel() {
        // @ts-ignore
        this.$refs.question.close();
    }

    showAnswers(question: Question) {
        this.showAnswerResult = !this.showAnswerResult;

        if (this.showAnswerResult) {
            let request = stores.Surveys.getAnswers(question.id).then((response: Answer[]) => {
                this.answers = response
            });
        }
    }

    mounted() {
        this.answers = [];
        // this.percentage =
    }

    getPercentage(question: Question) {
        return this.percentage ? this.percentage[question.id] ?? 0 : 0;
    }

    getLinesClass(question: Question) {
        const percentage = this.getPercentage(question);

        if (percentage >= 0 && percentage <= 10) {
            return ['bg-danger', 'bg-light bg-secondary', 'bg-light bg-secondary', 'bg-light bg-secondary', 'bg-light bg-secondary', 'bg-light bg-secondary'];
        } else if (percentage >= 11 && percentage <= 20) {
            return ['bg-danger', 'bg-danger', 'bg-light bg-secondary', 'bg-light bg-secondary', 'bg-light bg-secondary', 'bg-light bg-secondary'];
        } else if (percentage >= 21 && percentage <= 50) {
            return ['bg-warning', 'bg-warning', 'bg-warning', 'bg-light bg-secondary', 'bg-light bg-secondary', 'bg-light bg-secondary'];
        } else if (percentage >= 51 && percentage <= 70) {
            return ['bg-warning', 'bg-warning', 'bg-warning','bg-warning', 'bg-light bg-secondary', 'bg-light bg-secondary'];
        } else if (percentage >= 71 && percentage <= 90) {
            return ['bg-success', 'bg-success', 'bg-success', 'bg-success', 'bg-success', 'bg-light bg-secondary'];
        } else if (percentage >= 91 && percentage <= 100) {
            return ['bg-success', 'bg-success', 'bg-success', 'bg-success', 'bg-success', 'bg-success'];
        } else {
            return [];
        }
    }

}
