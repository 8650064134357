
import {Component, Vue, Prop} from 'vue-property-decorator';
import StreamModule from '@/stores/modules/Streams';

@Component({
  components: {
  },
})
export default class CreateTranscoder extends Vue {
  @Prop()
  module!: StreamModule;

  stream: ITranscoderStream = {
    id: null,
    name: 'null',
    hardware: {
      command: 'null',
      threadsV: 'null',
      threadsA: 'null',
      filterThreads: 'null',
    },
    in: {
      re: false,
      source: 'null',
      bufferSize: null,
      fifoSize: null,
      overrunNonfatal: false,
    },
    out: [{
      video: {
        vcodec: 'null',
        vf: 'null',
        bitrate: 'null',
        minrate: 'null',
        maxrate: 'null',
        bufsize: 'null',
        keyint: 'null',
        keyintMin: 'null',
        cbr: false,
        bV: 'null',
      },
      audio: {
        ac: 'null',
        acodec: 'null',
        bA: 'null',
      },
      destination: {
        codec: 'null',
        destination: 'null',
      },
    }],
    slug: 'null',
    active: true,
    log: [],
  };
  receivedData: boolean = false;

  cancel() {
    this.$router.back();
  }

  create() {
    this.module.createStream(this.stream)
      .then((response) => {
        console.log('You have successfully created a new stream!');
        this.$router.back();
      })
      .catch((error) => {
        console.log(error);
      });
  }

}
