
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import {translate} from "@/helpers/functions";

@Component({
  components: { SweetModal },
  mixins: [Clickaway],
})
export default class EditContact extends Vue {
  @Prop() con!: any;
  contact: any = null;
  errors: { key: string | null; value: string | null } = { key: null, value: null };

  @Watch('con')
  setModuleFromProps() {
    this.contact = JSON.parse(JSON.stringify(this.con));
  }

  validateForm(): void {
    // Reset errors
    this.errors.key = this.contact.key ? null : translate('contact-type-validation');
    this.errors.value = this.contact.value ? null : translate('contact-value-validation');
    // Type-specific validation
    if (this.contact.value) {
      if (this.contact.key.toLowerCase() === 'email') {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.errors.value = emailPattern.test(this.contact.value) ? null : translate('email-validation');
      } else if (this.contact.key.toLowerCase() === 'phone') {
        const phonePattern = /^\+?[1-9]\d{1,14}$/;
        this.errors.value = phonePattern.test(this.contact.value) ? null : translate('phone-validation');
      } else if (this.contact.key.toLowerCase() === 'fax') {
        const faxPattern = /^\+?[1-9]\d{1,14}$/;
        this.errors.value = faxPattern.test(this.contact.value) ? null : translate('fax-validation');
      }
    }

    if (!this.errors.key && !this.errors.value) {
      this.save();
    }
  }

  remove() {
    if (confirm('Do you really want to remove this customer contact?')) {
      this.$emit('removeContact', this.contact);
    }
  }

  save(): void {
    this.$emit('save', this.contact);
  }

  cancel(): void {
    this.contact = JSON.parse(JSON.stringify(this.con));
    // @ts-ignore
    this.$children[0].close();
  }
}
