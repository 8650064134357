
import stores from "@/stores";
import NevronHeader from "@/components/NevronHeader.vue";
import {Component, Vue} from 'vue-property-decorator';
import NevronFooter from "@/components/ShowNevronComponents/NevronFooter.vue";
import Skeleton from "@/modules/Skeleton.vue";
import NevronIcons from "@/components/NevronIcons.vue";
import {showToaster} from "@/components/DashboardComposer/helpers";
import {translate, translatedDisplayName} from "@/helpers/functions";

@Component({
  components: {
    NevronIcons,
    Skeleton,
    NevronFooter,
    NevronHeader,
  },
})
export default class CurrencyExchangeIndex extends Vue {

  public currencies: ICurrency[] = [];
  public formCurrencyExchangeRates: ICurrencyExchangeRates | null = null;
  public initialLoadCompleted = false;

  mounted() {
    Promise.all([
      stores.CurrencyExchange.fetchData(),
      stores.CurrencyExchange.fetchAllCurrencies()
    ]).then(([currencyExchangeRates, currencies]) => {
      this.formCurrencyExchangeRates = currencyExchangeRates;
      this.currencies = currencies;
      this.initializeFormData();
    });
  }

  public filteredCurrencies(currentCurrency: ICurrency | null) {
    const usedCurrencies = this.formCurrencyExchangeRates?.exchangeRates?.filter(x => x.targetCurrency?.id !== currentCurrency?.id)
      .map(x => x.targetCurrency?.id) ?? [];

    return this.currencies.filter(x => usedCurrencies.indexOf(x.id) < 0);
  }

  public addNewExchangeRate(): void {
    this.formCurrencyExchangeRates?.exchangeRates.push(this.getEmptyExchangeRate());
  }

  public removeExchangeRate(event: Event, index: number): void {
    event.preventDefault();
    event.stopPropagation();
    this.formCurrencyExchangeRates?.exchangeRates.splice(index, 1);
  }

  public onSubmit(): void {

    const form = this.$refs.form as HTMLFormElement;
    form.classList.add('was-validated');

    if (!form.checkValidity() || !this.formCurrencyExchangeRates) {
      return;
    }

    stores.CurrencyExchange.store(this.formCurrencyExchangeRates).then((data: ICurrencyExchangeRates) => {
      showToaster('success', translate("currency-exchange-rates"), translate('successfully-updated'));
      this.formCurrencyExchangeRates = data;
    })
  }

  private initializeFormData(): void {
    if (this.formCurrencyExchangeRates?.exchangeRates.length === 0) {
      this.addNewExchangeRate();
    }

    this.initialLoadCompleted = true;
  }

  private getEmptyExchangeRate(): ICurrencyExchangeRate {
    return {
      targetCurrency: {
        id: 0,
        name: '',
        symbol: '',
        code: ''
      },
      buying: 0,
      selling: 0
    };
  }

}

