
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import PerPageDropdown from '@/components/PerPageDropdown.vue';
import vPagination from '@/components/VuePlainPagination.vue';
import CreateOperator from '@/modules/SuperAdminOperators/Create.vue';
import NevronSearch from "@/components/NevronSearch.vue";
import MultiSelect from 'vue-multiselect';
import {showToaster} from "@/components/DashboardComposer/helpers";
import Filters from '@/modules/SuperAdminDevices/Filters.vue'
import SuperAdminPage from "@/components/partials/super-admin-page.vue";
import _ from 'lodash';

@Component({
  components: {
    SuperAdminPage,
    MultiSelect,
    NevronSearch,
    PerPageDropdown,
    vPagination,
    CreateOperator,
    Filters
  },
})
export default class SuperAdminOperators extends Vue {
  stores = stores;
  operators: IOperator[] = [];
  selectedOperator: IOperator | null = null;
  selectedOperatorIds: number[] = [];
  allChecked: boolean = this.selectedOperatorIds.length === this.operators.length && this.operators.length > 0;
  search: any = {
    query: null,
    current: null,
  };

  searchOperators: any = {
    query: null,
  };

  perPage: number = 20;
  config = stores.globalConfig.config;
  firstLoad: boolean = true
  loading: boolean = false
  response: any = null
  projectTypesList: IProjectType[] = [];
  deleteButtonEnabled: boolean = this.selectedOperatorIds.length > 0;
  appliedFilters: any = {};
  filtersQuery: string | null = null;

  get filters() {
    return {
      'environments': {
        type: 'checkbox',
        values: Object.keys(this.groupedProjectTypes),
      },
      'accomodation type': {
        type: 'checkbox',
        values: this.projectTypesList.map((projectType) => projectType.name),
      },
    };
  }

  get groupedProjectTypes() {
    const groups: { [key: string]: IProjectType[] } = {};
    this.projectTypesList.forEach((projectType) => {
      projectType.name = projectType.name.toLowerCase();
      projectType.group = projectType.group.toLowerCase();
      if (!groups[projectType.group]) {
        groups[projectType.group] = [];
      }
      groups[projectType.group].push(projectType);
    });
    return groups;
  }

  async mounted() {
    this.fetchData();
    this.projectTypesList = await stores.master.getProjectTypes() || [];
  }

  fetchData(index: number = 1) {
    stores.SuperAdminOperators.fetchData(index, this.search.query, this.perPage, this.filtersQuery)
      .then((response: any) => {
        this.response = response.data
        this.operators = this.response.data;
      })
      .catch((e: any) => {
        console.error(e);
      }).finally(() => {
      this.loading = false;
    });
  }

  openOperator(operator: IOperator) {
    //@ts-ignore
    this.$router.push({name: 'SuperAdminOperatorProjects', params: { id: operator.id },});
  }

  openCreateOperatorPopup() {
    this.selectedOperator = null;
    this.$nextTick(() => {
      // @ts-ignore
      this.$refs.create.$children[0].open();
    });
  }

  onOperatorFormSubmit() {
    this.fetchData();
  }

  @Watch('response.currentPage')
  pageChange() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.loading = true;
    this.fetchData(this.response.currentPage);
  }

  checkAll() {
    if (this.allChecked && this.operators?.length > 0) {
      this.selectedOperatorIds = [];  // Clear selection
    } else {
      // @ts-ignore
      this.selectedOperatorIds = this.operators.map(operator => operator.id);
    }
  }

  // Filters
  @Watch('appliedFilters', {immediate: true, deep: true})
  generateFiltersQuery() {
    let filters = this.handleEnvironmentsFilter(this.appliedFilters);
    this.filtersQuery = Filters.generateFilterQuery(filters);
    this.fetchData();
  }

  updateFilters(appliedFilters: any) {
    for (let filter in appliedFilters) {
      this.appliedFilters = {
        ...this.appliedFilters,
        [filter]: appliedFilters[filter]
      }
    }
  }

  clearFilters() {
    this.appliedFilters = {};
    this.filtersQuery = null;
  }

  isValidFilter(filters: any): boolean {
    return !(Filters.hasFilter(filters, 'environments') && !Filters.hasFilter(filters, 'accomodation type'));
  }

  handleEnvironmentsFilter(filters: any): any {
    let allEnvironmentAccommodationTypes: string[] = [];

    if (Filters.hasFilter(filters, 'environments')){
        filters.environments.forEach((environment: string) => {
        let groupProjectTypes = this.groupedProjectTypes[environment];
        const projectAccommodationTypes: string[] = groupProjectTypes.map((projectType) => projectType.name);
        allEnvironmentAccommodationTypes = allEnvironmentAccommodationTypes.concat(projectAccommodationTypes);
      });
    }

    let newAccommodationTypes = allEnvironmentAccommodationTypes;
    if (Filters.hasFilter(filters, 'accomodation type')) {
      newAccommodationTypes = allEnvironmentAccommodationTypes.filter((accomodationType: string) => {
        return filters['accomodation type'].includes(accomodationType);
      });
    }
    return {
      ...filters,
    }
  }

  deleteOperators() {
    const confirmDelete = confirm('Are you sure you want to delete the selected operators?');
    if (!confirmDelete) {
      return;
    }
    this.executeDelete(this.selectedOperatorIds);
  }

  editOperator(operator: IOperator) {
    this.selectedOperator = {...operator};
    // @ts-ignore
    this.$refs.create.$children[0].open();
  }

  deleteOperator(operatorId: number | null) {

    if (!operatorId) {
      return;
    }

    const confirmDelete = confirm('Are you sure you want to delete the selected operator?');
    if (!confirmDelete) {
      return;
    }
    this.executeDelete([operatorId]);
  }

  private executeDelete(operatorsToDelete: number[]): void {
    stores.SuperAdminOperators.delete(operatorsToDelete)
      .then((response) => {
        if (!response.success) {
          showToaster('danger', response.error.message, '');
        } else {
          showToaster('success', 'Operators deleted successfully', '');
          this.fetchData();
        }
      });
  }

}
