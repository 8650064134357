
  import {Component, Vue, Watch} from 'vue-property-decorator';
  import stores from '@/stores';
  import axiosInstance from '../../helpers/axios';
  import session from '@/stores/Session';
  import NevronHeader from '@/components/NevronHeader.vue';
  import AttachImage from '@/modules/Media/Attach.vue';
  import CreateFolder from '@/modules/Media/CreateFolder.vue';
  import {Language} from '../../stores/Language';
  import LanguageSelector from '@/components/LanguageDropDown.vue';
  import DynamicContent from '@/modules/Translation/DynamicContent.vue';
  import AddKey from './AddKey.vue';

  @Component({
    components: {
      NevronHeader,
      AttachImage,
      CreateFolder,
      LanguageSelector,
      DynamicContent,
      AddKey,
    },
  })
  export default class ShowTranslation extends Vue {
    items: any = [];
    language: ILanguage = {
      name: '',
      image: null,
      imageId: null,
      shortCode: '',
      id: null,
      territoryCode: '',
      localCode: '',
      fidelioCode: '',
      encoding: '',
      font: '',
      direction: 'rtol',
      active: true,
      default: true,
      sort: 1,
      translations: [],
      neighbours: {prev: null, next: null},
      pivot: {accountId: null, isDefault: false},
    };
    phrase: ITranslation = {
      key: '',
      languageId: null,
      value: '',
      id: null,
      static: true,
    };
    translation: any;
    translationKey: string = '';
    index: number= 0;
    mounted() {
      this.translationKey = this.$route.params.key
      this.refresh();
    }

    refresh() {
      stores.Language.showTranslationsByKey(this.$route.params.key)
        .then((response: any) => {
          this.items = response;
          Vue.prototype.WebsiteTitle.setPageName(this.items.key);
        });
    }

    openKeyManager(language: ILanguage, phrase: ITranslation,index: number) {            
      this.language = language;
      this.phrase = phrase;
      this.translation = this.items[index]?.translation?.value;           
      
      if(this.phrase === null){
        this.phrase = {
          key: this.translationKey,
          languageId: null,
          value: '',
          id: null,
          static: true,
        }
      }

      // @ts-ignore
      this.$refs.addkey.$children[0].open();
    }

    handleKey(phrase: ITranslation) {
      phrase.key = this.$route.params.key;      
      stores.Language.createTranslation(phrase)
        .then((response: any) => {
          this.refresh();
        });
    }
  }
