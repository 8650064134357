
import { Component, Prop, Vue, Emit,Watch } from 'vue-property-decorator';
// @ts-ignore
import { Sketch } from 'vue-color';

@Component({
    components: {
        'color-picker': Sketch
    },
})
export default class NevronColorPicker extends Vue {
    @Prop({ default: '#fff' }) color!: string;
    @Prop({ default: 'primaryColor' }) colorVariable!: string;
    @Prop({ default: 'hex8' }) colorFormat!: string;
    @Prop({ default: false }) alignUp!: boolean;
    selectedColor: string = '#fff'; // Local color state
    defaultColor: string = '#fff'; // Local color state
    // Lifecycle hook to initialize local color with prop value
    mounted() {
        if(this.color)
        {
          this.selectedColor = this.color;
        }else{
          this.selectedColor = this.defaultColor;
        }
    }

    showColorPicker: boolean = false;
    @Watch('color')
    changeColor(){
        this.selectedColor = this.color;
    }
    saveColor() {
        this.showColorPicker = false;
        this.emitUpdateColorEvent();
    }

    getColorFromColorObject(colorObject: any) {
      if(!colorObject) return this.defaultColor;
      if(typeof this.selectedColor === 'string') return this.selectedColor;
      if(this.colorFormat === 'hex')
        return colorObject.hex;
      if(this.colorFormat === 'hex8')
        return colorObject.hex8;
      if (this.colorFormat === 'rgb' && colorObject.rgba) {
          const { r, g, b, a } = colorObject.rgba;
          return `rgba(${r}, ${g}, ${b}, ${a})`;
      }
    }
    @Emit('updateColor')
    emitUpdateColorEvent() {
        let rbgaColor = this.getColorFromColorObject(this.selectedColor);
        this.selectedColor = rbgaColor;
        return { color: rbgaColor, variable: this.colorVariable };
    }
    parseColor(color: string) {
      if(color === null || color === undefined) return this.defaultColor
        if (color.startsWith('#')) {
            return color;
        }
        if (color.startsWith('rgb')) {
            return color
        }

        return color
    }
    toggleShowColorPicker()
    {
        // TODO
        // Handle click outside event in future.
    }
}
