
import { Component, Vue, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class CreateFolder extends Vue {

  @Prop({default: 1})
  parentFolderId!: number;

  name: string = '';

  onSubmit() {
    const form = this.$refs.form as HTMLFormElement;
    if (!form.checkValidity()) {
      form!.classList.add('was-validated');
    } else {
      form!.classList.add('was-validated');
      return stores.Folder.createFolder(this.name, String(this.parentFolderId))
        .then((response: any) => {
          this.cancel();
          this.$emit('refresh', response.id);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }

  }

  cancel(): void {
    this.name = '';
    // @ts-ignore
    this.$children[0].close();
    (this.$refs.form as HTMLFormElement).classList.remove('was-validated');
  }
}
