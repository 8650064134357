import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import { generateLog } from '@/helpers/functions';
import Axios from 'axios';

let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL = '/interactions-moods/5';


export class Mood extends Model<IMood> implements IMood {

  id: number | null = null;
  name: string = '';
  guest: string = '';
  date: string = '';
  mood: string = '';
  response:string = '';
  message: string = '';

  constructor(attributes: Partial<IMood>, parentCollection?: Collection) {
    super(attributes, parentCollection);
    Object.assign(this, attributes);
  }

  urlRoot(): string {
    return URL;
  }
}

export default class MoodCollection extends Collection<Mood> {
  routeName = 'moods';

  url(): string {
    return URL;
  }

  model(): new (attributes: IMood, collection?: Collection) => Mood {
    return Mood;
  }

  build(attributes: IMood): Mood {
    return new Mood(attributes, this);
  }

  fetchData(index: number = 1, search: string | null = null, perpage: number = 10) {
    if (typeof cancel === 'function') {
      cancel();
    }

    const cancelToken = new CancelToken(function executor(c: any) {
      cancel = c;
    });

    const baseURL = `${this.url()}?page=${index}&perpage=${perpage}`;

    const url = search ? `${baseURL}&search=${search}` : baseURL;

    return axiosInstance.get(url, { cancelToken })
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  fetchMoodData(index: number = 1, customerID: number | null = null, perpage: number | string = 4) {
    const url = `/customer-interactions-moods-history/5/${customerID}?page=${index}&perpage=${perpage}`;
    return axiosInstance.get(url)
      .then((response: any) => {
        return response.data;
      })
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  handleButtonClick(item: object,key: object)
  {
      // logic
  }

  showIcons(item: any, icon: any): boolean {
    if(!icon || !icon.name) return false;

    if(icon.name === 'emoji_happy')
    {
     
      let moodIcon = false
      if(item.mood === 4)
      {
        moodIcon = true;
      }

      return moodIcon;
    }else if(icon.name === 'emoji_content')
    {
      let moodIcon = false
      if(item.mood === 3)
      {
        moodIcon = true;
      }

      return moodIcon;
    } else if(icon.name === 'emoji_unhappy')
    {
      let moodIcon = false
      if(item.mood === 2)
      {
        moodIcon = true;
      }

      return moodIcon;
    } else if(icon.name === 'emoji_sad')
    {
      let moodIcon = false
      if(item.mood === 1)
      {
        moodIcon = true;
      }

      return moodIcon;
    }
    else if(icon.name === 'id')
    {
      let moodIcon = false
      if(item.mood === 1)
      {
        moodIcon = true;
      }

      return moodIcon;
    }
    return false;
  }


}
