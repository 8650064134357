import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import { generateLog } from '@/helpers/functions';

const URL: string = '/analytics';

export default class DashboardAnalytics{

    routeName = 'Profile';

    url(): string {
        return URL;
    }

    fetchCountriesStats() {
        return axiosInstance.post(`${this.url()}/dashboard/analytics`)
            .then((response) => response.data).catch((e) => {
                const log: any = {
                    route: this.routeName,
                    message: e,
                };
                generateLog(log);
            });
    }

    fetchStats(types: string[],timeInterval = 'year',isCached = true) {
        let commaSeparatedTypes = types.join(', ')

        return axiosInstance.get(`${this.url()}?type=${commaSeparatedTypes}&interval=${timeInterval}&cache=${isCached}`)
            .then((response) => response.data).catch((e) => {
                const log: any = {
                    route: this.routeName,
                    message: e,
                };
                generateLog(log);
            });
    }

}
