
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';
import {showToaster} from '@/components/DashboardComposer/helpers';
import {translate} from '@/helpers/functions';

@Component({
  components: {},
})

export default class DeleteFile extends Vue {
  /**
   * Fired when this module is created
   */
  created() {
    this.delete();
  }

  delete() {
    return stores.File.deleteFiles(Number(this.$route.params.fileId))
      .then((response) => {
        if (!response.success && response.responseCode !== 200) {
          showToaster('danger', '', translate(response.error.message), response.responseCode);
        } else {
          showToaster('success', translate('successfully-deleted'), '');
          this.$router.push({name: 'media.folder', params: {folderId: '1'}});
        }
      })
      .catch((reason) => {
        console.error('Could not delete', reason);
      });
  }
}
