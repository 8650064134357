
import { Component, Vue, Watch } from 'vue-property-decorator';
import stores from '../../stores';
import Skeleton from '../Skeleton.vue';
import { showToaster } from '@/components/DashboardComposer/helpers';
import SuperAdminHeader from '@/components/partials/super-admin-header.vue'
import NevronSearch from "@/components/NevronSearch.vue";
import PerPageDropdown from "@/components/PerPageDropdown.vue";
import vPagination from "@/components/VuePlainPagination.vue";
import SuperAdminPage from "@/components/partials/super-admin-page.vue";
import _ from "lodash";
import Filters from "@/modules/SuperAdminDevices/Filters.vue";
import ProjectManagementModal from "@/modules/Projects/ProjectManagementModal.vue";
import ProjectManagement from "@/modules/Projects/ProjectManagement.vue";

@Component({
  components: {
    ProjectManagement,
    ProjectManagementModal,
    Filters,
    SuperAdminPage, vPagination, PerPageDropdown, NevronSearch, Skeleton, SuperAdminHeader},
})

export default class ProjectsItem extends Vue {
  config = stores.globalConfig.config;
  master = stores.master;

  response: any = null
  projectTypesList: IProjectType[] = [];
  loading = false;

  search = {
    query: '',
    current: '',
  };
  searchItems = _.debounce(() => {
    this.fetchData(1);
  }, 400);

  appliedFilters: any = {};
  filtersQuery: string | null = null;


  perpage = 20;
  firstLoad = true;
  showProjectManagemenet = false;

  selectedProjects: IManagerProject[] = [];

  projectId: string | null = null;
  operator: IOperator | null = null;
  operatorSettings: {key: string, value: boolean}[] = [];

  // Computed
  get allChecked() {
    return this.selectedProjects.length === this.master.projects?.length;
  }

  get pageReady() {
    return this.response && this.response.data;
  }

  get groupedProjectTypes() {
    const groups: { [key: string]: IProjectType[] } = {};
    this.projectTypesList.forEach((projectType) => {
      projectType.name = projectType.name.toLowerCase();
      projectType.group = projectType.group.toLowerCase();
      if (!groups[projectType.group]) {
        groups[projectType.group] = [];
      }
      groups[projectType.group].push(projectType);
    });
    return groups;
  }

  get filters() {
    //return array of filters, each filter has type and values
    return {
      'environments': {
        type: 'checkbox',
        values: Object.keys(this.groupedProjectTypes),
      },
      'accomodation type': {
        type: 'checkbox',
        values: this.projectTypesList.map((projectType) => projectType.name),
      }
    }
  }

  get allowedFeatures() {
    return this.operatorSettings.map((setting) => setting.key);
  }

  backClick() {
    this.$router.push({ name: 'SuperAdminOperators' });
  }

  // Watchers
  @Watch('response.currentPage')
  changedPage() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.search.query = this.search.current;
    this.fetchData(this.response.currentPage);
  }

  // Methods
  async mounted() {
    this.fetchData(1);
    this.projectTypesList = await stores.master.getProjectTypes() || [];
  }

  fetchData(page: number = 1) {
    this.loading = true;
    // @ts-ignore
    stores.SuperAdminOperators.getOperator(this.$route.params.id)
      .then((response) => {
        this.operator = response.data;
        this.operatorSettings = this.operator ? JSON.parse(this.operator.settings) : [];
      })
      .then((response) => {
        this.master.fetchData(page, this.search.query, this.perpage, this.filtersQuery, this.operator?.name)
          .then((response) => {
            this.response = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }


  openProject(project: IManagerProject) {
    document.location.href = `/${project.id}`;
  }

  deleteProject(project: IManagerProject) {
    if (!project.isModified) {
      showToaster('danger', ' ', 'you can not remove this tenant', 500);
    } else {
      if (confirm(`Permanently delete project ${project.name}?`)) {
        this.master.projects = null;
        this.master.deleteProject(project.id)
          .then((response) => {
            this.fetchData(1);
          });
      }
      return;
    }
  }

  deleteProjects(list: any) {
    const confirmDelete = confirm('Are you sure you want to delete the selected projects?');

    if (confirmDelete) {
      for (let index in list) {
        const project = list[index];

        stores.master.deleteProject(project.id)
          .then((response) => {
            if (!response.success) {
              showToaster('danger', response.error.message, '');
            } else {
              showToaster('success', 'Projects deleted successfully', '');
              this.selectedProjects = [];
              this.fetchData();
            }
          });
      }

    } else {
      return;
    }
  }

  toggleActive(project: IManagerProject) {
    console.log(project.active);
    this.master.updateProject(project.id, project)
      .then((response) => {
        showToaster('success', ' ', 'Project updated', 200);
      })
      .catch((error) => {
        showToaster('danger', ' ', 'Error updating project', 500);
      });

  }

  openProjectModal(projectId: string | null = null) {
    this.projectId = projectId;
    this.$nextTick(() => {
      // @ts-ignore
      this.$refs.projectManagement.$children[0].open();
    });
  }

  editProject(project: IManagerProject) {
    if (!project.isModified) {
      showToaster('danger', ' ', 'you can not edit this tenant', 500);
    } else {
      this.openProjectModal(project.id);
    }
  }

  copyProject(project: IManagerProject) {
    this.$router.push({
      name: 'projects.copy',
      params: { id: project.id },
    });
  }


  checkAll() {
    if (this.allChecked) {
      this.selectedProjects = [];
    } else {
      this.selectedProjects = this.master.projects || [];
    }
  }

  defaultLogo(event?: any) {
    if (!event) {
      return require('../../../assets/nevron_logo.png');
    }
    event.target.src = require('../../../assets/nevron_logo.png');
  }

  // Filters
  @Watch('appliedFilters', { immediate: true, deep: true })
  generateFiltersQuery() {
    let filters = this.handleEnvironmentsFilter(this.appliedFilters);
    if (!this.isValidFilter(filters)) {
      this.response['data'] = [];
      this.response['total'] = 0;
      return;
    }
    this.filtersQuery = Filters.generateFilterQuery(filters);
    this.fetchData(1);
  }
  updateFilters(appliedFilters: any) {
    for (let filter in appliedFilters) {
      this.appliedFilters = {
        ...this.appliedFilters,
        [filter]: appliedFilters[filter]
      }
    }
  }
  clearFilters() {
    this.appliedFilters = {};
    this.filtersQuery = null;
  }
  isValidFilter(filters:any): boolean {
    if (Filters.hasFilter(filters, 'environments') && !Filters.hasFilter(filters, 'accomodation type')) {
      return false;
    }
    return true;
  }
  handleEnvironmentsFilter(filters: any): any {
    if (!Filters.hasFilter(filters, 'environments')) {
      return filters;
    }
    let allEnvironmentAccommodationTypes: string[] = [];
    filters.environments.forEach((environment: string) => {
      let groupProjectTypes = this.groupedProjectTypes[environment];
      const projectAccommodationTypes: string[] = groupProjectTypes.map((projectType) => projectType.name);
      allEnvironmentAccommodationTypes = allEnvironmentAccommodationTypes.concat(projectAccommodationTypes);
    });

    let newAccommodationTypes = allEnvironmentAccommodationTypes;
    if (Filters.hasFilter(filters, 'accomodation type')) {
      newAccommodationTypes = allEnvironmentAccommodationTypes.filter((accomodationType: string) => {
        return filters['accomodation type'].includes(accomodationType);
      });
    }
    return {
      ...filters,
      'accomodation type':  _.uniq(newAccommodationTypes),
    }
  }
}
