import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';
import Axios from "axios";

let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL = '/messages';

export interface IDeliveryChannel {
  key: string;
  name: string;
  group: string;
}

export class Message extends Model<IMessage> implements IMessage {
  id: number | null = null;
  name: string = '';
  status: MessageStatus = 'DRAFT';
  title: string = '';
  text: string = '';
  customers: ICustomer[] = [];
  deviceGroups: IDeviceGroup[] = [];
  deliveryChannels: string[] = [];
  messageContents: IMessageContent[] = [];
  isUrgent: boolean = false;
  active: boolean = true;

  constructor(attributes: Partial<IMessage>, parentCollection?: Collection) {
    super(attributes, parentCollection);
    Object.assign(this, attributes);
  }

  urlRoot(): string {
    return URL;
  }
}

export default class MessageCollection extends Collection<Message> {

  routeName = 'messaging';

  url(): string {
    return URL;
  }

  model(): new (attributes: IMessage, collection?: Collection) => Message {
    return Message;
  }

  build(attributes: IMessage): Message {
    return new Message(attributes, this);
  }

  deleteItem(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data);
  }

  deleteItems(ids: any[]) {
    return axiosInstance.post(`${this.url()}/delete`, {ids})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  fetchData(index: number = 1, search: string | null = null, perpage: number = 10) {
    if (typeof cancel === 'function') {
      cancel();
    }

    const cancelToken = new CancelToken(function executor(c: any) {
      cancel = c;
    });

    const baseURL = `${this.url()}?page=${index}&perpage=${perpage}`;
    const url = search ? `${baseURL}&search=${search}` : baseURL;

    return axiosInstance.get(url, {cancelToken})
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  createNewItem(message: IMessage) {
    return axiosInstance.post(`${this.url()}`, message)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(id: string | number, message: IMessage) {
    return axiosInstance.put(`${this.url()}/${message.id}`, message)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getDeliveryChannels(): Promise<IDeliveryChannel[]> {
    return axiosInstance.get(`${this.url()}/delivery-channels`)
      .then((response) => response.data.data)
      .catch((e) => {
        alert(e);
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }
}
