
import { Component, Vue, Prop,Emit,Watch } from 'vue-property-decorator';
import NevronSearch from '@/components/NevronSearch.vue';
import MultiSelect from 'vue-multiselect';

@Component({
  components: { NevronSearch, MultiSelect }
})
export default class FilterSelect extends Vue {
  @Prop({default:false}) filterOption!: any;
  @Prop({default:false}) filterTitle!: string;
  inputSearch: string = '';
  selectedItems: any = [];
  @Prop({default:false}) appliedFilters!: any;
  @Prop({default:false}) isClearFilters: boolean |any;
  search: any = {
    query: '',
    current: '',
  };
  foundItems: any = [];

  mounted() {
    this.getItems();
  }

  inputNameForFilter(filtername : string){
    return 'filter["'+this.filterTitle.replace(/ /g, "_").toLowerCase()+'"]["'+filtername+'"]'
  }

  getItems() {
    this.filterOption.fetchData(1, '', 100)
      .then((response: any) => {
        this.foundItems = response.data.data;
      })
      .catch((e: any) => {
        console.error(e);
      }).finally(() => {
    });
    this.search.current = this.search.query;

    return this.foundItems;
  }

  inputIdForFilter(){
    const id =  `filter-${this.filterTitle}-${this.filterTitle}`;
    return id.replace(/ /g, '-').replace(/[^a-zA-Z0-9-]/g, '');
  }

  @Watch('selectedItems')
  triggerUpdate(){
    this.updateFilterSelect();
  }

  @Emit('updateFilters')
  updateFilterSelect(){
    const mappedFilters = this.selectedItems.map((item: any) => item.name);

    return {
      isActive: this.selectedItems.length !== 0,
      type: 'select',
      filterOption: mappedFilters
    };
  }

  @Watch('isClearFilters')
  clearFilters(){
    if(this.isClearFilters == false) {
      this.selectedItems = [];
    }
  }

  get formattedfilterOption(){
    return this.filterOption.replace(/_/g, ' ').toLowerCase();
  }

}
