import stores from '@/stores';
import {Vue} from 'vue-property-decorator';

export class WebsiteTitle {
    moduleName = '';
    pageName = '';

    setModuleName(moduleName: string) {
        this.moduleName = Vue.prototype.translate(moduleName);
        this.pageName = '';
        this.updateWebsiteTitle();
    }

    setTranslatedModuleName(moduleName: string) {
        this.moduleName = moduleName;
        this.pageName = '';
        this.updateWebsiteTitle();
    }

    setPageName(pageName: string) {
        this.pageName = Vue.prototype.translate(pageName);
        this.updateWebsiteTitle();
    }

    setTranslatedPageName(pageName: string) {
        this.pageName = pageName;
        this.updateWebsiteTitle();
    }

    updateWebsiteTitle() {
        if (stores.session.project?.name) {
            const tenantString = `${stores.session.project.name} ${Vue.prototype.translate('manager')}`;
            const moduleString = this.moduleName ? ` | ${this.moduleName}` : '';
            let pageString = '';
            if (this.pageName) {
                if (this.moduleName) {
                    pageString = ` ${this.pageName}`;
                } else {
                    pageString = ` | ${this.pageName}`;
                }
            }
            window.document.title = `${tenantString}${moduleString}${pageString}`;

            return;
        }

        window.document.title = "Nevron";
    }
}

export default new WebsiteTitle();
