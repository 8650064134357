
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SuperAdminHeader from "@/components/partials/super-admin-header.vue";

@Component({
  components: {SuperAdminHeader}
})
export default class SuperAdminPage extends Vue {
}
