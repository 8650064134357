
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';
import stores from '@/stores';

@Component({
    components: {
        SweetModal,
    },
})

export default class SurveyAnswers extends Vue {


    @Prop({ default: false }) type!: any | null;
    @Prop({ default: false }) show!: boolean | null;
    // @Prop({default: false})questionId!: number | null;

    @Prop({ default: false }) answers: any | null;


    icons: any =
        {
            TV: '../../../../../img/tv-b.svg',
            MOBILE: '../../../../../img/mobile-b.svg',
            EMOTICON: '../../../../../img/my_mood-b.svg',
            CLOSE_ENDED: '../../../../../img/survey-b.svg',
        }

    emoticons: any = [
        '@/assets/emoticons/emoji_sad.svg',
        '@/assets/emoticons/emoji_unhappy.svg',
        '@/assets/emoticons/emoji_content.svg',
        '@/assets/emoticons/emoji_happy.svg'
    ]

    cancel() {
        // @ts-ignore
        this.$refs.question.close();
    }

    imageSource(answer: any) {
        return this.emoticons[answer.answer - 1];
    }
}
