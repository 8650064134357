
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import {translate} from '@/helpers/functions';

@Component({
  components: {
    SweetModal,
  },
})
export default class AddUserStay extends Vue {
  @Prop({default: null})
  userId!: number;

  reservationId: string = '';

  mounted() {
    const form = document.getElementById('create-stay-form')! as HTMLFormElement;
    // Loop over them and prevent submission
    form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      this.onSubmit();
      form.classList.add('was-validated');
    }, false);
  }

  onSubmit(): Promise<void | {}> {
    return stores.CombinedUsers.addStay(this.userId, this.reservationId)
      .then((response: any) => {
        if (!response.success) {
          showToaster('danger', '', translate(response.error.message));
        } else {
          showToaster('success', translate('stay-attached-successfully'), '');
          this.cancel();
          this.$emit('save', response.data);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
}
