
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {SweetModal} from 'sweet-modal-vue';
import NevronInput from '@/components/NevronInput.vue';
import { bus } from '@/helpers/functions';

@Component({
  components: {
    SweetModal,
    NevronInput,
  },
})
export default class EditElement extends Vue {
  @Prop()
  element: any | null;
  @Prop()
  elementIndex: any | null;
  @Prop()
  sliders: any | null;

  localElement = null;
  localSlider = null;
  dragData: any = {};

  @Watch('element')
  setInitValues() {
    this.localElement = this.element;
    this.localSlider = this.sliders;
    console.log('localPanel', this.localSlider);
    console.log('element', this.localElement);
  }

  mounted() {
    console.log('localPanel', this.localSlider);
    console.log('element', this.localElement);
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  openTranslator(localText: any, localKey: any, referenceKey: any) {
    bus().$emit('openTranslator', localText, localKey, referenceKey);
  }

  next() {
    const output = {
      element: this.localElement,
      elementIndex: this.elementIndex,
      // @ts-ignore
      sliderShowName: this.localSlider.showName,
    };
    console.log('element', output);
    this.$emit('next', output);
    // this.clearData();
    // @ts-ignore
    this.$children[0].close();
  }
}
