import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';
import {showToaster} from "@/components/DashboardComposer/helpers";
import Vue from 'vue';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/epgs';

export class EPGs extends Model<IEpg> implements IEpg {

  id!: number;
  name!: string;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {...{id: null, name: ''}, ...attributes}, // Default values
      parentCollection,
    );
  }
  urlRoot(): string {
    return URL;
  }
}

export default class EPGCollection extends Collection<EPGs> {

  routeName = 'EPG';

  providerTypes : IEpgProviderType[] = [
    {
      name : 'default-epg-provider',
      value : 1000,
    },
    {
      name : 'dialog-epg-provider',
      value : 2000,
    },
    {
      name : 'telemach-epg-provider',
      value : 3000,
    },
  ];

  model(): Constructor<EPGs> {
    return EPGs;
  }

  url(): string {
    return URL;
  }

  fetchAvailableEpgSources(page: number = 1, search: string | null = null) {

    if (cancel !== undefined) {
      cancel();
    }
    let url = this.url() + '?page=' + page;
    if (search) {
      url += '&search=' + encodeURIComponent(search);
    }

    return axiosInstance.get(url, { cancelToken: new CancelToken(function executor(c: any) { cancel = c; })})
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e.message || 'An error occurred',
        };
        generateLog(log);
      });
  }


  getEpgSources() {
    return axiosInstance.get(this.url() + '/sources', { cancelToken: new CancelToken(function executor(c: any) { cancel = c; })})
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createEpgSource(source: IEpgProvider) {
    return axiosInstance.post(`${this.url()}/sources/create`, { url: source.url, active: source.active,type : source.type })
    .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateEpgSource(source: IEpgProvider) {
    return axiosInstance.post(`${this.url()}/sources/update`, { id: source.id, url: source.url, active: source.active ,type : source.type})
    .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteEpgSource(source: IEpgProvider) {
    return axiosInstance.delete(`${this.url()}/sources/delete/${source.id}`)
    .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  // attachEPG(name: string) {
  //   return axiosInstance.post(`${this.url()}/attach`, name)
  //     .then((response) => response.data);
  // }
  importEpg(data: any, tableName: string) {
    return axiosInstance.post(`import/${tableName}`, {data})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  exportEpg(tableName: string) {
    return axiosInstance.post(`export/${tableName}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  /**
   * Triggers the update of the EPGs for current tenant
   */
  updateTenantEpgs(): Promise<any> {
    return axiosInstance.post(`${this.url()}/update`)
      .then((response) => {
        showToaster('success', Vue.prototype.translate('epgs-updating'),'');
        return response.data;
      })
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: 'Failed to update EPGs: ' + e.message,
        };
        generateLog(log);
        throw e;
      })
      .finally(() => {
      });
  }

}
