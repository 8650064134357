
import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import {deletePath} from '../../../../helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import session from '../../../../stores/Session';
import axiosInstance from '../../../../helpers/axios';
import NevronInput from '@/components/NevronInput.vue';
import CreateAccount from '@/modules/Accounts/Create.vue';
import AttachStays from '@/modules/Customers/AttachStays.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import MultiSelect from 'vue-multiselect';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';

@Component({
  components: {
    EditContact,
    virtualList,
    EditCustomer,
    PreEdit,
    // AccountAttach,
    CustomerAddressEdit,
    AttachImage,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,
    NevronFooter,
    MultiSelect,
    NevronAdvanceTextBox,
  },
  mixins: [Clickaway],
})

export default class CategoryDetails extends Vue {
  category: any = null;
  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;

  tempAcc!: IAccount;

  accountItems: IAccount[] = [];

  response: any = null;
  frontUrl: any  = null;
  backUrl: any  = null;
  search: any = {
    query: '',
  };

  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;

  indexAccountList: number[] = [];
  allAccountsChecked: boolean = false;
  searchInProgress: boolean = false;
  dragOver: boolean = false;
  preLeave: boolean = false;
  attachableContent: any = null;

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.$route.params.id));
  }
  dragEnter(num: number) {
    this.dragOver = true;
    this.preLeave = true;
    console.log('ENTER', num);
  }

  getAccountsOnPage(index: number) {
    return stores.accounts.fetchData(index, null, 10)
      .then((response) => {
        this.fullResponse = response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  dragLeave(num: number) {
    if (this.preLeave) {
      this.preLeave = false;
    } else {
      this.preLeave = false;
      this.dragOver = false;
    }
    console.log('LEAVE', num);
  }
  sortArrays(arrays: any) {
    return _.orderBy(arrays, 'sort', 'asc');
  }
  /**
   *
   */
  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  /**
   *
   */
  transmitFinish(files: any, res: any) {
    this.preLeave = false;
    this.dragOver = false;
    this.attachWithImage({result: res});
  }

  /*
   *
   */
  onSubmitStatus() {
    const form = this.$refs.form as HTMLFormElement;
    form.classList.add('was-validated');

    if (!form.checkValidity()) {
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
      return;
    }

    // @ts-ignore
    return stores.Rooms.updateCategoryDetailPage(this.category.id, this.category)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.fetchData(Number(this.$route.params.id));
          showToaster('success', Vue.prototype.translate(this.category.name), 'successfully updated');
        } else {
          showToaster('danger',  Vue.prototype.translate(this.category.name), 'Fail to update', response.code);
        }
      }).catch((e: any) => {
        console.log(e);
        showToaster('danger',  Vue.prototype.translate(this.category.name), 'Fail to update');
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(Number(this.$route.params.id));
  }
  deleteZoneCategory() {
    return stores.Rooms.deleteZoneCategory(this.category.id)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
  loadPage() {
    this.fetchData(Number(this.$route.params.id));
  }

  /**
   *
   */
  popoverMethod() {
    if (this.category && this.category.id) {
      return deletePath(this.category.id);
    }
  }

  fetchData(id: number) {
    return stores.Rooms.getCategoryDetail(id)
      .then((response: any) => {
        this.category = response;
        console.log('this.category', this.category);
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  /**
   *
   */
  attachWithImage(imgObj: any) {
    const data = {
      imageId: null,
    };
    if (imgObj) {
      this.category.imageId = imgObj.result.id;
      this.category.image = this.category.image || {};
      this.category.image.imageUrl = imgObj.result.imageUrl;
      // @ts-ignore
      this.$refs.image.$children[0].close();
      data.imageId = imgObj.result.id;
    } else {
      this.category.imageId = null;
      this.category.image = null;
    }
    // @ts-ignore
    return stores.Location.update(this.category.id, data)
      .then((response: any) => {
        console.log('Success');
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  updateCheck() {
    if (this.indexAccountList.length === this.category.accounts.length) {
      this.allAccountsChecked = true;
    } else {
      this.allAccountsChecked = false;
    }
  }

}
