
import { Component } from 'vue-property-decorator';
import ItemsIndex from "@/components/ListNevronComponents/Items/IndexGeneric.vue";
import TabManager from "@/mixins/TabManager";
import stores from "@/stores";

@Component({
  computed: {
    stores() {
      return stores
    }
  },
  components: {
    ItemsIndex,
  },
})
export default class WelcomeScreenIndex extends TabManager {
  get TAB_KEYS() {
    return ['welcome-screen-list'];
  }
}
