import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';
import Axios from 'axios';


const URL: string = '/welcomeScreen';
let cancel: any;
const CancelToken: any = Axios.CancelToken;

export class WelcomeScreen extends Model<IWelcomeScreen> implements IWelcomeScreen {

  id!: number | null;
  name!: string;
  status!: WelcomeScreenStatus;
  displayOnDevices!: boolean;
  enableLanguageSelector!: boolean;
  showBeforeWelcomeScreen!: boolean;
  active!: boolean;
  turnOnTvScreen!: boolean;
  timeIntervals!: ITimeInterval[];
  createdAt!: string | '';
  updatedAt!: string | '';

  constructor(attributes: any = {}, parentCollection?: any) {
    super({
        ...{
          id: null,
          title: '',
          status: 'DRAFT',
          active: true,
          createdAt: '',
          updatedAt: ''
        }, ...attributes,
      }, // Default values
      parentCollection);
  }

  urlRoot(): string {
    return URL;
  }
}

export default class WelcomeScreenCollection extends Collection<WelcomeScreen> {

  routeName = 'welcomeScreen';

  model(): Constructor<WelcomeScreen> {
    return WelcomeScreen;
  }

  url(): string {
    return URL;
  }

  build(attributes: IWelcomeScreen): WelcomeScreen {
    return new WelcomeScreen(attributes, this);
  }

  fetchData(index: number = 1, search: string | null = null, perpage: number = 10) {
    if (typeof cancel === 'function') {
      cancel();
    }

    const cancelToken = new CancelToken(function executor(c: any) {
      cancel = c;
    });

    const baseURL = `${this.url()}?page=${index}&perpage=${perpage}`;
    const url = search ? `${baseURL}&search=${search}` : baseURL;

    return axiosInstance.get(url, { cancelToken })
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  createNewItem(welcomeScreen: IWelcomeScreen) {
    return axiosInstance.post(`${this.url()}`, welcomeScreen)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(id: number, welcomeScreen: IWelcomeScreen) {
    return axiosInstance.put(`${this.url()}/${id}`, welcomeScreen)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteItem(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data);
  }

  deleteItems(ids: number[]) {
    return axiosInstance.post(`${this.url()}/delete`, { ids })
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
