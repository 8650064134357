
import { Component, Prop, Vue } from 'vue-property-decorator';
import stores from '../../stores';
import axiosInstance from '../../helpers/axios';
import { Session } from '@/stores/Session';
import { showToaster } from '@/components/DashboardComposer/helpers';

@Component
export default class AdminRestPassword extends Vue {
  token: string = '';
  password: string = '';
  passwordConfirmation: string = '';
  showPassword: boolean = false;
  showPasswordConfirmation: boolean = false;
  error: boolean = false;
  errorMessage: string = '';
  admin = stores.admin;
  image = require('@/assets/images/profile-img.png');
  step: number = 1;

  mounted() {
    this.token = this.$route.params.token
  }
  onSubmit(){
    if (this.password !== this.passwordConfirmation) {
      showToaster("warning", "", "The password and confirm password fields donot match")
    }
    const response = stores.SuperAdmins.resetPassword({
      token: this.token,
      password: this.password,
      password_confirmation: this.passwordConfirmation,
    })
    response.then((response: any) => {
      if (response && response.responseCode === 200) {
        this.error = false;
        this.errorMessage = '';
        showToaster("success", "", response.data)
        return this.$router.push('/admin/login');
      }
      showToaster("danger", "", "Something went wrong!")
    }).catch(e => {
      const response = e.response.data;
      if (response && response.errors) {
        this.error = true;
        if (response.errors.password) {
          this.errorMessage = response.errors.password[0];
        }
        showToaster("warning", "", "Invalid Request")
        return;
      }
      if (response && response.data) {
        showToaster("danger", "", response.data)
        return;
      }
      showToaster("danger", "", "Something went wrong!")
    })
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword
  }
  togglePasswordConfirmationVisibility() {
    this.showPasswordConfirmation = !this.showPasswordConfirmation
  }
  onImageLoadFailure(event: any) {
    event.target.src = require('../../../assets/nevron_logo.png');
  }
  get loginLink() {
    return '/admin/login'
  }
}
