import Axios, {CancelToken} from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import stores from '@/stores/index';
import {generateLog} from '@/helpers/functions';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/customers';

export class ChatContact extends Model<IChatContact> implements IChatContact {

  id!: number | null;
  firstName!: string;
  lastName!: string;
  isStaff!: boolean;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          firstName: '',
          id: -1,
          lastName: '',
          isStaff: false,
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }
}

export default class ChatContactCollection extends Collection<ChatContact> {

  routeName = 'customers';

  model(): Constructor<ChatContact> {
    return ChatContact;
  }

  url(): string {
    return URL;
  }

  /*
   * Get list
   */
  fetchData(index: number | null, search: string | null, perPage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }

    if (index === null) {
      index = 1;
    }

    let url = this.url() + '?page=' + index + '&perpage=' + perPage;
    if (search) {
      url = url + '&search=' + search;
    }

    return axiosInstance.get(url, {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    })
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }


  getAllChatContacts(isStaffList: boolean | false) {
    let url = this.url()+"/all";
    if(isStaffList){
      url = url + "?isStaffList=1";
    }
    return axiosInstance.get(`${url}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }


  getContactChatHistory(customerId: number): Promise<any> {
    return axiosInstance.get(`${this.url()}/history/${customerId}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.log(error);
        throw error;
      });
  }

  searchByFullName(searchQuery: string) {
    const encodedSearchQuery = encodeURIComponent(searchQuery);
    const url = `${this.url()}/name?search=${encodedSearchQuery}`;
    return axiosInstance.get(url, {
      cancelToken: new CancelToken(function executor(c: CancelToken) {
        cancel = c;
      }),
    })
      .then(response => response.data)
      .catch(error => {
        const log = {
          route: 'searchByName',
          message: error.toString(),
        };
        generateLog(log);
      });
  }
}
