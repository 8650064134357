
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';


@Component({
  components: {
    VueApexCharts
  }
})
export default class RoomCareStatistics extends Vue {
  @Prop({ default: true }) title !: string;

  @Prop({ default: true }) portalVisitorStatisticsData !: any;
  @Prop({ default: true })  roomCareContent !: any;
  @Prop({ default: true }) chartKey !: any;


  selectedMonth: string = 'January';
  months: any = [
        { value: 'january' },
        { value: 'february' },
        { value: 'march' },
        { value: 'april' },
        { value: 'may' },
        { value: 'june' },
        { value: 'july' },
        { value: 'august' },
        { value: 'september' },
        { value: 'october' },
        { value: 'november' },
        { value: 'december' }
      ]

  mounted() {
    this.selectedMonth = 'january';
  }

  @Watch('selectedMonth')
  onSelectedMonthChanged(newValue: string, oldValue: string) {
    this.$emit('handleRoomCareSelect', newValue);
  }
}
