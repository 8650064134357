
import {Component, Vue, Watch} from 'vue-property-decorator';
import DeviceTypesEdit from '@/modules/Devices/Types/Edit.vue';
import stores from '@/stores';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronInput from '../../../components/NevronInput.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    DeviceTypesEdit,
    Skeleton,
    NevronHeader,
    NevronFooter,
    NevronInput,
  },
})

export default class DeviceTypeDetails extends Vue {
  deviceType: IDeviceType | null = null;
  showConfirm: boolean = false;

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.reload(this.$route.params.id);
  }

  onSubmitStatus() {
    if (this.deviceType) {
      return stores.Devices.updateDeviceType(this.deviceType.id, {active: this.deviceType.active})
        .then(() => {
          console.log('success');
        }).catch((e: any) => {
          console.log(e);
        });
    }
  }

  save() {
    if (this.deviceType) {
      const data = {
        name: this.deviceType.name,
        default: this.deviceType.default,
      };
      return stores.Devices.updateDeviceType(this.deviceType.id, data)
        .then(async (response) => {
          if (response.responseCode === 200) {
            await this.reload(this.$route.params.id);
            // @ts-ignore
            showToaster('success', Vue.prototype.translate(this.deviceType.name), 'successfully updated');
          } else {
            // @ts-ignore
            showToaster('danger',  Vue.prototype.translate(this.deviceType.name), 'Fail to update');
          }
        }).catch((e: any) => {
          console.log(e);
          // @ts-ignore
          showToaster('danger',  Vue.prototype.translate(this.deviceType.name), 'Fail to update');
        });
    }
  }

  saveEdit(type: IDeviceType) {
    return stores.Devices.putDeviceType(type)
      .then((response: any) => {
        console.log('You have successfully edited a device type!');
        this.deviceType = response;
        // @ts-ignore
        this.$refs.edit.$children[0].close();
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  removeType() {
      return stores.Devices.deleteDeviceType(this.deviceType?.id!)
        .then((response) => {
          console.log('You have successfully deleted this device type!'),
            this.$router.push({name: 'devices.types'});
        })
        .catch((e) => {
          console.log(e);
        });
  }

  reload(id: string) {
    return stores.Devices.getSelectedDeviceType(Number(id))
      .then((response) => {
        this.deviceType = response;
        // TODO: Maks kle naj bo pridobitev vseh naprav takega tipa
        // if (this.device && this.device.id) {
        //   stores.Account.getSelectedAccount(this.device.id)
        //     .then(response2 => {
        //       this.account = response2;
        //     })
        //     .catch(e2 => {
        //       console.log(e2);
        //     });
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.reload(this.$route.params.id);
  }
}
