
import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import {deletePath} from '@/helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import NevronInput from '@/components/NevronInput.vue';

import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import MultiSelect from 'vue-multiselect';
import NevronImageField from '@/components/NevronImageField.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import {translate} from '@/helpers/functions';


@Component({
  components: {
    EditContact,
    virtualList,
    EditCustomer,
    PreEdit,
    // AccountAttach,
    CustomerAddressEdit,
    AttachImage,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,

    NevronFooter,
    MultiSelect,
    NevronImageField,
    TableGeneric,
    TableModalGeneric,
    NevronAdvanceTextBox,
  },
  mixins: [Clickaway],
})

export default class LibraryDetails extends Vue {
  library: any = null;
  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;
  tempAcc!: IAccount;
  accountItems: IAccount[] = [];
  response: any = null;
  frontUrl: any = null;
  backUrl: any = null;
  search: any = {
    query: '',
  };
  indexAssets = 1;

  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;
  module = stores.Library;

  indexAccountList: number[] = [];
  allAccountsChecked: boolean = false;
  searchInProgress: boolean = false;
  dragOver: boolean = false;
  preLeave: boolean = false;
  attachableContent: any = null;

  file: boolean = true;
  richText: boolean = true;
  stores = stores;

  imageSelected(image: IMediaItem) {
    if (this.library) {
      this.library.imageId = (image) ? image.id : null;
    }
  }

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.$route.params.id));
  }

  setDocumentType() {
    if (this.library.type === 'file') {
      this.richText = false;
      this.file = true;
    } else if (this.library.type === 'rich-text') {
      this.file = false;
      this.richText = true;
    } else {
      this.library.type = 'file';
      this.file = true;
      this.richText = false;
    }
  }

  removeDocument() {
    this.library.fileName = null;
    this.library.path = null;
    return stores.Library.updateItem(Number(this.$route.params.id), this.library)
      .then((response: any) => {
        console.log('success', response);
      }).catch((e: any) => {
        console.log(e);
      });
  }

  onFrontFileChange(e: any) {
    // @ts-ignore
    this.$refs.pickFrontFile.click();
  }

  pickFrontFile(e: any) {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append('file_name', file);
    formdata.append('sort', this.library.sort);
    formdata.append('type', 'file');
    if (this.library.uploadMaxSize < (file.size / 1000)) {
      showToaster('danger', ((this.library.uploadMaxSize) / 1024) + 'Mb', 'File size cannot be large than');
    } else {
      return stores.Library.updateItem(Number(this.$route.params.id), formdata)
        .then((response: any) => {
          if (response?.response?.status === 422) {
            const errorMessagesMapping: any = {
                'The file may not be greater than 16MB.': 'validation_error_messages_file_size',
                'The file name must be a file of type: doc, docm, docx, dot, dotm, dotx, htm, html, msg, pdf, rtf, txt, wpd, xhtml, xps, pot, potx, pps, ppt, pptm, pptx, csv, xls, xlsm, xlsx.': 'validation_error_messages_file_type',
            };
            const validationErrors = response.response.data.errors;
            
            for (const field in validationErrors) {
                if (Object.hasOwnProperty.call(validationErrors, field)) {
                    const errorMessages = validationErrors[field];
                    errorMessages.forEach((errorMessage: any) => {
                      const errorMessageKey = errorMessagesMapping[errorMessage];
                        showToaster('danger', '', translate(errorMessageKey), response.response.status);
                    });
                }
            }
          
            return
          }else{
            this.fetchData(Number(this.$route.params.id));
            return
          }
        }).catch((e: any) => {
          console.log(e, 'error')
          showToaster('danger', Vue.prototype.translate(this.library.name), 'Fail to update');
      });
    }

   

  }

  /*
   *
   */
  onSubmitStatus() {
    const form = this.$refs.form as HTMLFormElement;
    if (!form.checkValidity()) {
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
      form.classList.add('was-validated');
      return;
    }
    form.classList.add('was-validated');

    return stores.Library.updateItem(Number(this.$route.params.id), this.library)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.fetchData(Number(this.$route.params.id));
          showToaster('success', Vue.prototype.translate(this.library.name), 'successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.library.name), 'Fail to update', response.code);
        }
      }).catch((e: any) => {
        console.log(e);
        showToaster('danger', Vue.prototype.translate(this.library.name), 'Fail to update');
      });
  }

  onSubmitActive() {
    // @ts-ignore
    return stores.Library.updateItem(Number(this.$route.params.id), this.library)
      .then((response: any) => {
        this.library = response.data;
        console.log('success', response);
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(Number(this.$route.params.id));
  }

  loadPage() {
    this.fetchData(Number(this.$route.params.id));
  }

  /**
   *
   */
  popoverMethod() {
    if (this.library && this.library.id) {
      return deletePath(this.library.id);
    }
  }

  deleteLibrary() {
    return stores.Library.deleteItem(this.library.id)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  fetchData(id: number) {
    return stores.Library.getDetail(id)
      .then((response: any) => {
        this.library = response.data;
        this.setDocumentType();
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  // assets
  attachAssets(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.assetIds.includes(el.id));
    this.library.propertyAsset = this.library.propertyAsset.concat(newItems);
  }

  get assetIds() {
    return this.library.propertyAsset.map((el: IItem) => el.id);
  }

  detachAsset(item: IItem) {
    this.library.propertyAsset = this.library.propertyAsset.filter((el: any) => el.id !== item.id);
  }

}
