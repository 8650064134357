
import PageHeader from '@/components/partials/page-header.vue';
import Profile from '@/components/partials/widgets/profile.vue';
import Stat from '@/components/partials/widgets/stat.vue';
import StatisticsList from '@/components/partials/widgets/StatisticsList.vue';
import RoomCareStatistics from '@/components/partials/widgets/RoomCareStatistics.vue';
import PortalVisitorsStatistics from '@/components/partials/widgets/PortalVisitorsStatistics.vue'
import { Vue, Component } from 'vue-property-decorator';
import stores from '@/stores';
import { bus } from '@/helpers/functions';

@Component({
  components: {
    PageHeader,
    Profile,
    Stat,
    StatisticsList,
    RoomCareStatistics,
    PortalVisitorsStatistics
  },
})
export default class Home extends Vue {

  title: any = 'dashboard';
  roomCarKey: number = 0;
  activeGuestStatData: any =
    {
      icon: "../../../img/active-guest-w.svg",
      title: "active-guests",
      activeCount: 0,
      activePercentage: 0,
      count: {
        week: 0,
        month: 0,
        year: 0,
      },
      percentage: {
        week: 0,
        month: 0,
        year: 0,
      },
      fetched: {
        week: false,
        month: false,
        year: false,
      }
    };
  occupancyRateStatData: any =
    {
      icon: "../../../img/occupancy-rate-w.svg",
      title: "occupancy-rate",
      activeCount: 0,
      activePercentage: 0,
      count: {
        week: 0,
        month: 0,
        year: 0,
      },
      percentage: {
        week: 0,
        month: 0,
        year: 0,
      },
      fetched: {
        week: false,
        month: false,
        year: false,
      }
    }
  skipCleaningStatData: any =
    {
      icon: "../../../img/skip-cleaning-w.svg",
      title: "skip-cleaning",
      activeCount: 0,
      activePercentage: 0,
      count: {
        week: 0,
        month: 0,
        year: 0,
      },
      percentage: {
        week: 0,
        month: 0,
        year: 0,
      },
      fetched: {
        week: false,
        month: false,
        year: false,
      }
    }

  AverageStayStatData: any =
    {
      icon: "../../../img/average-stay-w.svg",
      title: "average_stays",
      activeCount: 0,
      activePercentage: 0,
      count: {
        week: 0,
        month: 0,
        year: 0,
      },
      percentage: {
        week: 0,
        month: 0,
        year: 0,
      },
      fetched: {
        week: false,
        month: false,
        year: false,
      }
    };

  buttonData: any = [
    {
      class: "",
      title: "week",
    },
    {
      class: "",
      title: "month",
    },
    {
      class: "active",
      title: "year",
    }
  ];

  averageStayButtonData: any = [
    {
      class: "",
      title: "week",
    },
    {
      class: "",
      title: "month",
    },
    {
      class: "active",
      title: "year",
    }
  ];

  countriesStatDisplayDetails: any = {
    title: "guest_nationality_distribution",
    image: "../../../img/country-w.svg",
    intervalOptions: [
      {
        title: "week",
        selected: false,
      },
      {
        title: "month",
        selected: false,
      },
      {
        title: "year",
        selected: true,
      }
    ]
  }
  mostVisitedPagesStat: any = {
    title: "most-visited-pages",
    image: "../../../img/most_visited_pages-w.svg",
    intervalOptions: [
      {
        title: "week",
        selected: false,
      },
      {
        title: "month",
        selected: false,
      },
      {
        title: "year",
        selected: true,
      }
    ]
  }

  topMostVisitedPage: IStatEntity | null = {
    id: 0,
    name: "",
    count: 0,
    percentage: 0,
  };

  statMostVisitedPagesList: IStatEntity[] = [];

  topStatCountry: IStatEntity | null = {
    id: 0,
    name: "",
    count: 0,
    percentage: 0,
  };
  statCountriesList: IStatEntity[] = [];

  roomCareStatisticsData: any = {
    series: [{
      name: 'Earning',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      colors: ['#556ee6'],
      xaxis: {
        type: 'day',
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
      tooltip: {
        x: {
          format: 'number'
        },
      },

    },
    extraData: {
      overAll: 0,
      currentMonth: 0,
    }
  };

  roomCareContent: any = {
    title: 'room-care-earnings',
    overAll: "overall",
    month: "month",
    thisMonth: "this-month",
  };

  portalVisitorStatData: any =
    {
      icon: "../../../assets/icons/white/active-guest-w.svg",
      title: "portal-visitors",
      count: 0,
      percentage: 0,
      fetched: {
        week: false,
        month: false,
        year: false,
      }
    }

  portalVisitorStatisticsData: any = {
    series: [{
      name: 'TV',
      data: [0, 0, 0, 0]
    }, {
      name: 'Mobile',
      data: [0, 0, 0, 0]
    }],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false // Disable zoom
        },
        toolbar: {
          show: false // Hide toolbar/menu options
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      colors: ['#556ee6', '#ebc78d'],
      xaxis: {
        type: 'week',
        categories: [2020, 2021, 2022, 2023, 2024],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy'
        },
      },

    }
  };

  portalVisitorButtonData: any = [
    {
      class: "",
      title: "week",
    },
    {
      class: "",
      title: "month",
    },
    {
      class: "active",
      title: "year",
    }
  ];

  chartKey: number = 0;
  portalVisitorKey: number = 0;




  handleSelect(title: any) {
    this.buttonData = this.buttonData.map((button: any) => {
      if (button.title === title) {
        button.class = 'active';
        return button;
      } else {
        button.class = '';
        return button;
      }
    })

    this.fetchActiveGuestsData(title)
    this.fetchOccupancyRateStatData(title)
    this.fetchSkipCleaningStatData(title)
  }

  handleAverageStayIntervalSelect(title: any) {
    this.averageStayButtonData = this.averageStayButtonData.map((button: any) => {
      if (button.title === title) {
        button.class = 'active';
        return button;
      } else {
        button.class = '';
        return button;
      }
    })

    this.fetchAverageStayStatData(title)
  }

  handleRoomCareSelect(interval: any) {
    this.fetchRoomCareData(interval)
  }

  handlePortalVisitorSelect(title: any) {
    this.portalVisitorButtonData = this.portalVisitorButtonData.map((button: any) => {
      if (button.title === title) {
        button.class = 'active';
        return button;
      } else {
        button.class = '';
        return button;
      }
    })

    this.fetchPoralVisitorStat(title)
  }


  mounted() {
    // Load all stats on Component Load
    this.fetchStats('year');
    this.fetchRoomCareData('January')
    this.updateMostVistedPageStat('year')
    this.fetchPoralVisitorStat('year')
  }
  fetchStats(timeInterval: any) {
    this.fetchActiveGuestsData(timeInterval)
    this.fetchOccupancyRateStatData(timeInterval)
    this.fetchSkipCleaningStatData(timeInterval)
    this.updateCountriesStat(timeInterval)
    this.fetchAverageStayStatData(timeInterval)
  }

  fetchActiveGuestsData(timeInterval: any) {
    if (this.activeGuestStatData.fetched[timeInterval]) {
      this.activeGuestStatData.activeCount = this.activeGuestStatData.count[timeInterval]
      this.activeGuestStatData.activePercentage = this.activeGuestStatData.percentage[timeInterval]
      return
    }
    stores.DashboardAnalytics.fetchStats(['active_guests'], timeInterval)
      .then((response: any) => {
        this.activeGuestStatData.fetched[timeInterval] = true;
        this.activeGuestStatData.count[timeInterval] = parseInt(response.data.activeGuests.current.guestCount);
        this.activeGuestStatData.percentage[timeInterval] = parseInt(response.data.activeGuests.current.percentage);
        this.activeGuestStatData.activeCount = this.activeGuestStatData.count[timeInterval]
        this.activeGuestStatData.activePercentage = this.activeGuestStatData.percentage[timeInterval]
      })
  }
  fetchOccupancyRateStatData(timeInterval: any) {
    if (this.occupancyRateStatData.fetched[timeInterval]) {
      this.occupancyRateStatData.activeCount = this.occupancyRateStatData.count[timeInterval]
      this.occupancyRateStatData.activePercentage = this.occupancyRateStatData.percentage[timeInterval]
      return
    }
    stores.DashboardAnalytics.fetchStats(['occupancy_rate'], timeInterval)
      .then((response: any) => {
        this.occupancyRateStatData.fetched[timeInterval] = true;
        this.occupancyRateStatData.count[timeInterval] = parseInt(response.data.occupancyRate.current?.occupancyRate);
        this.occupancyRateStatData.percentage[timeInterval] = parseInt(response.data.occupancyRate.current?.percentage);
        this.occupancyRateStatData.activeCount = this.occupancyRateStatData.count[timeInterval]
        this.occupancyRateStatData.activePercentage = this.occupancyRateStatData.percentage[timeInterval]
      })
  }
  fetchSkipCleaningStatData(timeInterval: any) {
    if (this.skipCleaningStatData.fetched[timeInterval]) {
      this.skipCleaningStatData.activeCount = this.skipCleaningStatData.count[timeInterval]
      this.skipCleaningStatData.activePercentage = this.skipCleaningStatData.percentage[timeInterval]
      return
    }
    stores.DashboardAnalytics.fetchStats(['skip_cleaning'], timeInterval)
      .then((response: any) => {
        this.skipCleaningStatData.fetched[timeInterval] = true;
        this.skipCleaningStatData.count[timeInterval] = parseInt(response.data.skipCleaning.current?.skipCleaningCount);
        this.skipCleaningStatData.percentage[timeInterval] = parseInt(response.data.skipCleaning.current?.percentage);
        this.skipCleaningStatData.activeCount = this.skipCleaningStatData.count[timeInterval]
        this.skipCleaningStatData.activePercentage = this.skipCleaningStatData.percentage[timeInterval]
      })
  }

  fetchAverageStayStatData(timeInterval: any) {
    if (this.AverageStayStatData.fetched[timeInterval]) {
      this.AverageStayStatData.activeCount = this.AverageStayStatData.count[timeInterval]
      this.AverageStayStatData.activePercentage = this.AverageStayStatData.percentage[timeInterval]
      return
    }
    stores.DashboardAnalytics.fetchStats(['average_stays'], timeInterval)
      .then((response: any) => {
        this.AverageStayStatData.fetched[timeInterval] = true;
        this.AverageStayStatData.count[timeInterval] = parseInt(response.data.averageStays.current?.averageStay);
        this.AverageStayStatData.percentage[timeInterval] = parseInt(response.data.averageStays.current?.percentage);
        this.AverageStayStatData.activeCount = this.AverageStayStatData.count[timeInterval]
        this.AverageStayStatData.activePercentage = this.AverageStayStatData.percentage[timeInterval]
      })
  }

  updateCountriesStat(intervalOption: any) {
    stores.DashboardAnalytics.fetchStats(['countries'], intervalOption?.title)
      .then((response: any) => {
        this.statCountriesList = response.data.countries.current.map((item: any) => {
          return {
            id: item.customerId,
            name: item.countryName,
            count: item.customerCount,
            percentage: item.percentage,
          }
        });

        if (this.statCountriesList.length > 0) {
          this.topStatCountry = this.statCountriesList[0];
        } else {
          this.topStatCountry = {
            id: 0,
            name: "",
            count: 0,
            percentage: 0,
          };
        }
      })
  }

  updateMostVistedPageStat(intervalOption: any) {
    stores.DashboardAnalytics.fetchStats(['most_visited_pages'], intervalOption?.title)
      .then((response: any) => {

        this.statMostVisitedPagesList = response.data.mostVisitedPages.map((item: any) => {
          return {
            id: item.idactionUrl,
            name: item.count,
            count: !item.actionUrlName?.trim() ? "home" : item.actionUrlName,
            percentage: item.percentage,
          }
        });

        if (this.statMostVisitedPagesList.length > 0) {
          this.topMostVisitedPage = this.statMostVisitedPagesList[0];
        } else {
          this.topMostVisitedPage = {
            id: 0,
            name: "",
            count: 0,
            percentage: 0,
          };
        }
      })
  }

  fetchRoomCareData(timeInterval: any) {
    stores.DashboardAnalytics.fetchStats(['room_care'], timeInterval)
      .then((response: any) => {
        this.roomCareStatisticsData.series[0].data = response.data.roomCare.current.monthEarning;
        this.roomCareStatisticsData.chartOptions.xaxis.categories = response.data.roomCare.current.monthDays;
        this.roomCareStatisticsData.extraData.overAll = response.data.roomCare.current.yearEarning;
        this.roomCareStatisticsData.extraData.currentMonth = response.data.roomCare.current.monthTotalEarning;
        this.roomCarKey++;
      })
  }
  fetchPoralVisitorStat(timeInterval: any) {
    stores.DashboardAnalytics.fetchStats(['portal_visitor'], timeInterval)
      .then((response: any) => {
        this.portalVisitorStatisticsData.series[0].data = response.data.portalVisitor.tv.data
        this.portalVisitorStatisticsData.series[1].data = response.data.portalVisitor.mobile.data
        this.portalVisitorStatisticsData.chartOptions.xaxis.categories = response.data.portalVisitor.tv.keys;
        this.portalVisitorStatisticsData.chartOptions.xaxis.type = timeInterval;
        this.portalVisitorKey++;
      })
  }




}
