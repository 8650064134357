import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import { generateLog } from '@/helpers/functions';
import Axios from "axios";

let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL = '/messageslog';

enum IconType {
    TV = 'tv',
    MOBILE = 'mobile',
  }

export class MessageLog extends Model<IMessageLog> implements IMessageLog {
    id: number | null = null;
    readAt: string = '';
    // message: IMessage;

    constructor() {
        super();
    }

    urlRoot(): string {
        return URL;
    }
}

export default class MessageCollection extends Collection<MessageLog> {

    routeName = 'messageslog';

    url(): string {
        return URL;
    }

    model(): new (attributes: IMessageLog, collection?: Collection) => MessageLog {
        return MessageLog;
    }

    build(): MessageLog {
        return new MessageLog();
    }

    fetchData(index: number = 1, search: string | null = null, perpage: number = 10) {
        if (typeof cancel === 'function') {
            cancel();
        }

        const cancelToken = new CancelToken(function executor(c: any) {
            cancel = c;
        });

        const baseURL = `${this.url()}?page=${index}&perpage=${perpage}`;
        const url = search ? `${baseURL}&search=${search}` : baseURL;

        return axiosInstance.get(url, { cancelToken })
            .then((response: any) => response.data)
            .catch((e: any) => {
                const log: any = {
                    route: this.routeName,
                    message: e,
                };
                generateLog(log);
                return Promise.reject(e);
            });
    }

    showIcons(item: any, icon: any): boolean {
        if (!icon || !icon.name) return false;
        if (icon.name === IconType.TV) {
            let showTvIcon = false
            item.message.displayedOn.forEach((element: any) => {
                if (element.toLowerCase() === IconType.TV)
                    showTvIcon = true;
            })
            return showTvIcon;
        }
        if (icon.name === IconType.MOBILE) {
            let showMobileIcon = false
            item.message.displayedOn.forEach((element: any) => {
                if (element.toLowerCase() === IconType.MOBILE)
                    showMobileIcon = true;
            })
            return showMobileIcon;
        }
        return false;
    }

    showTypes(item:any, subfield:any):String[]{
        let types: String[] = [];
        item.message.deliveryChannels.forEach((item:any,index:any) => {
            item = item.toLowerCase().split('_').join(" ");
            types.push(item.charAt(0).toUpperCase() + item.slice(1))
        });
        return types
    }
}
