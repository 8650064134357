
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';
import routerBase from '@/helpers/routerBase';
import globalConfig from '@/helpers/globalConfig';

import {AxiosError} from 'axios';
import { bus } from '@/helpers/functions';

@Component
export default class AdminLoginItem extends Vue {
  username: string = '';
  password: string = '';
  rememberMe: boolean = false;
  session = stores.session;

  inDevelopment = globalConfig.env === 'development';

  redirectPath: string | null = null;

  errorMessage: string | null = null;

  admin = stores.admin;
  image = require('@/assets/images/profile-img.png');

  @Watch('admin.isLoggedInWithoutOTPVerification', {immediate: true})
  adminWatch() {
    this.redirect();
  }

  redirect() {
    if (stores.admin.hasToken || !stores.admin.isLoggedInWithoutOTPVerification) {
      bus().$emit('logged', 'User logged');
      this.$router.replace(this.redirectPath || {name: 'projects'});
    }
    if (stores.admin.isLoggedInWithoutOTPVerification) {
      this.$router.replace({ name : 'admin.2FA'});
      return
    }
  }

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('login');
    this.redirectPath = '/admin/projects'
  }

  async onSubmit() {
    try {
      const loginResponse = await stores.admin.login(this.username, this.password, this.rememberMe, true);
      this.errorMessage = null;
      // Only complete login flow if token is set properly.
      if(stores.admin.hasToken)
      {
        bus().$emit('login');
      }
      this.redirect();
    } catch (error) {
      this.errorMessage = error?.data?.message || 'An unexpected error occurred.';
      throw error;
    }
  }
}
