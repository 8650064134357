import {Component, Vue, Watch} from 'vue-property-decorator';

/**
 * TabManager: A Vue mixin for tab management.
 *
 * - Maintains an activeTabIndex reflecting the active tab.
 * - Synchronizes with 'tab' URL query parameter.
 * - The component should implement the computed property TAB_KEYS with an array of tab keys.
 * - Initial tab selection is based on the URL during creation.
 */

@Component({
  watch: {
    $route: 'updateActiveTabFromQuery',
  },
})
export default class TabManager extends Vue {
  activeTabIndex: number = 0;

  // Should be overridden by the component.
  get TAB_KEYS(): string[] {
    return [];
  }

  created() {
    this.updateActiveTabFromQuery();
  }

  updateActiveTabFromQuery() {
    const tab = (this.$route.query.tab as string) || '';
    if (!this.isConvertibleToNumber(tab)) {
      const keyIndex = this.TAB_KEYS.indexOf(tab);
      this.activeTabIndex = keyIndex > -1 ? keyIndex : 0;
    } else {
      const tabIndex = parseInt(tab, 10);
      this.activeTabIndex = (tabIndex >= 0) ? tabIndex : 0;
    }
  }

  updateActiveTab(tabIndex: number) {
    if (this.activeTabIndex === tabIndex) {
      return;
    }
    this.activeTabIndex = tabIndex;
    const tabKey = this.TAB_KEYS[tabIndex];
    if (tabKey) {
      this.$router.replace({query: {...this.$route.query, tab: tabKey}});
    } else {
      this.$router.replace({query: {...this.$route.query, tab: String(tabIndex)}});
    }
  }

  isConvertibleToNumber(str: string) {
    return !isNaN(+str);
  }

}
