
import { Component, Vue, Watch } from 'vue-property-decorator';
import stores from "@/stores";
import ItemsIndex from "@/components/ListNevronComponents/Items/IndexGeneric.vue";
import {showToaster} from '@/components/DashboardComposer/helpers';
import globalConfig from "@/stores/GlobalConfig";

@Component({
  computed: {
    stores() {
      return stores
    },
    showFeature(){
      return globalConfig.config.showBetaFeatures
    }
  },
  components: {
    ItemsIndex,
  },
})

export default class DevicesList extends Vue {

  selectedItems: IDevice[] = [];
  deviceActionStatusKey: string = '';


  mounted() {
    console.log("in");
    this.updateActionStatusColumn('');
  }

  onActionClick(action: string) {

    if (this.selectedItems.length <= 0) {
      return;
    }

    this.updateActionStatusColumn(Vue.prototype.translate("IN_PROGRESS"));

    const macAddresses = this.selectedItems.map(x => x.id);

    let response = null;
    let popupType = '';
    switch (action) {
      case 'ping':
        response = stores.Devices.ping(macAddresses);
        popupType = Vue.prototype.translate("ping");
        break;
      case 'reboot':
        response = stores.Devices.reboot(macAddresses);
        popupType = Vue.prototype.translate("reboot");
        break;
      case 'factoryReset':
        response = stores.Devices.factoryReset(macAddresses);
        popupType = Vue.prototype.translate("factory-reset");
        break;
      default:
        break;
    }
    if (response !== null) {
      response.then((data:any) => {
        if(data.responseCode == 200) {
          this.updateActionStatusColumn(Vue.prototype.translate("SUCCESS"));
          showToaster('success', popupType, data.data);
        } else {
          this.updateActionStatusColumn(Vue.prototype.translate("WARNING"));
          showToaster('warning', popupType, data.error.message, data.responseCode);
        }
      }).catch((error:any) => {
        console.error('An error occurred:', error);
        showToaster('danger', popupType, error.message);
        this.updateActionStatusColumn(Vue.prototype.translate("SUCCESS"));
      });
    }
  }

  updateActionStatusColumn(status: string) {
    this.selectedItems.forEach((device, index) => {
      this.deviceActionStatusKey = Date.now().toString();
      this.$set(device, 'deviceActionStatus', status);
    });
  }
}
