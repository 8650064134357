
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import Skeleton from '@/modules/Skeleton.vue';
import SmallSkeleton from '@/modules/SmallSkeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronInput from '../../../components/NevronInput.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {expression} from 'joi';
import globalConfig from "@/stores/GlobalConfig";
import {concatenateErrors, translate, validateForm} from "@/helpers/functions";

@Component({
  components: {
    Skeleton,
    NevronHeader,
    NevronFooter,
    NevronInput,
    TableGeneric,
    SmallSkeleton,
    TableModalGeneric
  },
  computed: {
    showFeature(){
      return globalConfig.config.showBetaFeatures
    }
  },
})

export default class DeviceGroupDetails extends Vue {
  deviceGroup: IDeviceGroup = {
    id: null,
    name: '',
    default: null,
    active: false,
    sort: 0,
    shortDescription: '',
    type: null,
    devices: [],
  };
  stores = stores;
  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.reload(this.$route.params.id);
  }

  onSubmitStatus() {
    if (this.deviceGroup) {
      return stores.DeviceGroups.updateItem(this.deviceGroup.id, this.deviceGroup)
        .then((response) => {
          if(!response.success) {
            throw new Error(concatenateErrors(response));
          }
        }).catch((e: any) => {
          showToaster('danger', '', e.message);
          console.log(e);
        });
    }
  }

  save() {
    if (this.deviceGroup && validateForm('device-group-form')) {
      return stores.DeviceGroups.updateItem(this.deviceGroup.id, this.deviceGroup)
        .then(async (response: any) => {
          console.log(response);

          if (!response.success) {
            throw new Error(concatenateErrors(response));
          }

          await this.reload(this.$route.params.id);
          // @ts-ignore
          showToaster('success', Vue.prototype.translate(this.deviceGroup.name), 'successfully updated');
        }).catch((e: any) => {
          // @ts-ignore
          showToaster('danger',  Vue.prototype.translate(this.deviceGroup.name), e.message);
        });
    }
  }

  attachDevices(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.deviceIds.includes(el.id));
    this.deviceGroup.devices = this.deviceGroup.devices.concat(newItems);
  }
  get deviceIds() {
    return this.deviceGroup.devices.map((el: any) => el.id);
  }
  removeGroup() {
      return stores.DeviceGroups.deleteItem(this.deviceGroup?.id!)
        .then((response) => {
          if(!response.success) {
            showToaster('danger', '', response.error.message);
          } else {
            showToaster('success', Vue.prototype.translate(this.deviceGroup.name), Vue.prototype.translate('successfully-deleted'));
            this.$router.push({
              name: 'devices.index', query: {
                page: this.$route.query.lastpage,
                perpage: this.$route.query.perpage,
                tab: 'groups'
              }
            });
          }
        })
        .catch((e) => {
          showToaster('danger', '', e);
          console.log(e);
        });
  }

  reload(id: string) {
    return stores.DeviceGroups.editGroup(Number(id))
      .then((response:any) => {
        this.deviceGroup = response;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  detachDevice(item: IItem) {
    this.deviceGroup.devices = this.deviceGroup.devices.filter((el: any) => el.id !== item.id);
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.reload(this.$route.params.id);
  }

  async onActionClick(action: string) {
    if (!this.deviceGroup.id) {
      return;
    }

    stores.Devices.deviceMacAddressesFromGroupId([this.deviceGroup.id])
      .then((macAddresses) => {
        if (macAddresses.data.length == 0) {
          showToaster('warning', '', translate('no-devices-found-message'));
          return;
        }
        let response = null;
        let popupType = '';
        switch (action) {
          case 'ping':
            response = stores.Devices.ping(macAddresses.data);
            popupType = Vue.prototype.translate("ping");
            break;
          case 'reboot':
            response = stores.Devices.reboot(macAddresses.data);
            popupType = Vue.prototype.translate("reboot");
            break;
          default:
            break;
        }
        if (response !== null) {
          response.then((data:any) => {
            if(data.responseCode == 200) {
              showToaster('success', popupType, data.data);
            } else {
              showToaster('warning', popupType, data.error.message, data.responseCode);
            }
          }).catch((error:any) => {
            console.error('An error occurred:', error);
            showToaster('danger', popupType, error.message);
          });
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the asynchronous call
        console.error(error);
      });
  }

}
