
  import {Component, Vue} from 'vue-property-decorator';
  import stores from '@/stores';
  import NevronHeader from '@/components/NevronHeader.vue';
  import AttachImage from '@/modules/Media/Attach.vue';
  import axiosInstance from '../../helpers/axios';
  import { Setting } from '@/stores/Setting';
  import Skeleton from '@/modules/Skeleton.vue';
  import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
  import TimeZones from '@/modules/TimeZones.vue';

  @Component({
    components: {
      AttachImage,
      NevronHeader,
      Skeleton,
      NevronAdvanceTextBox,
      TimeZones
    },
  })
  export default class GeneralSetting extends Vue {

    logo: ISetting = new Setting({'key': 'logo'});
    mobileLoginBackground: ISetting = new Setting({'key': 'mobile_login_background'});
    mobileLogo: ISetting = new Setting({'key': 'mobile_logo'});
    defaultColor: ISetting = new Setting({'key': 'default_color'});
    secondaryColor: ISetting = new Setting({'key': 'secondary_color'});
    noImageIcon: ISetting = new Setting({'key': 'no_image_icon'});
    welcomeVideoLink: ISetting = new Setting({'key': 'welcome_video_link'});
    welcomeVideoEnable: ISetting = new Setting({'key': 'welcome_video_enable'});
    welcomeVideoLoop: ISetting = new Setting({'key': 'welcome_video_loop'});
    welcomeVideoSkip: ISetting = new Setting({'key': 'welcome_video_skip'});
    attachImageTo: ISetting | null = null;
    companyName: ISetting = new Setting({'key': 'company_name'});
    companyDescription:  ISetting = new Setting({'key': 'company_description'});
    generalTimezone: ISetting = new Setting({'key': 'general.time_zone'});

    mounted() {
      this.fetchSettings();
      Vue.prototype.WebsiteTitle.setModuleName('general-configuration');
    }

    fetchSettings() {
      stores.Setting.getSettings()
        .then((response) => {
          this.settings(response);
        });
    }

    settings(data: ISetting[]) {
      for (const index in data) {
        if (data[index].key === 'logo') {
          this.logo = data[index];
          this.logo.type = 'media_file';

        } else if (data[index].key === 'mobile_login_background') {
          this.mobileLoginBackground = data[index];
          this.mobileLoginBackground.type = 'media_file';

        } else if (data[index].key === 'mobile_logo') {
          this.mobileLogo = data[index];
          this.mobileLogo.type = 'media_file';

        } else if (data[index].key === 'no_image_icon') {
          this.noImageIcon = data[index];
          this.noImageIcon.type = 'media_file';

        } else if (data[index].key === 'primary_company_color') {
        this.defaultColor = data[index];

        } else if (data[index].key === 'secondary_company_color') {
          this.secondaryColor = data[index];
        } else if (data[index].key === 'company_name') {
          this.companyName = data[index];
        } else if (data[index].key === 'company_desc') {
          this.companyDescription = data[index];
        } else if (data[index].key === 'welcome_video_link') {
          this.welcomeVideoLink = data[index];
        } else if (data[index].key === 'welcome_video_enable') {
          this.welcomeVideoEnable = data[index];
          this.welcomeVideoEnable.value = this.welcomeVideoEnable.value !== '0';
        } else if (data[index].key === 'welcome_video_loop') {
          this.welcomeVideoLoop = data[index];
          this.welcomeVideoLoop.value = this.welcomeVideoLoop.value !== '0';
        } else if (data[index].key === 'welcome_video_skip') {
          this.welcomeVideoSkip = data[index];
          this.welcomeVideoSkip.value = this.welcomeVideoSkip.value !== '0';
        } else if(data[index].key === 'general.time_zone') {
          this.generalTimezone = data[index];
        }
      }

      if(!this.companyDescription.id){
        console.log('update info', this.companyDescription);
        this.companyDescription = {
            id:null,
            key:'company_desc',
            value:'',
            type:'translate'
        }
      }

      if(!this.companyName){
        this.companyName = {
            id:null,
            key:'company_name',
            value:'',
            type:''
        }
      }

    }

    updateSetting(setting: ISetting, newValue: string | null) {
      const data = { value: newValue, type: setting.type,key:setting.key };
      stores.Setting.update(setting.id, data)
        .then((response) => {
          this.fetchSettings();
        });
    }

    timeZoneSelected(zone: string) {
      this.generalTimezone.value = zone;
      this.updateSetting(this.generalTimezone, zone);
    }
    updateCheckbox(id: any, value: any) {
      stores.Setting.update(id, {value})
        .then((response) => {
          console.log('setting updated successfully..');
        });
    }

    attachWithImage(res: any) {
      this.updateSetting(this.attachImageTo!, res.result.id);
    }

    sendReload(files: any, result: any, setting: ISetting) {
      this.updateSetting(this.attachImageTo!, result.id);
    }

    getImages(setting: ISetting) {
      this.attachImageTo = setting;
      // @ts-ignore
      this.$refs.image.$children[0].open();
    }

    getFilePath(): string {
      return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
    }

    getHeaders() {
      return stores.File.getHeader();
    }
  }
