
  import {Component, Vue} from 'vue-property-decorator';
  import stores from '@/stores';
  import NevronHeader from '@/components/NevronHeader.vue';
  import {DashboardPanel} from '@/stores/Dashboard';
  import vPagination from '@/components/VuePlainPagination.vue';
  import Skeleton from '@/modules/Skeleton.vue';
  import NevronEmpty from '@/components/NevronEmpty.vue';
  import NevronSearch from '@/components/NevronSearch.vue';
  import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
  import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
  import NevronInput from '@/components/NevronInput.vue';

  @Component({
    components: {
      vPagination,
      NevronHeader,
      Skeleton,
      NevronEmpty,
      NevronSearch,
      NevronSearchNoResults,
      NevronClickConfirm,
      NevronInput,
    },
  })

  export default class ShowPanel extends Vue {
    panel: DashboardPanel = new DashboardPanel();

    created() {
      this.fetchData(this.$route.params.id);
    }

    onSubmit() {
      if (this.panel) {
        // preparing data to send
        const data = {
          name: this.panel.name,
          showName: this.panel.showName,
          active: this.panel.active,
          sort: this.panel.sort,
          panelType: this.panel.panelType,
          position: this.panel.position,
          elementType: this.panel.elementType,
        };
        console.log('submitted data is', data);
        // request to backend
        return stores.dashboards.updatePanel(this.panel.id, data)
          .then((response) => {
            console.log('Success');
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    fetchData(id: string) {
      stores.dashboards.getDashboardPanel(id)
        .then((dashboard) => {
          this.panel = dashboard;
        })
        .catch((reason) => {
          console.log('Panel Could not found', reason);
          if (!this.panel.id) {
            this.$router.go(-1);
          }
        });
    }
  }
