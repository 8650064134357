import stores from "@/stores";
// @ts-ignore
import i18n from "@/i18n.js";

let adminDataPromise: Promise<void> | null = null;

export async function ensureInitialAdminData(): Promise<void> {
  if (adminDataPromise) {
    return adminDataPromise;
  }
  if (stores.admin.isLoggedIn) {
    return Promise.resolve();
  }
  return fetchInitialAdminData();
}

export async function fetchInitialAdminData(): Promise<void> {
  if (!stores.admin.hasToken) {
    const token = await stores.admin.requestToken();
    if (!token) return Promise.reject('No token');
  }

  adminDataPromise = (async () => {
    try {
      const adminPromise = stores.admin.fetch().then(() => {
        if (stores.admin.language) {
          i18n.locale = stores.admin.language.localCode;
        } else {
          i18n.locale = 'en_UK';
        }
      });
      if (!stores.admin.isSuperAdmin) {
        const modulesPromise = stores.modules.fetch().then((response) => {
          localStorage.setItem('menus', JSON.stringify(response));
        });

        const settingsPromise = stores.Setting.getData();

        const translationsPromise = stores.Language.loadTranslations();

        await Promise.all([adminPromise, modulesPromise, settingsPromise, translationsPromise]);
      }

      if (stores.admin.isSuperAdmin) {
        await Promise.all([adminPromise]);
      }
      // Set adminDataPromise to null after it resolves
      adminDataPromise = null;
    } catch (e) {
      stores.admin.removeToken();
      adminDataPromise = null;
      console.error(e);
      throw e;
    }
  })();

  return adminDataPromise;
}
