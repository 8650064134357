
import {Vue} from 'vue-property-decorator';
import globalConfig from '../../stores/GlobalConfig';
import stores from "@/stores";
export default class ProjectFinish extends Vue {
  config = globalConfig.config;

  logo: ISetting = {
    id: 0,
    value: '',
    key: '',
  };
}
