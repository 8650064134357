
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
// @ts-ignore
import Switches from 'vue-switches';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import {deletePath} from '@/helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import session from '../../stores/Session';
import axiosInstance from '../../helpers/axios';
import NevronInput from '@/components/NevronInput.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import MultiSelect from 'vue-multiselect';
import NevronImageField from '@/components/NevronImageField.vue';

// @ts-ignore
import TableGeneric from '@/components/NevronTableGeneric.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import {dynamicModules} from '@/helpers/constants';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import NevronModuleQr from "@/components/NevronModuleQr.vue";
import {isSettingDisabled} from "@/helpers/modules-settings";
import {translate} from '@/helpers/functions';

@Component({
  methods: {isSettingDisabled},
  components: {
    NevronModuleQr,
    virtualList,
    PreEdit,
    AttachImage,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,
    NevronFooter,
    MultiSelect,
    NevronImageField,
    TableGeneric,
    TableModalGeneric,
    NevronAdvanceTextBox,
    Switches,
  },
  mixins: [Clickaway],
})

export default class GuestFlowModuleDetail extends Vue {
  guestFlowModule: any = null;
  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;
  assets = stores.Assets;
  module = stores.GuestFlow;
  library = stores.Library;
  promotion = stores.Promotions;
  stores = stores;
  tempAcc!: IAccount;

  accountItems: IAccount[] = [];
  // @ts-ignore
  // guestFlowModule: IGuestFlowModule | null = null;
  response: any = null;
  frontUrl: any = null;
  backUrl: any = null;
  search: any = {
    query: '',
  };

  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;

  indexAccountList: number[] = [];
  allAccountsChecked: boolean = false;
  searchInProgress: boolean = false;
  dragOver: boolean = false;
  preLeave: boolean = false;
  attachableContent: any = null;
  selectedModule: any = null;
  selectedModuleItem: any = null;
  moduleItems: any = null;
  loadModule: any = null;
  relatedItem: boolean = true;
  modules = stores.modules.models.filter((mod: IModule) => dynamicModules.includes(mod.type));

  @Prop()
  moduleId?: number | null;

  @Prop()
  moduleType?: any | null;

  get guestFlowModuleId() {
    return this.$route.query.guestFlowModuleId || this.moduleId || this.$route.params.id;
  }

  mounted() {
    this.fetchData(Number(this.guestFlowModuleId));
    this.fetchAssetList();
  }

  loadPage() {
    this.fetchData(Number(this.guestFlowModuleId));
  }

  @Watch('moduleId')
  watchModuleIdProp() {
    this.fetchData(Number(this.guestFlowModuleId));
  }

  @Watch('$route', {immediate: true, deep: true})
  watchRoute() {
    this.fetchData(Number(this.guestFlowModuleId));
  }

  imageSelected(image: IMediaItem) {
    if (this.guestFlowModule) {
      this.guestFlowModule.imageId = (image) ? image.id : null;
    }
  }

  dragEnter(num: number) {
    this.dragOver = true;
    this.preLeave = true;
    console.log('ENTER', num);
  }

  saveQrImage() {
    // @ts-ignore
    const figure = document.querySelector('#qrcode img').getAttribute('src');
    const a = document.createElement('a'); // Create <a>
    // @ts-ignore
    a.href = figure;
    a.download = `${Vue.prototype.translate(this.guestFlowModule.name)}.png`; // File name Here
    a.click(); // Downloaded file
  }

  getAccountsOnPage(index: number) {
    return stores.accounts.fetchData(index, null, 10)
      .then((response) => {
        this.fullResponse = response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  attachLibrary(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.libraryIds.includes(el.id));
    this.guestFlowModule.accommodationLibraries = this.guestFlowModule.accommodationLibraries.concat(newItems);
  }

  get libraryIds() {
    return this.guestFlowModule.accommodationLibraries.map((el: IItem) => el.id);
  }

  detachLibrary(item: IItem) {
    this.guestFlowModule.accommodationLibraries = this.guestFlowModule.accommodationLibraries.filter((el: any) => el.id !== item.id);
  }

  attachPromotions(items: any[]) {
    const newItems = items.filter((el: IPromotions) => !this.promotionsIds.includes(el.id));
    this.guestFlowModule.promotions = this.guestFlowModule.promotions.concat(newItems);
  }

  get promotionsIds() {
    return this.guestFlowModule.promotions.map((el: IPromotions) => el.id);
  }

  detachPromotions(item: IItem) {
    this.guestFlowModule.promotions = this.guestFlowModule.promotions.filter((el: any) => el.id !== item.id);
  }

  dragLeave(num: number) {
    if (this.preLeave) {
      this.preLeave = false;
    } else {
      this.preLeave = false;
      this.dragOver = false;
    }
    console.log('LEAVE', num);
  }

  sortArrays(arrays: any) {
    return _.orderBy(arrays, 'sort', 'asc');
  }

  /**
   *
   */
  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  getHeaders() {
    return {'X-Project': session.project!.id};
  }

  /**
   *
   */
  transmitFinish(files: any, res: any) {
    this.preLeave = false;
    this.dragOver = false;
    this.attachWithImage({result: res});
  }

  submitForm() {
    const form = document.getElementById('update-gfm-module-form') as HTMLFormElement;
    if (form.checkValidity()) {
      this.onSubmitStatus();
    } else {
      showToaster('danger', translate('some-fields-are-required'), '', 422);
    }
    form.classList.add('was-validated');
  }

  /*
   *
   */
  onSubmitStatus() {
    this.guestFlowModule.key = 'Data Consent';
    this.guestFlowModule.linkModule = this.selectedModule;
    this.guestFlowModule.linkItem = this.selectedModuleItem;
    if ((this.selectedModule === '' || this.selectedModule === '0') && (this.selectedModuleItem !== null)) {
      this.guestFlowModule.linkItem = null;
    }
    if (this.guestFlowModule.active === false) {
      this.guestFlowModule.active = 0;
    }
    return stores.GuestFlow.updateItem(this.guestFlowModule.id, this.guestFlowModule)
      .then((response) => {
        if (response.responseCode === 200) {
          this.fetchData(Number(this.guestFlowModuleId));
          showToaster('success', Vue.prototype.translate(this.guestFlowModule.name), 'successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.guestFlowModule.name), 'Fail to update', response.code);
        }
      }).catch((e: any) => {
        console.log(e);
        showToaster('danger', Vue.prototype.translate(this.guestFlowModule.name), 'Fail to update');
      });
  }

  /**
   *
   */
  popoverMethod() {
    if (this.guestFlowModule && this.guestFlowModule.id) {
      return deletePath(this.guestFlowModule.id);
    }
  }

  /**
   * Detaches the selected GuestFlowModule from this account
   */
  detachGuestFlowModule() {
    return stores.GuestFlow.deleteItem(Number(this.guestFlowModuleId))
      .then((response: any) => {
        this.$router.push({name: 'guestflowmodule'});
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  fetchData(id: number) {
    return stores.GuestFlow.getDetail(id)
      .then((response: any) => {
        this.guestFlowModule = response.data;
        if (this.guestFlowModule.active === 0) {
          this.guestFlowModule.active = false;
        }
        this.selectedModule = this.guestFlowModule.linkModule;
        this.selectedModuleItem = this.guestFlowModule.linkItem;
        if (this.selectedModuleItem || this.selectedModule) {
          this.relatedItem = false;
        }
        this.loadModuleItems();
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  fetchAssetList() {
    stores.Assets.fetchData(1, '', 20)
      .then((response: any) => {
        console.log('response', response);
        this.assets = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   *
   */
  attachWithImage(imgObj: any) {
    const data = {
      imageId: null,
    };
    if (imgObj) {
      this.guestFlowModule.imageId = imgObj.result.id;
      this.guestFlowModule.image = this.guestFlowModule.image || {};
      this.guestFlowModule.image.imageUrl = imgObj.result.imageUrl;
      // @ts-ignore
      this.$refs.image.$children[0].close();
      data.imageId = imgObj.result.id;
    } else {
      this.guestFlowModule.imageId = null;
      this.guestFlowModule.image = null;
    }
    // @ts-ignore
    return stores.GuestFlow.update(this.guestFlowModule.id, this.guestFlowModule)
      .then((response: any) => {
        console.log('Success');
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  updateCheck() {
    if (this.indexAccountList.length === this.guestFlowModule.accounts.length) {
      this.allAccountsChecked = true;
    } else {
      this.allAccountsChecked = false;
    }
  }

  loadModuleItems() {
    if (this.selectedModule) {
      if (this.selectedModule !== '0') {
        this.modules.forEach((item, index) => {
          if (item.id === this.selectedModule) {
            this.loadModule = item;
          }
        });
        this.loadModule.fetchData(1, '', '')
          .then((response: any) => {
            this.moduleItems = response.data;
          });
        this.relatedItem = false;
      } else {
        this.moduleItems = null;
        this.relatedItem = true;
      }
    } else {
      this.relatedItem = true;
    }
  }

}
