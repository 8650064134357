import Model from '@/stores/abstract/Model';
import Session from '@/stores/Session';
import axiosInstance from '@/helpers/axios';
import globalConfig from "@/helpers/globalConfig";
import {RolesEnum} from "@/enums/RolesEnum";
import { bus } from '@/helpers/functions';

interface ITokenMessage {
  type: 'request' | 'set';
  tokenName: string;
  token?: string;
}

interface IModulesSettings {
  [module: string]: {
    [field: string]: boolean;
  };
}

const bc = new BroadcastChannel('token_channel');

export default class TheAdmin extends Model {
  id: number = 0;
  name: string = '';
  chatAlias: string = '';
  email: string = '';
  enabled: boolean = false;
  languageId: number = 0;
  language?: ILanguage;
  permissions: string[] = [];
  roles?: IRole;
  role?: string;
  operatorId?: number;
  operatorName?: string;

  modulesSettings: IModulesSettings = {};

  superadmin: boolean = false;

  isOTPVerified: boolean = false;
  otpVerificationToken: null|string = null;
  needsOtpVerification: boolean = true;

  private pToken: string = '';
  private tokenRequestTimeout: ReturnType<typeof setTimeout> | null = null;

  constructor() {
    super();
    this.token = '';
    if (this.tokenName !== '') {
      this.token = localStorage.getItem(this.tokenName) || sessionStorage.getItem(this.tokenName) || '';
      if (this.token !== '' && this.tokenName === 'superadmin-token') {
        this.superadmin = true;
      }
      if(this.token.length > 0)
      {
        this.isOTPVerified = true;
        this.needsOtpVerification = true;
      }
    }
    this.setupMessageListener();
  }

  get token() {
    return this.pToken;
  }

  get tokenName() {
    return Session.project ? `${Session.project.id}-token` : 'superadmin-token';
  }

  get hasToken() {
    return !!this.token;
  }

  get isLoggedInWithoutOTPVerification(): boolean {
    if(this.hasToken) return false;
    return this.needsOtpVerification && (this.otpVerificationToken !== null) && (this.otpVerificationToken.length > 0) &&  !this.isOTPVerified;
  }

  get isLoggedIn(): boolean {
    // Only check OTP verification if required
    if(this.needsOtpVerification && !this.isOTPVerified) return false;
    return this.hasToken && !!this.id;
  }

  get isSuperAdmin(): boolean {
    return !!this.superadmin;
  }

  get isNevronSuperAdmin(): boolean {
    return !!this.role && this.role === RolesEnum.NEVRON_SUPERADMIN;
  }

  get isOperatorSuperAdmin(): boolean {
    return !!this.role && this.role === RolesEnum.OPERATOR_SUPERADMIN;
  }

  set token(value: string) {
    this.setToken(value);
  }

  async me() {
    return this.fetch().then(() => {
      return this.toJSON();
    });
  }

  urlRoot() {
    return '';
  }

  url() {
    if (this.superadmin) {
      return `${globalConfig.url}/${globalConfig.path}/admin/me`;
    }
    return `/admins/me`;
  }

  fullUrl() {
    return `${globalConfig.url}/${globalConfig.path}`;
  }

  permissionsUrl() {
    return '/admins/me/permissions';
  }

  setLanguage(languageId: any) {
    return axiosInstance.post(`/admins/language/${languageId}`).then((response) => response.data);
  }

  login(username: string, password: string, rememberMe = false, superadmin: boolean = false) {
    const loginUrl = superadmin ? this.fullUrl() + '/admin/login' : '/admins/login';

    return axiosInstance.post(loginUrl, {
      password,
      username,
    })
    .then((response) => {
      this.needsOtpVerification = response.data.otpVerificationRequired;
      if(!this.needsOtpVerification)
      {
          this.isOTPVerified = true;
          this.token = response.data.accessToken;
          this.superadmin = response.data.isSuperAdmin;
          if (rememberMe) {
            localStorage.setItem(this.tokenName, this.token);
          } else {
            localStorage.removeItem(this.tokenName);
          }
          return response;
      }
      this.otpVerificationToken = response.data.otpToken;
      if(this.otpVerificationToken)
      {
        localStorage.setItem('otpVerificationToken', this.otpVerificationToken);
      }
      return response;
    }).catch((error) => {
      return Promise.reject(error.response);
    });
  }

  otpVerify(otpCode: string, otpToken: string, rememberMe = false, superadmin: boolean = false) {
    const otpVerifyUrl = superadmin ? this.fullUrl() + '/admin/otp-verify' : '/admins/otp-verify';

    return axiosInstance.post(otpVerifyUrl, {
      otpCode,
      otpToken,
    })
    .then((response : any) => {
      this.token = response.data.accessToken;
      this.superadmin = response.data.isSuperAdmin;
      if (rememberMe) {
        localStorage.setItem(this.tokenName, this.token);
      } else {
        localStorage.removeItem(this.tokenName);
      }
      return response;
    }).catch((error) => {
      return Promise.reject(error.response);
    });
  }

  resendOtp(otpToken: string, rememberMe = false, superadmin: boolean = false) {
    const otpVerifyUrl = superadmin ? this.fullUrl() + '/admin/resend-otp' : '/admins/resend-otp';

    return axiosInstance.post(otpVerifyUrl, {
      otpToken,
    })
    .then((response : any) => {
      this.token = response.data.accessToken;
      this.superadmin = response.data.isSuperAdmin;
      if (rememberMe) {
        localStorage.setItem(this.tokenName, this.token);
      } else {
        localStorage.removeItem(this.tokenName);
      }
      return response;
    }).catch((error) => {
      return Promise.reject(error.response);
    });
  }

  logout() {
    if (!this.isLoggedIn) {
      return Promise.resolve();
    }

    return axiosInstance.post('/admins/logout')
      .catch((e) => {
        console.error(e);
      })
      .then(() => {
        this.removeToken();
        bus().$emit('after-logout');
      });
  }

  requestToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const sendMsg: ITokenMessage = {tokenName: this.tokenName, type: 'request'};
      bc.postMessage(sendMsg);

      const responseListener = (ev: MessageEvent) => {
        const msg: ITokenMessage = ev.data;
        if (msg.tokenName !== this.tokenName || msg.type !== 'set') {
          return;
        }
        clearTimeout(this.tokenRequestTimeout!);
        bc.removeEventListener('message', responseListener);
        resolve(msg.token || '');
      };
      bc.addEventListener('message', responseListener);

      this.tokenRequestTimeout = setTimeout(() => {
        bc.removeEventListener('message', responseListener);
        reject('Token request timed out.');
        console.warn('Token request timed out.');
      }, 200);
    });
  }

  removeToken() {
    this.token = '';
    localStorage.removeItem(this.tokenName);
    sessionStorage.removeItem(this.tokenName);
    delete axiosInstance.defaults.headers.common.Authorization;
    this.id = 0;
    this.isOTPVerified = false;
    this.needsOtpVerification = false;
    this.otpVerificationToken = null;
  }

  private setToken(value: string) {
    if (value === this.pToken) {
      return;
    }
    this.pToken = value;
    if (value) {
      console.log('Setting token to', value);
      sessionStorage.setItem(this.tokenName, value);
      axiosInstance.defaults.headers.common.Authorization = 'Bearer ' + value;
    } else {
      console.log('Removing token');
      this.removeToken();
    }
    this.sendToken();
  }

  private sendToken() {
    const sendMsg: ITokenMessage = {tokenName: this.tokenName, type: 'set', token: this.token};
    bc.postMessage(sendMsg);
    if (this.tokenRequestTimeout) {
      clearTimeout(this.tokenRequestTimeout);
      this.tokenRequestTimeout = null;
    }
  }

  private setupMessageListener() {
    bc.onmessage = (ev: MessageEvent) => {
      const msg: ITokenMessage = ev.data;
      if (ev.data.tokenName !== this.tokenName) {
        return;
      }
      if (msg.type === 'set') {
        const previousToken = this.token;
        if (previousToken === msg.token) {
          return;
        }
        if (!msg.token) {
          this.removeToken();
        } else {
          this.token = msg.token;
        }
        if (previousToken !== msg.token) {
          bus().$emit('token-changed');
        }
      } else if (msg.type === 'request') {
        this.sendToken();
      }
    };
  }

  reset() {
    this.superadmin = false;
    this.role = undefined;
    this.roles = undefined;
    this.permissions = [];
  }
  forgotPassword(email: string) {
    const forgotPassword = '/admins/forgot-password';
    return axiosInstance.post(forgotPassword, {
      email,
    })
    .then((response) => response.data).catch((e) => {
      return Promise.reject(e);
    });
  }
  resetPassword(data: object) {
    const resetPassword = '/admins/reset-password';
    return axiosInstance.post(resetPassword, data)
    .then((response) => response.data).catch((e) => {
      return Promise.reject(e);
    });
  }
}
