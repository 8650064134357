
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import stores from '../../stores';
import routerBase from '@/helpers/routerBase';
import globalConfig from '@/helpers/globalConfig';
import { bus } from '@/helpers/functions';
import TheAdmin from '@/stores/TheLogedInAdmin';
import { showToaster } from '@/components/DashboardComposer/helpers';

@Component
export default class TwoFactorAuthentication extends Vue {
  otpCode: string = '';
  otpToken: string = '';
  rememberMe: boolean = false;
  session = stores.session;
  inDevelopment = globalConfig.env === 'development';
  redirectPath: string | null = null;
  errorMessage: string | null = null;
  resendOTPCodeButtonEnabled: boolean = true;
  otpVerifyButtonEnabled: boolean = true;
  admin = stores.admin;
  image = require('@/assets/images/profile-img.png');

  @Watch('admin.isLoggedIn', { immediate: true })
  adminWatch() {
    this.redirect();
  }

  redirect() {
    bus().$emit('logged', 'User logged');
    if (this.admin.isLoggedIn) {
      this.$router.replace(this.redirectPath || '/dashboard');
    }
  }


  mounted() {
    // Check if OTP Verification token exists
    if (stores.admin.otpVerificationToken) {
      this.otpToken = stores.admin.otpVerificationToken;
    }
    if (this.otpToken.length === 0) {
      this.otpToken = localStorage.getItem('otpVerificationToken') as string;
    }
    Vue.prototype.WebsiteTitle.setModuleName('2FA');
  }

  async onSubmit() {
    try {
      await stores.admin.otpVerify(this.otpCode, this.otpToken)
      stores.admin.isOTPVerified = true;
      stores.admin.otpVerificationToken = null;
      localStorage.removeItem('otpVerificationToken');
      bus().$emit('login');
      this.redirect();
    } catch (error) {
      console.error(error);
      this.errorMessage = error?.data?.message || 'An unexpected error occurred.';
    }
  }
  async resendOTPCode() {
    try {
      this.resendOTPCodeButtonEnabled = false
      const response = await stores.admin.resendOtp(this.otpToken)
      if(response.data?.otpToken)
      {
        stores.admin.otpVerificationToken = response.data.otpToken;
        this.otpToken = response.data.otpToken;
        localStorage.setItem('otpVerificationToken', response.data.otpToken);
      }
      this.errorMessage = null;
      showToaster("success","", "2FA code was sent to your email address")
      this.resendOTPCodeButtonEnabled = true;
    } catch (error) {
      console.error(error);
      this.resendOTPCodeButtonEnabled = true;
      if(error.status === 401)
      {
        showToaster("danger","", error?.data?.message || 'An unexpected error occurred.')
        this.$router.replace('/login');
      }
      this.errorMessage = error?.data?.message || 'An unexpected error occurred.';
    }
  }
  goToLoginPage(){
    stores.admin.isOTPVerified = false;
    stores.admin.otpVerificationToken = null;
    stores.admin.needsOtpVerification = true;
    localStorage.removeItem('otpVerificationToken');
    this.$router.replace('login');
  }

  defaultLogo(event?: any) {
    if (!event) {
      return require('../../../assets/nevron_logo.png');
    }
    event.target.src = require('../../../assets/nevron_logo.png');
  }

}
