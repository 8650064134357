
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SweetModal, SweetButton } from 'sweet-modal-vue';
import stores from "@/stores";
import SurveyQuestionExport from "./QuestionExport.vue";
import { exportAsPdf, translate } from '@/helpers/functions';


@Component({
  components: {
    SweetModal,
    SurveyQuestionExport,
  },
})

export default class SurveyQuestionsExport extends Vue {

  survey : ISurvey =  {
    id: 0,
    name: '',
    active: true,
    status: 'ACTIVE',
    deliveryChannels: [] ,
    questions: [],
    customers: [],
    deviceGroups: [],
    scheduleType: '',
    fromDisplayDateTime: '',
  };

  loading = false;
  companyName = '';
  icons: any =
    {
      TV: '../../../../../img/tv-b.svg',
      MOBILE: '../../../../../img/mobile-b.svg',
      EMOTICON: '../../../../../img/my_mood-b.svg',
      CLOSE_ENDED: '../../../../../img/survey-b.svg',
    }

  async mounted() {
    this.loading = true;
    const result = await stores.Surveys.fetchData()
    const surveys = result.data
    surveys.forEach( (survey : ISurvey) => {
      // @ts-ignore
      if(survey.id == this.$route.params.id)
      {
        this.survey = survey
      }
    });
    await this.loadAnswers()
    this.loading = false;
    const companyNameSettings = await stores.Setting.getKeySetting('company_name');
    if(companyNameSettings && companyNameSettings.value)
    {
      this.companyName =  companyNameSettings.value;
    }

    // @ts-ignore
    this.$nextTick(function() {
      // @ts-ignore
      this.exportQuestionsAsPdf()
    })

  }
  async loadAnswers()
  {
      for (let index = 0; index < this.survey.questions.length; index++) {
        const element = this.survey.questions[index];
        const response = await stores.Surveys.getAnswers(element.id)
        this.survey.questions[index].answers = response.data;
      }
  }
  cancel() {
    // @ts-ignore
    this.$refs.question.close();
  }

  async exportQuestionsAsPdf() {
    // @ts-ignore
    const element = this.$refs.survey as HTMLElement;
    const rawPdf = await exportAsPdf(element);
    // Create a Blob from the raw PDF data
    const blob = new Blob([rawPdf], { type: 'application/pdf' });

    // Create a link element to download the PDF
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = translate(this.survey.name) + " " + translate("survey") + " " + translate("results");
    link.click();
    window.close();
  }

}
