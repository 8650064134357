
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';
import GlobalFunctions from '@/GlobalFunctions';
import { translate } from "@/helpers/functions";
import { BFormDatepicker } from "bootstrap-vue";
import NevronInput from "@/components/NevronInput.vue";
import CatalogueModule from '../../stores/modules/Catalogue';
import MultiSelect from 'vue-multiselect';
import AttachImage from "@/modules/Media/Attach.vue";
import moment from 'moment';

interface IAccessoryField {
  value: string;
}



@Component({
  methods: { translate },
  components: {
    AttachImage,
    NevronInput,
    SweetModal,
    BFormDatepicker,
    MultiSelect
  },
})

export default class CreateAccessory extends Vue {

  // Non-null when editing a playlist
  @Prop({default: null})
  item!: IAccessories | null;
  module!: CatalogueModule;
  // module!: IModule;
  isValidImage: boolean = false;

  accessories: any = {
    name: '',
    shortDescription: '',
    price: '0',
    countable: true,
    onlinePayments: false,
    active: true,
    commentsSection: true,
    dateAndTime: false,
    accessoryDate: '',
    accessoryTime: '',
    accessoryDateMandatory: false,
    accessoryTimeMandatory: false,
    moduleId: localStorage.getItem('moduleId'),
    categories: [],
    imageId: null,
    image: null,
    datesAndTimes: {}
  };

  dateSelected = true;
  timeSelected = true;
  nameRules = {};
  globalFunctions = new GlobalFunctions();
  dateInputs: IAccessoryField[] = [{value: this.currentDate()}];
  timeInputs: IAccessoryField[] = [{value: '11:00'}];
  selectedDate : string = '';
  selectedTime : string = '';
  allCategories: ICategories[] = [];

  // Watch item and update accessory and dateInputs
  @Watch('item')
  onItemChanged() {
    if (this.item?.datesAndTimes && Object.keys(this.item.datesAndTimes).length == 0) {
      this.item.datesAndTimes = {}
    }
    this.accessories = { ...this.item };
    this.accessories.accessoryCategories?.forEach((categoryItem: ICategories) => {
      if (Vue.prototype.translate(categoryItem.name).trim() !== '') {
        categoryItem.name = Vue.prototype.translate(categoryItem.name);
      }
    });
  }

  getCategories() {
    const module = stores.modules.get(this.$route.params.moduleId) as CatalogueModule;
    module.getAllCategories().then((response: any) => {
      this.allCategories = response.data;
      this.allCategories.forEach((category: ICategories) =>
        category.name = Vue.prototype.translate(category.name));
    })

  }

  clearTime() {
    this.selectedTime = ''
  }


  validateForm(formRef: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.$forceUpdate();
      this.$nextTick(() => {
        const form = this.$refs[formRef] as HTMLFormElement;
        form.classList.add('was-validated');
        resolve(form.checkValidity());
      });
    });
  }

  async onSubmit() {
    const isValid = await this.validateForm('createPageAccessory');
    if (!isValid) {
      console.log('Form validation failed.');
      return;
    }


    let promise = this.isEditMode
      ? stores.Accessories.updateItem(this.accessories.id, this.accessories)
      : stores.Accessories.createNewItem(this.accessories);

    promise
      .then((response) => {
        // Clear form fields
        this.accessories = {
          name: '',
          shortDescription: '',
          price: '0',
          countable: true,
          onlinePayments: false,
          active: true,
          commentsSection: true,
          dateAndTime: false,
          accessoryDateAndTime: [],
          accessoryTimeSlot: [],
          accessoryDateMandatory: false,
          accessoryTimeMandatory: false,
          moduleId: localStorage.getItem('moduleId'),
          accessoryCategories: [],
          imageId: null,
          image: null,
        };

        const form = this.$refs.createPageAccessory as HTMLFormElement;
        form.classList.remove('was-validated');

        // Close the modal
        this.cancel();

        let eventToEmit = this.isEditMode ? 'updated' : 'created';
        this.$emit(eventToEmit, response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }
  cancel() {
    this.$emit('cancel', this.accessories.accessoryCategories);
    this.accessories.datesAndTimes = {};
    this.selectedDate = "";
    // @ts-ignore
    this.$children[0].close();
  }

  addDate() {
    // @ts-ignore
    this.$refs.dateModal.open();
  }
  addTime(date: string) {
    this.selectedDate = date
    // @ts-ignore
    this.$refs.timeModal.open();
  }

  addTimePin() {
    // @ts-ignore
    this.$refs.timePinModal.open();
  }

  currentDate(formatDate: boolean = true) {

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const day = currentDate.getDate();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;


    return formatDate ? `${formattedDay}. ${formattedMonth}. ${year}` : `${year}-${month}-${day}`;
  }

  closeDateModal() {
    this.selectedDate = '';
    this.selectedTime = '';
    // @ts-ignore
    this.$refs.dateModal.close();
  }

  submitDateModal() {
    if(this.selectedDate=="")
    {
      // @ts-ignore
      this.$refs.dateModal.close();
      return;
    }

    if (!(this.selectedDate in this.accessories.datesAndTimes)) {
      Vue.set(this.accessories.datesAndTimes, this.selectedDate, [])
    }
    this.selectedDate = '';
    this.selectedTime = '';

    // @ts-ignore
    this.$refs.dateModal.close();
  }

  closeTimeModal() {
    // @ts-ignore
    this.$refs.timeModal.close();
  }

  closeTimePinModal() {
    // @ts-ignore
    this.$refs.timePinModal.close();
  }

  submitTimeModal() {
    if(this.selectedTime=="")
    {
      // @ts-ignore
      this.$refs.timeModal.close();
      return;
    }
    if (!(this.selectedDate in this.accessories.datesAndTimes)) {
      // @ts-ignore
      Vue.set(this.datesAndTimes, this.selectedDate, [])
    }

    const timeExists = this.accessories.datesAndTimes[this.selectedDate]?.find(
      (timeField : any) => timeField.time === this.selectedTime
    );

    if (!timeExists) {
      this.accessories.datesAndTimes[this.selectedDate].push({
        time: this.selectedTime,
        active: true, // Set the 'active' property as needed
        display: true
      });
    }


    this.selectedDate = '';
    this.selectedTime = '';
    // @ts-ignore
    this.$refs.timeModal.close();
  }

  selectDate(date: any) {
    this.selectedDate = date;
  }

  toggleTimeSlotStatus(date: string, time: string) {
    // Ensure that the date exists in datesAndTimes
    if (!this.accessories.datesAndTimes[date]) {
      return; // Date not found, exit function
    }

    // Find the index of the time slot with the specified time
    const index = this.accessories.datesAndTimes[date].findIndex((slot: any) => slot.time === time);
    // If the time slot exists, toggle its 'active' status
    if (index !== -1) {
      // Create a new object with the updated 'active' property
      const updatedSlot = Object.assign({}, this.accessories.datesAndTimes[date][index], {
        active: !this.accessories.datesAndTimes[date][index].active
      });
      // Update the time slot in the array
      Vue.set(this.accessories.datesAndTimes[date], index, updatedSlot);
    }
  }
  get isEditMode() {
    return !!(this.item && this.item.id);
  }

  attachMediaItem(result: any) {

    const mediaObject = result.result;
    this.isValidImage = !!(mediaObject && mediaObject.type && mediaObject.type.startsWith('image/'));

    if (mediaObject) {
      this.accessories.imageId = mediaObject.id;
      this.accessories.image = mediaObject;
    }
  }

  openMediaLibrary() {
    // @ts-ignore
    this.$refs.mediaLibrary.$children[0].open();
  }

  formatTime(time: any) {
    return time ? moment(time, 'HH:mm:ss').format('HH:mm') : '';
  }

  removeDate(date: any)
  {
    Vue.delete(this.accessories.datesAndTimes, date);
  }

  removeTime(date: any, time: any)
  {
    const indexToRemove = this.accessories.datesAndTimes[date].findIndex((item: any) => {
      return item.time == time.time
    } );
    if (indexToRemove !== -1) {
        this.accessories.datesAndTimes[date].splice(indexToRemove, 1);
    }
  }
  dateFormat(date: any)
  {
    if (date.includes('-')) {
      return date; 
    } else {
      return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    }
  }
  
}
