
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';
import stores from "@/stores";
import {showToaster} from "@/components/DashboardComposer/helpers";
import {ITranslation} from "@/stores/Translation";

@Component({
  components: {
    SweetModal,
  },
  mixins: [Clickaway],
})

export default class AddKey extends Vue {
  @Prop({default: null})
  language!: ILanguage;
  @Prop()
  phrase: any;
  @Prop({default: null})
  translation!: ITranslation;
  @Prop({default: 0})
  index!: number;

  reference: ITranslation = {
    key: '',
    languageId: null,
    value: '',
    id: null,
    static: true,
  };
  localLanguage: any = null;
  message: string = '';

  @Watch('index', {immediate: true})
  loadTranslation() {
    this.reference.value = '';
    this.message = '';
    this.reference.value = this.phrase?.value;
    if (this.translation !== null && this.language !== null && this.phrase?.value == null) {
      const data = {
        'text': this.translation,
        'language': this.language.shortCode
      };

      stores.Language.deepLTranslate(data).then((response: any) => {
        if (response !== 'error') {
          this.reference.value = response;
          this.message = 'Warning! The translation is autogenerated. Review it carefully before continuing!';
        } else {
          this.reference.value = '';
        }
      });
    }
  }

  @Watch('phrase', {immediate: true})
  setLocalPhrase() {
    if (this.phrase) {
      this.reference = JSON.parse(JSON.stringify(this.phrase));
    } else {
      this.reference = {
        key: '',
        languageId: null,
        value: '',
        id: null,
        static: true,
      };
    }
  }

  @Watch('language', {immediate: true})
  setLocalLanguage() {
    this.localLanguage = JSON.parse(JSON.stringify(this.language));
    if (this.language) {
      this.reference.languageId = this.localLanguage.id;
    }
  }

  updateKey() {    
    if (!this.localLanguage || (this.localLanguage && this.localLanguage.localCode === 'en_US')) {
      this.reference.key = this.reference.value ? this.reference.value.toLowerCase().replace(/\s+/g, '-') : '';      
    }
  }

  save() {
    stores.Language.createTranslation(this.reference)
      .then((response: any) => {
        if (response && response.key) {
          this.$router.push({name: 'translation.show', params: {key: response.key}});
          this.$emit('save', this.reference);
        }
      });
    this.cancel();
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.message = '';
  }
}
