
import { Vue,Component ,Prop,Emit,Watch } from 'vue-property-decorator';

@Component
export default class ChatTab extends Vue{
    @Prop({default: false}) tabTitle!: any;
    @Prop({default: false}) activeTab!: string 
    
    get currentActiveTab(): string{
        return this.activeTab == this.tabTitle.toLowerCase() ? 'nav-link active' : 'nav-link'
    }
    
    @Emit('tabClicked')
    tabClicked() {
            return this.tabTitle
    }
};
