
import { Component, Vue, Prop,Emit,Watch } from 'vue-property-decorator';

@Component({
})
export default class FilterCheckbox extends Vue {
    @Prop({default:false}) filterOption!: string;
    @Prop({default:false}) filterTitle!: string;
    isCheckboxchecked: boolean| any = false;
    @Prop({default:false}) appliedFilters!: any;
    @Prop({default:false}) isClearFilters: boolean |any;

    mounted() {
    }

    inputNameForFilter(filtername : string){
        return 'filter["'+this.filterTitle.replace(/ /g, "_").toLowerCase()+'"]["'+filtername+'"]'
    }

    inputIdForFilter(){
        const id =  `filter-${this.filterTitle}-${this.filterOption}`;
        return id.replace(/ /g, '-').replace(/[^a-zA-Z0-9-]/g, '');
    }

    @Emit('updateFilters')
    updateFilterCheckbox(filterOption:string){
        // handle checkbox and fire the event.
        this.isCheckboxchecked = !this.isCheckboxchecked;
        return {
          isActive: this.isCheckboxchecked,
          type: 'checkbox',
          filterOption: filterOption
        };
    }

    @Watch('isClearFilters')
    clearFilters(){
        this.isCheckboxchecked = false;
    }

    get formattedfilterOption(){
        return this.filterOption.replace(/_/g, ' ').toLowerCase();
    }

}
