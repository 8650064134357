
import { translate } from '@/helpers/functions';
import stores from '@/stores';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
})
export default class StatisticsList extends Vue {
    @Prop({ default: true }) topStatEntity: IStatEntity | any;
    @Prop({ default: true }) statEntitiesList: IStatEntity[] | any;
    @Prop({ default: true }) displayDetails: string | any;
    @Prop({ default: false }) limitString: number | any;
    selectedPeriod: string | any = '';
    dropdownOpen: boolean = false;

    mounted() {
        this.displayDetails.intervalOptions.forEach((option: any) => {
            if (option.selected) {
                this.selectedPeriod = option.title;
            }
        });
    }

    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    @Emit('update-period')
    selectPeriod(intervalOption: any) {
        this.selectedPeriod = intervalOption.title;
        this.dropdownOpen = false;
        return intervalOption;
    }

    get statTitle(){
        return this.displayDetails.title;
    }

    statEntityName(statEntityName: any){
        if(typeof statEntityName === 'string' && this.limitString){
            if(statEntityName.length > this.limitString){
                return statEntityName.substring(0,this.limitString) + '...'
            }
            return statEntityName;
        }
        return statEntityName
    }
}
