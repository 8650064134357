
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import Countries from '@/modules/Countries.vue';

@Component({
  components: {
    SweetModal,
    Countries,
  },
  mixins: [ Clickaway ],
})
export default class EditCustomerAddress extends Vue {
  @Prop()
    loc!: any;

  newAddress: ICustomerAddress = JSON.parse(JSON.stringify(this.loc));

  @Watch('loc', {immediate: true})
    setModuleFromProps() {
      if (this.loc) { // default address exists
        this.newAddress = JSON.parse(JSON.stringify(this.loc));
      } else { // default address does not exist
        this.reset();
      }
  }

  countrySelected(countrty: string) {
    this.newAddress.country = countrty;
  }

  removeAddress() {
    if (confirm('Do you really want to remove this address?')) {
      this.$emit('delete', this.newAddress.id);
      this.reset();
      // @ts-ignore
      this.$children[0].close();
    }
  }

  save() {
    if (this.newAddress.address.length > 0) {
      if (this.newAddress.id) {
        this.$emit('edit', this.newAddress);
      } else {
        this.$emit('create', this.newAddress);
      }

    }
    // @ts-ignore
    this.$children[0].close();
  }
  cancel(): void {
    if (!this.loc) {
      this.reset();
    } else {
      this.newAddress = JSON.parse(JSON.stringify(this.loc));
    }
    // @ts-ignore
    this.$children[0].close();
  }
  reset() {
    this.newAddress = {
      address: '',
      address2: '',
      city: '',
      country: '',
      id: null,
      customerId: null,
      post: '',
      default: 0,
      firstName: '',
      lastName: '',
      company: '',
    };
  }
}
