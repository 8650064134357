
export const imageExtensions = [
  'jpg', 'jpeg', 'png', 'gif', 'eps', 'bmp', 'tiff', 'tif', 'svg', 'raw',
];

export const videoExtensions = [
  'mp4', 'mov', 'avi', 'wmv', 'webm', 'mkv', 'mpeg-4', 'ogg',
];

export function isVideo(extension: string) {
  return videoExtensions.includes(extension);
}

export function isImage(extension: string) {
  return imageExtensions.includes(extension);
}
