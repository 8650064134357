
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import vPagination from '@/components/VuePlainPagination.vue';
import StreamModule from '@/stores/modules/Streams';
import Axios from 'axios';
import NevronHeader from '@/components/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import NevronSearch from '@/components/NevronSearch.vue';

@Component({
  components: {
    vPagination,
    NevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
  },
})
export default class TranscoderList extends Vue {
  @Prop()
  module!: StreamModule;

  streams: ITranscoderStream[] = [];
  receivedData: boolean = false;
  fullResponse: any = null;
  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  initial: boolean = false;
  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;

  @Watch('fullResponse.currentPage', { immediate: true })
  ChangedPage() {
    if (this.fullResponse) {
      this.getStreamsOnPage(this.fullResponse.currentPage);
    }
  }

  unknownClicked() {
    return null;
  }

  checkAll() {
    if (!this.allChecked) {
      this.indexList = [];
      for (let index = 0; index < this.streams.length; index++) {
        this.indexList.push(index);
      }
      this.allChecked = true;
    } else {
      this.indexList = [];
      this.allChecked = false;
    }
  }

  updateCheck() {
      if (this.indexList.length === this.streams.length) {
         this.allChecked = true;
      } else {
         this.allChecked = false;
      }
  }

  getAllStreams() {
    return this.module.getStreams()
      .then((response) => {
        this.fullResponse = response;
        this.streams = this.fullResponse.data;
        this.receivedData = true;
        this.initial = true;
      })
      .catch((error) => {
        console.log(error);
        this.receivedData = true;
      });
  }

  getStreamsOnPage(index: number) {
    return this.module.getStreamsOnPage(index)
      .then((response) => {
        this.fullResponse = response;
        this.streams = this.fullResponse.data;
        if (this.initial) {
          this.$router.push({query: {page: String(this.fullResponse.currentPage)}});
        }
        this.initial = true;
        this.receivedData = true;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    if (this.$route.query.page) {
      this.getStreamsOnPage(Number(this.$route.query.page));
    } else {
      this.getAllStreams();
    }
  }

  refresh() {
    if (this.$route.query.page) {
      this.getStreamsOnPage(Number(this.$route.query.page));
    } else {
      this.getAllStreams();
    }
  }

  search() {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();
    if (!this.searchInProgress) {
      this.searchInProgress = true;
      setTimeout(() => {
        return this.module.search(this.searchQuery, this.fullResponse.currentPage, this.source.token)
          .then((response) => {
            // console.log(this.searchQuery);
            this.fullResponse = response;
            this.streams = this.fullResponse.data;
            if (this.searchQuery !== '') {
              this.$router.push({query: {query: this.searchQuery, page: String(this.fullResponse.currentPage)}});
            } else {
              this.$router.push({query: {page: String(this.fullResponse.currentPage)}});
            }
            this.searchInProgress = false;
          })
          .catch((e) => {
            console.log(e);
          });
      }, 500);
    } else {
      this.source.cancel();
      // console.error('Cancle');
      // this.searchInProgress = false;
    }
  }
}
