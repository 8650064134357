
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import {SweetModal} from 'sweet-modal-vue';
import {translate} from "../../helpers/functions";

@Component({
  methods: {translate},
  components: {
    NevronHeader,
    SweetModal,
  },
})

export default class CreateUser extends Vue {

  @Prop({default: false})
  emitCreated!: boolean;
  // @ts-ignore
  user: IUser = {
    id: null,
    erpId: '',
    name: '',
    dashboardId: '',
    mobileDashboardId: '',
    pin: '',
    adult: false,
    pinRequired: false,
    active: true,
    username: '',
    email: null,
    password: '',
    passwordConfirmation: '',
    accounts: [],
    customer: null,
    dashboard: null,
    mobileDashboard: null,
    pmsId: null,
  };

  errors = {
    usernameTaken: false,
    passwordMatch: false,
    passwordLength: false,
    emailTaken: false,
  };

  get form() {
    return document.getElementById('create-form-user')! as HTMLFormElement;
  }

  mounted() {

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    // Loop over them and prevent submission
    const validation = this.form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      const htmlValidated = this.form.checkValidity();
      const customValidated = this.confirmPassword();
      if (htmlValidated && customValidated) {
        event.stopPropagation();
        this.onSubmit();
      }
      if (this.form.checkValidity() === false) {
        event.stopPropagation();
      }
      this.form.classList.add('was-validated');
    }, false);
  }

  confirmPassword(): boolean {
    this.errors.passwordMatch = (!!this.user.passwordConfirmation && this.user.passwordConfirmation !== this.user.password);
    return !(this.errors.passwordMatch);
  }

  onSubmit() {
    return stores.User.createNewItem(this.user)
      .then((response) => {
        console.log('response', response);
        if (response.success === true) {
          // @ts-ignore
          this.$children[0].close();
          if (this.emitCreated) {

            this.$emit('created', response.data);
            return;
          }
          this.$router.push({name: 'users.show', params: {id: response.data.id}});

        } else {
          for (const error in response.errors) {
            if (error) {
              if (error === 'email') {
                this.errors.emailTaken = true;
              }
              if (error === 'username') {
                this.errors.usernameTaken = true;
              }
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
}
