import { render, staticRenderFns } from "./StatisticsList.vue?vue&type=template&id=23a027eb&scoped=true&"
import script from "./StatisticsList.vue?vue&type=script&lang=ts&"
export * from "./StatisticsList.vue?vue&type=script&lang=ts&"
import style0 from "./StatisticsList.vue?vue&type=style&index=0&id=23a027eb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a027eb",
  null
  
)

export default component.exports